import React from 'react';
import PropTypes from 'prop-types';

const DefaultUser = ({ width, height, fill }) => {
  const randomMilliSecond = new Date().getMilliseconds().toString();
  return (
    <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width={width} height={height} viewBox='0 0 34 34'>
      <defs>
        <circle id={randomMilliSecond + '-default-user-1'} cx='17' cy='17' r='17' />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id={randomMilliSecond + '-default-user-2'} fill='#fff'><use xlinkHref={'#' + randomMilliSecond + '-default-user-1'} />
        </mask>
        <use fill={fill} xlinkHref={'#' + randomMilliSecond + '-default-user-1'} />
        <path fill='#FFF' d='M21.602 20.99a8.962 8.962 0 0 1 4.061 7.509v8.97H7.723V28.5a8.962 8.962 0 0 1 4.06-7.508 6.446 6.446 0 0 0 4.91 2.263 6.446 6.446 0 0 0 4.91-2.263zm-4.684.392c-3.422 0-6.196-3.064-6.196-6.844 0-3.78 2.774-6.845 6.196-6.845 3.422 0 6.196 3.064 6.196 6.845 0 3.78-2.774 6.844-6.196 6.844z' mask={'url(#' + randomMilliSecond + '-default-user-2)'} />
      </g>
    </svg>
  );
};

DefaultUser.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
};

DefaultUser.defaultProps = {
  width: '34',
  height: '34',
  fill: '#D8D8D8'
};

export default DefaultUser;
