import React, { useState, useEffect } from 'react';
import get from 'lodash-es/get';
import classnames from 'classnames';

import { Dialog } from '@quintype/em/components/dialog';
import { Button } from '@quintype/em/components/button';
import { Loader } from '@quintype/em/components/loader';
import Subscription from '../subscription/subscription';

import { getSubscriptionGroups, getSubscriberSubscriptions } from '../../api';

import './subscription-details.scss';

const SubscriptionDetails = (props) => {
  const [groups, setGroups] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [notification, setNotification] = useState({ show: false, type: '', message: '' });

  const selectedSubscription = props.selectedSubscription;
  const provider = get(selectedSubscription, ['preferred_identity', 'provider']);
  const identity = get(selectedSubscription, ['preferred_identity', 'value']);

  const loadSubscriptionData = () => {
    getSubscriberSubscriptions(provider, identity).then(subscriptions => {
      const subscriptionDetails = subscriptions.filter(subscription => subscription.id === selectedSubscription.id);
      setSubscription(subscriptionDetails[0]);
    });
  };

  const updateSubscription = (subscription) => {
    setSubscription(subscription);
    setIsUpdated(true);
  };

  const updateNotification = (show, type, message) => {
    setNotification({ show, type, message });
  };

  useEffect(() => {
    getSubscriptionGroups('all').then(allGroups => {
      const groups = allGroups.filter(group => !(['group_access', 'dynamic_asset'].includes(group.subscription_type)));
      setGroups(groups);
    });
    getSubscriberSubscriptions(provider, identity).then(subscriptions => {
      const subscriptionDetails = subscriptions.filter(subscription => subscription.id === selectedSubscription.id);
      setSubscription(subscriptionDetails[0]);
    });
  }, [identity, provider, selectedSubscription.id]);

  const notificationClass = classnames('subscription-dialog__notification', `subscription-dialog__notification-${notification.type}`);

  return (
    <Dialog isOpen onClose={props.onClose}>
      <div className='subscription-dialog'>
        <h2 className='subscription-dialog__title'>Subscription</h2>
        {subscription ? <React.Fragment>
          <Subscription
            allSubscriptionGroups={groups}
            identity={identity}
            provider={provider}
            subscription={subscription}
            key={props.selectedSubscription.id}
            isOnSaveSuccessful={isUpdated}
            setNotification={(show, type, message) => updateNotification(show, type, message)}
            updateSubscription={(subscription) => updateSubscription(subscription)}
            loadSubscriptionData={() => loadSubscriptionData()}
          />
          {notification.show && <p className={notificationClass}>{notification.message}</p>}
          <Button type='primary'><a href={props.subscriberLink}>Subscriber Details</a></Button>
        </React.Fragment> : <Loader message="Loading Details"/>}
      </div>
    </Dialog>
  );
};

export default SubscriptionDetails;
