import React from 'react';
import {
  DragSource,
  DropTarget
} from 'react-dnd';
import Collapsible from '../collapsible-sub-group-listing';
import { flow } from 'lodash-es';

const ITEM_TYPE = 'groupData';

const dragSourceCollector = (connect, monitor, props) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
};

const dropTargetCollector = (connect, monitor, props) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
};

const dragSourceSpec = {
  beginDrag: (props) => {
    return { groupData: props.groupData };
  },
  isDragging: (props, monitor) => {
    return props.groupData === monitor.getItem().groupData;
  },
  endDrag: (props) => {
    props.onDragEnd();
  },
  canDrag: (props) => {
    return props.canDrag;
  }
};

const dropTargetSpec = {
  hover: (props, monitor, component) => {
    var target = props.groupData;
    var src = monitor.getItem().groupData;

    if (src !== target) {
      props.onDragOver(src, target);
    }
  }
};

const OrderedCollapsible = (props) => {
  const { connectDragSource, connectDropTarget, ...rest } = props;

  return connectDragSource(
    connectDropTarget(
      <div data-test-id="collapsible-card">
        <Collapsible {...rest} />
      </div>
    )
  );
};

export default flow(
  DragSource(ITEM_TYPE, dragSourceSpec, dragSourceCollector),
  DropTarget(ITEM_TYPE, dropTargetSpec, dropTargetCollector)
)(OrderedCollapsible);
