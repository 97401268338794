import React, {
  useRef,
  createContext,
  useContext,
  useState,
  useMemo
} from 'react';
import classnames from 'classnames';
import './accordion.css';

import { useAccordionState } from './use-accordion-state';

export const genId = () => '_' + Math.random().toString(36).substr(2, 9);

const AccordionContext = createContext({
  focusRef: { current: null },
  selected: [null],
  expandedAll: [],
  onToggle: (id) => null,
  id: ''
});

export const useAccordionContext = () => useContext(AccordionContext);

const Accordion = ({
  children,
  expanded: controlledExpanded = null,
  onToggle: controlledOnToggle,
  hasSingleActivePanel,
  id,
  showBottomBorder = true
}) => {
  const isControlledRef = useRef(controlledExpanded != null);

  const uncontrolled = useAccordionState([], hasSingleActivePanel);
  const expanded = isControlledRef.current
    ? controlledExpanded
    : uncontrolled.expanded;
  const onToggle = isControlledRef.current
    ? controlledOnToggle
    : uncontrolled.onToggle;

  const uid = id || genId();
  const focusRef = useRef(null);
  const [selected] = useState([null]);

  const context = useMemo(
    () => ({
      focusRef,
      selected,
      expandedAll: expanded,
      onToggle,
      id: uid
    }),
    [selected, expanded, onToggle, children, uid]
  );

  const accordionClasses = classnames('accordion__container', {
    'hide-bottom-border': !showBottomBorder
  });
  return (
    <div id={uid} className={accordionClasses}>
      <AccordionContext.Provider value={context}>
        {React.Children.map(children, (child, index) => {
          if (!React.isValidElement(child)) {
            return child;
          }

          return React.cloneElement(child, {
            ...child.props,
            index
          });
        })}
      </AccordionContext.Provider>
    </div>
  );
};

export default Accordion;
export { Accordion };
