import React, { useCallback, useEffect, useState } from 'react';

import { Snackbar } from '@quintype/em/components/snackbar';

import Preview from './preview';
import Loading from './loading';

import { putPromise } from '../../request';
import { getFileName, getImagePreviewUrl, handleEventBehaviour, sanitizeFileName } from './utils';
import { generatePresignedParams } from '../../api';

import './upload-image.scss';

const UploadImage = ({
  onFileUpload,
  savedImageUrl,
  id,
  imageFile,
  disabled,
  handleImageTrash = () => null,
  setActiveAttributeImageFile = (f) => null
}) => {
  const [file, setFile] = useState(imageFile);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [savedImagePreviewUrl, setSavedImagePreviewUrl] = useState(savedImageUrl);
  const [isLoading, setLoading] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState(null);

  useEffect(() => {
    if (savedImageUrl !== savedImagePreviewUrl) { setSavedImagePreviewUrl(savedImageUrl); }
  }, [savedImageUrl]);

  const handlePreview = useCallback(() => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  }, [file]);

  useEffect(() => {
    if (file instanceof File) handlePreview();
  }, [file, handlePreview]);

  const handleFileUpload = (file) => {
    setSnackbarConfig(null);
    setLoading(true);
    generatePresignedParams(sanitizeFileName(file.name))
      .then((response) => {
        const { presigned_url: url, s3_key: s3Key } = response;

        putPromise(url, file)
          .then(() => {
            setFile(file);
            setActiveAttributeImageFile(file);
            setLoading(false);
            console.log('File uploaded successfully to temp');
            onFileUpload(s3Key);
            return null;
          })
          .catch((error) => handleCatch(error));

        return null;
      })
      .catch((error) => handleCatch(error));
  };

  const handleCatch = (error) => {
    setLoading(false);
    setSnackbarConfig({
      message: 'Something Went Wrong',
      level: 'error',
      position: 'bc'
    });
    console.error(error);
  };

  const handleDrop = (e) => {
    handleEventBehaviour(e);
    const files = [...e.dataTransfer.files];

    if (files.length && validateFileType(files[0])) {
      handleFileUpload(files[0]);
    }
  };

  const handleFileInput = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (validateFileType(file)) {
      handleFileUpload(file);
    }
  };

  const validateFileType = (file) => {
    if (file.type.includes('image')) {
      return true;
    }

    setSnackbarConfig({
      message: 'Invalid File Format',
      level: 'error',
      position: 'bc'
    });

    return false;
  };

  const handleTrash = () => {
    // Need to handle deleting from permanent bucket once storing in db is implemented
    setSnackbarConfig(null);
    setFile(null);
    setImagePreviewUrl(null);
    setSavedImagePreviewUrl(null);
    handleImageTrash();
  };

  const containerHeading = () => {
    return id.toLowerCase().includes('optional') ? 'Image (Optional)' : 'Image';
  };

  if (isLoading) {
    return <Loading label={containerHeading()} />;
  }

  if ((imagePreviewUrl && file) || savedImagePreviewUrl) {
    return (
      <Preview
        label={containerHeading()}
        imagePreviewUrl={getImagePreviewUrl(savedImagePreviewUrl, imagePreviewUrl)}
        fileName={getFileName(savedImagePreviewUrl, file)}
        handleTrash={handleTrash}
        disabled={disabled}
      />
    );
  }

  return (
    <div className="upload-container">
      <div className="upload-heading">{containerHeading()}</div>
      <div className="file-upload-wrapper">
        <div onDragOver={handleEventBehaviour} onDrop={handleDrop}>
          <label htmlFor={`${id}-files`} className="file-upload-btn">
            + Upload File
          </label>
          <input
            id={`${id}-files`}
            type="file"
            className="file-upload-input"
            onChange={handleFileInput}
            disabled={disabled}
          ></input>
          <span className="static-text">or Drag and Drop here</span>
          {snackbarConfig && <Snackbar notification={snackbarConfig} />}
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
