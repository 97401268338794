import React from 'react';
import PropTypes from 'prop-types';

import './Switch.scss';

const switchConfig = (ev, callback) => callback(ev);

const Switch = ({
  label, name, value, onChange, labelClassName
}) => (<div className='toggle-container'>
  <label htmlFor={name} className='toggle-checkbox'>
    <div className={`${labelClassName || ''}`}>{label}</div>
    <input
      id={name}
      type='checkbox'
      name={name}
      onChange={ev => switchConfig(ev, onChange)}
      value={value}
      checked={value}
    />
    <div className='slider' />
  </label>
</div>);

Switch.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

Switch.defaultProps = {
  label: 'Label',
  name: 'Default text',
  value: 'Default value'
};

export default Switch;
