import React from 'react';
import { ContextMenu, ContextMenuList, ContextMenuItem } from '@quintype/em/components/context-menu';

export default function SubscriptionListKebabMenu ({ onImportClick, onExportClick }) {
  return (
    <div style={{ float: 'right' }}>
      <ContextMenu position='bottomLeft'>
        <ContextMenuList>
          <ContextMenuItem
            label='Export Subscriptions'
            onClick={onExportClick}
          />
          <ContextMenuItem
            label='Import Subscriptions'
            onClick={onImportClick}
          />
        </ContextMenuList>
      </ContextMenu>
    </div>
  );
}
