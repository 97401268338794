import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash-es';
import dotProp from 'dot-prop-immutable';
import { nanoid } from 'nanoid';
import { Button as EmButton } from '@quintype/em/components/button';
import {
  Button,
  TitleBar,
  Card,
  ButtonGroup,
  SelectFieldWithLabelDatatest,
  SliderCheckbox,
  TextFieldWithLabel,
  TextAreaWithLabel,
  Layout,
  Link,
  Alert,
  StatusMessage,
  QuillEditorWithLabel
} from '../components/library-components';
import Assets from '../components/assets/assets';
import {
  getSubscriptionPlan,
  getPaymentGateways,
  createOrUpdateSubscriptionPlan,
  destroySubscriptionPlan,
  postCreateRemotePlan,
  getAssetCategoriesCors,
  getSubscriptionGroup,
  getSubscriptionGroups,
  postCreateVoucherifyProduct,
  updateVoucherifyProduct,
  connectRecurringPlan,
  updateRecurringPlan,
  getAcccountConfig
} from '../api';
import {
  SET_SUBSCRIPTION_PLAN,
  SET_ERRORS,
  UPDATE_SUBSCRIPTION_GROUPS
} from '../actions';
import occurrence from '../helpers/occurrence';
import currencyUtils, {
  ALL_CURRENCIES
} from '../helpers/currencyUtils';
import { ModifiedEmDialog } from '../components/modified-em-dialog/index';
import LinkPaymentGateway from '../components/link-gateway';

import validate from 'validate.js';
import '../../stylesheets/pages/create_update_subscription_plan.scss';

import Error from './Icons/error';
import RadioButton from '../components/library-components/RadioButton';
import UploadImage from '../components/upload-image';
import CustomAttributesCard from '../components/custom-attributes-card';
import SwitchPlansCard from '../components/switch-plans';
import ConfigurePlanFallbackCurrency from '../components/configure-plan-fallback-currency';
import PriceSelector from '../components/price-selector';
import { PAID_TRIAL_PAYMENT_GATEWAYS } from '../constants';

const LIFETIMEVALUE = 'lifetime';
const LIFETIMELABEL = 'lifetime (100 Years)';
const DURATION_UNITS_ONE_TIME = [
  'days',
  'weeks',
  'months',
  'years',
  LIFETIMELABEL
].map((k) => ({ label: k, value: k === LIFETIMELABEL ? LIFETIMEVALUE : k }));
const DURATION_UNITS_RECURRING = ['days', 'weeks', 'months', 'years'].map(
  (key) => ({ label: key, value: key })
);

const TRIAL_PERIOD_DURATION_UNITS = ['days', 'weeks', 'months', 'years'].map(
  (k) => ({ label: k, value: k })
);

const TRIAL_UNSUPPORTED_PG = ['adyen', 'omise'];

export function getOmiseDetails (enabledPGs) {
  return {
    onlyOmise: enabledPGs.length === 1 && enabledPGs[0] === 'omise',
    otherPgsEnabled: enabledPGs.length > 1,
    noOmise: !enabledPGs.includes('omise')
  };
}

validate.validators.priceValidation = (object, itemConstraints) => {
  const objKeys = Object.keys(object);
  const newObject = JSON.parse(JSON.stringify(object));

  for (const key of objKeys) {
    newObject[key].currency = key;
  }

  const errors = {};

  for (const key of objKeys) {
    const error = validate(newObject[key], itemConstraints);
    if (error) errors[key] = { error: error };
  }

  return Object.keys(errors).length === 0 ? null : { errors };
};

const getPricesConstraints = (message, greaterThanOrEqualTo, shouldValidatePaidTrialPrice = false) => {
  const priceConstraints = {
    currency: {
      presence: { allowEmpty: false },
      length: { is: 3, message: '^Choose a currency' }
    },
    amount_cents: {
      presence: true,
      numericality: {
        strict: true,
        lessThanOrEqualTo: 1000000000,
        greaterThanOrEqualTo: greaterThanOrEqualTo,
        message: message
      }
    }
  };

  if (shouldValidatePaidTrialPrice) {
    const paidTrialPriceConstraints = {
      presence: true,
      numericality: {
        strict: true,
        lessThanOrEqualTo: 1000000000,
        greaterThanOrEqualTo: greaterThanOrEqualTo,
        message: message
      }
    };
    return {
      priceValidation: { ...priceConstraints, paid_trial_amount_cents: paidTrialPriceConstraints }
    };
  }

  return { priceValidation: priceConstraints };
};

const constraintsPlan = {
  title: { presence: { allowEmpty: false } },
  description: { presence: { allowEmpty: false } },
  prices: getPricesConstraints('^Amount not within acceptable range')
};

export class CreateUpdateSubscriptionPlan extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      openAlertPopup: false,
      showNotification: {
        isNotificationOpen: false,
        status: '',
        statusMessage: ''
      },
      assetCategories: null,
      priceCents: '0',
      showDialog: false,
      dialogContext: '',
      voucherifyEnabled:
        _.get(window.qtConfig, 'voucherifyEnabled', false) === 'true',
      enabledPaymentProviders: [],
      accountFallbackCurrency: null, // global fallback currency
      selectedFallbackCurrency: null,
      showTrialUnsupportedPgErrorMessage: false,
      superUser:
        _.get(window.subConfig, 'currentAccountUserRole', '') === 'superuser',
      trial_period_type: null,
      richTextEnabled: false,
      descriptionText: ''
    };
    this.updatePriceWithPlanItems = this.updatePriceWithPlanItems.bind(this);
  }

  deleteSubscriptionPlan (e) {
    e.preventDefault();
    destroySubscriptionPlan(
      this.props.subscriptionGroupId,
      this.props.subscriptionPlanId
    ).then(
      (response) => {
        window.location = `/subscription_groups/${this.props.subscriptionGroupId}`;
      },
      (error) => {
        console.log(error, 'error');
      }
    );
    this.setState({ openAlertPopup: false });
  }

  openAlert () {
    this.setState({ openAlertPopup: true });
  }

  closeAlert () {
    this.setState({ openAlertPopup: false });
  }

  componentDidMount () {
    Promise.all([
      getSubscriptionPlan(
        this.props.subscriptionGroupId,
        this.props.subscriptionPlanId
      ),
      getAcccountConfig()
    ]).then(
      (data) => {
        const plan = data[0];
        const account = data[1].account;
        this.trialPeriodEnabled = plan.trial_period_enabled;
        const currencies = Object.keys(
          plan.prices || []
        );
        const isMultipleCurrency = currencies.length > 1;
        const selectedFallbackCurrency = plan.fallback_currency
          ? 'local'
          : isMultipleCurrency
            ? 'account'
            : null;
        this.setState({
          priceCents: currencyUtils.convertToCurrencyUnits(plan.price_cents),
          accountFallbackCurrency: account.fallback_currency,
          selectedFallbackCurrency,
          richTextEnabled: account.rich_text_enabled,
          descriptionText: plan.description
        });
        for (const key in plan.prices) {
          if (Object.hasOwnProperty.call(plan.prices, key)) {
            plan.prices[key].id = nanoid(10);
          }
        }
        this.props.setSubscriptionPlan(plan);
      },
      (errors) => {
        window.location = '/404.html';
      }
    );
    getAssetCategoriesCors().then((assetCategories) =>
      this.setState({ assetCategories: assetCategories })
    );
    getSubscriptionGroup(this.props.subscriptionGroupId).then((group) => {
      this.setState({
        subscriptionGroupName: group.name,
        subscriptionGroupType: group.subscription_type,
        subscriptionGroupCurrency: group.currency
      });
    });
    getSubscriptionGroups().then((plans) =>
      this.props.setSubscriptionGroups(plans)
    );

    getPaymentGateways().then((config) => {
      const pg = config.payment_gateways;
      const actualEnabledPaymentGateways = [];
      for (const key in pg) {
        const pgDetails = pg[key];
        if (pgDetails.enabled) {
          actualEnabledPaymentGateways.push(key);
        }
      }
      this.setState({
        enabledPaymentProviders: actualEnabledPaymentGateways
      });
    });
  }

  setPaymentGatewayLinkNotification (type, statusMessage) {
    if (type === 'success') {
      this.setState({
        showNotification: {
          isNotificationOpen: true,
          status: 'success',
          statusMessage
        }
      });
    } else {
      this.setState({
        showNotification: {
          isNotificationOpen: true,
          status: 'error',
          statusMessage
        }
      });
    }
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.subscriptionPlan.fallback_currency &&
      this.props.subscriptionPlan.fallback_currency !==
      prevProps.subscriptionPlan.fallback_currency
    ) {
      this.addAutomaticPrice();
    }

    const currenciesLen = Object.keys(
      this.props.subscriptionPlan.prices || []
    ).length;
    const prevCurrenciesLen = Object.keys(
      prevProps.subscriptionPlan.prices || []
    ).length;
    if (currenciesLen === 1 && currenciesLen !== prevCurrenciesLen) {
      this.setState({
        selectedFallbackCurrency: null
      });
    }
  }

  getPlan () {
    const plan = this.props.subscriptionPlan;
    const prices = this.props.subscriptionPlan.prices;
    const newPrices = {};
    for (const key of Object.keys(prices)) {
      newPrices[key] = {
        amount_cents: prices[key].amount_cents,
        paid_trial_amount_cents: prices[key].paid_trial_amount_cents
      };
    }

    const currencies = Object.keys(prices || []);
    const multiCurrencyEnabled = this.props.config.multipleCurrencyEnabled &&
      this.state.subscriptionGroupType === 'standard';
    const priceObject = multiCurrencyEnabled ? {} : {
      price_cents: prices[currencies[0]].amount_cents,
      price_currency: currencies[0]
    };
    const fallbackCurrency = this.state.selectedFallbackCurrency
      ? this.state.selectedFallbackCurrency === 'local'
        ? {}
        : { fallback_currency: null }
      : {
        fallback_currency: null
      };

    const updatedPlan = Object.assign(
      {},
      plan,
      {
        prices: newPrices
      },
      fallbackCurrency,
      priceObject,
      { trial_period_type: this.state.trial_period_type || this.props.subscriptionPlan.trial_period_type }
    );

    return updatedPlan;
  }

  sendUpdatedData () {
    const updatedPlan = this.getPlan();
    createOrUpdateSubscriptionPlan(
      this.props.subscriptionGroupId,
      updatedPlan
    ).then(
      (response) => {
        window.location = `/subscription_groups/${this.props.subscriptionGroupId}`;
      },
      (errors) => {
        if (errors.error['image']) {
          this.setState({
            showNotification: {
              isNotificationOpen: true,
              status: 'error',
              statusMessage: 'Some image uploads have failed. Kindly reload the page and try again'
            }
          });
        }
        if (this.state.showDialog) {
          this.setState({
            showDialog: false,
            dialogContext: ''
          });
        }
        if (typeof errors.errors === 'string') {
          this.setState({
            showNotification: {
              isNotificationOpen: true,
              status: 'error',
              statusMessage: errors.errors
            }
          });
          return;
        }

        this.props.setErrors(errors.errors);
      }
    );
  }

  showWarningDialog () {
    const nonTrialSupportedPGsEnabled = this.getNonTrialSupportedPGsEnabled();
    const showDialog = this.props.subscriptionPlan.recurring
      ? nonTrialSupportedPGsEnabled.length > 0
      : nonTrialSupportedPGsEnabled.includes('omise');
    if (
      this.isOnlyNonTrialPeriodPGsEnabled() &&
      this.props.subscriptionPlan.recurring &&
      this.props.subscriptionPlan.trial_period_enabled
    ) {
      this.setState({
        showTrialUnsupportedPgErrorMessage: true
      });
      return;
    }
    if (showDialog) {
      this.setState({
        showDialog: true,
        dialogContext: 'currency-warning'
      });
    } else {
      this.sendUpdatedData();
    }
  }

  createUpdateSubscriptionPlan () {
    var errors = validate(
      this.props.subscriptionPlan,
      this.constraintsSubscriptionPlan()
    );

    const currencies = Object.keys(this.props.subscriptionPlan.prices || {});

    if (!errors) {
      const currenciesLength = currencies.length;
      const fallbackCurrencyValid = this.state.selectedFallbackCurrency &&
        (this.state.selectedFallbackCurrency !== 'local' || this.props.subscriptionPlan.fallback_currency);

      if (
        this.props.config.multipleCurrencyEnabled && currenciesLength > 1 && !fallbackCurrencyValid
      ) {
        this.setState(
          {
            showDialog: true,
            dialogContext: 'fallback-currency-unset'
          },
          () => {
            this.fallbackCurrencyUnsetDialogData();
          }
        );

        return;
      }
      this.showWarningDialog();
    } else {
      this.props.setErrors(errors);
    }
  }

  constraintsSubscriptionPlan () {
    const groupAccessUserLimitConstraint = {};
    const shouldValidatePaidTrialPrice = this.state.trial_period_type === 'PAID_TRIAL' || this.props.subscriptionPlan.trial_period_type === 'PAID_TRIAL';
    let constraints = {};
    if (this.props.subscriptionPlan.recurring) {
      const priceConstraints = getPricesConstraints('Value not within acceptable range', 100, shouldValidatePaidTrialPrice);
      let recurringConstraints = {
        ...constraintsPlan,
        prices: priceConstraints
      };

      if (this.props.subscriptionPlan.trial_period_enabled) {
        recurringConstraints = Object.assign(
          {},
          recurringConstraints,
          shouldValidatePaidTrialPrice ? {} : trialPeriodConstraints
        );
      }

      constraints = Object.assign(
        {},
        recurringConstraints,
        recurringDurationConstraint(this.props.subscriptionPlan.duration_unit)
      );
    } else {
      constraints = Object.assign(
        {},
        constraintsPlan,
        oneTimeDurationConstraint(this.props.subscriptionPlan.duration_unit)
      );
    }

    if (this.state.subscriptionGroupType === 'group_access') {
      return Object.assign({}, groupAccessUserLimitConstraint, constraints);
    }
    return constraints;
  }

  updateField (field, value) {
    if (field === 'display_assets') {
      this.saveAssets(
        Object.assign({}, this.props.subscriptionPlan, { [field]: value })
      );
    } else if (field === 'price_cents') {
      this.setState({ priceCents: value });
      this.updateSubscriptionPlan({
        [field]: currencyUtils.convertToLowestDenomination(value)
      });
    } else {
      this.updateSubscriptionPlan({ [field]: value });
    }
  }

  updatePriceWithPlanItems (id, type, value, planItems = {}) {
    const prices = this.props.subscriptionPlan.prices;
    const newPrices = {};

    for (const currency in prices) {
      if (Object.hasOwnProperty.call(prices, currency)) {
        const price = prices[currency];
        if (price.id === id) {
          if (type === 'amount') {
            newPrices[currency] = {
              amount_cents: currencyUtils.convertToLowestDenomination(value),
              paid_trial_amount_cents: price.paid_trial_amount_cents,
              id
            };
          } else if (type === 'paid_trial_amount') {
            newPrices[currency] = {
              amount_cents: price.amount_cents,
              paid_trial_amount_cents: value === null ? null : currencyUtils.convertToLowestDenomination(value),
              id
            };
          } else if (type === 'currency') {
            newPrices[value] = price;
          }
        } else {
          newPrices[currency] = prices[currency];
        }
      }
    }

    this.updateSubscriptionPlan({ prices: newPrices, ...planItems });
  }

  handleAddCurrency (currency) {
    const prices = this.props.subscriptionPlan.prices;
    const id = `provisional_${nanoid(10)}`;
    const newPrices = {
      ...prices,
      [currency || id]: {
        amount_cents: 0,
        id
      }
    };

    this.updateSubscriptionPlan({ prices: newPrices });
  }

  handleFallbackButtonClick () {
    window.location = `/general_settings?src=plan-fallback-button`;
  }

  addAutomaticPrice () {
    const fallbackCurrency =
      this.state.selectedFallbackCurrency === 'local'
        ? this.props.subscriptionPlan.fallback_currency
        : this.state.accountFallbackCurrency;
    const isPriceSetForFallbackCurrency = !!this.props.subscriptionPlan.prices[fallbackCurrency];

    if (fallbackCurrency && !isPriceSetForFallbackCurrency) {
      this.handleAddCurrency(fallbackCurrency);
    }
  }

  updateOccurrenceField (field, value) {
    this.updateSubscriptionPlan(occurrence.occurrenceToDurationMapping[value]);
  }

  saveAssets (subscriptionPlan) {
    createOrUpdateSubscriptionPlan(
      this.props.subscriptionGroupId,
      subscriptionPlan
    ).then(
      (plan) => {
        this.props.setSubscriptionPlan(plan);
        this.props.setErrors(null);
      },
      (errors) => {
        if (errors.error['image']) {
          this.setState({
            showNotification: {
              isNotificationOpen: true,
              status: 'error',
              statusMessage: 'Some image uploads have failed. Kindly reload the page and try again'
            }
          });
        }

        this.props.setErrors(errors.error);
      }
    );
  }

  createRazorpayPlan (e, subscriptionPlanId) {
    e.preventDefault();
    const message = _.get(this.props.subscriptionPlan, [
      'metadata',
      'razorpay',
      'plan_id'
    ])
      ? 'Update'
      : 'Create';
    const createPlan = window.confirm(
      'Do you want to link/update this plan in razorpay?'
    );
    if (createPlan === false) return;
    postCreateRemotePlan('razorpay', subscriptionPlanId).then(
      (response) => {
        this.setState({
          showNotification: {
            isNotificationOpen: true,
            status: 'success',
            statusMessage: `Razorpay Product ${message}d Successfully`
          }
        });
        const plan = dotProp.set(
          this.props.subscriptionPlan,
          ['metadata', 'razorpay', 'plan_id'],
          response.remote_subscription_plan.razorpay.id
        );
        this.props.setSubscriptionPlan(plan);
      },
      (errors) =>
        this.setState({
          showNotification: {
            isNotificationOpen: true,
            status: 'error',
            statusMessage:
              _.get(errors, ['error', 'message']) || _.get(errors, 'errors')
          }
        })
    );
  }

  createPaypalPlan (e, subscriptionPlanId) {
    e.preventDefault();
    const message = _.get(this.props.subscriptionPlan, [
      'metadata',
      'paypal',
      'plan_id'
    ])
      ? 'Update'
      : 'Create';
    const createPlan = window.confirm(
      `Do you want to ${message.toLowerCase()} this plan in Paypal?`
    );
    if (createPlan === false) return;

    connectRecurringPlan(subscriptionPlanId, 'paypal').then(
      (response) => {
        this.setState({
          showNotification: {
            isNotificationOpen: true,
            status: 'success',
            statusMessage: `Paypal Product ${message}d Successfully`
          }
        });
        const plan = dotProp.set(
          this.props.subscriptionPlan,
          ['metadata', 'paypal', 'plan_id'],
          response.plan_id
        );
        this.props.setSubscriptionPlan(plan);
      },
      (errors) => {
        return this.setState({
          showNotification: {
            isNotificationOpen: true,
            status: 'error',
            statusMessage:
              _.get(errors, ['error', 'message']) ||
              _.get(errors, 'errors') ||
              'Error while connecting to paypal'
          }
        });
      }
    );
  }

  createVoucherifyPlan (e, subscriptionPlanId) {
    e.preventDefault();
    const createPlan = window.confirm(
      'Do you want to create this plan in Voucherify?'
    );
    if (createPlan === false) return;
    postCreateVoucherifyProduct(subscriptionPlanId).then(
      (response) => {
        this.setState({
          showNotification: {
            isNotificationOpen: true,
            status: 'success',
            statusMessage: 'Voucherify Product Created Successfully'
          }
        });
        const plan = dotProp.set(
          this.props.subscriptionPlan,
          ['metadata', 'voucherify', 'product_id'],
          response.voucherify_product_id
        );
        this.props.setSubscriptionPlan(plan);
      },
      (errors) =>
        this.setState({
          showNotification: {
            isNotificationOpen: true,
            status: 'error',
            statusMessage:
              _.get(errors, ['error', 'message']) || _.get(errors, 'errors')
          }
        })
    );
  }

  createStripeProduct (e, subscriptionPlanId) {
    e.preventDefault();
    const createPlan = window.confirm(
      'Do you want to create this plan in Stripe?'
    );
    if (createPlan === false) return;

    connectRecurringPlan(subscriptionPlanId, 'stripe').then(
      (response) => {
        this.setState({
          showNotification: {
            isNotificationOpen: true,
            status: 'success',
            statusMessage: 'Stripe Product Created Successfully'
          }
        });
        const plan = dotProp.set(
          this.props.subscriptionPlan,
          ['metadata', 'stripe', 'plan_id'],
          response.plan_id
        );
        this.props.setSubscriptionPlan(plan);
      },
      (errors) => {
        return this.setState({
          showNotification: {
            isNotificationOpen: true,
            status: 'error',
            statusMessage:
              _.get(errors, ['error', 'message']) || _.get(errors, 'errors')
          }
        });
      }
    );
  }

  updateStripeProduct (e, subscriptionPlanId) {
    e.preventDefault();
    const updatePlan = window.confirm(
      'Do you want to update this plan in Stripe?'
    );
    if (updatePlan === false) return;

    updateRecurringPlan(subscriptionPlanId, 'stripe').then(
      (response) => {
        this.setState({
          showNotification: {
            isNotificationOpen: true,
            status: 'success',
            statusMessage: 'Stripe Product Updated Successfully'
          }
        });
        const plan = dotProp.set(
          this.props.subscriptionPlan,
          ['metadata', 'stripe', 'plan_id'],
          response.plan_id
        );
        this.props.setSubscriptionPlan(plan);
      },
      (errors) => {
        return this.setState({
          showNotification: {
            isNotificationOpen: true,
            status: 'error',
            statusMessage:
              _.get(errors, ['error', 'message']) || _.get(errors, 'errors')
          }
        });
      }
    );
  }

  updateVoucherifyPlan (e, subscriptionPlanId) {
    e.preventDefault();
    const createPlan = window.confirm(
      'Do you want to update this plan in Voucherify?'
    );
    if (createPlan === false) return;
    updateVoucherifyProduct(subscriptionPlanId).then(
      (response) => {
        this.setState({
          showNotification: {
            isNotificationOpen: true,
            status: 'success',
            statusMessage: 'Voucherify Product Updated Successfully'
          }
        });
      },
      (errors) =>
        this.setState({
          showNotification: {
            isNotificationOpen: true,
            status: 'error',
            statusMessage:
              _.get(errors, ['error', 'message']) || _.get(errors, 'errors')
          }
        })
    );
  }

  getAllSubscriptionPlans () {
    const allSubscriptionPlans = _.flatMap(
      this.props.subscriptionGroups,
      (group) => group.subscription_plans
    );
    const exceptCurrentPlan = _.filter(
      allSubscriptionPlans,
      (plan) => plan.id !== parseInt(this.props.subscriptionPlanId)
    );
    return _.map(exceptCurrentPlan, (plan) => ({
      name: plan.title,
      id: plan.id
    }));
  }

  getDurationOptions () {
    const planOccurrence = occurrence.getValue(this.props.subscriptionPlan);
    return planOccurrence === 'one time'
      ? DURATION_UNITS_ONE_TIME
      : DURATION_UNITS_RECURRING;
  }

  getSupportedPaymentProviders () {
    return _.get(
      this.props.subscriptionPlan,
      ['supported_payment_providers'],
      []
    );
  }

  paidTrialSelected () {
    return this.state.trial_period_type === 'PAID_TRIAL' || this.props.subscriptionPlan.trial_period_type === 'PAID_TRIAL';
  }

  getPaidTrialSupportedPaymentProiders () {
    return _.get(
      this.props.subscriptionPlan,
      ['supported_payment_providers'],
      []
    ).filter(pg => PAID_TRIAL_PAYMENT_GATEWAYS.includes(pg));
  }

  isPaymentProviderSupported (paymentProvider) {
    return this.getSupportedPaymentProviders().includes(paymentProvider);
  }

  omiseEnabledDetails () {
    const enabledPGs = this.state.enabledPaymentProviders;
    return getOmiseDetails(enabledPGs);
  }

  isOnlyNonTrialPeriodPGsEnabled () {
    const enabledPGs = this.state.enabledPaymentProviders;
    return enabledPGs.every((elem) => TRIAL_UNSUPPORTED_PG.includes(elem));
  }

  getNonTrialSupportedPGsEnabled () {
    const enabledPGs = this.state.enabledPaymentProviders;
    const enabledTrialUnspportedPgs = [];
    enabledPGs.forEach((pg) => {
      if (TRIAL_UNSUPPORTED_PG.includes(pg)) {
        enabledTrialUnspportedPgs.push(pg);
      }
    });
    return enabledTrialUnspportedPgs;
  }

  isTrialPeriodPGEnabled () {
    const enabledPGs = this.state.enabledPaymentProviders;
    return enabledPGs.some((elem) => !TRIAL_UNSUPPORTED_PG.includes(elem));
  }

  // Generally, we don't show trial period switch component when only omise is enabled
  // Edge Case: when the plan has a trial period enabled and  the enabled pgs are stripe, omise.
  // Let's assume the client goes to deactivate stripe , so that only enabled pg would be omise
  // The trial period switch component would initially be visible, since we display the trial switch component if the trial period
  // is enabled, no matter which pg is enabled.
  // But when we disable the  trial period for the plan, the trial period switch  component disappears instead of showing
  // in disabled state.
  // This function basically handles this edge case
  handleTrickyTrialPeriodCase () {
    const oldtrialPeriodEnabled = this.trialPeriodEnabled;
    return (
      this.isOnlyNonTrialPeriodPGsEnabled() &&
      oldtrialPeriodEnabled &&
      oldtrialPeriodEnabled !==
      _.get(this.props.subscriptionPlan, 'trial_period_enabled', false)
    );
  }

  getCustomCurrencies () {
    if (this.state.accountFallbackCurrency) {
      // All currencies - accountFallbackCurrency
      return currencyUtils.getRemainingCurrencies([this.state.accountFallbackCurrency]);
    }
    return ALL_CURRENCIES;
  }

  currencyWarningDialogData () {
    return {
      title: 'Warning',
      content: `${this.props.subscriptionPlan.recurring
        ? 'Some of the selected Payment Gateways do not support Free Trials and transactions above or below the specificied Min-Max caps'
        : 'Some of the selected Payment Gateways do not support transactions above or below the specificied Min-Max caps'
      }. Are you sure you want to ${this.props.subscriptionPlanId === 'new' ? 'create' : 'update'
      } the plan?`,
      firstButton: {
        type: 'primary',
        onClick: (e) => {
          e.preventDefault();
          this.sendUpdatedData();
        },
        content: `${this.props.subscriptionPlanId === 'new' ? 'Create' : 'Update'
        } Plan`
      },
      secondButton: {
        type: 'default',
        onClick: (e) => {
          e.preventDefault();
          this.setState({ showDialog: false, dialogContext: '' });
        },
        content: 'Cancel'
      }
    };
  }

  getPriceError (obj, type = 'amount_cents') {
    const error = obj.error;
    const errorMessage = [];
    if (error[type]) {
      errorMessage.push(error[type][0]);
    }
    if (error.currency) {
      errorMessage.push(error.currency[0]);
    }

    return errorMessage.join(' & ');
  }

  currencyRemoveDialogData () {
    return {
      title: 'Remove Currency?',
      content: 'Are you sure you want to remove this currency?',
      firstButton: {
        type: 'primary',
        variant: 'danger',
        onClick: (e) => {
          e.preventDefault();
          this.updatePriceWithPlanItems(this.priceId, 'delete');
          this.setState({
            showDialog: false,
            dialogContext: ''
          });
        },
        content: 'Remove'
      },
      secondButton: {
        type: 'default',
        onClick: (e) => {
          e.preventDefault();
          this.setState({ showDialog: false, dialogContext: '' });
        },
        content: 'Cancel'
      }
    };
  }

  fallbackCurrencyUnsetDialogData () {
    return {
      title: 'Set Fallback Currency',
      content:
        'This plan supports multiple currencies. Set a Fallback Currency to Save.',
      firstButton: {
        type: 'primary',
        onClick: (e) => {
          e.preventDefault();
          this.setState({
            showDialog: false,
            dialogContext: ''
          });
        },
        content: 'Ok'
      },
      secondButton: {}
    };
  }

  dialogOptions () {
    if (this.state.dialogContext === 'currency-warning') {
      return this.currencyWarningDialogData();
    } else if (this.state.dialogContext === 'currency-remove') {
      return this.currencyRemoveDialogData();
    } else if (this.state.dialogContext === 'fallback-currency-unset') {
      return this.fallbackCurrencyUnsetDialogData();
    }
  }

  updateSubscriptionPlan (updatedPlanItems) {
    this.props.setSubscriptionPlan(
      Object.assign({}, this.props.subscriptionPlan, updatedPlanItems)
    );
  }

  updateRichTextDescription (value) {
    this.setState({ descriptionText: value });
    this.updateField('description', value);
  }

  paidTrialSupportedPgWarningMessage () {
    return `Paid Trial is supported for plans associated with 
      ${PAID_TRIAL_PAYMENT_GATEWAYS.slice(0, -1).join(', ')}
      ${PAID_TRIAL_PAYMENT_GATEWAYS.length > 1 ? ' and ' : ''}
      ${PAID_TRIAL_PAYMENT_GATEWAYS.slice(-1)}.`;
  }

  render () {
    const omiseDetails = this.omiseEnabledDetails();
    const shouldShowTrialPeriodToggle =
      (_.get(this.props.subscriptionPlan, 'trial_period_enabled', false) ||
        !this.isOnlyNonTrialPeriodPGsEnabled() ||
        this.handleTrickyTrialPeriodCase()) &&
      this.props.subscriptionPlan.recurring;
    const pgTrialUnsupporteMessage =
      this.getNonTrialSupportedPGsEnabled().length > 0;
    const showOmiseRelatedMessages = !omiseDetails.noOmise;
    const multiCurrencyEnabled =
      this.props.config.multipleCurrencyEnabled &&
      this.state.subscriptionGroupType === 'standard';
    const currencies = Object.keys(this.props.subscriptionPlan.prices || []);
    const isMultipleCurrency = currencies.length > 1;
    const fallbackCurrency =
      this.state.selectedFallbackCurrency === 'local'
        ? this.props.subscriptionPlan.fallback_currency
        : this.state.selectedFallbackCurrency === 'account'
          ? this.state.accountFallbackCurrency
          : null;
    const dialogOptions = this.dialogOptions();
    const priceErrors = this.props.errors.prices;

    const onFileUpload = (s3key) => this.updateField('image_s3_key', s3key);

    const handleImageTrash = () => this.updateField('image_s3_key', null);

    const switchPlansEnabled = _.get(window.qtConfig, 'switchPlansEnabled', false);

    const layout = (
      <Layout>
        <TitleBar
          title={
            this.props.subscriptionPlanId === 'new'
              ? 'Add Subscription Plan'
              : 'Edit Subscription Plan'
          }
          breadcrumbTitle={`${this.state.subscriptionGroupName} Subscription Group`}
          breadcrumbPath={`/subscription_groups/${this.props.subscriptionPlan.subscription_group_id}`}
        />

        <Card title="Subscription Plan Details">
          <TextFieldWithLabel
            label="Name"
            placeholder="Name"
            value={this.props.subscriptionPlan.title || ''}
            onChange={(e) => this.updateField('title', e.target.value)}
            errors={_.get(this.props.errors, ['title', '0'])}
            dataTest="subscription-plan-name"
          />

          {this.state.richTextEnabled
            ? <QuillEditorWithLabel
              errors={_.get(this.props.errors, ['description', '0'])}
              label="Description"
              placeholder="Description"
              value={this.state.descriptionText}
              onChange={this.updateRichTextDescription.bind(this)}
              dataTest="subscription-plan-description"
            />
            : <TextAreaWithLabel
              rows="3"
              label="Description"
              placeholder="Description"
              value={this.props.subscriptionPlan.description || ''}
              onChange={(e) => this.updateField('description', e.target.value)}
              errors={_.get(this.props.errors, ['description', '0'])}
              dataTest="subscription-plan-description"
            />
          }

          <UploadImage
            id="plan-optional-image"
            savedImageUrl={_.get(this.props, ['subscriptionPlan', 'image_url'])}
            onFileUpload={onFileUpload}
            handleImageTrash={handleImageTrash}
          />

          <div className="form-row">
            <SelectFieldWithLabelDatatest
              label="Occurrence"
              value={occurrence.getValue(this.props.subscriptionPlan)}
              options={occurrence.getAllOptions(
                this.state.subscriptionGroupType
              )}
              onChange={(m) =>
                this.updateOccurrenceField('occurrence', m.value)
              }
              clearable={false}
              dataTest="subscription-plan-occurrence"
            />
          </div>

          {(occurrence.getValue(this.props.subscriptionPlan) === 'one time' ||
            occurrence.getValue(this.props.subscriptionPlan) === 'custom') && (
            <div className="duration-container">
              <div className="form-row">
                <TextFieldWithLabel
                  errors={_.get(this.props.errors, ['duration_length', '0'])}
                  label="Duration"
                  value={
                    this.props.subscriptionPlan.duration_unit === LIFETIMEVALUE
                      ? (this.props.subscriptionPlan.duration_length = 1)
                      : this.props.subscriptionPlan.duration_length || ''
                  }
                  onChange={(e) =>
                    this.updateField('duration_length', e.target.value)
                  }
                  disabled={
                    this.props.subscriptionPlan.duration_unit === LIFETIMEVALUE
                  }
                  dataTest="subscription-plan-duration"
                />
                <SelectFieldWithLabelDatatest
                  label="Time Unit"
                  value={this.props.subscriptionPlan.duration_unit || ''}
                  options={this.getDurationOptions()}
                  onChange={(m) => this.updateField('duration_unit', m.value)}
                  clearable={false}
                  errors={_.get(this.props.errors, ['duration_unit', [0]])}
                  dataTest="subscription-plan-time-unit"
                />
              </div>
            </div>
          )}
          {shouldShowTrialPeriodToggle && (
            <div className="form-row">
              <SliderCheckbox
                dataTest="enable_trial_period"
                label="Enable trial period"
                id="enable_trial_period"
                name="enable_trial_period"
                enabled={_.get(
                  this.props.subscriptionPlan,
                  'trial_period_enabled',
                  false
                )}
                onChange={(event) => {
                  if (
                    !event.target.checked &&
                    this.state.showTrialUnsupportedPgErrorMessage
                  ) {
                    this.setState({
                      showTrialUnsupportedPgErrorMessage: false
                    });
                  }
                  if (!event.target.checked) {
                    this.updatePriceWithPlanItems(this.props.subscriptionPlan.prices[this.props.subscriptionPlan.price_currency].id, 'paid_trial_amount', null, {
                      trial_period_type: null,
                      trial_period_enabled: false,
                      max_trial_period_length: null,
                      max_trial_period_unit: null
                    });

                    this.setState({ trial_period_type: null });
                  } else {
                    this.updateField(
                      'trial_period_enabled',
                      event.target.checked
                    );

                    !this.props.subscriptionPlan.trial_period_type && this.updateSubscriptionPlan({ trial_period_type: 'FREE_TRIAL' });
                  }
                }}
              />
            </div>
          )}
          {shouldShowTrialPeriodToggle && pgTrialUnsupporteMessage && (
            <p
              className={`sub_plan-notes sub_plan-notes-1 ${this.state.showTrialUnsupportedPgErrorMessage
                ? 'sub_plan-notes--error'
                : ''
              }`}
            >
              * Trial periods are not supported by{' '}
              {this.getNonTrialSupportedPGsEnabled().join(',')}
            </p>
          )}

          {this.props.subscriptionPlan.recurring && this.props.subscriptionPlan.trial_period_enabled && <div className='trial-toggle-wrapper'>
            {this.props.config.paidTrialenabled && <RadioButton
              id="free-trial"
              label="Free Trial"
              onChange={e => {
                if (e.target.checked) {
                  this.updatePriceWithPlanItems(this.props.subscriptionPlan.prices[this.props.subscriptionPlan.price_currency].id, 'paid_trial_amount', null, { trial_period_type: 'FREE_TRIAL' });
                }
                this.setState({ trial_period_type: 'FREE_TRIAL' });
              }}
              checked={(this.props.subscriptionPlan.trial_period_type === 'FREE_TRIAL' || this.props.subscriptionPlan.trial_period_type !== 'PAID_TRIAL') && this.state.trial_period_type !== 'PAID_TRIAL'}
            />}
            {this.props.subscriptionPlan.trial_period_enabled && (!this.paidTrialSelected() || !this.props.config.paidTrialenabled) && (
              <div className="duration-container">
                <div className="form-row">
                  <TextFieldWithLabel
                    errors={_.get(this.props.errors, [
                      'max_trial_period_length',
                      '0'
                    ])}
                    label="Trial Period Duration"
                    value={
                      this.props.subscriptionPlan.max_trial_period_length || ''
                    }
                    onChange={(e) => {
                      if (!this.props.config.paidTrialenabled) {
                        this.updatePriceWithPlanItems(this.props.subscriptionPlan.prices[this.props.subscriptionPlan.price_currency].id, 'paid_trial_amount', null, {
                          trial_period_type: 'FREE_TRIAL',
                          max_trial_period_length: e.target.value
                        });
                      } else {
                        this.updateField('max_trial_period_length', e.target.value);
                      }
                    }
                    }
                    dataTest="trial_period_length"
                  />

                  <SelectFieldWithLabelDatatest
                    label="Trial period Time Unit"
                    value={
                      this.props.subscriptionPlan.max_trial_period_unit || ''
                    }
                    options={TRIAL_PERIOD_DURATION_UNITS}
                    onChange={(m) =>
                      this.updateField('max_trial_period_unit', m.value)
                    }
                    clearable={false}
                    errors={_.get(this.props.errors, [
                      'max_trial_period_unit',
                      [0]
                    ])}
                    dataTest="trial_period_unit"
                  />
                </div>
              </div>
            )}
            {this.props.config.paidTrialenabled && <RadioButton
              id="paid-trial"
              label="Paid Trial"
              onChange={e => {
                this.setState({ trial_period_type: 'PAID_TRIAL' });

                this.updateSubscriptionPlan({
                  trial_period_type: 'PAID_TRIAL',
                  max_trial_period_length: null,
                  max_trial_period_unit: null
                });
              }}
              checked={this.props.subscriptionPlan.trial_period_type === 'PAID_TRIAL' || this.state.trial_period_type === 'PAID_TRIAL'}
            />}
            {this.props.config.paidTrialenabled && (this.props.subscriptionPlan.trial_period_type === 'PAID_TRIAL' || this.state.trial_period_type === 'PAID_TRIAL') && (
              <div className='paid-trial-warning'>
                <p>{this.paidTrialSupportedPgWarningMessage()}</p>
                <p>Trial is only applied for the first recurring cycle - {this.props.subscriptionPlan.duration_length} {this.props.subscriptionPlan.duration_unit}.</p>
              </div>
            )}
          </div>}

          {this.props.config.paidTrialenabled && this.props.subscriptionPlan.recurring && (this.state.trial_period_type === 'PAID_TRIAL' || this.props.subscriptionPlan.trial_period_type === 'PAID_TRIAL') && <div>
            <h3 className="plan__price-header">Trial Price</h3>
            <PriceSelector
              type='paid-trial'
              multiCurrencyEnabled={multiCurrencyEnabled}
              onPriceItemUpdate={this.updatePriceWithPlanItems}
              subscriptionGroupType={this.state.subscriptionGroupType}
              subscriptionGroupCurrency={this.state.subscriptionGroupCurrency}
              priceErrors={priceErrors}
              onDeleteClick={(priceId) => {
                this.priceId = priceId;
                this.setState({
                  showDialog: true,
                  dialogContext: 'currency-remove'
                });
              }}
              subscriptionPlan={this.props.subscriptionPlan}
              fallbackCurrency={fallbackCurrency}
            />
          </div>}
          {this.state.subscriptionGroupType === 'group_access' && (
            <>
              <div className="form-row">
                <div className="user_limit_error_note">
                  <div className="error_icon">
                    <Error />
                  </div>
                  <p className="user_limit_error_note_text">
                    <strong>User Limit</strong> has been shifted to{' '}
                    <a className="sub_plan-link" href="/organizations">
                      Organization Page
                    </a>{' '}
                    under the{' '}
                    <strong>&apos;Add New Subscription Card&apos;</strong>.
                  </p>
                </div>
              </div>
            </>
          )}
          <div className="plan_main_data_container">
            <h3 className="plan__price-header"> Pricing</h3>
            {multiCurrencyEnabled && (
              <div className="plan__add_currency_btn">
                <EmButton
                  onClick={() => {
                    this.handleAddCurrency();
                  }}
                >
                  + Add New Currency
                </EmButton>
              </div>
            )}
            <PriceSelector
              multiCurrencyEnabled={multiCurrencyEnabled}
              onPriceItemUpdate={this.updatePriceWithPlanItems}
              subscriptionGroupType={this.state.subscriptionGroupType}
              subscriptionGroupCurrency={this.state.subscriptionGroupCurrency}
              priceErrors={priceErrors}
              onDeleteClick={(priceId) => {
                this.priceId = priceId;
                this.setState({
                  showDialog: true,
                  dialogContext: 'currency-remove'
                });
              }}
              subscriptionPlan={this.props.subscriptionPlan}
              fallbackCurrency={fallbackCurrency}
            />

            {showOmiseRelatedMessages && (
              <p className="sub_plan-notes sub_plan__strict-message">
                Stricter minimum and maximum amounts for charges may be enforced
                for specific payment methods. See the relevant payment method{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="sub_plan-link"
                  href="https://www.omise.co/currency-and-amount#charge-limits"
                >
                  documentation
                </a>{' '}
                for details.
              </p>
            )}
            {/*  fb - fallback */}
            <ConfigurePlanFallbackCurrency
              multiCurrencyEnabled={multiCurrencyEnabled}
              isMultipleCurrency={isMultipleCurrency}
              accountFallbackCurrency={this.state.accountFallbackCurrency}
              selectedFallbackCurrency={this.state.selectedFallbackCurrency}
              onSelectedFallbackCurrencyUpdate={type => {
                this.setState(
                  {
                    selectedFallbackCurrency: type
                  },
                  () => {
                    this.addAutomaticPrice();
                  }
                );
                if (type === 'account') {
                  this.updateField('fallback_currency', null);
                }
              }}
              fallbackCurrency={this.props.subscriptionPlan.fallback_currency || ''}
              onFallbackCurrencyChange={(currency) => {
                this.updateField('fallback_currency', currency);
              }} />

            {!this.props.subscriptionPlan.recurring &&
              this.state.voucherifyEnabled && (
              <TextFieldWithLabel
                label="Voucherify Product ID"
                placeholder="This plan is not linked with Voucherify"
                disabled
                dataTest="voucherify-product-id"
                value={_.get(
                  this.props.subscriptionPlan.metadata,
                  ['voucherify', 'product_id'],
                  ''
                )}
              />
            )}
          </div>
          {(!this.paidTrialSelected() || this.getPaidTrialSupportedPaymentProiders().length > 0) && multiCurrencyEnabled && <div className="multi-currency-link-gateways">
            <div>
              {this.props.subscriptionPlan.recurring &&
                this.props.subscriptionPlanId !== 'new' && (
                <LinkPaymentGateway
                  enabledPaymentGateway={this.paidTrialSelected() ? this.getPaidTrialSupportedPaymentProiders() : this.getSupportedPaymentProviders()}
                  subscriptionPlanId={this.props.subscriptionPlanId}
                  planMetadata={this.props.subscriptionPlan.metadata}
                  isSuperUser={this.state.superUser}
                  subscriptionPlan={this.props.subscriptionPlan}
                  setSubscriptionPlan={this.props.setSubscriptionPlan}
                  setNotification={(type, message) => {
                    this.setPaymentGatewayLinkNotification(type, message);
                  }}
                  paidTrial={this.paidTrialSelected()}
                />
              )}
            </div>
          </div>
          }

          {!this.paidTrialSelected() && !multiCurrencyEnabled && <div className="link-gateways">
            <div>
              {this.props.subscriptionPlan.recurring &&
                this.props.subscriptionPlanId !== 'new' &&
                this.isPaymentProviderSupported('razorpay') && (
                <TextFieldWithLabel
                  label="Razorpay Plan ID"
                  placeholder="This recurring plan is not linked with Razorpay"
                  disabled
                  value={_.get(
                    this.props.subscriptionPlan.metadata,
                    ['razorpay', 'plan_id', this.props.subscriptionPlan.price_currency],
                    ''
                  ) || _.get(
                    this.props.subscriptionPlan.metadata,
                    ['razorpay', 'plan_id'],
                    ''
                  )}
                />
              )}
              <div>
                {this.state.superUser &&
                  this.isPaymentProviderSupported('razorpay') &&
                  this.props.subscriptionPlanId !== 'new' &&
                  this.props.subscriptionPlan.recurring && (
                  <Link
                    onClick={(e) =>
                      this.createRazorpayPlan(
                        e,
                        this.props.subscriptionPlanId
                      )
                    }
                    dataTest="razorpay-update"
                  >
                    {_.get(this.props.subscriptionPlan, [
                      'metadata',
                      'razorpay',
                      'plan_id'
                    ], false)
                      ? 'Update Razorpay'
                      : 'Connect Razorpay'}
                  </Link>
                )}
              </div>
            </div>

            <div>
              {this.props.subscriptionPlan.recurring &&
                this.props.subscriptionPlanId !== 'new' &&
                this.isPaymentProviderSupported('stripe') && (
                <TextFieldWithLabel
                  label="Stripe Plan ID"
                  dataTest="stripe-plan-id"
                  placeholder="This recurring plan is not linked with Stripe"
                  disabled
                  value={_.get(
                    this.props.subscriptionPlan.metadata,
                    ['stripe', 'plan_id', this.props.subscriptionPlan.price_currency],
                    ''
                  ) || _.get(
                    this.props.subscriptionPlan.metadata,
                    ['stripe', 'plan_id'],
                    ''
                  )}
                />
              )}
              <div>
                {this.state.superUser &&
                  this.isPaymentProviderSupported('stripe') &&
                  this.props.subscriptionPlanId !== 'new' &&
                  this.props.subscriptionPlan.recurring &&
                  _.get(this.props.subscriptionPlan, [
                    'metadata',
                    'stripe',
                    'plan_id'
                  ]) && (
                  <Link
                    onClick={(e) =>
                      this.updateStripeProduct(
                        e,
                        this.props.subscriptionPlanId
                      )
                    }
                    dataTest="stripe-update"
                  >
                      Update Stripe
                  </Link>
                )}
                {this.state.superUser &&
                  this.isPaymentProviderSupported('stripe') &&
                  this.props.subscriptionPlanId !== 'new' &&
                  this.props.subscriptionPlan.recurring &&
                  !_.get(this.props.subscriptionPlan, [
                    'metadata',
                    'stripe',
                    'plan_id'
                  ]) && (
                  <Link
                    onClick={(e) =>
                      this.createStripeProduct(
                        e,
                        this.props.subscriptionPlanId
                      )
                    }
                    dataTest="stripe-connect"
                  >
                      Connect Stripe
                  </Link>
                )}
              </div>
            </div>

            <div>
              {this.props.subscriptionPlan.recurring &&
                this.props.subscriptionPlanId !== 'new' &&
                this.isPaymentProviderSupported('paypal') && (
                <TextFieldWithLabel
                  label="Paypal Plan ID"
                  placeholder="This recurring plan is not linked with Paypal"
                  disabled
                  value={_.get(
                    this.props.subscriptionPlan.metadata,
                    ['paypal', 'plan_id', this.props.subscriptionPlan.price_currency],
                    ''
                  ) || _.get(
                    this.props.subscriptionPlan.metadata,
                    ['paypal', 'plan_id'],
                    ''
                  )}
                />
              )}
              <div>
                {this.state.superUser &&
                  this.isPaymentProviderSupported('paypal') &&
                  this.props.subscriptionPlanId !== 'new' &&
                  this.props.subscriptionPlan.recurring && (
                  <Link
                    onClick={(e) =>
                      this.createPaypalPlan(e, this.props.subscriptionPlanId)
                    }
                    dataTest="paypal-update"
                  >
                    {_.get(this.props.subscriptionPlan, [
                      'metadata',
                      'paypal',
                      'plan_id'
                    ])
                      ? 'Update Paypal'
                      : 'Connect Paypal'}
                  </Link>
                )}
              </div>
            </div>

            <div>
              {this.props.subscriptionPlan.recurring &&
                this.props.subscriptionPlanId !== 'new' &&
                this.isPaymentProviderSupported('omise') && (
                <TextFieldWithLabel
                  label="Omise"
                  placeholder="Omise is linked"
                  disabled
                />
              )}
              {this.props.subscriptionPlan.recurring &&
                this.props.subscriptionPlanId !== 'new' &&
                this.isPaymentProviderSupported('adyen') && (
                <TextFieldWithLabel
                  label="Adyen"
                  placeholder="Adyen is linked"
                  disabled
                />
              )}
            </div>
          </div>}

          {this.props.subscriptionPlanId !== 'new' &&
            this.state.voucherifyEnabled &&
            !this.props.subscriptionPlan.recurring &&
            _.get(this.props.subscriptionPlan, [
              'metadata',
              'voucherify',
              'product_id'
            ]) && (
            <Link
              onClick={(e) =>
                this.updateVoucherifyPlan(e, this.props.subscriptionPlanId)
              }
              dataTest="voucherify-update"
            >
                Update Voucherify
            </Link>
          )}

          {this.props.subscriptionPlanId !== 'new' &&
            this.state.voucherifyEnabled &&
            !this.props.subscriptionPlan.recurring &&
            !_.get(this.props.subscriptionPlan, [
              'metadata',
              'voucherify',
              'product_id'
            ]) && (
            <Link
              onClick={(e) =>
                this.createVoucherifyPlan(e, this.props.subscriptionPlanId)
              }
            >
                Connect Voucherify
            </Link>
          )}
        </Card>

        {switchPlansEnabled &&
          <SwitchPlansCard
            planConfig={this.props.subscriptionPlan}
            updateConfig={(field, value) => this.updateField(field, value)}
          />}

        <CustomAttributesCard
          customAttributes={_.get(this.props, ['subscriptionPlan', 'custom_attributes'], [])}
          onChange={(attributes) =>
            this.updateField('custom_attributes', attributes)
          }
        />

        <Card>
          <Assets
            assets={this.props.subscriptionPlan.display_assets || []}
            onChange={(assets) => this.updateField('display_assets', assets)}
            onSave={(e) => this.saveAssets(e)}
            assetCategories={this.state.assetCategories}
            errors={this.props.errors}
            id={this.props.subscriptionPlanId}
            assetsFor="Subscription Plan"
            subscriptionPlan={this.props.subscriptionPlan}
            showPublishedAt={
              this.props.subscriptionPlan.duration_unit === 'lifetime'
            }
            assetFrom={this.getAllSubscriptionPlans()}
            subscriptionGroupType={this.state.subscriptionGroupType}
          />
        </Card>
        <ButtonGroup
          columns={this.props.subscriptionPlanId !== 'new' ? 'two' : ''}
        >
          {this.props.subscriptionPlanId !== 'new' && (
            <Button onClick={(e) => this.openAlert(e)} destructive>
              {' '}
              Delete{' '}
            </Button>
          )}
          <Button onClick={(e) => this.createUpdateSubscriptionPlan()} primary>
            {' '}
            Save{' '}
          </Button>
        </ButtonGroup>
        {this.state.showDialog && (
          <ModifiedEmDialog
            isOpen={this.state.showDialog}
            onClose={() => {
              this.setState({ showDialog: false, dialogContext: '' });
            }}
            title={dialogOptions.title}
            content={dialogOptions.content}
            firstButton={dialogOptions.firstButton}
            secondButton={dialogOptions.secondButton}
          />
        )}

        <Alert
          title="Delete Subscription Plan"
          open={this.state.openAlertPopup}
          destructive
          onCancel={() => this.closeAlert()}
          onConfirm={(e) => this.deleteSubscriptionPlan(e)}
        >
          Deleted data cannot be recovered. Do you still want to continue?
        </Alert>

        {this.state.showNotification.isNotificationOpen && (
          <StatusMessage
            statusMessage={this.state.showNotification.statusMessage}
            status={this.state.showNotification.status}
            onCancel={() =>
              this.setState({
                showNotification: {
                  isNotificationOpen: false,
                  status: '',
                  statusMessage: ''
                }
              })
            }
          />
        )}
      </Layout>
    );

    return layout;
  }
}
function mapStateToProps (state) {
  return {
    subscriptionPlan: state.subscriptionPlan || {},
    errors: state.errors || [],
    subscriptionGroups: state.subscriptionGroups || [],
    config: state.config || {}
  };
}

function mapDispatchToProps (dispatch) {
  return {
    setSubscriptionPlan: (plan) =>
      dispatch({ type: SET_SUBSCRIPTION_PLAN, subscriptionPlan: plan }),
    setErrors: (errors) => dispatch({ type: SET_ERRORS, errors: errors }),
    setSubscriptionGroups: (plans) =>
      dispatch({ type: UPDATE_SUBSCRIPTION_GROUPS, subscriptionGroups: plans })
  };
}

const trialPeriodConstraints = {
  max_trial_period_length: {
    presence: true,
    numericality: {
      strict: true,
      onlyInteger: true,
      greaterThan: 0,
      lessThanOrEqualTo: 999999
    }
  },
  max_trial_period_unit: { presence: { message: 'cannot be empty' } }
};

function oneTimeDurationConstraint (durationUnit) {
  return {
    duration_length: {
      presence: true,
      numericality: {
        strict: true,
        onlyInteger: true,
        greaterThan: 0,
        lessThanOrEqualTo: maximumOneTimeDuration[durationUnit]
      }
    }
  };
}

const maximumOneTimeDuration = {
  days: 36524,
  weeks: 5217,
  months: 1200,
  years: 100,
  lifetime: 1
};

function recurringDurationConstraint (durationUnit) {
  return {
    duration_length: {
      presence: true,
      numericality: {
        strict: true,
        onlyInteger: true,
        greaterThan: 0,
        lessThanOrEqualTo: maximumRecurringDuration[durationUnit]
      }
    }
  };
}

const maximumRecurringDuration = {
  days: 3650,
  weeks: 52,
  months: 12,
  years: 10
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUpdateSubscriptionPlan);
