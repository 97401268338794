import React from 'react';
import dotProp from 'dot-prop-immutable';
import get from 'lodash-es/get';

import {
  GeneralAccordion as Accordion,
  GeneralAccordionSection as AccordionSection,
  Link,
  TextFieldWithLabel
} from '../library-components';

import {
  postCreateRemotePlan,
  connectRecurringPlan,
  updateRecurringPlan
} from '../../api';

import './index.scss';
import { PAID_TRIAL_PAYMENT_GATEWAYS } from '../../constants';

function ConnectAutoLinkedPaymentGateway (props) {
  const {
    paymentGateway,
    isConnectType,
    onConnectClick,
    subscriptionPlanId,
    isSuperUser
  } = props;
  return (
    <div className="auto-linked-pg">
      <h3 className="auto-linked-pg__title">{paymentGateway}</h3>
      <div className="auto-linked-pg__action">
        {isConnectType ? (
          isSuperUser ? (
            <Link
              onClick={(e) => {
                onConnectClick(e, subscriptionPlanId);
              }}
              dataTest={`${paymentGateway}-connect`}
            >
              Connect
            </Link>
          ) : (
            <p className="auto-linked-pg__message">Not Linked</p>
          )
        ) : (
          <p className="auto-linked-pg__message">Linked</p>
        )}
      </div>
    </div>
  );
}

function AccordionHeader (props) {
  const {
    onUpdateClick,
    paymentGateway,
    subscriptionPlanId,
    expanded,
    isSuperUser
  } = props;

  return (
    <div className="accordion-header__container">
      <h3 className="accordion-header__pg-title">{paymentGateway}</h3>
      <div
        className={`accordion-header-action-container ${
          expanded ? 'link-pg-expanded' : ''
        }`}
      >
        <div className="accordion-header__pg-action">
          {isSuperUser && (
            <Link
              onClick={(e) => {
                onUpdateClick(e, subscriptionPlanId);
              }}
              dataTest={`${paymentGateway}-update`}
            >
              Update
            </Link>
          )}
        </div>
        <div className="accordion-header__expand-message">
          <Link onClick={() => {}} dataTest={`${paymentGateway}-expand`}>
            {expanded ? 'Hide IDs' : 'Show IDs'}
          </Link>
        </div>
      </div>
    </div>
  );
}

function ConnectedPaymentGateway (props) {
  const {
    paymentGateway,
    onUpdateClick,
    paymentGatewayMetadata,
    subscriptionPlanId,
    subscriptionPlan,
    isSuperUser,
    index
  } = props;

  const isPaymentGatewayMetadataString =
    typeof paymentGatewayMetadata === 'string';

  const currencies = Object.keys(subscriptionPlan.prices);

  const currencyLen = currencies.length;

  return (
    <AccordionSection
      index={index} // ConnectedPaymentGateway was  cloned  and index prop passed by Accordion
      header={
        <AccordionHeader
          onUpdateClick={onUpdateClick}
          subscriptionPlanId={subscriptionPlanId}
          paymentGateway={paymentGateway}
          isSuperUser={isSuperUser}
        />
      }
      excludeSelector=".accordion-header__pg-action"
    >
      <div className="link-accordion-content">
        {currencies.map((currency) => {
          if (currency.includes('provisional')) {
            return null;
          }
          let value;
          if (isPaymentGatewayMetadataString) {
            if (currencyLen > 1) {
              value =
                subscriptionPlan.price_currency === currency
                  ? paymentGatewayMetadata
                  : 'Please update again to link this currency';
            } else {
              value = paymentGatewayMetadata;
            }
          } else {
            value =
              paymentGatewayMetadata[currency] ||
              'Please update again to link this currency';
          }

          return (
            <TextFieldWithLabel
              key={currency}
              label={currency}
              disabled
              value={value}
              dataTest={`plan-id-${paymentGateway}-${currency}`}
            />
          );
        })}
      </div>
    </AccordionSection>
  );
}

const NON_LINKABLE_PGS = ['adyen', 'omise'];

function getConnectedPaymentGateway (metadata, paidTrial = false) {
  const paymentGateways = Object.keys(metadata);
  const connectedPaymentGateways = [];
  paymentGateways.forEach((pg) => {
    const value = metadata[pg]['plan_id'];
    if (value) {
      const currenciesLen = Object.keys(value).length;
      if (currenciesLen > 0) {
        connectedPaymentGateways.push(pg);
      }
    }
  });

  return paidTrial ? connectedPaymentGateways.filter(pg => PAID_TRIAL_PAYMENT_GATEWAYS.includes(pg)) : connectedPaymentGateways;
}

function getAutoLinkedUnconnectedPaymentGateway (
  enabledPaymentGateway,
  connectedPaymentGateway,
  displayedPaymentGateways
) {
  return enabledPaymentGateway.filter(
    (paymentGateway) =>
      !connectedPaymentGateway.includes(paymentGateway) &&
      displayedPaymentGateways.includes(paymentGateway)
  );
}

export default function LinkPaymentGateway (props) {
  const {
    planMetadata,
    enabledPaymentGateway,
    subscriptionPlanId,
    subscriptionPlan,
    isSuperUser,
    setNotification,
    setSubscriptionPlan,
    paidTrial
  } = props;

  function updateSubscriptionPlan (paymentGateway, errors) {
    if (errors.plan_id) {
      const plan = dotProp.set(
        subscriptionPlan,
        ['metadata', paymentGateway, 'plan_id'],
        errors.plan_id
      );
      setSubscriptionPlan(plan);
    }
  }

  function createRazorpayPlan (e, subscriptionPlanId) {
    e.preventDefault();
    const message = get(planMetadata, ['razorpay', 'plan_id'])
      ? 'Update'
      : 'Create';
    const createPlan = window.confirm(
      'Do you want to link/update this plan in razorpay?'
    );
    if (createPlan === false) return;
    postCreateRemotePlan('razorpay', subscriptionPlanId).then(
      (response) => {
        const statusMessage = `Razorpay Product ${message}d Successfully`;

        setNotification('success', statusMessage);
        const plan = dotProp.set(
          subscriptionPlan,
          ['metadata', 'razorpay', 'plan_id'],
          response.remote_subscription_plan.razorpay.id
        );
        setSubscriptionPlan(plan);
      },
      (errors) => {
        const statusMessage =
          get(errors, ['error', 'message']) || get(errors, 'errors');
        setNotification('error', statusMessage);
        updateSubscriptionPlan('razorpay', errors);
      }
    );
  }

  function createPaypalPlan (e, subscriptionPlanId) {
    e.preventDefault();
    const message = get(planMetadata, ['paypal', 'plan_id'])
      ? 'Update'
      : 'Create';
    const createPlan = window.confirm(
      `Do you want to ${message.toLowerCase()} this plan in Paypal?`
    );
    if (createPlan === false) return;

    connectRecurringPlan(subscriptionPlanId, 'paypal').then(
      (response) => {
        const statusMessage = `Paypal Product ${message}d Successfully`;

        setNotification('success', statusMessage);
        const plan = dotProp.set(
          subscriptionPlan,
          ['metadata', 'paypal', 'plan_id'],
          response.plan_id
        );
        setSubscriptionPlan(plan);
      },
      (errors) => {
        const statusMessage =
          get(errors, ['error', 'message']) ||
          get(errors, 'errors') ||
          'Error while connecting to paypal';

        setNotification('error', statusMessage);
        updateSubscriptionPlan('paypal', errors);
      }
    );
  }

  function createStripeProduct (e, subscriptionPlanId) {
    e.preventDefault();
    const createPlan = window.confirm(
      'Do you want to create this plan in Stripe?'
    );
    if (createPlan === false) return;

    connectRecurringPlan(subscriptionPlanId, 'stripe').then(
      (response) => {
        const statusMessage = 'Stripe Product Created Successfully';

        setNotification('success', statusMessage);
        const plan = dotProp.set(
          subscriptionPlan,
          ['metadata', 'stripe', 'plan_id'],
          response.plan_id
        );
        setSubscriptionPlan(plan);
      },
      (errors) => {
        const statusMessage =
          get(errors, ['error', 'message']) || get(errors, 'errors');

        setNotification('error', statusMessage);
        updateSubscriptionPlan('stripe', errors);
      }
    );
  }

  function updateStripeProduct (e, subscriptionPlanId) {
    e.preventDefault();
    const updatePlan = window.confirm(
      'Do you want to update this plan in Stripe?'
    );
    if (updatePlan === false) return;

    updateRecurringPlan(subscriptionPlanId, 'stripe').then(
      (response) => {
        const statusMessage = 'Stripe Product Updated Successfully';

        setNotification('success', statusMessage);
        const plan = dotProp.set(
          subscriptionPlan,
          ['metadata', 'stripe', 'plan_id'],
          response.plan_id
        );
        setSubscriptionPlan(plan);
      },
      (errors) => {
        const statusMessage =
          get(errors, ['error', 'message']) || get(errors, 'errors');
        setNotification('error', statusMessage);
        updateSubscriptionPlan('stripe', errors);
      }
    );
  }

  const paymentGatewayMapping = {
    stripe: {
      create: createStripeProduct,
      update: updateStripeProduct
    },
    paypal: {
      create: createPaypalPlan,
      update: createPaypalPlan
    },
    razorpay: {
      create: createRazorpayPlan,
      update: createRazorpayPlan
    },
    adyen: {},
    omise: {}
  };

  const connectedPaymentGateway = getConnectedPaymentGateway(planMetadata, paidTrial);
  const autoLinkedUnconnectedPaymentGateway =
    getAutoLinkedUnconnectedPaymentGateway(
      enabledPaymentGateway,
      connectedPaymentGateway,
      Object.keys(paymentGatewayMapping)
    );

  return (
    <>
      {connectedPaymentGateway.length > 0 && (
        <Accordion
          showBottomBorder={autoLinkedUnconnectedPaymentGateway.length > 0}
        >
          {connectedPaymentGateway.map((paymentGateway) => {
            return (
              <ConnectedPaymentGateway
                key={paymentGateway}
                paymentGateway={paymentGateway}
                onUpdateClick={paymentGatewayMapping[paymentGateway]['update']}
                paymentGatewayMetadata={planMetadata[paymentGateway]['plan_id']}
                subscriptionPlan={subscriptionPlan}
                subscriptionPlanId={subscriptionPlanId}
                isSuperUser={isSuperUser}
              />
            );
          })}
        </Accordion>
      )}
      <div className="auto-linked-unconnected-pgs-container">
        {autoLinkedUnconnectedPaymentGateway.map((paymentGateway) => {
          return (
            <ConnectAutoLinkedPaymentGateway
              key={paymentGateway}
              paymentGateway={paymentGateway}
              subscriptionPlanId={subscriptionPlanId}
              isConnectType={!NON_LINKABLE_PGS.includes(paymentGateway)}
              onConnectClick={paymentGatewayMapping[paymentGateway]['create']}
              isSuperUser={isSuperUser}
            />
          );
        })}
      </div>
    </>
  );
}
