import React, { useEffect, useState } from 'react';
import { DateRangePicker } from '@quintype/em/components/date-range-picker';
import { Callout } from '@quintype/em/components/callout';
import { AnimatedSpinner } from '@quintype/em/icons/animated-spinner';
import { ToolTip } from '@quintype/em/components/tooltip';
import addDays from 'date-fns/addDays';
import set from 'date-fns/set';
import getUnixTime from 'date-fns/getUnixTime';
import fromUnixTime from 'date-fns/fromUnixTime';
import isAfter from 'date-fns/isAfter';
import isEqual from 'date-fns/isEqual';

import {
  downloadSubscriptionsToRenewCsv,
  getDownloadUrl,
  getSubscriptionsDueToRenew
} from '../../api';
import Select from '../../components/library-components/SelectFieldWithLabel/SelectFieldWithLabel';
import { Button, Card } from '../../components/library-components';

import './subscriptions_due_renew.scss';

const intervalOptions = [
  { value: 1, label: 'Today' },
  { value: 7, label: 'Next 7 days' },
  { value: 15, label: 'Next 15 days' },
  { value: 30, label: 'Next 30 days' },
  { value: 60, label: 'Next 60 days' },
  { value: 90, label: 'Next 90 days' },
  { value: 'custom', label: 'Custom date range' }
];

function getUTCformattedDate (date) {
  const isoDate = date.toISOString();
  return isoDate.slice(0, 10); // formated date yyyy-mm-dd
}

function getDatesFromInterval (interval) {
  if (interval === 1) {
    return [new Date(), new Date()];
  }
  const startDate = addDays(new Date(), 1);
  const endDate = addDays(new Date(), interval);
  return [startDate, endDate];
}

function getInitialUnixStamp () {
  const tomorrow = addDays(new Date(), 1);
  const sevenDaysAfter = addDays(new Date(), 7);

  return [getUnixTime(tomorrow), getUnixTime(sevenDaysAfter)];
}

function getAPIdates (interval, timestamp) {
  if (typeof interval.value === 'string') {
    return {
      from_date: getUTCformattedDate(fromUnixTime(timestamp.start)),
      to_date: getUTCformattedDate(fromUnixTime(timestamp.end))
    };
  }

  const [fromDate, toDate] = getDatesFromInterval(interval.value);
  return {
    from_date: getUTCformattedDate(fromDate),
    to_date: getUTCformattedDate(toDate)
  };
}

function setupChannelSubscription (cableApp, channel, callback) {
  cableApp.notify_user = cableApp.cable.subscriptions.create(channel, {
    received: function (data) {
      callback(data);
    }
  });
}

export default function SubscribersdueToRenew (props) {
  const [timestamp, setTimeStamp] = useState({
    start: getInitialUnixStamp()[0],
    end: getInitialUnixStamp()[1]
  });
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [interval, setInterval] = useState(intervalOptions[0]);

  const generateReport = () => {
    downloadSubscriptionsToRenewCsv(getAPIdates(interval, timestamp)).then(
      (message) => {
        props.onDownloadClick(message, true);
      }
    );
  };

  useEffect(() => {
    function getData () {
      getSubscriptionsDueToRenew(getAPIdates(interval, timestamp)).then(
        (result) => {
          setLoading(false);
          setValue(result);
        }
      );
    }
    setLoading(true);
    getData();
  }, [interval.value, timestamp.start, timestamp.end]);

  useEffect(() => {
    setupChannelSubscription(props.cableApp, 'NotifyUsersChannel', (data) => {
      getDownloadUrl(data.description.text.filename).then((downloadUrl) => {
        window.location.href = downloadUrl;
      });
    });
  }, [props.cableApp]);

  return (
    <article className="sdr__container">
      <Card>
        <h3 className="sdr__title"> Subscriptions due to renew</h3>
        <div className="sdr__content-container">
          <div className="sdr__count">
            {loading ? (
              <AnimatedSpinner width="32" height="32" />
            ) : (
              <span data-for="sdr-tooltip" data-tip={true}>
                {new Intl.NumberFormat('en-US', {
                  notation: 'compact',
                  compactDisplay: 'short'
                }).format(value)}
                <ToolTip
                  value={`${value}`}
                  id="sdr-tooltip"
                  place="top"
                  classname="sdr__tooltip"
                />
              </span>
            )}
          </div>
          <div className="sdr__interval">
            <Select
              options={intervalOptions}
              onChange={setInterval}
              value={interval}
              label="Interval"
              clearable={false}
            />
            {interval.value === 'custom' && (
              <DateRangePicker
                startTimeStamp={timestamp.start}
                endTimeStamp={timestamp.end}
                onDateRangeSelect={(start, end) => {
                  setTimeStamp({
                    start,
                    end
                  });
                }}
                customValidation={({ startTimeStamp }) => {
                  const startDate = fromUnixTime(startTimeStamp);
                  const today = set(new Date(), {
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                    milliseconds: 0
                  });
                  const isTodayOrFutureDate =
                    isAfter(startDate, today) || isEqual(startDate, today);
                  if (!isTodayOrFutureDate) {
                    return {
                      type: 'from',
                      message: 'From date should be in future'
                    };
                  }
                }}
              />
            )}
          </div>
        </div>
        <div className="sdr__callout-container">
          <Callout
            variant="info"
            title="Note"
            content="The following data is independent of the selected interval on top. To view the total number of subscriptions due to renew in a future interval, select the interval above."
          />
        </div>
        <div className="sdr__download_container">
          <Button onClick={generateReport} primary>
            Download List
          </Button>
        </div>
      </Card>
    </article>
  );
}
