import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { getAnalyticalReportsData } from '../../api';
import { Table } from '../../components/library-components';
import currencyUtils from '../../helpers/currencyUtils';
import './top_plans.scss';

function TopPlans ({ intervalDuration, intervalUnit, currency }) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [revenuePlansLoaded, setRevenuePlansLoaded] = useState(false);
  const [subscriptionPlansLoaded, setSubscriptionPlansLoaded] = useState(false);
  const [revenuePlans, setRevenuePlans] = useState([]);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  useEffect(() => {
    setRevenuePlansLoaded(false);
    setSubscriptionPlansLoaded(false);
  }, [intervalDuration, intervalUnit, currency]);

  useEffect(() => {
    if (selectedTabIndex === 0 && !revenuePlansLoaded && currency) {
      getAnalyticalReportsData('top_revenue_plans', {
        interval_duration: intervalDuration,
        interval_unit: intervalUnit,
        currency: currency
      }).then(({ data }) => {
        setRevenuePlans(data.plans);
        setRevenuePlansLoaded(true);
      });
    }
    if (selectedTabIndex === 1 && !subscriptionPlansLoaded) {
      getAnalyticalReportsData('top_subscription_plans', {
        interval_duration: intervalDuration,
        interval_unit: intervalUnit
      }).then(({ data }) => setSubscriptionPlans(data.plans));
      setSubscriptionPlansLoaded(true);
    }
  }, [
    intervalDuration,
    intervalUnit,
    currency,
    selectedTabIndex,
    revenuePlansLoaded,
    subscriptionPlansLoaded
  ]);
  return (
    <div className="top_plans__container">
      <h2 className="top_plans__title">Top 5 Plans</h2>
      <Tabs selectedIndex={selectedTabIndex} onSelect={setSelectedTabIndex}>
        <TabList>
          <Tab>Revenue</Tab>
          <Tab>Subscription</Tab>
        </TabList>
        <TabPanel>
          <Table
            header={['Plan Name', 'Revenue']}
            className="table-default--2cols"
          >
            {revenuePlans.map((plan) => (
              <a
                key={plan.subscription_plan_id}
                href={`/subscription_groups/${plan.subscription_group_id}/subscription_plans/${plan.subscription_plan_id}`}
                className="sub_group_plan_link"
              >
                <div className="table-default__row">
                  <div className="table-default__cell">{plan.title}</div>
                  {currency && (
                    <div className="table-default__cell">
                      {currencyUtils.convertToCurrencyWithSymbol(
                        plan.amount || 0,
                        currency
                      )}
                    </div>
                  )}
                </div>
              </a>
            ))}
          </Table>
        </TabPanel>
        <TabPanel>
          <Table
            header={['Plan Name', 'Subscriptions']}
            className="table-default--2cols"
          >
            {subscriptionPlans.map((plan) => (
              <a
                key={plan.subscription_plan_id}
                href={`/subscription_groups/${plan.subscription_group_id}/subscription_plans/${plan.subscription_plan_id}`}
                className="sub_group_plan_link"
              >
                <div className="table-default__row">
                  <div className="table-default__cell">{plan.title}</div>
                  <div className="table-default__cell">{plan.count}</div>
                </div>
              </a>
            ))}
          </Table>
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default TopPlans;
