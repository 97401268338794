import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Button.scss';
import { UnstyledLink } from './UnstyledLink';

const Button = ({
  url, disabled, children, onClick, onFocus, onBlur, external,
  primary, secondary, submit, accessibilityLabel, destructive, value, dataTest, customClass
}) => {
  const className = classNames(
    'button',
    customClass,
    {
      disabled, primary, secondary, destructive
    }
  );

  const type = submit ? 'submit' : 'button';

  const childMarkup = children ? <span>{children}</span> : null;

  const content = <span className='content'>{childMarkup}</span>;

  return (
    url
      ? <UnstyledLink
        url={url}
        external={external}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        className={className}
        disabled={disabled}
        aria-label={accessibilityLabel}
        dataTest={dataTest}
      >
        {content}
      </UnstyledLink>
      : <button
        type={type}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        className={className}
        disabled={disabled}
        aria-label={accessibilityLabel}
        data-test={dataTest}
      >
        {content}
      </button>
  );
};

Button.propTypes = {
  url: PropTypes.string,
  children: PropTypes.node,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  destructive: PropTypes.bool,
  external: PropTypes.bool,
  submit: PropTypes.bool,
  accessibilityLabel: PropTypes.string,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string
};

export default Button;
