import React from 'react';
import { Button as EmButton } from '@quintype/em/components/button';
import { Dialog } from '@quintype/em/components/dialog';
import './modified-em-dialog.scss';

export function ModifiedEmDialog ({
  isOpen,
  onClose,
  title,
  content,
  firstButton,
  secondButton
}) {
  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <div className="modified_em__dialog-container">
        <h3 className="modified_em__dialog-title">{title}</h3>
        <p className="modified_em__dialog-content">{content}</p>
        <div className="modified_em__dialog-button-container">
          {firstButton.content && <EmButton
            type={firstButton.type}
            variant={firstButton.variant}
            onClick={firstButton.onClick}
            classname="modified_em__first-button"
          >
            {firstButton.content}
          </EmButton>}
          {secondButton.content && <EmButton
            type={secondButton.type}
            onClick={secondButton.onClick}
            variant={secondButton.variant}
            greyed={secondButton.greyed}
          >
            {secondButton.content}
          </EmButton>}
        </div>
      </div>
    </Dialog>
  );
}
