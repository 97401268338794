import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash-es';

import { getSubscriptionAttempts, getSubscriptionGroupsLite, getSubscriptionGroup, getSubscriptionAttemptsCsv, getSubscriptionAttemptsReports, getDownloadUrl } from '../api';
import { SET_SUBSCRIPTION_ATTEMPTS, UPDATE_SUBSCRIPTION_GROUPS, UPDATE_SUBSCRIPTIONS_REPORTS } from '../actions';
import { Button, TitleBar, Card, Layout, SelectField, StatusMessage, Table, Pagination, Description, Link } from '../components/library-components';
import { getDateFromISO } from '../helpers/dateUtils';
import { DATE } from '../constants';
import PhoneIdentity from '../components/subscriber-identity/phone-identity';

import '../../stylesheets/pages/subscriptions.scss';

export class SubscriptionAttempts extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      selectedGroup: this.props.subscriptionGroupId,
      total_pages: 1,
      total_entries: 0,
      showNotification: { isNotificationOpen: false, status: '', statusMessage: '' },
      isDownloadDisabled: false
    };

    this.onChangeOfGroups = this.onChangeOfGroups.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.updateSubscriptionsReport = this.updateSubscriptionsReport.bind(this);
  }

  onPageChange (page) {
    this.loadSubscriptionAttempts(page);
  }

  updateSubscriptionsReport (data) {
    this.loadSubscriptionsReports();
    this.setState({ showNotification: { isNotificationOpen: true, status: data.description.text.status, statusMessage: data.description.text.message } });
  }

  setupChannelSubscription () {
    this.props.cableApp.notify_user = this.props.cableApp.cable.subscriptions.create('NotifyUsersChannel', {
      connected: function () {
        // Called when the subscription is ready for use on the server
      },

      disconnected: function () {
        // Called when the subscription has been terminated by the server
      },

      received: function (data) {
        // Called when there's incoming data on the websocket for this channel
        this.updateSubscriptionsReport(data);
      },

      updateSubscriptionsReport: this.updateSubscriptionsReport
    });
  }

  render () {
    return (
      <Layout>
        <TitleBar title='Subscription Attempts' />

        <Card>
          <div className='Subscription-group-list'>
            <SelectField options={this.props.subscriptionGroups}
              value={this.state.selectedGroup}
              onChange={this.onChangeOfGroups}
              clearable={false}
              searchable={false}
              placeholder={false}
              disabled={this.props.subscriptionGroupId !== 'all'}
            />

            {this.state.selectedGroup !== 'all' &&
              <Description>

                {_.get(this.props.subscriptionsReports, ['0', 'status']) === 'generated' && this.props.subscriptionsReports[0].url && !this.props.subscriptionsReports[0].filename &&
                  <Link iconLeft='download-arrow' url={this.props.subscriptionsReports[0].url}>
                    {this.props.subscriptionsReports[0] != null &&
                      this.props.subscriptionsReports[0]['status'] === 'generated' &&
                      getDateFromISO(_.get(this.props.subscriptionsReports, ['0', 'created_at']), DATE.DATE_SEC_FMT)}
                  </Link>
                }

                {_.get(this.props.subscriptionsReports, ['0', 'status']) === 'generated' && this.props.subscriptionsReports[0].filename &&
                  <Link iconLeft='download-arrow' onClick={() => this.downloadReport(this.props.subscriptionsReports[0].filename)}>
                    {this.props.subscriptionsReports[0] != null &&
                      this.props.subscriptionsReports[0]['status'] === 'generated' &&
                      getDateFromISO(_.get(this.props.subscriptionsReports, ['0', 'created_at']), DATE.DATE_SEC_FMT)}
                  </Link>
                }

                <Button disabled={this.state.isDownloadDisabled} onClick={(e) => { e.preventDefault(); this.generateReport(); }}>Generate CSV</Button>

              </Description>
            }
          </div>
          <Table header={['Identity', 'Subscription Plan', 'Subscription Type', 'Date', 'Status']} className='table-default--4cols'>
            {this.props.subscriptionAttempts.map((subscriptionAttempt, index) =>
              <div key={index} className='table-default__row'>
                <div className='table-default__cell truncate-word'>
                  {subscriptionAttempt.preferred_identity.value}
                  {subscriptionAttempt.preferred_identity.provider !== 'phone_number' && < PhoneIdentity phoneNum={subscriptionAttempt.subscriber_phone_number} />}
                </div>
                <div className='table-default__cell'>{subscriptionAttempt.plan_name}</div>
                <div className='table-default__cell'>{_.capitalize(subscriptionAttempt.group_type)}</div>
                <div className='table-default__cell'>{_.replace(subscriptionAttempt.updated_at, 'T', ' ')}</div>
                <div className='table-default__cell'>{subscriptionAttempt.status}</div>
              </div>
            )}
          </Table>

          <Pagination
            onPageChange={this.onPageChange}
            pageCount={this.state.total_pages}
            containerClassName='pagination'
            pageRangeDisplayed={0}
            marginPagesDisplayed={1}
            pageClassName='pagination__item'
            breakClassName='pagination__item'
            breakLabel='...'
            totalEnteries={`${this.state.total_entries} rows`}
          />

        </Card>

        {this.state.showNotification.isNotificationOpen &&
          <StatusMessage statusMessage={this.state.showNotification.statusMessage}
            status={this.state.showNotification.status}
            onCancel={() => this.setState({ showNotification: { isNotificationOpen: false, status: '', statusMessage: '' } })} />}
      </Layout>
    );
  }

  loadSubscriptionAttempts (pageNum = 1) {
    getSubscriptionAttempts(this.state.selectedGroup, pageNum, 'all').then(resp => {
      this.setState({
        total_pages: resp.total_pages,
        total_entries: resp.total_entries
      });

      return this.props.setSubscriptionAttempts(resp.subscription_attempts);
    });
  }

  onChangeOfGroups (value) {
    this.setState({ selectedGroup: value.value }, function () { this.loadSubscriptionAttempts(); });
  }

  loadSubscriptionsReports () {
    if (this.state.selectedGroup !== 'all') {
      getSubscriptionAttemptsReports(this.state.selectedGroup).then(subscriptionsReports => {
        this.props.setSubscriptionsReports(subscriptionsReports);
        if (_.get(subscriptionsReports, ['0', 'status']) === 'in_progress') {
          this.setState({ isDownloadDisabled: true });
        } else {
          this.setState({ isDownloadDisabled: false });
        }
      });
    }
  }

  loadSubscriptionGroups () {
    getSubscriptionGroupsLite('all').then(subscriptionGroups => {
      return this.props.setSubscriptionGroups(subscriptionGroups);
    });
  }

  loadSubscriptionGroup (id) {
    getSubscriptionGroup(id).then(subscriptionGroup => {
      return this.props.setSubscriptionGroups([subscriptionGroup]);
    });
  }

  componentDidMount () {
    this.props.subscriptionGroupId !== 'all'
      ? this.loadSubscriptionGroup(this.props.subscriptionGroupId)
      : this.loadSubscriptionGroups();
    this.loadSubscriptionAttempts();
    this.loadSubscriptionsReports();
    this.setupChannelSubscription();
  }

  generateReport () {
    getSubscriptionAttemptsCsv(this.state.selectedGroup).then((message) => {
      this.setState({ showNotification: { isNotificationOpen: true, status: 'success', statusMessage: message }, isDownloadDisabled: true });
      this.props.setSubscriptionsReports([]);
    });
  }

  downloadReport (filename) {
    getDownloadUrl(filename).then(downloadUrl => (window.location.href = downloadUrl));
  }

  isDownloadDisabled () {
    return _.get(this.props.subscriptionsReports, ['0', 'status']) === 'in_progress';
  }
}

function createGroupOptions (subscriptionGroups) {
  const allGroupOption = [{ label: 'All Groups', value: 'all' }];
  return _.concat(allGroupOption, _.map(subscriptionGroups, (group) => ({ label: group.name, value: group.id.toString() })));
}

function mapStateToProps (state) {
  return {
    subscriptionAttempts: state.subscriptionAttempts || [],
    subscriptionGroups: createGroupOptions(state.subscriptionGroups || []),
    subscriptionsReports: state.subscriptionsReports || []
  };
}

function mapDispatchToProps (dispatch) {
  return {
    setSubscriptionAttempts: (subscriptionAttempts) => dispatch({ type: SET_SUBSCRIPTION_ATTEMPTS, subscriptionAttempts: subscriptionAttempts }),
    setSubscriptionGroups: (subscriptionGroups) => dispatch({ type: UPDATE_SUBSCRIPTION_GROUPS, subscriptionGroups: subscriptionGroups }),
    setSubscriptionsReports: (subscriptionsReports) => dispatch({ type: UPDATE_SUBSCRIPTIONS_REPORTS, subscriptionsReports: subscriptionsReports })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionAttempts);
