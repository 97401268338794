import React from 'react';

import { Logo, Button } from '../components/library-components';
import '../../stylesheets/pages/home.scss';

export default function Home () {
  const DashboardBtn = () => {
    return (
      <Button submit primary onClick={(e) => { window.location = '/dashboard'; }}> Go to Dashboard </Button>
    );
  };

  return (
    <div>
      <section className='home-container'>
        <div className='home-container__content'>
          <Logo fullLogo={true} />
          <DashboardBtn />
        </div>
      </section>
    </div>);
}
