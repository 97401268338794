import React from 'react';
import PropTypes from 'prop-types';

import wrapLabelAndError from '../../behaviors/wrap_label_and_error';

import './text-area-with-label.scss';

const TextArea = ({
  placeholder, value, onChange, name, rows, cols, dataTest, disabled = false
}) => (
  <textarea
    className='text-area'
    rows={rows}
    cols={cols}
    placeholder={placeholder}
    value={value}
    disabled={disabled}
    onChange={onChange}
    name={name}
    data-test={dataTest}
  />
);

TextArea.propTypes = {
  placeholder: PropTypes.string,
  rows: PropTypes.string,
  cols: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  dataTest: PropTypes.string
};

const TextAreaWithLabel = wrapLabelAndError(TextArea);
export default TextAreaWithLabel;
