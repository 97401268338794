import React from 'react';
import {
  Button
} from '@quintype/em/components/button';
import Reports from './reports';
import './download_reports.scss';

function DownloadReports ({ generateReport, reports }) {
  return (
    <div>
      <h5 className='download_reports_heading'>Download Reports</h5>
      <Button classname='generate_and_download' testId='generate_and_download' primary onClick={() => generateReport()}> Generate and download </Button>
      <div className='generate_hint'>Generates a new report with currently active filters</div>
      <div className='recently_downloaded'>Recently Downloaded</div>
      <Reports reports={reports} />
    </div>
  );
}

export default DownloadReports;
