import React from 'react';
import PropTypes from 'prop-types';

const BackIcon = ({ width = '16', height = '16', fill = '#132437' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width={width} height={height} viewBox='0 0 16 16'>
    <defs>
      <path
        id='back-a'
        d='M5.5 8.548l5.166-4.817A1 1 0 1 0 9.302 2.27L3.318 7.847a1 1 0 0 0
            .03 1.49l5.984 5.14a1 1 0 0 0 1.304-1.516L5.499 8.548z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <mask id='back-b' fill='#fff'>
        <use xlinkHref='#back-a' />
      </mask>
      <use fill={fill} fillRule='nonzero' xlinkHref='#back-a' />
      <g fill='#818A98' mask='url(#back-b)'>
        <path d='M0 0h16v16H0z' />
      </g>
    </g>
  </svg>
);

BackIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
};

export default BackIcon;
