import React from 'react';

import { Chip } from '@quintype/em/components/chip';

import Download from '../../pages/Icons/download';
import { getDownloadUrl } from '../../api';
import { getDateFromISO } from '../../helpers/dateUtils';
import { DATE } from '../../constants';

import './reports.scss';

function downloadReport (filename) {
  getDownloadUrl(filename).then(
    downloadUrl => (window.location.href = downloadUrl)
  );
}

function Reports ({ reports, showFilters = true }) {
  return (
    <div>
      {reports.slice(0, 5).map(report =>
        <div key={report.id}>
          <div data-test-id='heading_and_download' className='heading_and_download'>
            <div>
              <h4>
                {getDateFromISO(
                  report.created_at
                  , DATE.DATE_SEC_FMT
                )}
              </h4>
            </div>
            <div data-test-id={`download_report report_${report.id}`} className='file_download file_download_background' onClick={() => downloadReport(report.filename)}>
              {report.status === 'generated' && <Download />}
            </div>
          </div>
          {showFilters &&
            <div className='chips'>
              {report.filters && report.filters.map((filter, id) => <Chip value={filter} key={id} />)}
            </div>}
          <hr />
        </div>)}
    </div>);
}

export default Reports;
