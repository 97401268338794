import React from 'react';
import _ from 'lodash-es';
import classnames from 'classnames';
import '../components/accordion.scss';
import { SliderCheckbox } from '../components/library-components';
import UpArrow from '../components/library-components/Icons/UpArrow';
import DownArrow from '../components/library-components/Icons/DownArrow';

export default class PaymentGatewayAccordion extends React.Component {
  render () {
    const cardClass = classnames('accordion-item accordion-item--white', { 'accordion-item--collapsed': true, 'accordion-item--expand': this.props.active });
    const iconClass = classnames('accordion-item--collapse-icon');
    const logoClass = classnames('accordion-item-header__logo', this.props.paymentGateway);
    const errorMessageClass = classnames('error-message', this.props.paymentGateway);

    const renderSliderCheckBox = () => {
      if (this.props.showSlider) {
        return <SliderCheckbox enabled={_.get(this.props.paymentGateways, [this.props.paymentGateway, 'enabled'], false)} onChange={(e) => this.props.toggleSwitch(e)} />;
      }
    };

    return <div className={cardClass} data-test={this.props.paymentGateway} >
      <div className='accordion-item__header accordion-item-header'>
        <div className='accordion-item-header__left' onClick={(e) => this.props.toggleAccordion(e)}>
          <h2 className={logoClass}>{this.props.logo}</h2>
          <div className={errorMessageClass}>{_.get(this.props.status, [this.props.paymentGateway], '')}</div>
        </div>
        <div className='accordion-item-header__right'>
          {renderSliderCheckBox()}
          { <span className={iconClass} onClick={(e) => this.props.toggleAccordion(e)}>{this.props.active ? <UpArrow /> : <DownArrow />}</span> }
        </div>
      </div>
      <div className='accordion-item__body'>
        {this.props.children}
      </div>
    </div>;
  }
}
