import React from 'react';
import { Layout, TitleBar, Card, Table } from '../components/library-components';

import { getOrganizations } from '../api';

class Organizations extends React.Component {
  constructor () {
    super();
    this.state = {
      organizations: []
    };
  }

  componentDidMount () {
    getOrganizations()
      .then(({ organizations }) => this.setState({ organizations }));
  }

  renderOrgDetails (org) {
    return (
      <a href={`/organizations/${org.id}`} className='table-default__row' data-test-id={`organization-${org.id}`}>
        <div className='table-default__cell truncate-word' data-test-id='org-name'>{org.name}</div>
        <div className='table-default__cell truncate-word' data-test-id='org-email'>{org.primary_email}</div>
        <div className='table-default__cell truncate-word' data-test-id='org-domains'>{org.domains.join(' , ')}</div>
      </a>
    );
  }

  render () {
    return (
      <Layout>
        <TitleBar
          title='Organizations'
          button primary buttonText='Add new Organization' buttonUrl='/organizations/new'
          buttonDataTestID='add-organization-btn'
        />
        <Card title='Organizations List'>
          <Table
            header={[
              'Name',
              'Primary Email',
              'Domains'
            ]}
          >
            {this.state.organizations.map(org => this.renderOrgDetails(org))}
          </Table>
        </Card>
      </Layout>
    );
  }
}

export default Organizations;
