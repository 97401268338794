import React from 'react';
import PropTypes from 'prop-types';
import './status-message.scss';
import classNames from 'classnames';

const StatusMessage = ({ statusMessage, status, onCancel }) => {
  const statusColor = status === 'success' ? 'status-bar--success' : 'status-bar--failure';
  const className = classNames(
    'status-bar',
    statusColor
  );

  return (
    <div className={className}>
      <span>{ statusMessage }</span>
      {onCancel && <div className='status-bar-close-button' onClick={onCancel}>X</div>}
    </div>
  );
};

StatusMessage.propTypes = {
  statusMessage: PropTypes.string,
  status: PropTypes.string,
  onCancel: PropTypes.func
};

export default StatusMessage;
