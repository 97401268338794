import React from 'react';
import { connect } from 'react-redux';
import TaxRates from '../components/invoicing/tax_rates';
import InvoiceConfiguration from '../components/invoicing/invoice_configuration';
import { Layout, TitleBar } from '../components/library-components';

export class Invoicing extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
    };
  }

  render () {
    return <Layout>
      <TitleBar title='Invoicing & Taxes'/>
      <TaxRates />
      <InvoiceConfiguration />
    </Layout>;
  }
}

function mapStateToProps (state) {
  return {
  };
}

function mapDispatchToProps (dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoicing);
