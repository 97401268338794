import React from 'react';
import {
  TitleBar,
  Layout,
  StatusMessage
} from '../components/library-components';
import { getMeteringSettings, getNewMeteringSettings } from '../api';
import MeteringRule from '../components/metering_rule/metering_rule';
import _ from 'lodash-es';
import './paywall_settings.scss';

const USER_TYPES = ['anonymous', 'logged_in'];

const ANONYMOUS_CONFIGURATION_TYPES = [
  {
    user_type: 'anonymous',
    asset_access_type: 'login'
  },
  {
    user_type: 'anonymous',
    asset_access_type: 'subscription'
  }
];

const LOGGED_IN_CONFIGURATION_TYPES = [
  {
    user_type: 'logged_in',
    asset_access_type: 'subscription'
  }
];

var meterDefaultValues = {
  asset_limit: 5,
  asset_type: 'story',
  duration_value: 2,
  duration_unit: 'days'
};

class PaywallSettings extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      metered: {},
      errors: null,
      showNotification: {
        isNotificationOpen: false,
        status: '',
        statusMessage: ''
      },
      meteringSettings: []
    };
  }

  componentWillMount () {
    getMeteringSettings().then((meteringSettings) => {
      getNewMeteringSettings().then((initialMeteringSettings) => {
        meteringSettings = [
          ...ANONYMOUS_CONFIGURATION_TYPES,
          ...LOGGED_IN_CONFIGURATION_TYPES
        ].map((configType) => {
          return (
            _.find(meteringSettings, configType) ||
            Object.assign(
              {},
              initialMeteringSettings,
              {
                wall_type:
                  configType.asset_access_type === 'login'
                    ? 'registration_wall'
                    : 'pay_wall'
              },
              configType,
              meterDefaultValues
            )
          );
        });
        this.setState({ meteringSettings: meteringSettings });
      });
    });
  }

  filterExistingUserTypes (existingUserTypes) {
    const reducer = (accumulator, currentValue) => {
      if (existingUserTypes.includes(currentValue)) {
        return accumulator;
      } else {
        return accumulator.concat(currentValue);
      }
    };
    return USER_TYPES.reduce(reducer, []);
  }

  updateMeteringSettings (updatedMeteringSetting) {
    const userType = updatedMeteringSetting.user_type;

    const settingsWithoutChanges = this.state.meteringSettings.filter(
      (obj) =>
        !(obj.user_type === userType &&
        obj.asset_access_type === updatedMeteringSetting.asset_access_type)
    );

    const newState = [...settingsWithoutChanges, updatedMeteringSetting];

    this.setState({ meteringSettings: newState });
  }

  showStatusMessage (statusMessage) {
    this.setState({
      showNotification: {
        isNotificationOpen: true,
        status: statusMessage.status,
        statusMessage: statusMessage.statusMessage
      }
    });
  }

  render () {
    return (
      <Layout>
        <TitleBar title="Metered Wall Settings" />
        <h2 className='metered-settings-user-title'>Anonymous Users</h2>
        {this.state.meteringSettings.length > 0 &&
          ANONYMOUS_CONFIGURATION_TYPES.map((config) => (
            <MeteringRule
              key={`${config.user_type}-${config.asset_access_type}`}
              meteringSetting={_.find(this.state.meteringSettings, config)}
              showStatusMessage={this.showStatusMessage.bind(this)}
              updateMeteringSettings={this.updateMeteringSettings.bind(this)}
            />
          ))}

        <h2 className='metered-settings-user-title'>Logged in Users</h2>
        {this.state.meteringSettings.length > 0 &&
          LOGGED_IN_CONFIGURATION_TYPES.map((config) => (
            <MeteringRule
              key={`${config.user_type}-${config.asset_access_type}`}
              meteringSetting={_.find(this.state.meteringSettings, config)}
              showStatusMessage={this.showStatusMessage.bind(this)}
              updateMeteringSettings={this.updateMeteringSettings.bind(this)}
            />
          ))}

        {this.state.showNotification.isNotificationOpen && (
          <StatusMessage
            statusMessage={this.state.showNotification.statusMessage}
            status={this.state.showNotification.status}
            onCancel={() =>
              this.setState({
                showNotification: {
                  isNotificationOpen: false,
                  status: '',
                  statusMessage: ''
                }
              })
            }
          />
        )}
      </Layout>
    );
  }
}

export default PaywallSettings;
