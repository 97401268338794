export const UPDATE_SUBSCRIPTION_GROUPS = 'UPDATE_SUBSCRIPTION_GROUPS';
export const SET_SUBSCRIPTION_GROUP = 'SET_SUBSCRIPTION_GROUP';

export const UPDATE_SUBSCRIBER_ASSETS = 'UPDATE_SUBSCRIBER_ASSETS';
export const UPDATE_SUBSCRIBER_SUBSCRIPTIONS =
  'UPDATE_SUBSCRIBER_SUBSCRIPTIONS';
export const UPDATE_SUBSCRIBER_SUBSCRIPTION = 'UPDATE_SUBSCRIBER_SUBSCRIPTION';

export const SET_IDENTITIES = 'SET_IDENTITIES';

export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';

export const SET_ERRORS = 'SET_ERRORS';

export const SET_PAYMENT_GATEWAYS = 'SET_PAYMENT_GATEWAYS';

export const SET_SUBSCRIPTION_ATTEMPTS = 'SET_SUBSCRIPTION_ATTEMPTS';

export const SET_SUBSCRIPTION_PLAN = 'SET_SUBSCRIPTION_PLAN';

export const SET_COUPONS = 'SET_COUPONS';

export const UPDATE_SUBSCRIPTIONS_REPORTS = 'UPDATE_SUBSCRIPTIONS_REPORTS';

export const SET_SETTINGS = 'SET_SETTINGS';

export const SET_ACCOUNT_USERS = 'SET_ACCOUNT_USERS';

export const SET_WEBHOOKS = 'SET_WEBHOOKS';
