import React from 'react';
import './ProgressBar.scss';

const ProgressBar = ({ progressPercent, progressText }) => {
  const style = {
    width: progressPercent
  };
  return (
    <div className='progress-bar-container'>
      <div className='progress-bar'>
        <span style={style} />
      </div>
      {progressText && <span className='info'>{progressPercent} {progressText}</span>}
    </div>
  );
};

export default ProgressBar;
