import React from 'react';
import { Radio, RadioOption } from '@quintype/em/components/radio-button';
import Select from '@quintype/em/components/select';
import { NumberField } from '@quintype/em/components/number-field';
import _ from 'lodash-es';

const DURATION_UNITS_ONE_TIME = ['days', 'weeks', 'months', 'years'].map((k) => ({ label: k, value: k }));

export class PublishedAt extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      selected: 'anytime',
      fromOptions: [
        { label: 'Anytime', value: 'since_the_beginning' },
        { label: 'Date of Activation', value: 'subscription_activation' }
      ],
      durationLength: null,
      durationUnit: null,
      from: null
    };
    this.updateState = this.updateState.bind(this);
  }

  updateState () {
    if (this.state.selected === 'anytime') {
      this.props.updateAsset('published_at', {});
    }

    if (this.state.selected === 'published_at') {
      this.props.updateAsset('published_at', { from: this.state.from, to: this.getDuration() });
    }
  }

  getDuration () {
    if (this.state.durationLength && this.state.durationUnit) {
      return {
        duration_length: this.state.durationLength,
        duration_unit: this.state.durationUnit
      };
    } else {
      return null;
    }
  }

  componentDidMount () {
    if (this.props.published_at_field === undefined || Object.keys(this.props.published_at_field).length === 0) {
      return this.setState({ selected: 'anytime' }, () => this.updateState());
    }

    this.setState({
      selected: 'published_at',
      from: this.props.published_at_field.from,
      durationLength: this.props.published_at_field.to.duration_length,
      durationUnit: this.props.published_at_field.to.duration_unit
    });
  }

  render () {
    return (
      <div>
        <Radio
          name="published_at"
          label=""
          align="vertical"
          selected={this.state.selected}
          onChange={e => this.setState({ selected: e.target.value }, () => this.updateState())}
        >
          <RadioOption value="anytime">Anytime</RadioOption>
          <RadioOption value="published_at">Published at</RadioOption>
        </Radio>
        { this.state.selected === 'published_at' && <Select
          value={this.state.from}
          label='From'
          options={this.state.fromOptions}
          onChange={e => this.setState({ from: e.value }, () => this.updateState()) }
        />
        }

        { this.state.selected === 'published_at' && !this.props.hideToField && <div className='form-row'>
          <div><bold>To</bold></div>
          <NumberField
            value={this.state.durationLength}
            label="Duration"
            onChange={(value) => this.setState({ durationLength: value }, () => this.updateState())}
            placeholder="Enter duration length"
            errorMessage={_.get(this.props.errors, ['duration_length', '0'])}
          />
          <div className='width-150'>
            <Select
              value={this.state.durationUnit}
              label='Duration Unit'
              options={DURATION_UNITS_ONE_TIME}
              onChange={e => this.setState({ durationUnit: e.value }, () => this.updateState())}
            />
          </div>
          <div>after Activation</div>
        </div> }
      </div>
    );
  }
}
