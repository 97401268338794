import React from 'react';
import PropTypes from 'prop-types';
import ReactTagsInput from 'react-tagsinput';
import './TagsInput.scss';

const TagsInput = ({
  value, onChange, onlyUnique = true, dataTest, inputProps, className = ''
}) => {
  return (
    <div data-test={dataTest} className="react-tagsinput-wrap">
      <ReactTagsInput
        className={`react-tagsinput ${className}`}
        value={value}
        onChange={onChange}
        inputProps={inputProps}
        onlyUnique={onlyUnique}
      />
    </div>
  );
};

TagsInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  dataTest: PropTypes.string,
  className: PropTypes.string,
  onlyUnique: PropTypes.bool,
  inputProps: PropTypes.object
};

export default TagsInput;
