import React from 'react';

const Paytm = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='80' height='32' viewBox='0 0 80 32'>
      <g fill='none' fillRule='evenodd'>
        <path fill='#233375' d='M.063 5.645c.002-.47.319-.705.709-.705h6.894c2.115.24 3.593 1.315 3.738 4.139V12.9c0 .567-.035 1.044-.198 1.596-.548 1.84-1.867 2.753-3.836 2.88H4.413v4.677c0 .352-.295.527-.653.527H.655c-.378 0-.656-.21-.655-.632L.063 5.645zm4.382 3.63H6.28c.412 0 .751.338.751.75v2.208a.755.755 0 0 1-.751.752H4.445v-3.71zM15.121 4.94h4.727c2.767.164 4.264 1.645 4.226 4.716V21.03c0 .882-.874 1.55-1.621 1.55h-6.197c-1.88 0-3.12-1.494-3.43-2.958-.297-1.407-.076-3.109-.076-4.545 0-2.217 1.441-4.416 3.842-4.416h2.522c.734-.04.701-1.675 0-1.618h-3.942c-.4 0-.68-.216-.717-.63v-2.98c0-.38.307-.493.666-.493zm2.607 9.833h1.441c.34 0 .62.285.62.632v2.562c0 .349-.28.633-.62.633h-1.441a.626.626 0 0 1-.618-.633v-2.562c0-.347.277-.632.618-.632zM25.342 5.77v7.134c.175 2.237 2.2 3.912 4.401 3.912h2.038c.842 0 .791 1.404 0 1.404h-4.407c-.478 0-.652.247-.652.665v3.14c0 .317.258.555.596.555H31.4c3.405 0 5.344-1.62 5.344-5.252V5.494c0-.386-.246-.536-.483-.536H32.84c-.316 0-.5.34-.5.59v6.484c0 .279-.157.443-.409.443h-1.62c-.372 0-.577-.2-.577-.554V5.66c0-.415-.167-.72-.63-.72h-3.292c-.374 0-.47.368-.47.83' />
        <path fill='#00A3DA' d='M38.857 9.156V6.071c0-.331.267-.602.59-.602h.728c1.875-.398 2.32-1.52 3.466-2.781.704-.779 1.323-.983 1.779-.158V5.47h1.718c.324 0 .589.27.589.602v3.085c0 .33-.266.602-.59.602H45.42v12.64a.598.598 0 0 1-.59.602h-3.024a.597.597 0 0 1-.588-.602V9.758h-1.77a.6.6 0 0 1-.59-.602M60.888 21.838a.757.757 0 0 1-.764.742H57.19a.756.756 0 0 1-.766-.742V10.31c.023-1.246-2.543-1.44-2.543 0v11.527a.756.756 0 0 1-.765.742h-2.933a.758.758 0 0 1-.767-.742V5.858c0-.412.346-.747.767-.747h2.933c.421 0 .765.335.765.747v.219c1.715-1.605 4.688-1.351 6.27.316 2.977-3.34 8.077-.364 7.841 3.3 0 4.05-.037 8.06-.037 12.145a.757.757 0 0 1-.764.742h-2.935a.756.756 0 0 1-.765-.742v-11.45c-.168-1.6-2.636-1.226-2.632 0 0 3.805.03 7.61.03 11.45' />
      </g>
    </svg>
  );
};

export default Paytm;
