import React from 'react';
import { Callout } from '@quintype/em/components/callout';
import { Radio, RadioOption } from '@quintype/em/components/radio-button';
import currencyUtils, { ALL_CURRENCIES } from '../helpers/currencyUtils';
import { Button as EmButton } from '@quintype/em/components/button';
import EmSelect from '@quintype/em/components/select';

function handleFallbackButtonClick () {
  window.location = `/general_settings?src=plan-fallback-button`;
}

function getCustomCurrencies (accountFallbackCurrency) {
  if (accountFallbackCurrency) {
    // All currencies - accountFallbackCurrency
    return currencyUtils.getRemainingCurrencies([accountFallbackCurrency]);
  }
  return ALL_CURRENCIES;
}

function ConfigurePlanFallbackCurrency ({
  multiCurrencyEnabled,
  isMultipleCurrency,
  accountFallbackCurrency,
  selectedFallbackCurrency,
  onSelectedFallbackCurrencyUpdate,
  fallbackCurrency,
  onFallbackCurrencyChange
}) {
  const showMulticurrencyConfig = multiCurrencyEnabled && isMultipleCurrency;
  return (
    <div className="plan__fallback_container">
      {showMulticurrencyConfig && !accountFallbackCurrency && (
        <div className="plan__set_account_currency">
          <Callout
            variant="info"
            title="Set Account Fallback Currency"
            content="Fallback Currency will be used for those subscribers who are coming from the countries that do not support currencies
                    that are part of pricing list. If the custom fallback currency is set, then it will override the account fallback currency."
          />
          <div className="plan__fallback_button">
            <EmButton
              type="primary"
              onClick={handleFallbackButtonClick}
            >
              Set Fallback Currency
            </EmButton>
          </div>
        </div>
      )}
      {showMulticurrencyConfig && (
        <div className="plan__fallback_radio_buttons">
          <Radio
            name="fallback currency"
            label="Fallback Currency"
            align="vertical"
            selected={selectedFallbackCurrency}
            onChange={(e) => onSelectedFallbackCurrencyUpdate(e.target.value)}
          >
            {accountFallbackCurrency && (
              <RadioOption value="account">Use Default ({accountFallbackCurrency})</RadioOption>
            )}
            <RadioOption value="local">Custom</RadioOption>
          </Radio>
        </div>
      )}
      {selectedFallbackCurrency === 'local' && showMulticurrencyConfig && (
        <div className="plan__fallback_custom_select ">
          <EmSelect
            label="Choose Currency"
            value={fallbackCurrency || ''}
            options={getCustomCurrencies()}
            clearable={false}
            onChange={(currency) => {
              onFallbackCurrencyChange(currency.value);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default ConfigurePlanFallbackCurrency;
