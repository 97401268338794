import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './link.scss';
import AddNew from '../Icons/add-new';
import Pencil from '../Icons/Pencil';
import UpArrow from '../Icons/UpArrow';
import DownArrow from '../Icons/DownArrow';
import Integrations from '../Icons/Integrations';

import DownloadArrow from '../Icons/DownloadArrow';

const Link = ({
  external, children, url, onClick, destructive, iconLeft, iconRight, active, dataTest
}) => {
  const Icon = (item, active) => {
    const fillColor = active ? '#2f81e4' : '#818A98';
    let icon;
    switch (item) {
      case 'add-new':
        icon = <AddNew />;
        break;
      case 'up-arrow':
        icon = <UpArrow />;
        break;
      case 'down-arrow':
        icon = <DownArrow />;
        break;
      case 'pencil':
        icon = <Pencil fill={fillColor} />;
        break;
      case 'integrations':
        icon = <Integrations fill={fillColor} />;
        break;
      case 'download-arrow':
        icon = <DownloadArrow fill={fillColor} />;
        break;
      default:
        icon = <Pencil fill={fillColor} />;
    }
    return icon;
  };

  Icon.propTypes = {
    item: PropTypes.string
  };

  return (
    <a target={external ? '_blank' : undefined} href={url} data-test={dataTest} className={classnames('Link', { 'Link--destructive': destructive })} onClick={onClick} >
      {iconLeft && <span className='Link__icon-left'> {Icon(iconLeft, active)} </span>}
      <span>{children}</span>
      {iconRight && <span className='Link__icon-right'>{Icon(iconRight, active)} </span>}
    </a>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  external: PropTypes.bool,
  url: PropTypes.string,
  onClick: PropTypes.func,
  destructive: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  dataTest: PropTypes.string
};

export default Link;
