import React from 'react';
import PropTypes from 'prop-types';

const FileDownload = ({ width, height, fill }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 25 24'>
      <path fill={fill} fillRule='evenodd' d='M17.046 21.25c.469 0 .867-.164 1.195-.492.328-.328.493-.727.493-1.196V7.891c0-.47-.165-.868-.493-1.196l-2.953-2.953c-.328-.328-.726-.492-1.195-.492H6.92c-.469 0-.867.164-1.195.492-.328.328-.492.727-.492 1.196v14.624c0 .47.164.868.492 1.196.328.328.726.492 1.195.492h10.125zm0-1.125H6.921c-.164 0-.299-.053-.404-.158-.106-.106-.158-.24-.158-.405V4.938c0-.165.052-.3.158-.405.105-.105.24-.158.404-.158h6.188v3.656c0 .235.082.434.246.598.164.164.363.246.597.246h3.657v10.687c0 .165-.053.3-.159.405-.105.105-.24.158-.404.158zm.563-12.375h-3.375V4.41c.093.024.175.07.246.14l2.953 2.954c.07.07.129.152.176.246zm-5.625 10.688c.28 0 .515-.106.703-.317l2.39-2.496c.14-.164.229-.34.264-.527.035-.188.012-.375-.07-.563-.082-.187-.2-.334-.352-.44-.152-.105-.322-.157-.51-.157h-1.863v-3.094c0-.07-.03-.135-.088-.194-.058-.058-.123-.087-.193-.087h-.563c-.07 0-.134.029-.193.087-.059.059-.088.123-.088.194v3.094H9.558c-.188 0-.358.052-.51.158-.152.105-.27.252-.352.44-.082.187-.105.374-.07.562.035.187.123.363.264.527l2.39 2.496c.188.211.422.317.704.317zm0-1.196l-2.075-2.18h4.149l-2.074 2.18z' />
    </svg>

  );
};

FileDownload.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
};

FileDownload.defaultProps = {
  width: '25',
  height: '25',
  fill: '#4860BC'
};

export default FileDownload;
