import React from 'react';
import _ from 'lodash-es';
import { Button, ButtonGroup, TextFieldWithLabel } from '../library-components';
import { createOrUpdateTaxRate } from '../../api';
import validate from 'validate.js';

export default class TaxRate extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  onSave () {
    const errors = validate(this.props.taxRate, constraints);
    if (errors) {
      this.setState({ errors });
      return;
    }

    createOrUpdateTaxRate(this.props.taxRate).then((response) => {
      this.props.afterSave();
    }, (errorResponse) => {
      this.props.afterSave(errorResponse.errors);
    });
  }

  render () {
    const taxRate = this.props.taxRate || {};
    const { errors } = this.state;
    return <div className='assets-container'>
      { taxRate && <div className='form-row'>
        <TextFieldWithLabel
          label='Tax Name'
          value={taxRate.name}
          onChange={(e) => this.props.updateTaxRateField('name', e.target.value)}
          placeholder='Name of the tax'
          errors={_.get(errors, ['name', '0'])}
        />

        <TextFieldWithLabel
          label='Tax Rate %'
          value={taxRate.percentage}
          onChange={(e) => this.props.updateTaxRateField('percentage', e.target.value)}
          placeholder='Tax rate in percentage'
          errors={_.get(errors, ['percentage', '0'])}
        />
      </div>
      }
      <ButtonGroup>
        <Button primary onClick={(e) => this.onSave(e)}>Save</Button>
        <Button onClick={(e) => this.props.onCancel(e)}>Cancel</Button>
      </ButtonGroup>
    </div>;
  }
}

const constraints = {
  name: { presence: { allowEmpty: false, message: 'Required' } },
  percentage: { presence: true,
    numericality: { strict: true, lessThanOrEqualTo: 100, greaterThan: 0 },
    format: {
      pattern: /^(\d{0,2}\.\d{0,2}|\d{0,2}|\.\d{0,2})$/,
      message: 'can only have decimal scale up to 2'
    }
  }
};
