import dfnsFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

// Get the date in the required format
export function getDateInFormat (timeStamp) {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  const getDate = timeStamp.getDate();
  const getYear = timeStamp.getFullYear();
  const getMonth = `${months[new Date(timeStamp).getMonth()]}`;
  const fullDate = `${getDate} ${getMonth} ${getYear}`;
  return fullDate;
}

export function getDateFromISO (isoString, format) {
  return dfnsFormat(parseISO(isoString), format);
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export function getLastUpdatedString (seconds) {
  let delta = seconds;
  const lastUpdatedString = (delta, timeUnit) => {
    return `Last updated ${delta} ${timeUnit} ago`;
  };
  const days = Math.floor(seconds / 86400);
  if (days > 0) {
    return lastUpdatedString(days, 'days');
  }
  delta -= days * 86400;
  const hours = Math.floor(delta / 3600) % 24;
  if (hours > 0) {
    return lastUpdatedString(hours, 'hrs');
  }
  delta -= hours * 3600;
  const minutes = Math.floor(delta / 60) % 60;
  if (minutes > 0) {
    return lastUpdatedString(minutes, 'mins');
  }
  return lastUpdatedString(Math.max(1, seconds), 'secs');
}
