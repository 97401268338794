import React from 'react';
import _ from 'lodash-es';
import AreaChart from '../../components/library-components/chart/area_chart';
import { getAnalyticalReportRevenue, getUniqueCurrencies } from '../../api';
import OptionsFilter from '../options-filter';
import AnalyticsFilterBar from '../analytics-filter-bar';
import chartEvents from '../../helpers/chartUtils';
import { months } from '../../helpers/dateUtils';
import CurrencyFilter from '../currency-filter';
import SelectField from '../../components/library-components/SelectField/SelectField';
import '../../../stylesheets/components/subscriptions-revenue-graph.scss';
import { getRandomColor } from '../../helpers/colorUtils';

const DAY = 'DAY';

class SubscriptionsRevenueGraph extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      dateType: 'DAY',
      from: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      to: new Date(),
      plans: {},
      currentPlans: { initial: 'data' }, // to not trigger no data message
      currentSubscriptionsCreatedByPlan: { initial: 'data' }, // to not trigger no data message
      currentSubscriptionsCreatedByGroup: { initial: 'data' }, // to not trigger no data message
      currencies: [],
      groupBy: 'group',
      currentSelectedCurrency: null
    };
    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    this.handleDateTypeChange = this.handleDateTypeChange.bind(this);
    this.getValues = this.getValues.bind(this);
    this.getValuesForRevenueByPlan = this.getValuesForRevenueByPlan.bind(this);
    this.getRevenueForDuration = this.getRevenueForDuration.bind(this);
    this.clickCheckBox = this.clickCheckBox.bind(this);
    this.changeCurrency = this.changeCurrency.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.deselectAll = this.deselectAll.bind(this);
  }

  updateSubscriptionsData (data, type) {
    const newColors = Object.keys(data).map((plan) => data[plan]['color']);
    let x;
    (type === 'group') ? x = 'currentSubscriptionsCreatedByGroup' : x = 'currentSubscriptionsCreatedByPlan';
    this.setState({
      [x]: data,
      currentPlans: data,
      colors: newColors
    });
  }

  selectSubscriptionsData (data) {
    let newData = {};
    const newObj = {
      group: this.state.currentSubscriptionsCreatedByGroup,
      plan: this.state.currentSubscriptionsCreatedByPlan
    };
    // eslint-disable-next-line no-prototype-builtins
    if (!newObj[this.state.groupBy].hasOwnProperty('initial')) {
      newData = newObj[this.state.groupBy];
    } else {
      newData = data;
    }
    return newData;
  }

  clickCheckBox (e, planKey) {
    var currentPlans = Object.keys(this.state.currentPlans);
    if (e.target.checked) {
      currentPlans = currentPlans.concat(planKey);
    } else {
      currentPlans = currentPlans.filter((plan) => plan !== planKey);
    }

    const newCurrentPlans = _.pick(this.state.plans, currentPlans); // eslint-disable-line no-undef
    this.updateSubscriptionsData(newCurrentPlans, this.state.groupBy);
  }

  selectAll () {
    this.updateSubscriptionsData(this.state.plans, this.state.groupBy);
  }

  deselectAll () {
    this.updateSubscriptionsData({}, this.state.groupBy);
  }

  componentDidMount () {
    getUniqueCurrencies(this.state.from, this.state.to)
      .then((data) => {
        const currency = data[0];
        const self = this;
        this.setState({ currentSelectedCurrency: currency, currencies: data }, () => self.getFilteredData());
      });
  }

  getValues () {
    const values = this.state.currentPlans;
    return this.getValuesForRevenueByPlan(values, this.state.dateType);
  }

  // Get value type for axis
  getTypeForAxis (dateType) {
    return dateType === DAY ? 'date' : 'string';
  }

  getRevenueForDuration (subs, filter) {
    const planKeys = Object.keys(subs);
    const finalArray = [];

    Object.keys(subs[planKeys[0]] || {}).map(function (key) {
      let dateFormat = new Date(key.replace(/-/g, '/'));

      if (filter !== DAY) {
        dateFormat = `${months[new Date(key).getMonth()]} ${new Date(key).getFullYear()}`;
      }
      const tempArray = [dateFormat];
      planKeys.map(function (planKey) {
        tempArray.push(subs[planKey][key]);
      });
      finalArray.push(tempArray);
    });
    finalArray.pop();
    return finalArray;
  }

  // Get formatted values to populate the graph
  getValuesForRevenueByPlan (subs, filter) {
    const z = Object.keys(subs).map(function (key) {
      return { type: 'number', label: key };
    });
    const x = this.getRevenueForDuration(subs, filter);
    const y = [[{ type: this.getTypeForAxis(this.state.dateType), label: 'Date' }, ...z]];

    return y.concat(x);
  }

  handleDateRangeChange (from, to) {
    var self = this;
    this.setState({ from, to }, () => self.getFilteredData());
  }

  handleDateTypeChange (selectedDateType) {
    const self = this;
    this.setState({ dateType: selectedDateType.value }, () => self.getFilteredData());
  }

  randomColorGenerate (data) {
    const colors = Array.from(Array(Object.keys(data).length), getRandomColor);
    Object.keys(data).map((planName, index) => data[planName]['color'] = colors[index]); // eslint-disable-line no-return-assign
    return { data, colors };
  }

  updateCurrentPlansBasedOnIntersection (data) {
    const newData = this.selectSubscriptionsData(data);
    const allPlanKeys = Object.keys(data);
    const currentPlanKeys = Object.keys(newData);
    const intersection = _.intersection(allPlanKeys, currentPlanKeys);

    let newObj = {};
    intersection.map(item => {
      newObj = Object.assign(newObj, { [item]: newData[item] });
    });
    this.setState({
      currentPlans: newObj
    });
  }

  getFilteredData (opts) {
    getAnalyticalReportRevenue(this.state.from, this.state.to, this.state.dateType, this.state.currentSelectedCurrency, this.state.groupBy).then(data => {
      const dataWithColors = this.randomColorGenerate(data);
      this.setState({
        plans: dataWithColors.data,
        colors: dataWithColors.colors,
        errorMessage: null
      }, () => this.updateCurrentPlansBasedOnIntersection(data));
    }).catch(e => this.setState({ errorMessage: e.response.body.error.message }));
  }

  getCurrencyOptions () {
    return this.state.currencies.map(currency => ({ value: currency, label: currency }));
  }

  changeCurrency (e) {
    const self = this;
    this.setState({ currentSelectedCurrency: e.value }, () => self.getFilteredData());
  }

  setFilter (value) {
    const self = this;
    this.setState({ groupBy: value }, () => self.getFilteredData());
  }

  render () {
    return (
      <div>
        <AnalyticsFilterBar
          dateType={this.state.dateType}
          handleDateTypeChange={(e) => this.handleDateTypeChange(e)}
          from={this.state.from}
          to={this.state.to}
          errorMessage={this.state.errorMessage}
          handleDateRangeChange={this.handleDateRangeChange}
        />
        <div className='analytical-graph-wrap'>
          {Object.keys(this.state.currentPlans).length === 0 && <p className='analytical-graph-wrap__no-data-message'>{this.state.deselectAllCheckBoxes ? `Select ${this.state.groupBy}s to see data` : 'No data to display'}</p> }
          {Object.keys(this.state.currentPlans).length !== 0 && <AreaChart
            height={'400px'}
            width={'100%'}
            loader={<div className='loader-custom' />}
            values={this.getValues()}
            legendPosition='top'
            pointSize={0}
            colors={this.state.colors}
            seriesAxis0='Count'
            yAxisLabel='Revenue'
            yAxisKey='Count'
            hAxisLabel='Time'
            vAxisLabel='Revenue by subscription plan'
            hAxisFormat={'dd/MM'}
            vAxisFormat={'short'}
            isStacked
            chartEvents={chartEvents}
          />}
        </div>
        <div className='filters'>
          <div className='compare'>
            <h4 className='compare-heading'>Compare</h4>
            <SelectField
              options={[{ label: 'Groups', value: 'group' }, { label: 'Plans', value: 'plan' }]}
              clearable={false}
              className='select-provider'
              placeholder='Plans/Groups'
              searchable={false}
              valueKey='value'
              labelKey='label'
              value={this.state.groupBy}
              onChange={(e) => this.setFilter(e.value)}
            />
          </div>
          <CurrencyFilter options={this.getCurrencyOptions()} value={this.state.currentSelectedCurrency} changeCurrency={this.changeCurrency} />
        </div>
        <OptionsFilter
          options={this.state.plans}
          currentOptions={this.state.currentPlans}
          selectAll={this.selectAll}
          deselectAll={this.deselectAll}
          clickCheckBox={(e, optionKey) => this.clickCheckBox(e, optionKey)}
        />
      </div>
    );
  }
}

export default SubscriptionsRevenueGraph;
