import React from 'react';
import _ from 'lodash-es';
import {
  Layout,
  TitleBar,
  Card,
  Pagination,
  Table,
  StatusMessage
} from '../components/library-components';
import {
  getInvoices,
  downloadInvoicesCsv,
  getDownloadUrl,
  getInvoiceReports,
  searchInvoices
} from '../api';
import currencyUtils from '../helpers/currencyUtils';
import { Dialog } from '@quintype/em/components/dialog';
import { SearchBar } from '@quintype/em/components/search-bar';
import { Loader } from '@quintype/em/components/loader';
import Download from '../pages/Icons/download';
import DownloadReports from '../components/reports/download_reports';

import { Button } from '@quintype/em/components/button';
import { getSubscriptionGroupsLite } from 'api';

import './invoices.scss';
import { InvoiceFilters } from './InvoiceFilters';
import PhoneIdentity from '../components/subscriber-identity/phone-identity';

class Invoices extends React.Component {
  constructor () {
    super();
    this.state = {
      showLoader: false,
      searchTerm: '',
      invoices: [],
      currentGroup: 'all',
      currentPlan: 'all',
      total_pages: 1,
      total_entries: 0,
      isFiltersOpened: false,
      subscriptionGroups: [],
      subscriptionPlans: [{ label: 'All', value: 'all' }],
      isDownloadDialogOpen: false,
      downloadFeatureEnabled: false,
      invoice_reports: [],
      isReportProcessing: false,
      showNotification: {
        isNotificationOpen: false,
        status: '',
        statusMessage: ''
      },
      'invoice-date': {
        start: null,
        end: null
      }
    };
    this.loadInvoices = this.loadInvoices.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.loadSubscriptionGroups = this.loadSubscriptionGroups.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.startFilterSession = this.startFilterSession.bind(this);
    this.endFilterSession = this.endFilterSession.bind(this);
    this.downloadToBrowser = this.downloadToBrowser.bind(this);
    this.isDownloadEnabled = this.isDownloadEnabled.bind(this);
    this.openDownloadDialog = this.openDownloadDialog.bind(this);
    this.searchInvoices = this.searchInvoices.bind(this);
    this.formatInvoicesReponse = this.formatInvoicesReponse.bind(this);
    this.setSearchTerm = this.setSearchTerm.bind(this);
    this.setInvoiceDates = this.setInvoiceDates.bind(this);
  }

  componentDidMount () {
    this.loadInvoices({ page: 1 });
    this.loadSubscriptionGroups();
    this.setupChannelSubscription('NotifyUsersChannel', this.downloadToBrowser);
  }

  isDownloadEnabled () {
    return this.state.searchTerm === '' && this.state.downloadFeatureEnabled;
  }

  loadSubscriptionGroups () {
    getSubscriptionGroupsLite('all', true).then((subscriptionGroups) => {
      const options = [];
      options.push({ label: 'All', value: 'all' });
      for (const subscriptionGroup of subscriptionGroups) {
        const option = {
          label: subscriptionGroup.name.concat(
            '  (',
            subscriptionGroup.subscription_type,
            ')'
          ),
          value: subscriptionGroup.id,
          subscriptionPlans: subscriptionGroup.subscription_plans
        };
        options.push(option);
      }
      this.setState({ subscriptionGroups: options });
    });
  }

  formatInvoicesReponse (invoices) {
    const tempInvoicesArray = [];
    // eslint-disable-next-line camelcase
    for (const {
      id,

      subscriber,
      subscription,
      sequenced_invoice_number: sequencedInvoiceNumber,
      amount_cents: amountCents,
      amount_currency: amountCurrency,
      external_payment_id: externalPaymentId
    } of invoices) {
      const tableRow = {
        name: subscriber.name,
        plan: subscription.plan_name,
        invoice_number: sequencedInvoiceNumber,
        amount: currencyUtils.convertToCurrencyWithSymbol(
          amountCents,
          amountCurrency
        ),
        email_id: subscriber.email,
        phone_number: subscriber.phone_number,
        payment_id: externalPaymentId,
        id: id
      };
      tempInvoicesArray.push(tableRow);
    }

    return tempInvoicesArray;
  }

  loadInvoices ({ page }) {
    this.setState({ showLoader: true });
    getInvoices(
      this.state.currentGroup,
      this.state.currentPlan,
      page,
      this.state['invoice-date']
    ).then((response) => {
      const invoices = response.invoices;
      const invoicesArray = this.formatInvoicesReponse(invoices);
      this.setState(
        {
          invoices: invoicesArray,
          total_pages: response.total_pages,
          total_entries: response.total_entries,
          downloadFeatureEnabled: response.invoices_download_enabled
        },
        () => this.setState({ showLoader: false })
      );
    });
  }

  searchInvoices (page = 1) {
    if (this.state.searchTerm === '') {
      return;
    }
    this.setState({ showLoader: true });
    searchInvoices(
      this.state.currentGroup,
      this.state.currentPlan,
      page,
      this.state.searchTerm,
      this.state['invoice-date']
    ).then((response) => {
      const invoices = response.invoices;
      const invoicesArray = this.formatInvoicesReponse(invoices);
      this.setState(
        {
          invoices: invoicesArray,
          total_pages: response.total_pages,
          total_entries: response.total_entries
        },
        () => this.setState({ showLoader: false })
      );
    });
  }

  applyFilters () {
    if (this.state.searchTerm !== '') {
      this.searchInvoices();
      return;
    }
    this.loadInvoices({ page: 1 });
  }

  setSearchTerm (searchTerm) {
    this.setState({ searchTerm: searchTerm }, () => {
      if (searchTerm === '') {
        this.loadInvoices({ page: 1 });
      }
    });
  }

  setFilter (type, category) {
    if (type === 'subscription_group') {
      const selectedGroup = category.value;
      this.setState({ currentGroup: selectedGroup });
      if (selectedGroup === 'all') {
        return this.setState({
          currentPlan: 'all',
          subscriptionPlans: [{ label: 'All', value: 'all' }]
        });
      }
      const subscriptionPlans = this.state.subscriptionGroups.find(
        (subscriptionGroup) => subscriptionGroup.value === selectedGroup
      ).subscriptionPlans;
      const options = [];
      options.push({ label: 'All', value: 'all' });
      for (const subscriptionPlan of subscriptionPlans) {
        const option = {
          label: subscriptionPlan.title,
          value: subscriptionPlan.id
        };
        options.push(option);
      }
      this.setState({ subscriptionPlans: options, currentPlan: 'all' });
    }

    if (type === 'subscription_plan') {
      const selectedPlan = category.value;
      this.setState({ currentPlan: selectedPlan });
    }
  }

  onPageChange (page) {
    if (this.state.searchTerm !== '') {
      return this.searchInvoices(page);
    }
    this.loadInvoices({ page: page });
  }

  startFilterSession () {
    localStorage.setItem('previous_state', JSON.stringify(this.state));
    this.setState({ isFiltersOpened: true });
  }

  endFilterSession () {
    const previousState = JSON.parse(localStorage.getItem('previous_state'));
    localStorage.removeItem('previous_state');
    this.setState(previousState);
  }

  setupChannelSubscription (channel, callback) {
    this.props.cableApp.notify_user = this.props.cableApp.cable.subscriptions.create(
      channel,
      {
        connected: function () {
          // Called when the subscription is ready for use on the server
        },

        disconnected: function () {
          // Called when the subscription has been terminated by the server
        },

        received: function (data) {
          // Called when there's incoming data on the websocket for this channel
          // this.updateSubscriptionsReport(data);
          callback(data);
        }
      }
    );
  }

  generateReport () {
    downloadInvoicesCsv(
      this.state.currentGroup,
      this.state.currentPlan,
      this.state['invoice-date']
    ).then((message) => {
      this.setState({
        showNotification: {
          isNotificationOpen: true,
          status: 'success',
          statusMessage: message
        },
        isDownloadDialogOpen: false,
        isReportProcessing: true
      });
    });
  }

  downloadToBrowser (data) {
    this.setState({
      showNotification: {
        isNotificationOpen: true,
        status: data.description.text.status,
        statusMessage: data.description.text.message
      },
      isReportProcessing: false
    });

    const filename = data.description.text.filename;

    if (filename) {
      getDownloadUrl(filename).then(
        (downloadUrl) => (window.location.href = downloadUrl)
      );
    }
  }

  openDownloadDialog () {
    this.setState({ isDownloadDialogOpen: true });
    getInvoiceReports().then((reports) =>
      this.setState({
        invoice_reports: _.filter(reports, function (o) {
          return !!o.generated_by_user_name;
        })
      })
    );
  }

  setInvoiceDates (start, end) {
    this.setState({
      'invoice-date': {
        start,
        end
      }
    });
  }

  checkAndOpenDownloadDialog () {
    if (this.state.isReportProcessing) {
      this.setState({
        showNotification: {
          isNotificationOpen: true,
          status: 'failure',
          statusMessage:
            'The report is being generated, please wait for it to be downloaded.'
        }
      });
      return;
    }

    if (this.state.total_entries > 60000) {
      this.setState({
        showNotification: {
          isNotificationOpen: true,
          status: 'failure',
          statusMessage:
            'The number of record too high to download, please apply filter to reduce the count'
        }
      });
      return;
    }

    this.openDownloadDialog();
  }

  render () {
    return (
      <Layout bgColor>
        <TitleBar title="Invoices">
          {this.isDownloadEnabled() && (
            <div
              data-test-id="download_csv"
              className="margin-right-10 file_download_background"
              type="secondary"
              onClick={() => this.checkAndOpenDownloadDialog()}
            >
              {' '}
              <Download />{' '}
            </div>
          )}
        </TitleBar>

        <div className="search_and_filter">
          <div className="search_bar">
            <SearchBar
              hasBorder
              onChange={(e) => this.setSearchTerm(e)}
              placeholder="Search..."
            />
          </div>
          <Button
            className="search_button"
            testId="search"
            type="primary"
            onClick={() => this.searchInvoices()}
          >
            Search
          </Button>
          <div className="filters">
            <Button
              testId="invoices_filter"
              customClass="margin-right-10"
              primary
              onClick={() => this.startFilterSession()}
            >
              {' '}
              Filters{' '}
            </Button>
          </div>
        </div>

        {!this.state.showLoader && (
          <div className="results" data-test-id="results">
            <div data-test-id="number_of_results">
              {this.state.total_entries} total results{' '}
            </div>
          </div>
        )}
        {this.state.showLoader && <Loader />}
        <Dialog
          isOpen={this.state.isDownloadDialogOpen}
          onClose={() => this.setState({ isDownloadDialogOpen: false })}
        >
          <DownloadReports
            generateReport={() => this.generateReport()}
            reports={this.state.invoice_reports}
          />
        </Dialog>

        <InvoiceFilters
          opened={this.state.isFiltersOpened}
          onClose={() => this.endFilterSession()}
          onActionButtonClick={() => {
            this.setState({ isFiltersOpened: false });
            this.applyFilters();
          }}
          groups={this.state.subscriptionGroups}
          groupValue={this.state.currentGroup}
          onChangeGroup={(e) => this.setFilter('subscription_group', e)}
          setInvoiceDates={this.setInvoiceDates}
          plans={this.state.subscriptionPlans}
          planValue={this.state.currentPlan}
          invoiceDateRange={this.state['invoice-date']}
          onChangePlan={(e) => this.setFilter('subscription_plan', e)}
        />
        <Card>
          <Table
            header={[
              'Name',
              'Plan',
              'Invoice No',
              'Amount',
              'Identity',
              'Payment ID'
            ]}
          >
            {this.state.invoices.map((invoice) => {
              return (
                <div
                  className="table-default__row"
                  key={invoice.id}
                  data-test={`invoice_id_${invoice.id}`}
                >
                  <div className="table-default__cell truncate-word">
                    {invoice.name}
                  </div>
                  <div className="table-default__cell truncate-word">
                    {invoice.plan}
                  </div>
                  <div className="table-default__cell truncate-word">
                    {invoice.invoice_number}
                  </div>
                  <div className="table-default__cell truncate-word">
                    {invoice.amount}
                  </div>
                  <div className="table-default__cell truncate-word">
                    {invoice.email_id}
                    <PhoneIdentity phoneNum={invoice.phone_number}/>
                  </div>
                  <div className="table-default__cell truncate-word">
                    {invoice.payment_id}
                  </div>
                </div>
              );
            })}
          </Table>
          <Pagination
            onPageChange={this.onPageChange}
            pageCount={this.state.total_pages}
            containerClassName="pagination"
            pageRangeDisplayed={0}
            marginPagesDisplayed={1}
            pageClassName="pagination__item"
            breakClassName="pagination__item"
            breakLabel="..."
            totalEnteries={`${this.state.total_entries} rows`}
          />
        </Card>
        {this.state.showNotification.isNotificationOpen && (
          <StatusMessage
            statusMessage={this.state.showNotification.statusMessage}
            status={this.state.showNotification.status}
            onCancel={() =>
              this.setState({
                showNotification: {
                  isNotificationOpen: false,
                  status: '',
                  statusMessage: ''
                }
              })
            }
          />
        )}
      </Layout>
    );
  }
}

export default Invoices;
