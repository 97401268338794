import React from 'react';
import _ from 'lodash-es';
import PhoneInput from 'react-phone-input-2';
import PhoneNumber from 'awesome-phonenumber';

import validate from 'validate.js';
import { Logo } from '../../components/library-components';
import './create_account.scss';
import { TextField } from '@quintype/em/components/text-field';
import { ErrorMessage } from '@quintype/em/components/error-message';
import Select from '@quintype/em/components/select';
import { Button } from '@quintype/em/components/button';
import { createAccount } from '../../api';

// For expert advice on how to go behind a paywall the right way, contact us here. Set up your subscription plans and starting generating revenue.
const freeTrialData = {
  title: 'Start free trial',
  content: 'Implement your own reader revenue strategy with Accesstype. Sign up for the free plan. No credit card required.',
  freeTrialListData: ['Easy payment gateway integrations', 'Customizable subscription plans', 'Seasonal subscription offers', 'Metered access plans', 'Subscriber data management and reports']
};
const { title, content, freeTrialListData } = freeTrialData;
const ALL_PLATFORM_TYPES = ['quintype', 'wordpress', 'others'].map((k) => ({ label: (k[0].toUpperCase() + k.slice(1)), value: k }));

function getPhoneInstance (number) {
  const e164FormatNumber = '+' + number;
  const phoneNumber = new PhoneNumber(e164FormatNumber);
  return phoneNumber;
}

// custom validation for phone number
validate.validators.customPhoneCheck = function (
  value
) {
  if (validate.isEmpty(value)) {
    return 'is required';
  }
  const phoneNumber = getPhoneInstance(value);
  const isValidPhoneNumber = phoneNumber.isValid();
  if (!isValidPhoneNumber) {
    return 'is not valid';
  }
};

// custom validation for website url
validate.validators.urlAllowBlank = function (value, options, attribute, attributes) {
  if (validate.isEmpty(value)) {
    return;
  }
  return validate.validators.url(value, options, attribute, attributes);
};

export default class CreateAccount extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      errors: {},
      account: {
        name: '',
        website_url: '',
        platform_type: 'wordpress',
        phoneNumber: ''
      }
    };

    this.updateField = this.updateField.bind(this);
    this.onSaveAccount = this.onSaveAccount.bind(this);
  }

  updateField (field, value) {
    const newAccountState = Object.assign({}, this.state.account, {
      [field]: value
    });
    this.setState({
      account: newAccountState
    });
  }

  onSaveAccount () {
    const constraints = {
      name: { presence: { allowEmpty: false, message: 'Required' } },
      platform_type: { presence: { allowEmpty: false, message: 'Required' } },
      website_url: {
        urlAllowBlank: {}
      },
      phoneNumber: { customPhoneCheck: 'phone' }
    };

    const errors = validate(this.state.account, constraints);

    if (errors) {
      this.setState({ errors });
      return;
    }

    const phoneNumber = getPhoneInstance(this.state.account.phoneNumber);
    const accountDetails = {
      name: this.state.account.name,
      website_url: this.state.account.website_url,
      platform_type: this.state.account.platform_type,
      phone_number: phoneNumber.getNumber('international')
    };

    createAccount(accountDetails)
      .then(() => { window.location.href = '/dashboard'; })
      .catch(errors => this.setState({ errors }));
  }

  render () {
    return (
      <div className='acc-main-wrapper'>
        <div className='logo-wrapper'>
          <a href='/at-home' className='logo-url'>
            <Logo
              bgColor={false}
            />
            <span className='logo-text'>Accesstype</span>
          </a>
        </div>
        <div className='wrapper'>
          <div className='content-wrapper'>
            <div className='title'>
              <h2 className='title-text'>{title}</h2>
            </div>
            <div className='content'>
              <p className='free-days-text'>{content}</p>
            </div>
            <div className='free-trial-data'>
              <div className='list-title'>Get started with -</div>
              <ul>
                {freeTrialListData.map((data, index) => {
                  return (
                    <li key={index} className='feature-list'>
                      <p className='free-days-text'>{data}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className='content'>
              <p className='free-days-text'>For expert advice on how to go behind a paywall the right way, contact us <a className='contact-us-link' href='https://www.quintype.com/contact-us'>here </a>. Set up your subscription plans and starting generating revenue.</p>
            </div>
          </div>
          <div className="account-form-wrapper">
            <div className='account-form'>
              <Select
                label='Platform'
                dataTest='platform_type'
                className='platform-type'
                options={ALL_PLATFORM_TYPES}
                value={_.get(this.state.account, ['platform_type'], '')}
                onChange={e => this.updateField('platform_type', e.value)}
                errorMessage={_.get(this.state.errors, ['platform_type', '0'])}
              />
              <TextField
                label='Name'
                classname='form-text-field'
                value={_.get(this.state.account, ['name'])}
                onChange={value => this.updateField('name', value)}
                placeholder='Name'
                dataTest='name'
                errorMessage={_.get(this.state.errors, ['name', '0'])}
              />
              <div className="account__phonenumber-container">
                <label className="account__phonenumber-label">Phone number</label>
                <PhoneInput
                  country="in"
                  value={this.state.account.phoneNumber}
                  onChange={phone => this.setState({
                    account: {
                      ...this.state.account,
                      phoneNumber: phone
                    }
                  })}
                />
                <ErrorMessage message={_.get(this.state.errors, ['phoneNumber', '0'], '')} />
              </div>

              <TextField
                label='Website Url'
                classname='form-text-field website-url'
                value={_.get(this.state.account, ['website_url'])}
                onChange={value => this.updateField('website_url', value)}
                placeholder='Website Url'
                dataTest='website_url'
                errorMessage={_.get(this.state.errors, ['website_url', '0'])}
              />
              <div className='submit-button-wrapper'>
                <Button
                  primary
                  id='create-account-save'
                  classname='save-account-btn'
                  onClick={e => this.onSaveAccount(e)}
                >Submit</Button>
              </div>
              { this.props.isAnyExistingAccount ? (<div className='already-account-wrapper'>
                <span>Already have any account?<a href='/dashboard' className='dashboard-link'> Go to dashboard</a></span>
              </div>) : (<div className='already-account-wrapper'>
                <span><a href='/at-home' className='dashboard-link'> Go back to home</a></span>
              </div>)
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
