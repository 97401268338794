import React, { Fragment } from 'react';
import _ from 'lodash-es';
import validate from 'validate.js';
import * as Promise from 'bluebird';
import { connect } from 'react-redux';

import {
  TitleBar,
  Card,
  Layout,
  StatusMessage,
  TagsInputWithLabel,
  SelectFieldWithLabel
} from '../components/library-components';
import { TextField } from '@quintype/em/components/text-field';
import { PasswordField } from '@quintype/em/components/password-field';
import { Button } from '@quintype/em/components/button';

import {
  getCurrentAccountUserDetails,
  getAcccountConfig,
  updateAcccountConfig,
  getNotificationSettings
} from '../api';
import { isUrl } from '../helpers/urlUtils';

import './account-config.scss';

import { ALL_CURRENCIES } from '../helpers/currencyUtils';

import { ModifiedEmDialog } from '../components/modified-em-dialog';

import BackArrow from './Icons/back-arrow';

class GeneralSettings extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showNotification: {
        isNotificationOpen: false,
        status: '',
        statusMessage: ''
      },
      errors: [],
      account: {},
      superUser: false,
      initialAccount: {},
      renewalEmailEnabled: false,
      editMode: false,
      inputProps: {
        placeholder:
          'Enter domains to be whitelisted, like https://example.com'
      },
      openDefaultCurrencyAlertPopup: false,
      confirmDefaultCurrencyUpdate: false
    };

    this.updateField = this.updateField.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onStatusCancelClick = this.onStatusCancelClick.bind(this);
    this.handleDomainChange = this.handleDomainChange.bind(this);
    this.closeDefaultCurrencyAlert = this.closeDefaultCurrencyAlert.bind(this);
    this.confirmDefaultCurrencyAlert = this.confirmDefaultCurrencyAlert.bind(this);
    this.getStickyPlansNavigationLink = this.getStickyPlansNavigationLink.bind(this);
  }

  componentDidMount () {
    (async () => {
      const data = await Promise.all([
        getAcccountConfig(),
        getCurrentAccountUserDetails(),
        getNotificationSettings()
      ]);

      this.setState({
        account: data[0].account,
        initialAccount: data[0].account,
        superUser: data[1].superuser,
        renewalEmailEnabled: data[2].renewal_reminder
      });
    })();
  }

  updateField (field, value) {
    const newAccountState = Object.assign({}, this.state.account, {
      [field]: value
    });
    this.setState({
      account: newAccountState,
      editMode: true
    });
  }

  updateStoryAttributesConfig (field, value) {
    const storyAttributesConfig = this.state.account[
      'story_attributes_api_config'
    ];
    storyAttributesConfig[field] = value === '' ? null : value;
    const newAccountState = Object.assign(this.state.account, {
      story_attributes_api_config: storyAttributesConfig
    });
    this.setState({
      account: newAccountState,
      editMode: true
    });
  }

  updateCustomTaxonomyApiConfig (field, value) {
    const customTaxonomyApiConfig = this.state.account[
      'account_custom_attributes_api_config'
    ];
    customTaxonomyApiConfig[field] = value === '' ? null : value;
    const newAccountState = Object.assign(this.state.account, {
      account_custom_attributes_api_config: customTaxonomyApiConfig
    });
    this.setState({
      account: newAccountState,
      editMode: true
    });
  }

  validateDomainUrls (domains) {
    return domains.length > 0 && domains.every((domain) => isUrl(domain));
  }

  handleDomainChange (whitelistedDomains) {
    const domains = _.uniq(whitelistedDomains.map((str) => str.trim()));
    if (this.validateDomainUrls(domains) || _.isEmpty(domains)) {
      const accountSettings = Object.assign({}, this.state.account, {
        whitelisted_domains: domains
      });
      const errors = Object.assign({}, this.state.errors, {
        whitelisted_domains: null
      });
      this.setState({
        account: accountSettings,
        errors: errors,
        editMode: true
      });
    } else {
      const errors = Object.assign({}, this.state.errors, {
        whitelisted_domains: 'Please enter a unique and valid domain url'
      });
      this.setState({ errors: errors });
    }
  }

  onSubmitClick () {
    const subUrl = this.state.account['subscription_url'];
    if (this.props.config.multipleCurrencyEnabled &&
      this.state.initialAccount.fallback_currency &&
      this.state.account.fallback_currency !== this.state.initialAccount.fallback_currency &&
      !this.state.confirmDefaultCurrencyUpdate) {
      this.setState({ openDefaultCurrencyAlertPopup: true });
      return;
    }
    const { renewalEmailEnabled } = this.state;

    const renewEmailConstraint =
      renewalEmailEnabled || (subUrl && subUrl.trim().length > 0)
        ? {
          subscription_url: {
            presence: { allowEmpty: false, message: 'is required' },
            url: {
              allowLocal: true
            }
          }
        }
        : {};
    const constraints = {
      name: { presence: { allowEmpty: false, message: 'Required' } },
      website_url: {
        presence: { allowEmpty: false, message: 'Required' },
        url: true
      },
      'story_attributes_api_config.url': {
        url: {
          allowLocal: true
        }
      },
      ...renewEmailConstraint
    };

    const errors = validate(this.state.account, constraints);
    if (errors) {
      this.setState({ errors, editMode: true });
    } else {
      const { superUser } = this.state;
      const data = superUser
        ? this.state.account
        : {
          subscription_url: this.state.account['subscription_url'] || null
        };

      updateAcccountConfig(data)
        .then((data) => {
          this.setState({
            account: data.account,
            initialAccount: data.account,
            editMode: !this.state.editMode,
            error: '',
            isSaveDisabled: true,
            errors: {},
            showNotification: {
              isNotificationOpen: true,
              status: 'success',
              statusMessage: 'General settings saved Successfully!'
            },
            confirmDefaultCurrencyUpdate: false
          });
        })
        .catch((errors) => {
          const message = errors.response.body.error.message;
          this.setState({
            showNotification: {
              isNotificationOpen: true,
              status: 'error',
              statusMessage: message
            }
          });
        });
    }
  }

  onCancelClick () {
    this.setState({
      account: this.state.initialAccount,
      editMode: false,
      errors: {}
    });
  }

  onStatusCancelClick () {
    this.setState({
      showNotification: {
        isNotificationOpen: false,
        status: '',
        statusMessage: ''
      }
    });
  }

  closeDefaultCurrencyAlert () {
    this.updateField('fallback_currency', this.state.initialAccount['fallback_currency']);
    this.setState({
      openDefaultCurrencyAlertPopup: false
    });
  }

  confirmDefaultCurrencyAlert () {
    this.setState({
      openDefaultCurrencyAlertPopup: false,
      confirmDefaultCurrencyUpdate: true
    }, this.onSubmitClick);
  }

  getStickyPlansNavigationLink () {
    const previousPathSource = new URLSearchParams(location.search).get('src');
    if (previousPathSource !== 'plan-fallback-button') {
      return;
    }
    const previousPath = document.referrer !== '' ? new URL(document.referrer).pathname : '';
    const pattern = /\/subscription_groups\/\d+\/subscription_plans\/\d+/;
    const foundPattern = pattern.exec(previousPath);
    if (foundPattern && foundPattern[0] === previousPath) {
      return document.referrer;
    }
  }

  render () {
    const plansBackNavigationLink = this.getStickyPlansNavigationLink();
    return (
      <div className='general-settings-page'>
        <Layout ref={fieldEditor1 => (this.fieldEditor1 = fieldEditor1)}>
          <TitleBar title='General'/>
          {
            plansBackNavigationLink &&
            <a href={plansBackNavigationLink} className='general-settings-page__sticky_plans_navigation'>
              <BackArrow /> Return to Plan
            </a>
          }

          <Card>
            <TextField
              value={_.get(this.state.account, ['name'])}
              label="Name"
              placeholder="Type name"
              hint="This will be used as display name of your property across accesstype platform."
              onChange={(value) => this.updateField('name', value)}
              errorMessage={_.get(this.state.errors, ['name', '0'])}
              readOnly={!this.state.superUser}
            />

            <TextField
              value={_.get(this.state.account, ['platform_type'])}
              label="Platform"
              placeholder="Type your platform"
              readOnly
            />

            <TextField
              value={_.get(this.state.account, ['website_url'])}
              label="Property default URL"
              placeholder="https://example.com"
              onChange={(value) => this.updateField('website_url', value)}
              errorMessage={_.get(this.state.errors, ['website_url', '0'])}
              readOnly={!this.state.superUser}
            />

            <TextField
              value={_.get(this.state.account, ['subscription_url'])}
              label="Subscription Url"
              placeholder="https://example.com"
              onChange={(value) =>
                this.updateField('subscription_url', value)
              }
              errorMessage={_.get(this.state.errors, [
                'subscription_url',
                '0'
              ])}
            />

            {this.state.superUser && (
              <form>
                <TextField
                  value={_.get(this.state.account, [
                    'story_attributes_api_config',
                    'url'
                  ])}
                  label="Story attributes Endpoint"
                  placeholder="https://example.com/api/story/attributes"
                  onChange={(value) =>
                    this.updateStoryAttributesConfig('url', value)
                  }
                  errorMessage={_.get(this.state.errors, [
                    'story_attributes_api_config.url',
                    '0'
                  ])}
                  readOnly={!this.state.superUser}
                />

                <TextField
                  value={_.get(this.state.account, [
                    'account_custom_attributes_api_config',
                    'url'
                  ])}
                  label="Account Custom Taxonomy Endpoint"
                  placeholder="https://example.com/api/v1/accesstype/taxonomy"
                  onChange={(value) =>
                    this.updateCustomTaxonomyApiConfig('url', value)
                  }
                  errorMessage={_.get(this.state.errors, [
                    'account_custom_attributes_api_config.url',
                    '0'
                  ])}
                  readOnly={!this.state.superUser}
                />
                <TagsInputWithLabel
                  label={'Whitelisted Domains'}
                  dataTest={'whitelisted_domains'}
                  value={_.get(this.state.account, 'whitelisted_domains', [])}
                  onChange={this.handleDomainChange}
                  inputProps={this.state.inputProps}
                  onlyUnique
                />

                <p className="error-msg">
                  {_.get(this.state.errors, ['whitelisted_domains'])}
                </p>

                {
                  this.props.config.multipleCurrencyEnabled &&
                  <div className="general-settings-page__fallback_currency">
                    <label
                      htmlFor='react-select-wrapper-title-Fallback-Currency'
                      className='label'
                    >Fallback Currency</label>
                    <p className='general-settings-page__fallback_currency_desc'>Fallback currency is used as an alternative to other currencies in case they dont work. You must set a common fallback currency for all plans. You can always overwrite the fallback currency for any plan in the plans page.</p>

                    <div className='form-row'>
                      <SelectFieldWithLabel
                        value={_.get(this.state.account, 'fallback_currency', '')}
                        options={ALL_CURRENCIES}
                        clearable={false}
                        onChange={(currency) => {
                          this.updateField('fallback_currency', currency.value);
                        }}
                      />
                    </div>
                  </div>
                }

              </form>
            )}

            <ModifiedEmDialog
              title="Change Default Currency?"
              isOpen={this.state.openDefaultCurrencyAlertPopup}
              onClose={() => this.closeDefaultCurrencyAlert()}
              onConfirm={() => this.confirmDefaultCurrencyAlert()}
              content="Please make sure that all the plans have this currency. Setting the default currency that is not supported by the payment gateway may cause payment failures. Are you sure you want to update?"
              firstButton={{
                type: 'primary',
                onClick: this.confirmDefaultCurrencyAlert,
                content: 'Change Default Currency'
              }}
              secondButton={{
                onClick: this.closeDefaultCurrencyAlert,
                content: 'Cancel'
              }}
            />

            <div className="general-settings-page__button-group">
              <Button
                id="general-setting-cancel"
                onClick={(e) => this.onCancelClick(e)}
              >
                Cancel
              </Button>
              <Button
                id="general-setting-save"
                onClick={(e) => this.onSubmitClick(e)}
                disabled={!this.state.editMode}
                type="primary"
              >
                Save
              </Button>
            </div>
          </Card>

          <Card title={'Authentication Keys'}>
            <PasswordField
              value={_.get(this.state.account, ['at_js_integration_key'])}
              label="Account key (Safe to be used on Client side)"
              readOnly
            />

            {this.state.superUser && (
              <Fragment>
                <PasswordField
                  value={_.get(this.state.account, [
                    'authentication_shared_secret_token'
                  ])}
                  label="User JWT secret token"
                  readOnly
                />
                <PasswordField
                  value={_.get(this.state.account, ['api_key'])}
                  label="API Authentication key (To be kept secured)"
                  readOnly
                />
              </Fragment>
            )}
          </Card>

          {this.state.showNotification.isNotificationOpen && (
            <StatusMessage
              statusMessage={this.state.showNotification.statusMessage}
              status={this.state.showNotification.status}
              onCancel={(e) => this.onStatusCancelClick(e)}
            />
          )}
        </Layout>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    config: state.config || {}
  };
}

function mapDispatchToProps (dispatch) {
  return {
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralSettings);
