import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@quintype/em/components/button';

import './title-bar.scss';

const TitleBar = ({ title, buttonText, onActionButtonClick }) => {
  return (<div className='title-bar'>
    <div className='title-bar__title-container'>
      <h3 className='title-bar__title'>{title}</h3>
      {buttonText && <Button type="primary" onClick={onActionButtonClick}>{buttonText}</Button>}
    </div>
  </div>);
};

TitleBar.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onActionButtonClick: PropTypes.func
};

export default TitleBar;
