/* eslint-disable react/no-children-prop */
import React from 'react';

import { ContextMenu, ContextMenuList, ContextMenuItem } from '@quintype/em/components/context-menu';
import { QuestionCircle } from '@quintype/em/icons/question-circle';

const StaticMenuLinks = () => {
  return (<ContextMenu position={'topRight'} icon={<QuestionCircle color={'#ffffff'} />} >
    <ContextMenuList>
      <ContextMenuItem
        label="Documentation"
        children={() => <a className="external-links" href="http://developers.quintype.com/subtype" target="_blank" rel="noopener noreferrer">Documentation</a>}
      />
    </ContextMenuList>
  </ContextMenu>);
};

export { StaticMenuLinks };
