import React from 'react';
import { connect } from 'react-redux';
import { SET_WEBHOOKS } from '../actions';
import { TitleBar, Card, Description, Table, Layout, StatusMessage } from '../components/library-components';
import { getWebhooks, createWebhook, deleteWebhook } from '../api';
import CustomSelect from '../components/coupons/custom-select/custom-select';
import TableRowWithActionDropdown from '../components/table-row-with-action-dropdown/table-row-with-action-dropdown';
import pluralize from 'pluralize';

const WEBHOOKS_STATE = [
  { label: 'Enabled', value: true },
  { label: 'Disabled', value: false }
];

export class Webhooks extends React.Component {
  constructor (props) {
    super(props);
    this.state = { showNotification: { isNotificationOpen: false, status: '', statusMessage: '' } };
    this.destroyWebhook = this.destroyWebhook.bind(this);
  }

  loadWebhooks () {
    getWebhooks().then((response) => {
      return this.props.setWebhooks(response.webhooks);
    },
    (errors) => this.setState({ showNotification: { isNotificationOpen: true, status: 'error', statusMessage: errors.message } }));
  }

  destroyWebhook (webhookId) {
    deleteWebhook(webhookId).then((response) => { this.loadWebhooks(); },
      (errors) => this.setState({ showNotification: { isNotificationOpen: true, status: 'error', statusMessage: errors.message } }));
  }

  onChangeStatus (selectedItem, webhookId) {
    createWebhook({ id: webhookId, enabled: selectedItem }).then((response) => {
      this.setState({ showNotification: { isNotificationOpen: true, status: 'success', statusMessage: 'Status updated successfully!' } });
      this.loadWebhooks();
    },
    (errors) => this.setState({ showNotification: { isNotificationOpen: true, status: 'error', statusMessage: errors.message } }));
  }

  componentDidMount () {
    this.loadWebhooks();
  }

  onPageChange (page) {
    this.loadWebhooks();
  }

  render () {
    return (
      <Layout>
        <TitleBar title='Outgoing Webhooks' buttonText='Add Webhook'
          button primary
          buttonUrl={`/webhooks/new`} />
        <Card title='Outgoing Webhooks'>
          <Description description='Webhooks allow external systems to be notified when certain events happen. When the specified events happen, we’ll send a POST request to each of the URLs you provide. You will receive an email in case the POST request returns a failure response.' />
          <Table header={['Webhook Url', 'Events', 'Status']} className='table-default--3cols'>
            {this.props.webhooks && this.props.webhooks.reverse().map((webhook, index) => {
              const actionsForWebhook = [{ action: 'Edit', actionHandler: function (id) { window.location = `/webhooks/${id}`; }, id: webhook.id },
                { action: 'Delete', actionHandler: this.destroyWebhook, id: webhook.id }];
              return <TableRowWithActionDropdown id={webhook.id} dropdownActions={actionsForWebhook} key={webhook.id} dataTest={'row-' + index} >
                <div className='table-default__cell' data-test={'url-' + index}> {webhook.url} </div>
                <div className='table-default__cell' data-test={'events-' + index}> {webhook.event_ids.length} {pluralize('event', webhook.event_ids.length)} selected </div>
                <div className='table-default__cell' data-test={'status-' + index}>
                  <CustomSelect
                    id={webhook.id}
                    value={webhook.enabled}
                    onChangeActive={(selectedItem) => this.onChangeStatus(selectedItem, webhook.id, webhook.event_ids)}
                    options={WEBHOOKS_STATE} />
                </div>
              </TableRowWithActionDropdown>;
            }
            )}
          </Table>
        </Card>
        {this.state.showNotification.isNotificationOpen &&
        <StatusMessage statusMessage={this.state.showNotification.statusMessage}
          status={this.state.showNotification.status}
          onCancel={() => this.setState({ showNotification: { isNotificationOpen: false, status: '', statusMessage: '' } })} />}
      </Layout>
    );
  }
}

function mapStateToProps (state) {
  return {
    webhooks: state.webhooks || {}
  };
}

function mapDispatchToProps (dispatch) {
  return {
    setWebhooks: (webhooks) => dispatch({ type: SET_WEBHOOKS, webhooks: webhooks })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Webhooks);
