import React from 'react';
import PropTypes from 'prop-types';
import {
  ContextMenu,
  ContextMenuList,
  ContextMenuItem
} from '@quintype/em/components/context-menu';

const HamburgerMenu = ({ item, onEdit, onDelete }) => {
  return (
    <ContextMenu>
      <ContextMenuList>
        {onEdit && <ContextMenuItem
          label="Edit"
          onClick={() => onEdit(item)}
        />}
        {onDelete && <ContextMenuItem
          label="Delete"
          onClick={() => onDelete(item)}
        />}
      </ContextMenuList>
    </ContextMenu>
  );
};

HamburgerMenu.propTypes = {
  item: PropTypes.any,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};

export default HamburgerMenu;
