import React from 'react';

import { Inspector } from '@quintype/em/components/inspector';
import { CreatableSelect } from '@quintype/em/components/select';
import { get } from 'lodash-es';
import { Checkbox } from '@quintype/em/components/checkbox';
import validate from 'validate.js';
import { Radio, RadioOption } from '@quintype/em/components/radio-button';
import { DateRangePicker } from '@quintype/em/components/date-range-picker';
import { ErrorMessage } from '@quintype/em/components/error-message';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';
import parseISO from 'date-fns/parseISO';
import set from 'date-fns/set';
import addDays from 'date-fns/addDays';

import './subscription_filter.scss';

import { getSubscriptionGroupsLite } from '../../api';
import endOfDay from 'date-fns/endOfDay';

const allOption = [{ label: 'All', value: 'all' }];

export function customValidation ({ endTimeStamp }) {
  // startTimeStamp from date picker is date instance with time as (12:00 am)
  // so we are formatting the current date with 12:00 am time
  const todayDate = set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0
  });

  const tommorrowTimeStamp = getUnixTime(addDays(todayDate, 1));

  if (endTimeStamp > tommorrowTimeStamp) {
    return {
      message: 'Future date is not allowed',
      type: 'to'
    };
  }
}

export default class SubscriptionFilter extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      group: '',
      plans: [],
      errors: [],
      status: this.props.filters.status,
      cancelled: this.props.filters.cancelled,
      couponValidityEnabled: !!this.props.filters['sub-date'].start,
      isDatePopupOpen: false,
      subscriptionGroups: null,
      subscriptionGroupsOptions: null,
      timeStamp: {
        start: this.props.filters['sub-date'].start,
        end: this.props.filters['sub-date'].end
      }
    };
  }

  componentDidMount () {
    this.loadSubscriptionGroups();
  }

  loadSubscriptionGroups () {
    getSubscriptionGroupsLite('all', true).then((subscriptionGroups) => {
      const [group, plans] = this.getInitialGroupAndPlan(subscriptionGroups);
      this.setState({
        subscriptionGroups,
        subscriptionGroupsOptions: this.makeOptions(subscriptionGroups),
        group,
        plans
      });
    });
  }

  getInitialGroupAndPlan (subscriptionGroups) {
    if (this.props.filters.group === 'all') {
      return [...allOption, allOption];
    }
    const selectedGroup = subscriptionGroups.filter(
      (group) => group.id.toString() === this.props.filters.group
    );
    const selectedPlans = selectedGroup[0][
      'subscription_plans'
    ].filter((plan) => this.props.filters.plans.includes(plan.id.toString()));
    const group = this.makeOptions(selectedGroup, false);
    const plans = this.makeOptions(selectedPlans, false);
    return [...group, plans];
  }

  makeOptions (array, showAllOption = true) {
    const options = array.map((item) => ({
      label: item.name || item.title,
      value: item.id.toString()
    }));
    return showAllOption ? allOption.concat(options) : options;
  }

  planOptions () {
    if (this.state.group.value === 'all') {
      return allOption;
    }
    const selectedGroup = this.state.subscriptionGroups.filter(
      (group) => group.id.toString() === this.state.group.value
    )[0];
    return this.makeOptions(selectedGroup['subscription_plans'], false);
  }

  updateFilter (field, value) {
    if (field === 'group') {
      this.setState({ group: value, plans: [] });
      return;
    }

    this.setState({ [field]: value });
  }

  updateStatus (field, value) {
    const statusArr = this.state.status;
    if (value) {
      this.setState({
        status: [...this.state.status, field]
      });
    } else {
      var index = statusArr.indexOf(field);
      if (index > -1) {
        const newArr = statusArr.filter(status => status !== field);
        this.setState({
          status: newArr
        });
      }
    }
  }

  timeStampConditions () {
    const { couponValidityEnabled } = this.state;

    return couponValidityEnabled
      ? {
        start_timestamp: {
          presence: true
        },
        end_timestamp: {
          presence: true
        }
      }
      : {};
  }

  constraints () {
    return Object.assign({}, this.timeStampConditions());
  }

  applyFilters () {
    const currentDates = {
      start_timestamp: this.state.timeStamp.start,
      end_timestamp: this.state.timeStamp.end
    };

    const validatedData = validate(currentDates, this.constraints());
    if (validatedData) {
      this.setState({ errors: validatedData });
    } else {
      this.props.onApplyFilters({
        group: this.state.group.value,
        plans: this.state.plans.length
          ? this.state.plans.map((plan) => plan.value)
          : ['all'],
        status: this.state.status,
        cancelled: this.state.cancelled,
        'sub-date': {
          start: this.state.timeStamp.start,
          end: this.state.timeStamp.end
        }
      });
    }
  }

  render () {
    const {
      couponValidityEnabled,
      errors,
      isDatePopupOpen,
      timeStamp: { start, end }
    } = this.state;
    const dateRangePickerProps =
      start && end
        ? {
          startTimeStamp: getUnixTime(parseISO(start)),
          endTimeStamp: getUnixTime(parseISO(end))
        }
        : {};

    const isTimestampNotSelected = get(errors, ['start_timestamp', 0], false);
    return (
      <div className="sub-filter-container">
        <Inspector
          title="Filters"
          isActive
          onClose={this.props.onClose}
          actionButtonLabel="Apply filters"
          onActionButtonClick={() => this.applyFilters()}
        >
          <CreatableSelect
            label="Subscription groups"
            options={this.state.subscriptionGroupsOptions || []}
            value={this.state.group}
            onChange={(option) => this.updateFilter('group', option)}
            clearable={false}
            searchable={false}
          />
          <CreatableSelect
            label="Subscription plans"
            options={this.state.subscriptionGroups ? this.planOptions() : []}
            value={this.state.subscriptionGroups ? this.state.plans : null}
            helpText="All plans are selected by default."
            onChange={(value) => this.updateFilter('plans', value)}
            isMulti
            isClearable={false}
          />
          <span className="status-label">Status</span>
          <div className="status-list">
            {this.props.statusList.map((status, i) => (
              <div className="status-item" data-test-id={`filter-status-item-${status}`} key={i}>
                <Checkbox
                  label={status}
                  id={status}
                  onChange={(checked) => this.updateStatus(status, checked)}
                  checked={this.state.status.includes(status)}
                />
              </div>
            ))}
          </div>
          <Radio
            name="cancelled"
            label="User Initiated Cancellation"
            selected={this.state.cancelled || false}
            onChange={(e) => this.updateFilter('cancelled', e.target.value)}
          >
            <RadioOption value="true">Yes</RadioOption>
            <RadioOption value="false">No</RadioOption>
            <RadioOption value="all">Both</RadioOption>
          </Radio>
          <div className="checkbox-container">
            <Checkbox
              id="date-range-checkbox"
              label="Apply Date Range"
              onChange={(checked) => {
                this.setState({
                  couponValidityEnabled: checked
                });
                if (!checked) {
                  this.setState({
                    timeStamp: {
                      start: null,
                      end: null
                    }
                  });
                }
              }}
              checked={couponValidityEnabled}
            />
          </div>
          {couponValidityEnabled && (
            <>
              <DateRangePicker
                {...dateRangePickerProps}
                dateLabel="Subscription Created date"
                dateFieldClass={isTimestampNotSelected ? 'red-border' : ''}
                customValidation={customValidation}
                onDateRangeSelect={(start, end) => {
                  this.setState({
                    timeStamp: {
                      start: fromUnixTime(start).toISOString(),
                      end: endOfDay(fromUnixTime(end)).toISOString()
                    }
                  });
                }}
                onDatePopupOpenOrClose={(popupState) => {
                  if (popupState) {
                    this.setState({
                      isDatePopupOpen: true
                    });
                  } else {
                    this.setState({
                      isDatePopupOpen: false
                    });
                  }
                }}
              />
              <div className="error-message-container">
                <ErrorMessage
                  message={
                    isTimestampNotSelected ? 'Please select date range' : ''
                  }
                />
              </div>
              {/* This increases the height of parent container when date popup is open
               * as normal flow parent (not absolute/ fixed positioned) does not account for
               * height of absolutely/fixed positioned element (date popup). Although a fixed positioned
               * parent accounts height  for fixed positioned child, still we need some space below
               * This can be removed if there are enough items below the date range picker */}
              {isDatePopupOpen && (
                <div className="sub-coupon-parent-ht-increase"></div>
              )}
            </>
          )}
        </Inspector>
      </div>
    );
  }
}
