import React from 'react';
import PropTypes from 'prop-types';

const AccesstypeLogo = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" transform="translate(3 5)">
          <path
            fill="#55D2B6"
            d="M31.9033043 22.896L31.9033043 0.0973913043 10.2295652 0.0973913043z"
            opacity="0.8"
          ></path>
          <path
            fill="#0E8167"
            d="M27.4003478 27.3996522L27.4003478 4.60034783 5.7266087 4.60034783 7.54991304 6.51895652 16.6490435 16.0897391 25.1707826 25.0553043z"
          ></path>
          <path
            fill="#2AC7A4"
            d="M21.7704348 31.9033043L21.7704348 9.104 0.0966956522 9.104z"
            opacity="0.8"
          ></path>
        </g>
      </g>
    </svg>
  );
};

AccesstypeLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
};

AccesstypeLogo.defaultProps = {
  width: '40',
  height: '40'
};

export default AccesstypeLogo;
