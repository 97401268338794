import React, { useState, useEffect } from 'react';
import { Table } from '@quintype/em/components/table';
import { Layout, Card } from '../components/library-components';
import { TitleBar, HamburgerMenu } from '../components/accesstype';
import { CouponInspector } from '../components/coupons/coupon-inspector';
import { getCoupons, deleteCoupon } from '../api';

export default function Coupons () {
  const [activeCoupon, updateActiveCoupon] = useState({
    active: false
  });
  const [coupons, updateAllcoupons] = useState(null);
  const [isInspectorActive, setInspectorState] = useState(false);

  useEffect(() => {
    loadCoupons();
  }, []);

  function loadCoupons () {
    getCoupons().then((response) => {
      updateAllcoupons(response.vouchers);
    });
  }

  function getCouponData () {
    if (coupons) {
      const allCoupons = coupons.map((coupon) => {
        const status = coupon.active ? 'Active' : 'Inactive';
        return {
          code: coupon.code,
          name: coupon.title,
          discount: coupon.value,
          status: status,
          id: coupon.id,
          discountType: coupon.discount_type
        };
      });

      return allCoupons;
    } else {
      return [];
    }
  }

  function setCouponState (row) {
    let klass = '';
    if (row.original.status === 'Active') {
      klass = 'success';
    } else {
      klass = 'danger';
    }
    return <span className={klass}>{row.original.status}</span>;
  }

  function setDiscountValue (row) {
    if (row.original.discountType === 'percent') {
      return row.original.discount + ' %';
    } else if (row.original.discountType === 'amount') {
      return row.original.discount / 100;
    } else {
      return row.original.discount;
    }
  }

  function inspectorToggle () {
    loadCoupons();
    closeInspector();
  }

  function closeInspector () {
    setInspectorState(false);
  }

  function onEdit (couponId) {
    const coupon = coupons.find((coupon) => coupon.id === couponId);
    updateActiveCoupon(coupon);
    setInspectorState(true);
  }

  function onDelete (couponId) {
    deleteCoupon(couponId).then(() => loadCoupons());
  }

  function generateRandomKey () {
    return Date.now();
  }
  // a new coupon will only have  active key
  //  we use this to disable active button for new coupons.
  const couponObjectKeys = Object.keys(activeCoupon);
  const isActiveButtonDisabled =
    couponObjectKeys.length === 1 ? true : activeCoupon.active;

  return (
    <Layout>
      <TitleBar
        title="Coupons"
        buttonText="Add Coupon"
        onActionButtonClick={() => {
          updateActiveCoupon({
            active: false
          });
          setInspectorState(true);
        }}
      />
      <Card>
        <Table
          noDataText="No Data Available"
          isLoading={!coupons}
          columns={[
            {
              Header: 'Code',
              accessor: 'code'
            },
            {
              Header: 'Display Name',
              accessor: 'name',
              Cell: ({ row }) => (row.original.name ? row.original.name : '')
            },
            {
              Header: 'Discount value',
              accessor: 'discount',
              Cell: ({ row }) => setDiscountValue(row)
            },
            {
              Header: 'Status',
              accessor: 'status',
              Cell: ({ row }) => setCouponState(row)
            },
            {
              Header: '',
              accessor: 'context-menu',
              Cell: function Hamburger ({ row }) {
                return (
                  <HamburgerMenu
                    item={row.original.id}
                    onEdit={onEdit}
                    onDelete={onDelete}
                  />
                );
              }
            }
          ]}
          data={getCouponData()}
        ></Table>
      </Card>
      <CouponInspector
        key={generateRandomKey()}
        coupon={activeCoupon}
        isInspectorActive={isInspectorActive}
        actionInspector={inspectorToggle}
        onClose={closeInspector}
        isActionButtonDisabled={isActiveButtonDisabled}
      />
    </Layout>
  );
}
