import React from 'react';

function Icon () {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="30"
      viewBox="0 0 569.015 220.672"
    >
      <g fill="#0abf53">
        <path d="M80.175 76.436H1.444v25.07h51.314a5.747 5.747 0 015.747 5.747v44.395H47.538a5.747 5.747 0 01-5.747-5.747v-31.86h-21.67A20.114 20.114 0 00.007 134.157v22.448a20.114 20.114 0 0020.114 20.114h80.168V96.55a20.114 20.114 0 00-20.114-20.114zM175.5 151.648h-10.967a5.747 5.747 0 01-5.747-5.747V76.435h-21.669a20.114 20.114 0 00-20.114 20.115v60.054a20.114 20.114 0 0020.114 20.114h80.169V34.651H175.5l-.002 116.997zM292.496 151.648H281.53a5.747 5.747 0 01-5.747-5.747V76.435h-41.784v80.169a20.114 20.114 0 0020.115 20.114h38.384v12.535h-57.062v29.244h78.732a20.114 20.114 0 0020.115-20.114V76.435h-41.787v75.213zM431.164 76.436h-80.168v80.168a20.114 20.114 0 0020.115 20.114h78.73v-25.07h-51.314a5.747 5.747 0 01-5.747-5.747v-44.394h10.966a5.746 5.746 0 015.747 5.747v31.858h21.67a20.114 20.114 0 0020.115-20.114V96.55a20.114 20.114 0 00-20.114-20.114zM548.16 76.436h-80.168v100.282h41.784v-75.212h10.967a5.764 5.764 0 015.747 5.747v69.465h41.785V96.55a20.114 20.114 0 00-20.115-20.114z" />
      </g>
    </svg>
  );
}

export default Icon;
