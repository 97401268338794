const occurrenceToDurationMapping = {
  'one time': {
    recurring: false,
    duration_length: null,
    duration_unit: null
  },
  '1 months': {
    recurring: true,
    duration_length: 1,
    duration_unit: 'months'
  },
  '3 months': {
    recurring: true,
    duration_length: 3,
    duration_unit: 'months'
  },
  '6 months': {
    recurring: true,
    duration_length: 6,
    duration_unit: 'months'
  },
  '1 years': {
    recurring: true,
    duration_length: 1,
    duration_unit: 'years'
  },
  custom: {
    recurring: true,
    duration_length: null,
    duration_unit: null
  }
};

function getAllOptions (subscriptionGroupType) {
  if (subscriptionGroupType === 'campaign') {
    return [{ label: 'One Time', value: 'one time' }];
  } else {
    return [
      { label: 'One Time', value: 'one time' },
      { label: '1 month', value: '1 months' },
      { label: '3 months', value: '3 months' },
      { label: '6 months', value: '6 months' },
      { label: '1 Year', value: '1 years' },
      { label: 'Custom', value: 'custom' }
    ];
  }
}

function getValue (plan) {
  if (plan.recurring === false) {
    return 'one time';
  }

  if (plan.duration_length === 1 && plan.duration_unit === 'months') {
    return '1 months';
  }

  if (plan.duration_length === 3 && plan.duration_unit === 'months') {
    return '3 months';
  }

  if (plan.duration_length === 6 && plan.duration_unit === 'months') {
    return '6 months';
  }

  if (plan.duration_length === 1 && plan.duration_unit === 'years') {
    return '1 years';
  }

  return 'custom';
}

export default { occurrenceToDurationMapping, getAllOptions, getValue };
