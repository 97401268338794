export const getIdentity = (subscriberIdentities) => {
  if (!subscriberIdentities) {
    return null;
  }
  const emailIdentityCheck = subscriberIdentities.find(
    (identity) => identity.provider === 'email'
  );
  if (emailIdentityCheck) {
    return emailIdentityCheck;
  }
  return subscriberIdentities[0];
};
