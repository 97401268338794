import React from 'react';
import PropTypes from 'prop-types';
import './table.scss';

const Table = ({ children, header, className }) => {
  return (
    <div className={`table-default ${className}`}>
      {header && (
        <div className="table-default__row table-default--header">
          {header.map((column, index) => {
            return (
              <div key={index} className="table-default__cell table-default--headings">
                {Array.isArray(column) ? (
                  <>
                    <div>
                      {column[0]}
                    </div>
                    {column.slice(1).map((header, index) => {
                      return (
                        <div
                          key={index}

                        >
                          {header}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div >
                    {column}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      {children}
    </div>
  );
};

Table.propTypes = {
  header: PropTypes.array,
  children: PropTypes.node
};

export default Table;
