/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';

import { Poll } from '@quintype/em/icons/poll';
import { CustomData } from '@quintype/em/icons/custom-data';
import { UserFriends } from '@quintype/em/icons/user-friends';
import { SliderHorizontal } from '@quintype/em/icons/slider-h';
import { Cog } from '@quintype/em/icons/cog';

const MainMenuIcons =
{ analytics: <Poll label='Analysis' />,
  'subscription-group': <CustomData label='Groups and Plans' />,
  customers: <UserFriends label='Customers' />,
  configure: <SliderHorizontal label='Configure' />,
  settings: <Cog label='Settings' />
};

const MainMenu = ({ links, onClickMainMenuItem, activeMainMenuButton, clickedMainMenu }) => {
  return (
    <ul className='vertical-nav-main-menu'>
      {links.map((item, index) => {
        return (
          <li key={index} className='vertical-nav-main-menu__item'>
            <a onClick={(e) => onClickMainMenuItem(e, item, item.dropdown)} data-test={item.dataTest} className={`vertical-nav-main-menu__button ${clickedMainMenu === item.icon ? 'is-clicked' : ''} ${activeMainMenuButton === item.icon || item.active ? 'is-active' : ''}`}>
              {MainMenuIcons[item.icon]}
            </a>
            <span className='vertical-nav-main-menu__item-tooltip'>{item.text}</span>
          </li>);
      })}
    </ul>);
};

const headlinelinksShape = PropTypes.shape({
  headline: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    active: PropTypes.bool,
    dataTest: PropTypes.string,
    linkTo: PropTypes.string,
    text: PropTypes.string
  }))
});

MainMenu.propTypes = {
  activeMainMenuButton: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    dataTest: PropTypes.string,
    dropdown: PropTypes.bool,
    icon: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(headlinelinksShape)) | undefined,
    text: PropTypes.string
  })),
  onClickMainMenuItem: PropTypes.func
};

export { MainMenu };
