import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import OrderedCollapsible from '../../ordered-collapsible/ordered-collapsible';

class Accordion extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      active: null,
      swappedGroups: null
    };
  }

  toggle (i) {
    if (this.state.active === i) {
      this.setState({ active: null });
      return;
    }

    this.setState({ active: i });
  }

  isSelected (item, sortedItems) {
    if (sortedItems.find((i) => i.id === item.id)) {
      return true;
    } else {
      return false;
    }
  }

  swapItems (items, firstItem, afterItem, initialSwappedItems) {
    if (firstItem === afterItem) return;
    const itemIndex = items.indexOf(firstItem);
    const afterIndex = items.indexOf(afterItem);
    const swappedItems = [...items];
    const [movingItem] = swappedItems.splice(itemIndex, 1);
    swappedItems.splice(afterIndex, 0, movingItem);

    if (initialSwappedItems !== swappedItems) {
      this.setState({ swappedGroups: swappedItems });
    }
  }

  uppdateSwappedGroup (swappedGroups, updateGroups, updateTypeOrdering) {
    const groupsWithApiOrdering = swappedGroups.map((group, index) => { return { ...group, api_ordering: index + 1 }; });
    const ordering = groupsWithApiOrdering.reduce((acc, order) => { return { ...acc, [order.id]: order.api_ordering }; }, {});
    updateGroups(groupsWithApiOrdering);
    updateTypeOrdering(ordering);
    this.setState({ swappedGroups: null });
  }

  render () {
    return (
      <DndProvider backend={HTML5Backend}>
        {this.props.data.map((group, index) =>
          <OrderedCollapsible
            key={group.id}
            collapsible
            active={this.state.active === index}
            toggle={(e) => this.toggle(index)}
            isSelected={this.isSelected(group, this.props.data)}
            onDragOver={(firstItem, secondItem) =>
              this.swapItems(this.props.data, firstItem, secondItem, this.state.swappedGroups)
            }
            onDragEnd={() => this.state.swappedGroups && this.uppdateSwappedGroup(this.state.swappedGroups, this.props.updateGroups, this.props.updateTypeOrdering)}
            canDrag={this.props.data.length > 1}
            groupData={group}
          />
        )}
      </DndProvider>
    );
  }
}

export default Accordion;
