import { combineReducers } from 'redux';
import _ from 'lodash-es';
import {
  UPDATE_SUBSCRIPTION_GROUPS,
  SET_SUBSCRIPTION_GROUP,
  UPDATE_SUBSCRIBER_ASSETS,
  UPDATE_SUBSCRIBER_SUBSCRIPTIONS,
  SET_IDENTITIES,
  SET_SUBSCRIPTIONS,
  SET_ERRORS,
  SET_PAYMENT_GATEWAYS,
  UPDATE_SUBSCRIBER_SUBSCRIPTION,
  SET_SUBSCRIPTION_ATTEMPTS,
  SET_SUBSCRIPTION_PLAN,
  SET_COUPONS,
  UPDATE_SUBSCRIPTIONS_REPORTS,
  SET_SETTINGS,
  SET_ACCOUNT_USERS,
  SET_WEBHOOKS
} from './actions';

const initialSubscriptionGroup = {
  name: null,
  assets: [],
  public: null,
  description: null,
  subscription_plans: [],
  'subscription-type': null
};

const initialSubscriptionPlan = {
  title: null,
  description: null,
  duration_length: null,
  price_currency: null,
  price_cents: null,
  durartion_unit: null,
  assets: []
};

function subscriptionGroupReducer (state = [], action) {
  switch (action.type) {
    case UPDATE_SUBSCRIPTION_GROUPS:
      return action.subscriptionGroups;
    default:
      return state;
  }
}

function createOrEditSubscriptionGroupReducer (
  state = initialSubscriptionGroup,
  action
) {
  switch (action.type) {
    case SET_SUBSCRIPTION_GROUP:
      return action.subscriptionGroup;
    default:
      return state;
  }
}

function subscriberReducer (state = { assets: [], subscriptions: [] }, action) {
  switch (action.type) {
    case UPDATE_SUBSCRIBER_ASSETS:
      return Object.assign({}, state, { assets: action.assets });
    case UPDATE_SUBSCRIBER_SUBSCRIPTIONS:
      return Object.assign({}, state, { subscriptions: action.subscriptions });
    case UPDATE_SUBSCRIBER_SUBSCRIPTION:
      const subscriptions = _.map(state.subscriptions, (subscription, index) =>
        subscription.id === action.subscription.id
          ? Object.assign({}, subscription, action.subscription)
          : subscription
      );
      return Object.assign({}, state, { subscriptions: subscriptions });
    default:
      return state;
  }
}

function setIdentities (state = [], action) {
  switch (action.type) {
    case SET_IDENTITIES:
      return action.identities;
    default:
      return state;
  }
}

function setSubscriptions (state = [], action) {
  switch (action.type) {
    case SET_SUBSCRIPTIONS:
      return action.subscriptions;
    default:
      return state;
  }
}

function setErrors (state = [], action) {
  switch (action.type) {
    case SET_ERRORS:
      return action.errors;
    default:
      return state;
  }
}

function setPaymentGateways (state = {}, action) {
  switch (action.type) {
    case SET_PAYMENT_GATEWAYS:
      return action.paymentGateways;
    default:
      return state;
  }
}

function setSubscriptionAttempts (state = [], action) {
  switch (action.type) {
    case SET_SUBSCRIPTION_ATTEMPTS:
      return action.subscriptionAttempts;
    default:
      return state;
  }
}

function createOrEditSubscriptionPlan (state = initialSubscriptionPlan, action) {
  switch (action.type) {
    case SET_SUBSCRIPTION_PLAN:
      return action.subscriptionPlan;
    default:
      return state;
  }
}

function setCoupons (state = [], action) {
  switch (action.type) {
    case SET_COUPONS:
      return action.coupons;
    default:
      return state;
  }
}

function subscriptionsReportsReducer (state = [], action) {
  switch (action.type) {
    case UPDATE_SUBSCRIPTIONS_REPORTS:
      return action.subscriptionsReports;
    default:
      return state;
  }
}

function settings (state = [], action) {
  switch (action.type) {
    case SET_SETTINGS:
      return action.settings;
    default:
      return state;
  }
}

function setAccountUsers (state = [], action) {
  switch (action.type) {
    case SET_ACCOUNT_USERS:
      return action.accountUsers;
    default:
      return state;
  }
}

function setWebhooks (state = [], action) {
  switch (action.type) {
    case SET_WEBHOOKS:
      return action.webhooks;
    default:
      return state;
  }
}

export default combineReducers({
  subscriptionGroups: subscriptionGroupReducer,
  subscriptionGroup: createOrEditSubscriptionGroupReducer,
  subscriptionPlan: createOrEditSubscriptionPlan,
  subscriber: subscriberReducer,
  identities: setIdentities,
  subscriptions: setSubscriptions,
  paymentGateways: setPaymentGateways,
  subscriptionAttempts: setSubscriptionAttempts,
  errors: setErrors,
  coupons: setCoupons,
  subscriptionsReports: subscriptionsReportsReducer,
  settings: settings,
  accountUsers: setAccountUsers,
  webhooks: setWebhooks,
  config: x => x || {}
});
