import React from 'react';
import PropTypes from 'prop-types';
import './TitleBar.scss';
import { Button, Breadcrumb } from '../../index';

const TitleBar = ({
  title, button, primary, buttonText, breadcrumbPath, breadcrumbTitle, buttonUrl, buttonDataTestID, children
}) => (
  <div className='title-bar'>
    { breadcrumbPath && breadcrumbTitle && <Breadcrumb title={breadcrumbTitle} path={breadcrumbPath} />}
    <div className='title-bar__title-container'>
      <h3 className='title-bar__title'>{title}</h3>
      {children}
      {button && <Button url={buttonUrl} primary={primary} dataTest={buttonDataTestID}> {buttonText} </Button>}
    </div>
  </div>
);

TitleBar.propTypes = {
  button: PropTypes.bool,
  title: PropTypes.string,
  primary: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  breadcrumbTitle: PropTypes.string,
  breadcrumbPath: PropTypes.string
};

export default TitleBar;
