import { uniq, isEmpty } from 'lodash-es';
import React, { useState } from 'react';
import { TagsInputWithLabel } from '../library-components';

function isValidIp (ips) {
  const digitExp = '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)';
  const ipRegexText = `^${digitExp}\\.${digitExp}\\.${digitExp}\\.${digitExp}$`;
  const ipRegex = new RegExp(ipRegexText);
  return ips.length > 0 && ips.every((ip) => ipRegex.test(ip));
}

function AllowedIpsInput ({ allowedIps, onIpsChange, disabled }) {
  const [error, setError] = useState(null);

  const handleAllowedIpsChange = (ips) => {
    const allowedIps = uniq(ips.map((str) => str.trim()));
    if (isValidIp(allowedIps) || isEmpty(allowedIps)) {
      onIpsChange(allowedIps);
      setError(null);
    } else {
      setError('Please enter a unique and valid ip address');
    }
  };

  return <div className="allowed-ips-input">
    <TagsInputWithLabel
      label={'Static IP Addresses'}
      dataTest={'subscription-allowed-ips'}
      value={allowedIps}
      onChange={handleAllowedIpsChange}
      inputProps={{ placeholder: 'Enter ip address to allow access, like 192.168.1.50', disabled: disabled }}
      onlyUnique
    />
    <p className="error-msg">
      {error}
    </p>
  </div>;
}

export default AllowedIpsInput;
