import React from 'react';
import Select from 'react-select';
import Selected from '../../library-components/Icons/Selected';
import DownArrowFilled from '../../library-components/Icons/DownArrowFilled';
import './custom-select.scss';

class CustomSelect extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      selectedValue: this.props.value
    };
  }

  renderSelectedValue (options) {
    if (options.value) {
      return <span className='custom-select--selected-value'>{options.label}</span>;
    } else {
      return <span>{options.label}</span>;
    }
  }

  renderOption (options, value) {
    console.log(value, 'value');
    return <div className='custom-select__option'>
      <span>{options.label}</span>
      {options.value === this.state.selectedValue && <span className='custom-select--selected-option'><Selected /></span>}
    </div>;
  }

  onChange (e) {
    this.setState({ selectedValue: e.value }, () => this.props.onChangeActive(this.state.selectedValue));
  }

  arrowRenderer () {
    return <DownArrowFilled uniqueId={this.props.id} />;
  }

  render () {
    return (
      <Select
        className='custom-select'
        value={this.props.value}
        onChange={(e) => this.onChange(e)}
        options={this.props.options}
        optionRenderer={(options, value) => this.renderOption(options, value)}
        valueRenderer={(options, value) => this.renderSelectedValue(options, value)}
        clearable={false}
        searchable={false}
        arrowRenderer={() => this.arrowRenderer()}
      />
    );
  }
}

export default CustomSelect;
