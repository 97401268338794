import React from 'react';
import PropTypes from 'prop-types';

const UpArrow = ({ width, height, fill }) => (
  <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width={width} height={height} viewBox='0 0 16 16'>
    <title>Chevron-up</title>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='fff' fillRule='evenodd'>
      <g id='icon-chevron-up' fillRule='nonzero' fill={fill}>
        <g transform='translate(1.000000, 4.000000)' id='Path-3'>
          <path
            d='M7,6.15147186 L1.42426407,0.575735931 C1.18994949,0.341421356 0.810050506,
                0.341421356 0.575735931,0.575735931 C0.341421356,0.810050506 0.341421356,
                1.18994949 0.575735931,1.42426407 L6.57573593,7.42426407 C6.81005051,
                7.65857864 7.18994949,7.65857864 7.42426407,7.42426407 L13.4242641,
                1.42426407 C13.6585786,1.18994949 13.6585786,0.810050506 13.4242641,
                0.575735931 C13.1899495,0.341421356 12.8100505,0.341421356 12.5757359,
                0.575735931 L7,6.15147186 Z'
            transform='translate(7.000000, 4.000000) scale(-1, -1) translate(-7.000000, -4.000000) '
          />
        </g>
      </g>
    </g>
  </svg>
);

UpArrow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
};

UpArrow.defaultProps = {
  width: '16',
  height: '16',
  fill: '#818A98'
};

export default UpArrow;
