import React from 'react';
import {
  Button,
  ButtonGroup,
  NumberField,
  SelectField,
  CardHeader,
  Description,
  SliderCheckbox,
  TagsInput,
  Card
} from '../library-components';
import { createOrUpdateMeteringSetting } from '../../api';
import { defaultPaywallMeteringRules } from './rules/rules';
import { isEmpty, findIndex, uniq, get } from 'lodash';
import validate from 'validate.js';
import './metering_rule.scss';
import { Radio, RadioOption } from '@quintype/em/components/radio-button';

const DURATION_UNITS = [
  { label: 'Days', value: 'days' },
  { label: 'Weeks', value: 'weeks' },
  { label: 'Months', value: 'months' }
];

const ASSET_TYPES = [{ label: 'Stories', value: 'story' }];

const USER_TYPE_DISPLAY_NAME = {
  anonymous: 'Anonymous',
  logged_in: 'Logged In'
};

const WALL_TYPE_DISPLAY_NAME = {
  pay_wall: 'Paywall',
  registration_wall: 'Registration Wall'
};

const WALL_TYPE_OPTIONS = [
  { label: 'Paywall', value: 'pay_wall' },
  { label: 'Registration Wall', value: 'registration_wall' }
];

const STORY_ACCESS_TYPE_DISPLAY_NAME = {
  login: 'Login',
  subscription: 'Subscription'
};

const SUPPORTED_FACTS = defaultPaywallMeteringRules.any.map(
  (rules) => rules.fact
);

export default class MeteringRule extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      errors: {},
      isSaveAssetsDisabled: true
    };
  }

  defaultMeteringSetting () {
    return Object.assign(
      {},
      this.props.meteringSetting.rules,
      defaultPaywallMeteringRules
    );
  }

  getRules () {
    if (isEmpty(this.props.meteringSetting.rules)) {
      return Array.from(this.defaultMeteringSetting().any);
    }
    return Array.from(this.props.meteringSetting.rules.any);
  }

  updateRules (fact, value) {
    this.setState({ isSaveAssetsDisabled: false });
    if (SUPPORTED_FACTS.includes(fact)) {
      if (
        isEmpty(value) &&
        this.props.meteringSetting.rules.any &&
        this.props.meteringSetting.rules.any.length <= 1
      ) {
        this.updateMeteringField('rules', {});
      } else {
        var rules = this.getRules();
        var rule = Object.assign(
          {},
          rules.find((rule) => rule.fact === fact)
        );
        var indexOfRule = findIndex(rules, rule);
        rule.value = value;
        rules[indexOfRule] = rule;
        this.updateMeteringField('rules', { any: rules });
      }
    } else {
      this.reportUnsupportedRuleFact(fact);
    }
  }

  setMeteringSetting (meteringSetting) {
    this.props.updateMeteringSettings(meteringSetting);
  }

  updateMeteringField (field, updatedValue) {
    this.setState({ isSaveAssetsDisabled: false });

    const updatedMeteringSetting = Object.assign(
      {},
      this.props.meteringSetting,
      { [field]: updatedValue }
    );
    this.setMeteringSetting(updatedMeteringSetting);

    const errors = this.validateMeteringSetting(updatedMeteringSetting);

    if (errors) {
      this.setState({ errors: errors, isSaveAssetsDisabled: true });
    }
  }

  validateMeteringSetting (meteringSetting) {
    return validate(meteringSetting, CONSTRAINTS);
  }

  saveMeteringSetting () {
    this.setState({ isSaveAssetsDisabled: true });
    const errors = this.validateMeteringSetting(this.props.meteringSetting);

    if (errors) {
      this.setState({ errors });
      return;
    }

    if (!isEmpty(this.state.errors)) {
      this.setState({ errors: {} });
    }

    createOrUpdateMeteringSetting(this.props.meteringSetting)
      .then((meteringSetting) => {
        this.setMeteringSetting(meteringSetting);
        this.props.showStatusMessage({
          status: 'success',
          statusMessage: 'Saved metering rules'
        });
      })
      .catch((response) =>
        this.props.showStatusMessage({
          status: 'error',
          statusMessage: `Unable to save rules setting, ${response.errors && response.errors[0]}`
        })
      );
  }

  updateErrors (key, value) {
    const errors = this.state.errors;
    errors[key] = value;
    this.setState({ errors });
  }

  validateAccessLevels (accessLevels) {
    return (
      accessLevels.length > 0 &&
      accessLevels.every((accessLevel) => /^\d+$/.test(accessLevel))
    );
  }

  reportUnsupportedRuleFact (fact) {
    console.log(new Error(`Unsupported fact ${fact}. Check SUPPORTED_FACTS`));
  }

  handleAccessLevelChange (accessLevels) {
    if (this.validateAccessLevels(accessLevels) || isEmpty(accessLevels)) {
      const parsedAccessLevels = uniq(
        accessLevels.map((accessLevel) => parseInt(accessLevel))
      );
      this.updateRules('accessLevels', parsedAccessLevels);
      this.updateErrors('accessLevels', null);
    } else {
      this.updateErrors(
        'accessLevels',
        'Please enter a unique and valid access level'
      );
    }
  }

  getRule (fact) {
    if (SUPPORTED_FACTS.includes(fact)) {
      const rules = this.props.meteringSetting.rules.any || [];
      const rule = rules.find((rule) => rule.fact === fact);
      return get(rule, 'value');
    } else {
      this.reportUnsupportedRuleFact(fact);
    }
  }

  render () {
    const showWallOptions = this.props.meteringSetting.user_type === 'anonymous' &&
      this.props.meteringSetting.asset_access_type === 'subscription';
    return (
      <div className={'metering-rule-container' + (showWallOptions ? ' metering-rule-container__wall-options--visible' : '')}>
        <Card>
          <div className="metering-rule-header">
            <h3>Stories behind {STORY_ACCESS_TYPE_DISPLAY_NAME[this.props.meteringSetting.asset_access_type]}</h3>
            <SliderCheckbox
              enabled={this.props.meteringSetting.enabled}
              onChange={() =>
                this.updateMeteringField(
                  'enabled',
                  !this.props.meteringSetting.enabled
                )
              }
              dataTest={`slider-checkbox-${this.props.meteringSetting.user_type}`}
            />
          </div>

          {this.props.meteringSetting.user_type === 'anonymous' &&
            this.props.meteringSetting.asset_access_type === 'subscription' && (
            <div className="form-row form-row--wall-type-options">
              <div className="text-container">Show</div>
              <Radio
                name="wall_type"
                selected={this.props.meteringSetting.wall_type}
                onChange={(e) => this.updateMeteringField('wall_type', e.target.value)}
              >
                {WALL_TYPE_OPTIONS.map((option) => (
                  <RadioOption key={option.value} value={option.value}>
                    {option.label}
                  </RadioOption>
                ))}
              </Radio>
            </div>
          )}
          <CardHeader title={`Display ${WALL_TYPE_DISPLAY_NAME[this.props.meteringSetting.wall_type]} for ${USER_TYPE_DISPLAY_NAME[this.props.meteringSetting.user_type]} users`} />
          <div className="form-row">
            <div className="text-container">After</div>
            <NumberField
              min={0}
              max={999}
              className={'number-field-short'}
              placeholder="Limit"
              value={this.props.meteringSetting.asset_limit || ''}
              onChange={(e) =>
                this.updateMeteringField('asset_limit', e.target.value)
              }
              error={this.state.errors && this.state.errors.asset_limit}
              dataTest={`asset-limit-${this.props.meteringSetting.user_type}-${this.props.meteringSetting.asset_access_type}`}
            />
            <SelectField
              className={'select-field'}
              options={ASSET_TYPES}
              placeholder="Asset type"
              value={this.props.meteringSetting.asset_type}
              clearable={false}
              onChange={(e) => this.updateMeteringField('asset_type', e.value)}
              dataTest={`asset-type-${this.props.meteringSetting.user_type}`}
            />
            <div className="text-container">every</div>
            <NumberField
              min={1}
              max={999}
              className={'number-field-long'}
              placeholder="Duration"
              value={this.props.meteringSetting.duration_value || ''}
              onChange={(e) =>
                this.updateMeteringField('duration_value', e.target.value)
              }
              error={this.state.errors && this.state.errors.duration_value}
              dataTest={`duration-value-${this.props.meteringSetting.user_type}`}
            />
            <SelectField
              className={'select-field'}
              options={DURATION_UNITS}
              placeholder="Duration unit"
              value={this.props.meteringSetting.duration_unit}
              clearable={false}
              onChange={(e) => {
                this.updateMeteringField('duration_unit', e.value);
              }}
              dataTest={`duration-unit-${this.props.meteringSetting.user_type}`}
            />
          </div>
          <p className="error-msg">
            {this.state.errors && this.state.errors.duration_unit}
          </p>
          <p className="error-msg">
            {this.state.errors && this.state.errors.asset_type}
          </p>
          <CardHeader title="Include Stories with" />
          <Description>Access Levels</Description>
          <TagsInput
            value={this.getRule('accessLevels') || []}
            onChange={this.handleAccessLevelChange.bind(this)}
            inputProps={{ placeholder: 'Add new access level' }}
            onlyUnique
          />
          <p className="error-msg">
            {this.state.errors && this.state.errors.accessLevels}
          </p>
          <ButtonGroup>
            <Button
              dataTest={`save-button-${this.props.meteringSetting.user_type}-${this.props.meteringSetting.asset_access_type}`}
              primary
              onClick={() => this.saveMeteringSetting()}
              disabled={this.state.isSaveAssetsDisabled}
            >
              Save
            </Button>
          </ButtonGroup>
        </Card>
      </div>
    );
  }
}

const CONSTRAINTS = {
  asset_limit: {
    numericality: { strict: true, greaterThan: 0, lessThan: 1000 }
  },
  duration_value: {
    numericality: { strict: true, greaterThan: 0, lessThan: 1000 }
  },
  asset_type: {
    presence: true
  },
  duration_unit: {
    presence: true
  }
};
