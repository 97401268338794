import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash-es';
import dotProp from 'dot-prop-immutable';
import { getPaymentGateways, updatePaymentGatewayCredentials, validatePaymentGatewayCredentials, enablePaymentGateway } from '../api';
import { Button, TitleBar, ButtonGroup, TextFieldWithLabel, TextAreaWithLabel, Layout, StatusMessage, SelectFieldWithLabelDatatest } from '../components/library-components';
import Razorpay from './Icons/razorpay';
import Googlepay from './Icons/googlepay';
import Simpl from './Icons/simpl';
import Paytm from './Icons/paytm';
import Juspay from './Icons/juspay';
import Stripe from './Icons/stripe';
import Paypal from './Icons/paypal';
import Omise from './Icons/omise';
import AppleIAP from './Icons/appleiap';
import Adyen from './Icons/adyen';
import Paytrail from './Icons/paytrail';
import Payflow from './Icons/payflow';
import validate from 'validate.js';
import PaymentGatewayAccordion from './payment_gateway_accordion';
import Copy from './Icons/copy';
import { PAYMENT_GATEWAY_CONSTRAINTS } from '../constants';

import '../../stylesheets/pages/payment-gateways.scss';

const applepayEnvironmentTypes = [
  { label: 'sandbox', value: 'sandbox' },
  { label: 'live', value: 'live' }
];

const adyenEnvironmentTypes = [
  { label: 'test', value: 'test' },
  { label: 'live', value: 'live' },
  { label: 'live-au', value: 'live-au' },
  { label: 'live-us', value: 'live-us' }
];

export class PaymentGateways extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showNotification: { isNotificationOpen: false, status: '', statusMessage: '' },
      activeItem: '',
      status: {},
      sliderActive: [],
      paymentGateways: {},
      paymentGateway: '',
      errors: {},
      paytmEnabled: false,
      juspayEnabled: false,
      stripeEnabled: false,
      showCopyMessage: false,
      adyenEnabled: false,
      payflowEnabled: false
    };
  }

  render () {
    return (
      <Layout>
        <TitleBar title='Payment Gateways' />
        <form>

          <PaymentGatewayAccordion showSlider={this.state.sliderActive.includes('razorpay')} errors={this.state.errors} logo={<Razorpay />} active={this.state.activeItem === 'razorpay'} paymentGateway='razorpay' toggleSwitch={(e) => { this.onToggleSwitch(e, 'razorpay', true); }} paymentGateways={this.state.paymentGateways} toggleAccordion={(e) => this.toggleAccordion(e, 'razorpay')} status={this.state.status}>
            <div>
              <div className='form-row'>
                <TextFieldWithLabel label='App Key' dataTest='app_key' placeholder='Razorpay App Key' value={_.get(this.state.paymentGateways, ['razorpay', 'app_key'], '')} onChange={(e) => this.updateField(['razorpay', 'app_key'], e.target.value)} errors={_.get(this.state.errors, ['razorpay_errors', 'app_key', '0'])} />
                <TextFieldWithLabel label='Secret' dataTest='secret' placeholder='Razorpay Secret' value={_.get(this.state.paymentGateways, ['razorpay', 'secret'], '')} onChange={(e) => this.updateField(['razorpay', 'secret'], e.target.value)} errors={_.get(this.state.errors, ['razorpay_errors', 'secret', '0'])} />
              </div>
              <div className='form-row'>
                <TextFieldWithLabel label='Webhook Secret' dataTest='webhook_secret' placeholder='Webhook Secret' value={_.get(this.state.paymentGateways, ['razorpay', 'webhook_secret'], '')} onChange={(e) => this.updateField(['razorpay', 'webhook_secret'], e.target.value)} />
              </div>
              <div className='buttons-container'>
                <ButtonGroup>
                  {this.state.sliderActive.includes('razorpay') && <Button onClick={(e) => this.checkStatus(e, 'razorpay')} primary>Check Status</Button>}
                  <Button onClick={(e) => this.updatePaymentGateway(e, 'razorpay')} primary>Save</Button>
                </ButtonGroup>
              </div>
              <div className='payment-gateways__info'>
                <TextFieldWithLabel label='Razorpay Callback URL:' value={`https://${window.location.host}/api/v1/callbacks/${this.state.accountKey}/razorpay.json`} copyLogo={<Copy />} copy showCopiedMessage={() => this.showCopiedMessage()} />
                {this.state.showCopyMessage && <div className='copy-success-message'> URL Copied to Clipboard </div> }
              </div>
            </div>
          </PaymentGatewayAccordion>

          { this.state.paymentGateways['androidpay'] && <PaymentGatewayAccordion showSlider={this.state.sliderActive.includes('androidpay')} logo={<Googlepay />} active={this.state.activeItem === 'androidpay'} paymentGateway='androidpay' toggleSwitch={(e) => this.onToggleSwitch(e, 'androidpay', true)} paymentGateways={this.state.paymentGateways} toggleAccordion={(e) => this.toggleAccordion(e, 'androidpay')} status={this.state.status}>
            <TextAreaWithLabel label='JWT' placeholder='Androidpay JWT' value={JSON.stringify(_.get(this.state.paymentGateways, ['androidpay', 'jwt'], ''))} onChange={(e) => this.updateField(['androidpay', 'jwt'], JSON.parse(e.target.value))} errors={_.get(this.state.errors, ['androidpay_errors', 'jwt', '0'])} />
            <div className='buttons-container'>
              <ButtonGroup>
                {this.state.sliderActive.includes('androidpay') && <Button onClick={(e) => this.checkStatus(e, 'androidpay')} primary>Check Status</Button>}
                <Button onClick={(e) => this.updatePaymentGateway(e, 'androidpay')} primary>Save</Button>
              </ButtonGroup>
            </div>
            <div className='payment-gateways__info'>Androidpay Renewal Topic: &nbsp; PENDING (please contact support to generate one)</div>
          </PaymentGatewayAccordion>}

          { <PaymentGatewayAccordion showSlider={this.state.sliderActive.includes('googlepay')} logo={<Googlepay />} active={this.state.activeItem === 'googlepay'} paymentGateway='googlepay' toggleSwitch={(e) => this.onToggleSwitch(e, 'googlepay', true)} paymentGateways={this.state.paymentGateways} toggleAccordion={(e) => this.toggleAccordion(e, 'googlepay')} status={this.state.status}>
            <TextAreaWithLabel label='JWT' placeholder='Googlepay JWT' value={JSON.stringify(_.get(this.state.paymentGateways, ['googlepay', 'jwt']))} onChange={(e) => this.updateField(['googlepay', 'jwt'], JSON.parse(e.target.value))} errors={_.get(this.state.errors, ['googlepay_errors', 'jwt', '0'])} />
            <TextFieldWithLabel label='Package name' placeholder='Package name' value={_.get(this.state.paymentGateways, ['googlepay', 'package_name'])} onChange={(e) => this.updateField(['googlepay', 'package_name'], e.target.value)} errors={_.get(this.state.errors, ['googlepay_errors', 'package_name', '0'])} />
            <div className='buttons-container'>
              <ButtonGroup>
                {this.state.sliderActive.includes('googlepay') && <Button onClick={(e) => this.checkStatus(e, 'googlepay')} primary>Check Status</Button>}
                <Button onClick={(e) => this.updatePaymentGateway(e, 'googlepay')} primary>Save</Button>
              </ButtonGroup>
            </div>
            <div className='payment-gateways__info'>
              <TextFieldWithLabel label='Googlepay Callback URL:' value={`https://${window.location.host}/api/v1/callbacks/${this.state.accountKey}/googlepay`} copyLogo={<Copy />} copy showCopiedMessage={() => this.showCopiedMessage()} />
              {this.state.showCopyMessage && <div className='copy-success-message'> URL Copied to Clipboard </div> }
            </div>
          </PaymentGatewayAccordion>}

          <PaymentGatewayAccordion showSlider={this.state.sliderActive.includes('simpl')} logo={<Simpl />} active={this.state.activeItem === 'simpl'} paymentGateway='simpl' toggleSwitch={(e) => this.onToggleSwitch(e, 'simpl')} paymentGateways={this.state.paymentGateways} toggleAccordion={(e) => this.toggleAccordion(e, 'simpl')} status={this.state.status}>
            <div className='form-row'>
              <TextFieldWithLabel label='Client Id' dataTest='client_id' placeholder='Simpl Client Id' value={_.get(this.state.paymentGateways, ['simpl', 'client_id'], '')} onChange={(e) => this.updateField(['simpl', 'client_id'], e.target.value)} errors={_.get(this.state.errors, ['simpl_errors', 'client_id', '0'])} />
              <TextFieldWithLabel label='Client Secret' dataTest='client_secret' placeholder='Simpl Client Secret' value={_.get(this.state.paymentGateways, ['simpl', 'client_secret'], '')} onChange={(e) => this.updateField(['simpl', 'client_secret'], e.target.value)} errors={_.get(this.state.errors, ['simpl_errors', 'client_secret', '0'])} />
            </div>
            <div className='buttons-container'>
              <ButtonGroup>
                { this.state.sliderActive.includes('simpl') && <Button onClick={(e) => this.checkStatus(e, 'simpl')} primary>Check Status</Button> }
                <Button onClick={(e) => this.updatePaymentGateway(e, 'simpl')} primary>Save</Button>
              </ButtonGroup>
            </div>
          </PaymentGatewayAccordion>

          {this.state.paytmEnabled && <PaymentGatewayAccordion showSlider={this.state.sliderActive.includes('paytm')} logo={<Paytm />} active={this.state.activeItem === 'paytm'} paymentGateway='paytm' toggleSwitch={(e) => this.onToggleSwitch(e, 'paytm')} paymentGateways={this.state.paymentGateways} toggleAccordion={(e) => this.toggleAccordion(e, 'paytm')} status={this.state.status}>
            <div className='form-row'>
              <TextFieldWithLabel label='Client Id' dataTest='paytm_client_id' placeholder='Paytm Client Id' value={_.get(this.state.paymentGateways, ['paytm', 'client_id'], '')} onChange={(e) => this.updateField(['paytm', 'client_id'], e.target.value)} errors={_.get(this.state.errors, ['paytm_errors', 'client_id', '0'])} />
              <TextFieldWithLabel label='Client Secret' dataTest='paytm_client_secret' placeholder='Paytm Client Secret' value={_.get(this.state.paymentGateways, ['paytm', 'client_secret'], '')} onChange={(e) => this.updateField(['paytm', 'client_secret'], e.target.value)} errors={_.get(this.state.errors, ['paytm_errors', 'client_secret', '0'])} />
            </div>
            <div className='form-row'>
              <TextFieldWithLabel label='Merchant Key' dataTest='merchant_key' placeholder='Merchant Key' value={_.get(this.state.paymentGateways, ['paytm', 'merchant_key'], '')} onChange={(e) => this.updateField(['paytm', 'merchant_key'], e.target.value)} errors={_.get(this.state.errors, ['paytm_errors', 'merchant_key', '0'])} />
              <TextFieldWithLabel label='MID' dataTest='mid' placeholder='MID' value={_.get(this.state.paymentGateways, ['paytm', 'mid'], '')} onChange={(e) => this.updateField(['paytm', 'mid'], e.target.value)} errors={_.get(this.state.errors, ['paytm_errors', 'mid', '0'])} />
            </div>
            <ButtonGroup>
              <Button onClick={(e) => this.updatePaymentGateway(e, 'paytm')} primary>Save</Button>
            </ButtonGroup>
          </PaymentGatewayAccordion>}

          {this.state.juspayEnabled && <PaymentGatewayAccordion showSlider={this.state.sliderActive.includes('juspay')} errors={this.state.errors} logo={<Juspay />} active={this.state.activeItem === 'juspay'} paymentGateway='juspay' toggleSwitch={(e) => { this.onToggleSwitch(e, 'juspay', true); }} paymentGateways={this.state.paymentGateways} toggleAccordion={(e) => this.toggleAccordion(e, 'juspay')} status={this.state.status}>
            <div>
              <div className='form-row'>
                <TextFieldWithLabel label='Api Key' dataTest='juspay_api_key' placeholder='Juspay API Key' value={_.get(this.state.paymentGateways, ['juspay', 'api_key'], '')} onChange={(e) => this.updateField(['juspay', 'api_key'], e.target.value)} errors={_.get(this.state.errors, ['juspay_errors', 'api_key', '0'])} />
                <TextFieldWithLabel label='Response Key' dataTest='juspay_response_key' placeholder='Juspay Response Key' value={_.get(this.state.paymentGateways, ['juspay', 'response_key'], '')} onChange={(e) => this.updateField(['juspay', 'response_key'], e.target.value)} errors={_.get(this.state.errors, ['juspay_errors', 'response_key', '0'])} />

              </div>
              <div className='form-row'>
                <TextFieldWithLabel label='Webhook UserName' dataTest='juspay_webhook_username' placeholder='Webhook UserName' value={_.get(this.state.paymentGateways, ['juspay', 'webhook_username'], '')} onChange={(e) => this.updateField(['juspay', 'webhook_username'], e.target.value)} errors={_.get(this.state.errors, ['juspay_errors', 'webhook_username', '0'])} />
                <TextFieldWithLabel label='Webhook Password' dataTest='juspay_webhook_password' placeholder='Webhook Password' value={_.get(this.state.paymentGateways, ['juspay', 'webhook_password'], '')} onChange={(e) => this.updateField(['juspay', 'webhook_password'], e.target.value)} errors={_.get(this.state.errors, ['juspay_errors', 'webhook_password', '0'])} />

              </div>
              <div className='buttons-container'>
                <ButtonGroup>
                  {this.state.sliderActive.includes('juspay') && <Button dataTest='juspay_check_status' onClick={(e) => this.checkStatus(e, 'juspay')} primary>Check Status</Button>}
                  <Button dataTest='juspay_save' onClick={(e) => this.updatePaymentGateway(e, 'juspay')} primary>Save</Button>
                </ButtonGroup>
              </div>
              <div className='payment-gateways__info'>
                <TextFieldWithLabel label='Juspay Callback URL:' value={`https://${window.location.host}/api/v1/callbacks/${this.state.accountKey}/juspay.json`} copyLogo={<Copy />} copy showCopiedMessage={() => this.showCopiedMessage()} />
                {this.state.showCopyMessage && <div className='copy-success-message'> URL Copied to Clipboard </div> }
              </div>
            </div>
          </PaymentGatewayAccordion>}

          {this.state.stripeEnabled && <PaymentGatewayAccordion showSlider={this.state.sliderActive.includes('stripe')} errors={this.state.errors} logo={<Stripe />} active={this.state.activeItem === 'stripe'} paymentGateway='stripe' toggleSwitch={(e) => { this.onToggleSwitch(e, 'stripe', true); }} paymentGateways={this.state.paymentGateways} toggleAccordion={(e) => this.toggleAccordion(e, 'stripe')} status={this.state.status}>
            <div>
              <div className='form-row'>
                <TextFieldWithLabel label='Api Key' dataTest='stripe_api_key' placeholder='Stripe API Key' value={_.get(this.state.paymentGateways, ['stripe', 'api_key'], '')} onChange={(e) => this.updateField(['stripe', 'api_key'], e.target.value)} errors={_.get(this.state.errors, ['stripe_errors', 'api_key', '0'])} />
              </div>
              <div className='form-row'>
                <TextFieldWithLabel label='Publishable Key' dataTest='stripe_publishable_key' placeholder='Stripe Publishable Key' value={_.get(this.state.paymentGateways, ['stripe', 'publishable_key'], '')} onChange={(e) => this.updateField(['stripe', 'publishable_key'], e.target.value)} errors={_.get(this.state.errors, ['stripe_errors', 'publishable_key', '0'])} />
              </div>
              <div className='buttons-container'>
                <ButtonGroup>
                  {this.state.sliderActive.includes('stripe') && <Button dataTest='stripe_check_status' onClick={(e) => this.checkStatus(e, 'stripe')} primary>Check Status</Button>}
                  <Button dataTest='stripe_save' onClick={(e) => this.updatePaymentGateway(e, 'stripe')} primary>Save</Button>
                </ButtonGroup>
              </div>
            </div>
          </PaymentGatewayAccordion>}

          {this.state.paypalEnabled && <PaymentGatewayAccordion showSlider={this.state.sliderActive.includes('paypal')} errors={this.state.errors} logo={<Paypal />} active={this.state.activeItem === 'paypal'} paymentGateway='paypal' toggleSwitch={(e) => { this.onToggleSwitch(e, 'paypal', true); }} paymentGateways={this.state.paymentGateways} toggleAccordion={(e) => this.toggleAccordion(e, 'paypal')} status={this.state.status}>
            <div>
              <div className='form-row'>
                <TextFieldWithLabel label='Client ID' dataTest='paypal_client_id' placeholder='Paypal Client ID' value={_.get(this.state.paymentGateways, ['paypal', 'client_id'], '')} onChange={(e) => this.updateField(['paypal', 'client_id'], e.target.value)} errors={_.get(this.state.errors, ['paypal_errors', 'client_id', '0'])} />
              </div>
              <div className='form-row'>
                <TextFieldWithLabel label='Secret' dataTest='paypal_secret' placeholder='Paypal Secret' value={_.get(this.state.paymentGateways, ['paypal', 'secret'], '')} onChange={(e) => this.updateField(['paypal', 'secret'], e.target.value)} errors={_.get(this.state.errors, ['paypal_errors', 'secret', '0'])} />
              </div>
              <div className='buttons-container'>
                <ButtonGroup>
                  {this.state.sliderActive.includes('paypal') && <Button dataTest='paypal_check_status' onClick={(e) => this.checkStatus(e, 'paypal')} primary>Check Status</Button>}
                  <Button dataTest='paypal_save' onClick={(e) => this.updatePaymentGateway(e, 'paypal')} primary>Save</Button>
                </ButtonGroup>
              </div>
            </div>
          </PaymentGatewayAccordion>}

          {this.state.omiseEnabled && <PaymentGatewayAccordion showSlider={this.state.sliderActive.includes('omise')} logo={<Omise />} active={this.state.activeItem === 'omise'} paymentGateway='omise' toggleSwitch={(e) => this.onToggleSwitch(e, 'omise')} paymentGateways={this.state.paymentGateways} toggleAccordion={(e) => this.toggleAccordion(e, 'omise')} status={this.state.status}>
            <div>
              <h3 className='payment-gateways__note-title'>Points to Note</h3>
              <ol>
                <li className='payment-gateways__note-item'>1.Omise doesn’t allow you to set Free Trials for your Recurring Plans.</li>
                <li className='payment-gateways__note-item'>2.Stricter minimum and maximum amounts for charges may be enforced for specific payment methods. See the relevant payment method <a target="_blank" rel="noopener noreferrer" className='payment-gateways__note-link' href="https://www.omise.co/currency-and-amount#charge-limits">documentation</a> for details.</li>
              </ol>
              <div className='form-row'>
                <TextFieldWithLabel label='Publishable Key' dataTest='omise_publishable_key' placeholder='Omise Publishable Key' value={_.get(this.state.paymentGateways, ['omise', 'publishable_key'], '')} onChange={(e) => this.updateField(['omise', 'publishable_key'], e.target.value)} errors={_.get(this.state.errors, ['omise_errors', 'publishable_key', '0'])} />
              </div>
              <div className='form-row'>
                <TextFieldWithLabel label='Secret Key' dataTest='omise_secret_key' placeholder='Omise Secret Key' value={_.get(this.state.paymentGateways, ['omise', 'secret_key'], '')} onChange={(e) => this.updateField(['omise', 'secret_key'], e.target.value)} errors={_.get(this.state.errors, ['omise_errors', 'secret_key', '0'])} />
              </div>
              <div className='buttons-container'>
                <ButtonGroup>
                  {this.state.sliderActive.includes('omise') && <Button dataTest='omise_check_status' onClick={(e) => this.checkStatus(e, 'omise')} primary>Check Status</Button>}
                  <Button onClick={(e) => this.updatePaymentGateway(e, 'omise')} primary>Save</Button>
                </ButtonGroup>
              </div>
            </div>
          </PaymentGatewayAccordion>}

          {this.state.adyenEnabled && <PaymentGatewayAccordion showSlider={this.state.sliderActive.includes('adyen')} logo={<Adyen />} active={this.state.activeItem === 'adyen'} paymentGateway='adyen' toggleSwitch={(e) => this.onToggleSwitch(e, 'adyen')} paymentGateways={this.state.paymentGateways} toggleAccordion={(e) => this.toggleAccordion(e, 'adyen')} status={this.state.status}>
            <div>
              <div className='form-row'>
                <SelectFieldWithLabelDatatest label='Environment' dataTest='adyen_environment' options={adyenEnvironmentTypes} value={_.get(this.state.paymentGateways, ['adyen', 'environment'], '')} onChange={(env) => this.updateField(['adyen', 'environment'], env.value)} errors={_.get(this.state.errors, ['adyen_errors', 'environment', '0'])} />
              </div>
              <div className='form-row'>
                <TextFieldWithLabel label='Api key' dataTest='adyen_api_key' placeholder='Adyen Api Key' value={_.get(this.state.paymentGateways, ['adyen', 'api_key'], '')} onChange={(e) => this.updateField(['adyen', 'api_key'], e.target.value)} errors={_.get(this.state.errors, ['adyen_errors', 'api_key', '0'])} />
              </div>
              <div className='form-row'>
                <TextFieldWithLabel label='Client Key' dataTest='adyen_client_key' placeholder='Adyen Client Key' value={_.get(this.state.paymentGateways, ['adyen', 'client_key'], '')} onChange={(e) => this.updateField(['adyen', 'client_key'], e.target.value)} errors={_.get(this.state.errors, ['adyen_errors', 'client_key', '0'])} />
              </div>
              <div className='form-row'>
                <TextFieldWithLabel label='Merchant Account' dataTest='adyen_merchant_account' placeholder='Adyen Merchant Account' value={_.get(this.state.paymentGateways, ['adyen', 'merchant_account'], '')} onChange={(e) => this.updateField(['adyen', 'merchant_account'], e.target.value)} errors={_.get(this.state.errors, ['adyen_errors', 'merchant_account', '0'])} />
              </div>
              <div className='buttons-container'>
                <ButtonGroup>
                  {this.state.sliderActive.includes('adyen') && <Button dataTest='adyen_check_status' onClick={(e) => this.checkStatus(e, 'adyen')} primary>Check Status</Button>}
                  <Button onClick={(e) => this.updatePaymentGateway(e, 'adyen')} primary>Save</Button>
                </ButtonGroup>
              </div>
            </div>
          </PaymentGatewayAccordion>}

          {this.state.applepayEnabled && <PaymentGatewayAccordion showSlider={this.state.sliderActive.includes('applepay')} logo={<AppleIAP />} active={this.state.activeItem === 'applepay'} paymentGateway='applepay' toggleSwitch={(e) => this.onToggleSwitch(e, 'applepay')} paymentGateways={this.state.paymentGateways} toggleAccordion={(e) => this.toggleAccordion(e, 'applepay')} status={this.state.status}>
            <div>
              <div className='form-row'>
                <SelectFieldWithLabelDatatest label='Environment' dataTest='applepay_environment' options={applepayEnvironmentTypes} value={_.get(this.state.paymentGateways, ['applepay', 'environment'], '')} onChange={(env) => this.updateField(['applepay', 'environment'], env.value)} errors={_.get(this.state.errors, ['applepay_errors', 'environment', '0'])} />
              </div>
              <div className='form-row'>
                <TextAreaWithLabel label='Private Api Key' dataTest='applepay_private_key' placeholder='Private Api Key' value={_.get(this.state.paymentGateways, ['applepay', 'api_key'], '')} onChange={(e) => this.updateField(['applepay', 'api_key'], e.target.value)} errors={_.get(this.state.errors, ['applepay_errors', 'api_key', '0'])} />
              </div>
              <div className='form-row'>
                <TextFieldWithLabel label='Key Id' dataTest='applepay_key_id' placeholder='Key Id' value={_.get(this.state.paymentGateways, ['applepay', 'key_id'], '')} onChange={(e) => this.updateField(['applepay', 'key_id'], e.target.value)} errors={_.get(this.state.errors, ['applepay_errors', 'key_id', '0'])} />
              </div>
              <div className='form-row'>
                <TextFieldWithLabel label='Bundle Id' dataTest='applepay_bundle_id' placeholder='Key Id' value={_.get(this.state.paymentGateways, ['applepay', 'bundle_id'], '')} onChange={(e) => this.updateField(['applepay', 'bundle_id'], e.target.value)} errors={_.get(this.state.errors, ['applepay_errors', 'bundle_id', '0'])} />
              </div>
              <div className='form-row'>
                <TextFieldWithLabel label='Issuer Id' dataTest='applepay_issuer_id' placeholder='Key Id' value={_.get(this.state.paymentGateways, ['applepay', 'issuer_id'], '')} onChange={(e) => this.updateField(['applepay', 'issuer_id'], e.target.value)} errors={_.get(this.state.errors, ['applepay_errors', 'issuer_id', '0'])} />
              </div>
              <div className='form-row'>
                <TextFieldWithLabel label='Shared Secret' dataTest='applepay_shared_password' placeholder='Shared Secret' value={_.get(this.state.paymentGateways, ['applepay', 'shared_password'], '')} onChange={(e) => this.updateField(['applepay', 'shared_password'], e.target.value)} errors={_.get(this.state.errors, ['applepay_errors', 'shared_password', '0'])} />
              </div>
              <div className='buttons-container'>
                <ButtonGroup>
                  {this.state.sliderActive.includes('applepay') && <Button dataTest='applepay_check_status' onClick={(e) => this.checkStatus(e, 'applepay')} primary>Check Status</Button>}
                  <Button onClick={(e) => this.updatePaymentGateway(e, 'applepay')} primary>Save</Button>
                </ButtonGroup>
              </div>
            </div>
          </PaymentGatewayAccordion>}

        </form>
        {this.state.showNotification.isNotificationOpen &&
          <StatusMessage statusMessage={this.state.showNotification.statusMessage}
            status={this.state.showNotification.status}
            onCancel={() => this.setState({ showNotification: { isNotificationOpen: false, status: '', statusMessage: '' } })} />}

        {this.state.paytrailEnabled && <PaymentGatewayAccordion showSlider={this.state.sliderActive.includes('paytrail')} errors={this.state.errors} logo={<Paytrail />} active={this.state.activeItem === 'paytrail'} paymentGateway='paytrail' toggleSwitch={(e) => { this.onToggleSwitch(e, 'paytrail', true); }} paymentGateways={this.state.paymentGateways} toggleAccordion={(e) => this.toggleAccordion(e, 'paytrail')} status={this.state.status}>
          <div>
            <div className='form-row'>
              <TextFieldWithLabel label='Merchant ID' dataTest='checkout_account' placeholder='Paytrail Checkout Account' value={_.get(this.state.paymentGateways, ['paytrail', 'checkout_account'], '')} onChange={(e) => this.updateField(['paytrail', 'checkout_account'], e.target.value)} errors={_.get(this.state.errors, ['paytrail_errors', 'checkout_account', '0'])} />
              <TextFieldWithLabel label='Secret Key' dataTest='api_key' placeholder='Paytrail API Key' value={_.get(this.state.paymentGateways, ['paytrail', 'api_key'], '')} onChange={(e) => this.updateField(['paytrail', 'api_key'], e.target.value)} errors={_.get(this.state.errors, ['paytrail_errors', 'api_key', '0'])} />
            </div>
            <ButtonGroup>
              <Button onClick={(e) => this.updatePaymentGateway(e, 'paytrail')} primary>Save</Button>
            </ButtonGroup>
          </div>
        </PaymentGatewayAccordion>}

        {this.state.payflowEnabled && <PaymentGatewayAccordion showSlider={this.state.sliderActive.includes('payflow')} errors={this.state.errors} logo={<Payflow />} active={this.state.activeItem === 'payflow'} paymentGateway='payflow' toggleSwitch={(e) => { this.onToggleSwitch(e, 'payflow', true); }} paymentGateways={this.state.paymentGateways} toggleAccordion={(e) => this.toggleAccordion(e, 'payflow')} status={this.state.status}>
          <div>
            <div className='form-row'>
              <TextFieldWithLabel label='Client ID' dataTest='payflow_client_id' placeholder='Payflow Client ID' value={_.get(this.state.paymentGateways, ['payflow', 'client_id'], '')} onChange={(e) => this.updateField(['payflow', 'client_id'], e.target.value)} errors={_.get(this.state.errors, ['payflow_errors', 'client_id', '0'])} />
            </div>
            <div className='form-row'>
              <TextFieldWithLabel label='Client Secret' dataTest='payflow_client_secret' placeholder='Client Secret' value={_.get(this.state.paymentGateways, ['payflow', 'client_secret'], '')} onChange={(e) => this.updateField(['payflow', 'client_secret'], e.target.value)} errors={_.get(this.state.errors, ['payflow_errors', 'client_secret', '0'])} />
            </div>
            <div className='buttons-container'>
              <ButtonGroup>
                <Button dataTest='payflow_save' onClick={(e) => this.updatePaymentGateway(e, 'payflow')} primary>Save</Button>
              </ButtonGroup>
            </div>
          </div>
        </PaymentGatewayAccordion>}

      </Layout>
    );
  }

  onToggleSwitch (e, paymentGateway) {
    var enabled = e.target.checked;
    this.setState(
      { paymentGateways: dotProp.set(this.state.paymentGateways, [paymentGateway, 'enabled'], e.target.checked) },
      () => {
        enablePaymentGateway(paymentGateway, enabled).then(
          (response) => {
            const status = response.enable === true ? 'enabled' : 'disabled';
            this.setState({ showNotification: { isNotificationOpen: true, status: 'success', statusMessage: `${paymentGateway} ${status}` } });
          }
        );
      }
    );
  }

  showCopiedMessage () {
    this.setState({ showCopyMessage: true }, () => setTimeout(() => this.setState({ showCopyMessage: false }), 3000));
  }

  toggleAccordion (e, paymentGateway) {
    this.setState({ activeItem: (this.state.activeItem !== paymentGateway) ? paymentGateway : '' });
  }

  checkStatus (e, paymentGateway) {
    validatePaymentGatewayCredentials(paymentGateway).then((response) => {
      const status = response.valid === true ? 'connected' : 'Invalid Credentials or Problems with payment gateway';
      const updatedStatusObj = _.merge(this.state.status, { [paymentGateway]: status });
      this.setState({ status: updatedStatusObj, paymentGateways: response.payment_gateways });
    }).catch(() => this.setState({ status: { [paymentGateway]: 'Invalid Credentials or Problems with payment gateway' } }));
  }

  paymentGatewayValidations (paymentGateway) {
    const paymentErrors = paymentGateway + '_errors';
    const errors = validate(this.state.paymentGateways[paymentGateway], PAYMENT_GATEWAY_CONSTRAINTS[paymentGateway]);
    this.setState({ errors: { [paymentErrors]: errors } });
    return errors;
  }

  setPaymentGatewaysState () {
    getPaymentGateways().then(config => {
      this.setState({ sliderActive: Object.keys(config.payment_gateways), paymentGateways: config.payment_gateways, accountKey: config.account_key, paytmEnabled: config.paytm_enabled, juspayEnabled: config.juspay_enabled, stripeEnabled: config.stripe_enabled, paypalEnabled: config.paypal_enabled, omiseEnabled: config.omise_enabled, adyenEnabled: config.adyen_enabled, applepayEnabled: config.applepay_enabled, paytrailEnabled: config.paytrail_enabled, payflowEnabled: config.payflow_enabled });
    });
  }

  enableSlider (paymentGateway) {
    if (this.state.sliderActive && !this.state.sliderActive.includes(paymentGateway)) {
      const cloneSliderActive = this.state.sliderActive;
      cloneSliderActive.push(paymentGateway);
      this.setState({ sliderActive: cloneSliderActive });
    }
  }

  updatePaymentGateway (e, paymentGateway) {
    var errors = this.paymentGatewayValidations(paymentGateway);
    if (!errors) {
      updatePaymentGatewayCredentials(_.pick(this.state.paymentGateways, [paymentGateway]))
        .then(() => {
          this.enableSlider(paymentGateway);
          this.setState({ showNotification: { isNotificationOpen: true, status: 'success', statusMessage: `${paymentGateway} credentials updated` } });
          this.checkStatus(e, paymentGateway);
        },
        (errors) => this.setState({ showNotification: { isNotificationOpen: true, status: 'error', statusMessage: errors.map((error, index) => <span key={index}>{error}</span>) } }));
    }
  }

  updateField (fieldPath, value, toggle = false) {
    this.setState({ paymentGateways: dotProp.set(this.state.paymentGateways, fieldPath, value) });
  }

  componentDidMount () {
    this.setPaymentGatewaysState();
  }
}

function mapStateToProps (state) {
  return {};
}

function mapDispatchToProps (dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentGateways);
