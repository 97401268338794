import React from 'react';

import { AnimatedSpinner } from '@quintype/em/icons/animated-spinner';

import './upload-image.scss';

const Loading = ({ label }) => (
  <div className='upload-container'>
    <div className="upload-heading">{label || 'Image'}</div>
    <div className="image-preview-wrapper upload-loader">
      <div className="icon-wrapper">
        <AnimatedSpinner width="24" height="24" color="black" />
      </div>
      <div className='uploading-text'>uploading...</div>
    </div>
  </div>
);

export default Loading;
