import React from 'react';
import _ from 'lodash-es';
import { connect } from 'react-redux';
import { Layout, Description, StatusMessage, TitleBar } from '../components/library-components';
import { getSubscriptionGroup, getAssetCategoriesCors, getAccountSettings } from '../api';
import { SET_SUBSCRIPTION_GROUP, SET_ERRORS } from '../actions';
import PayPerAssetGroupPlanItem from './pay_per_asset_group_plan_item';
import { getPPAPlanPageUrl } from '../helpers/utils';

export class PayPerAssetGroups extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showPlanDetails: false,
      showNotification: { isNotificationOpen: false, status: '', statusMessage: '' },
      showSave: false,
      payPerAssetEnabled: true,
      expandPlanIndex: -1
    };
  }

  componentDidMount () {
    this.loadSubscriptionGroup();
    getAssetCategoriesCors(true).then(assetCategories => this.setState({ assetCategories: assetCategories }));
    this.setState({ errros: {} });
    getAccountSettings().then(response => {
      this.setState({ payPerAssetEnabled: response.account.pay_per_asset_enabled });
    });
  }

  loadSubscriptionGroup () {
    getSubscriptionGroup(this.props.groupId).then((response) => this.props.setSubscriptionGroup(response));
  }

  render () {
    const group =
      <Layout>
        <TitleBar title="Pay Per Asset Pricing"
          button primary buttonText='Add Pricing Rule' buttonUrl={getPPAPlanPageUrl(this.props.groupId, 'new')}
        />
        { !this.state.payPerAssetEnabled && <Description>
            Pay per asset is not enabled for this account. Please contact support@quintype.com to enable this feature.
        </Description>}
        {_.map(_.get(this.props.subscriptionGroup, ['subscription_plans']), (plan, index) => (
          <PayPerAssetGroupPlanItem
            plan={plan}
            groupId={this.props.groupId}
            expand={this.state.expandPlanIndex === index}
            onExpandToggleClick={() => this.setState({ expandPlanIndex: ((this.state.expandPlanIndex === index) ? -1 : index) })}
          />
        ))}
        {this.state.showNotification.isNotificationOpen &&
          <StatusMessage statusMessage={this.state.showNotification.statusMessage}
            status={this.state.showNotification.status}
            onCancel={() => this.setState({ showNotification: { isNotificationOpen: false, status: '', statusMessage: '' } })} />}
      </Layout>;
    return (group);
  }
}

function mapStateToProps (state) {
  return {
    subscriptionGroup: state.subscriptionGroup || [],
    errors: state.errors || []
  };
}

function mapDispatchToProps (dispatch) {
  return {
    setSubscriptionGroup: (subscriptionGroup) => dispatch({ type: SET_SUBSCRIPTION_GROUP, subscriptionGroup: subscriptionGroup }),
    setErrors: (errors) => dispatch({ type: SET_ERRORS, errors: errors })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PayPerAssetGroups);
