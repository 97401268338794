import React from 'react';
import PropTypes from 'prop-types';
import './NumberField.scss';

const NumberField = ({
  id, placeholder, value, onChange, name, disabled, defaultValue, className, showCopiedMessage, copyLogo, dataTest, inputType, error, min, max
}) => (
  <div className='text-field'>
    <input
      type={'number'}
      id={id}
      className={`input ${className} `}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      name={name}
      disabled={disabled}
      onKeyDown={event => (event.which === 13 ? event.preventDefault() : null)}
      defaultValue={defaultValue}
      data-test={dataTest}
      min={min || 0}
      max={max}
    />
    {error && <p className='error-msg'>{error}</p>}
  </div>
);

NumberField.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  dataTest: PropTypes.string,
  inputType: PropTypes.string,
  error: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number
};

export default NumberField;
