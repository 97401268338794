import React from 'react';
import PropTypes from 'prop-types';
import './TextField.scss';

const copyContent = (e, showCopiedMessage) => {
  e.preventDefault();
  const buttonClick = e.target;
  buttonClick.closest('.text-field').querySelector('input').select();
  document.execCommand('copy', true);
  showCopiedMessage();
};

const TextField = ({
  id,
  placeholder,
  value,
  onChange,
  name,
  disabled,
  defaultValue,
  className,
  copy,
  showCopiedMessage,
  copyLogo,
  dataTest,
  inputType,
  error
}) => (
  <div className="text-field">
    <input
      type={inputType || 'text'}
      id={id}
      className={`input ${className || ''} `}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      name={name}
      disabled={disabled}
      onKeyDown={(event) =>
        event.which === 13 ? event.preventDefault() : null
      }
      defaultValue={defaultValue}
      data-test={dataTest}
    />
    {copy && (
      <button
        className="copy-content"
        onClick={(e) => copyContent(e, showCopiedMessage)}
      >
        {copyLogo}
      </button>
    )}
    {error && <p className="error-msg">{error}</p>}
  </div>
);

TextField.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  dataTest: PropTypes.string,
  inputType: PropTypes.string,
  error: PropTypes.string
};

export default TextField;
