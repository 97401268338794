import React from 'react';
import _ from 'lodash-es';
import { getAccounts, createOrUpdateAccount } from '../api';
import { Card, Button, Table, ButtonGroup, CardHeader, SelectFieldWithLabel, TextFieldWithLabel } from '../../javascript/components/library-components';
import TableRowWithActionDropdown from '../components/table-row-with-action-dropdown/table-row-with-action-dropdown';
import validate from 'validate.js';

// import '../../stylesheets/pages/admin.scss';

const ALL_PLATFORM_TYPES = ['quintype', 'wordpress', 'others'].map((k) => ({ label: k, value: k }));
const ALL_ACCOUNT_TYPES = ['sandbox', 'live'].map((k) => ({ label: k, value: k }));

class Admin extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      errors: {},
      showAccountDetails: false,
      accounts: []
    };
    this.onEdit = this.onEdit.bind(this);
    this.onSave = this.onSave.bind(this);
    this.addNewAccount = this.addNewAccount.bind(this);
  }

  componentDidMount () {
    this.loadAccounts();
  }

  loadAccounts () {
    getAccounts().then((accounts) => { this.setState({ accounts }); });
  }

  onSave () {
    const constraints = {
      name: { presence: { allowEmpty: false, message: 'Required' } },
      platform_type: { presence: { allowEmpty: false, message: 'Required' } },
      account_type: { presence: { allowEmpty: false, message: 'Required' } }
    };

    const errors = validate(this.state.selectedAccount, constraints);

    if (errors) {
      this.setState({ errors });
      return;
    }

    createOrUpdateAccount(this.state.selectedAccount).then((response) => {
      window.location.reload();
    });
  }

  onEdit (accountId) {
    const selectedAccount = _.find(this.state.accounts, { id: accountId }) || {};
    this.setState({
      showAccountDetails: true,
      selectedAccount: selectedAccount
    });
  }

  addNewAccount () {
    this.onEdit('new');
  }

  updateField (field, value) {
    this.setState({
      selectedAccount: Object.assign({}, this.state.selectedAccount, { [field]: value })
    });
  }

  showAccount (account) {
    return (
      <div className='assets-container'>
        <div className='form-row'>
          <TextFieldWithLabel
            label='Name'
            value={_.get(account, ['name'], '')}
            onChange={(e) => this.updateField('name', e.target.value)}
            placeholder='Name'
            dataTest='name'
            errors={_.get(this.state.errors, ['name', '0'])}
          />
          <TextFieldWithLabel
            label='Website URL'
            value={_.get(account, ['website_url'], '')}
            onChange={(e) => this.updateField('website_url', e.target.value)}
            placeholder='https://www.example.com'
            dataTest='website_url'
            errors={_.get(this.state.errors, ['website_url', '0'])}
          />
        </div>
        <div className='form-row'>
          <SelectFieldWithLabel label='Platform' value={_.get(account, ['platform_type'], '')} dataTest='platform_type' options={ALL_PLATFORM_TYPES} onChange={(e) => this.updateField('platform_type', e.value)} id='platform_type' clearable={false} errors={_.get(this.state.errors, ['platform_type', '0'])} />

          <SelectFieldWithLabel label='type' value={_.get(account, ['account_type'], '')} dataTest='account_type' options={ALL_ACCOUNT_TYPES} onChange={(e) => this.updateField('account_type', e.value)} id='account_type' clearable={false} errors={_.get(this.state.errors, ['account_type', '0'])} />
        </div>
        <ButtonGroup>
          <Button primary onClick={(e) => this.onSave(e)}>Save</Button>
        </ButtonGroup>
      </div>
    );
  }

  render () {
    return (
      <div>
        <section className='admin-container'>
          <Card>
            {<CardHeader
              title='Accounts'
              buttonText='Add'
              buttonOnClick={() => this.addNewAccount()}
              isButton
            />}

            { this.state.showAccountDetails && this.showAccount(this.state.selectedAccount) }

            <Table header={['Name', 'Website Url', 'Account Type', 'Created By', 'Created At']} className='table-default--5cols'>
              { _.map(this.state.accounts, (account) => {
                const actionsForAccount = [{ action: 'Edit', actionHandler: this.onEdit, id: account.id }];
                return <TableRowWithActionDropdown id={account.id} dropdownActions={actionsForAccount} key={account.id} >
                  <div className='table-default__cell'>{account.name}</div>
                  <div className='table-default__cell'>{account.website_url}</div>
                  <div className='table-default__cell'>{account.account_type}</div>
                  <div className='table-default__cell'>{account.created_by_user_name}</div>
                  <div className='table-default__cell'>{account.created_at}</div>
                </TableRowWithActionDropdown>;
              }) }
            </Table>
          </Card>
        </section>
      </div>);
  }
}

export default Admin;
