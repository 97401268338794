import React from 'react';
import Checkbox from '../CheckBox/CheckBox';
import { Trash } from '@quintype/em/icons/trash';
import PropTypes from 'prop-types';
import './table_with_checkbox_and_delete.scss';

const TableWithCheckboxAndDelete = ({ children, header, className, onCheckboxClick, onDeleteMembers, showDeleteButton, checked, checkboxDataTest, deleteDataTest }) => {
  return (
    <div className={`table-default ${className}`}>
      {header &&
        <div className='table-default__row table-default--header'>
          <div className='table-default__cell table-default__checkbox'>
            <Checkbox
              onChange={onCheckboxClick}
              checked={checked}
              dataTest={checkboxDataTest}
            />
          </div>
          {header.map((column, index) => <div className='table-default__cell table-default--headings' key={index}> {column}</div>)}
          {<div onClick={onDeleteMembers} style={{ cursor: 'pointer', visibility: showDeleteButton ? 'visible' : 'hidden' }} data-test-id={deleteDataTest}><Trash /></div>}
        </div>}
      {children}
    </div>
  );
};

TableWithCheckboxAndDelete.propTypes = {
  header: PropTypes.array,
  children: PropTypes.node
};

export default TableWithCheckboxAndDelete;
