import React from 'react';
import _ from 'lodash-es';
import validate from 'validate.js';
import { Button, TitleBar, Card, ButtonGroup, TextFieldWithLabel, Layout, Description, CheckBox } from '../components/library-components';
import { createWebhook, getWebhook, getEvents } from '../api';

function chunkArray (myArray, chunkSize) {
  const results = [];
  var cloneArray = myArray.slice(0);

  while (cloneArray.length) {
    results.push(cloneArray.splice(0, chunkSize));
  }

  return results;
}

class CreateWebhook extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      webhook: {
        expiry_reminder_due_days: 1
      },
      errors: null,
      events: [],
      showExpiryReminder: false
    };
  }

  componentDidMount () {
    getEvents()
      .then((response) => {
        const events = response.events;
        const expiryReminderEventId = events.find(
          (event) => event.action === 'expiry_reminder'
        ).id;
        this.setState({ events, expiryReminderEventId }, () => {
          if (this.props.webhookId !== 'new') {
            getWebhook(this.props.webhookId)
              .then(response => {
                let webhook = response.webhook;
                if (!webhook.expiry_reminder_due_days) {
                  webhook = Object.assign({}, webhook, { expiry_reminder_due_days: 1 });
                }
                this.setState({
                  webhook: webhook,
                  showExpiryReminder: webhook.event_ids.includes(this.state.expiryReminderEventId)
                });
              }, errors => { this.setState({ errors: errors }); }
              );
          }
        });
      });
  }

  updateField (field, value) {
    this.setState({ webhook: Object.assign({}, this.state.webhook, { [field]: value }) });
  }

  updateWebhookEvents (eventId) {
    let webhookEventIds;

    if (this.state.webhook.event_ids) {
      if (this.state.webhook.event_ids.includes(eventId)) {
        webhookEventIds = _.difference(this.state.webhook.event_ids, [eventId]);
      } else {
        webhookEventIds = this.state.webhook.event_ids.concat([eventId]);
      }
    } else {
      webhookEventIds = [eventId];
    }

    this.setState({ showExpiryReminder: webhookEventIds.includes(this.state.expiryReminderEventId) });
    this.updateField('event_ids', webhookEventIds);
  }

  createOrUpdateWebhook () {
    const constraints = {
      url: { presence: true, url: true },
      expiry_reminder_due_days: {
        presence: { message: 'cannot be blank' },
        numericality: {
          onlyInteger: true,
          greaterThan: 0,
          lessThanOrEqualTo: 30,
          message: 'should be between 1 and 30'
        }
      }
    };

    const validatedData = validate(this.state.webhook, constraints);
    if (validatedData) {
      this.setState({ errors: validatedData });
    } else {
      let webhook = this.state.webhook;
      if (!webhook.event_ids.includes(this.state.expiryReminderEventId)) {
        webhook = Object.assign({}, webhook, { expiry_reminder_due_days: null });
      }
      createWebhook(webhook)
        .then((response) => (window.location = '/webhooks'),
          (errors) => this.setState({ errors }));
    }
  }

  getExpiryReminderFieldError () {
    let errorMessage = _.get(this.state.errors, ['expiry_reminder_due_days', '0']);
    if (errorMessage) {
      errorMessage = errorMessage.replace('Expiry reminder due days', 'Number of days');
      return errorMessage;
    }
  }

  render () {
    return (
      <Layout>
        <TitleBar title='Add/Edit Webhook'
          breadcrumbTitle='Outgoing Webhook List'
          breadcrumbPath='/webhooks' />
        <Card title='Outgoing Webhooks'>
          <Description description='Webhooks allow external systems to be notified when certain events happen. When the specified events happen, we’ll send a POST request to the URL you provide.  You will receive an email in case the POST request returns a failure response.' />
          <TextFieldWithLabel label='Webhook URL'
            value={_.get(this.state.webhook, 'url', '')}
            onChange={(e) => this.updateField('url', e.target.value)}
            errors={_.get(this.state.errors, ['url', '0'])}
            dataTest='webhook-url'
          />

          <div className='form-row'>
            <TextFieldWithLabel label='Secret'
              value={_.get(this.state.webhook, 'secret', '')}
              onChange={(e) => this.updateField('secret', e.target.value)}
              errors={_.get(this.state.errors, ['secret', '0'])}
              dataTest='secret' />
          </div>
          <h4 className='card-heading'>Status</h4>
          <div className='form-row'>
            <CheckBox label='Active'
              checked={_.get(this.state.webhook, 'enabled', false)}
              value={_.get(this.state.webhook, 'enabled', false)}
              onChange={(e) => this.updateField('enabled', !!e.target.checked)}
              errors={_.get(this.state.errors, ['enabled', '0'])}
              dataTest='status' />
          </div>

          <h4 className='card-heading'>Select Events</h4>
          <div className='webhook-events'>
            { chunkArray(this.state.events, 3).map((events) =>
              <div className='form-row-events'>
                { events && events.map((event) => <CheckBox label={_.startCase(`${event.entity} ${event.action}`)}
                  checked={this.state.webhook && this.state.webhook.event_ids && this.state.webhook.event_ids.includes(event.id)}
                  onChange={(e) => this.updateWebhookEvents(event.id)}
                  dataTest={`${event.entity}-${event.action}`} />) }
              </div>
            )
            }
            {this.state.showExpiryReminder && <div>
              <span className='label expiry_reminder'>Number of Days Before Subscription Expiry </span>
              <input type='number' id='expiry_reminder_due_days' name='expiry_reminder_due_days'
                min='1' max='30' onKeyDown={event => (event.which === 13 ? event.preventDefault() : null)}
                value={this.state.webhook.expiry_reminder_due_days}
                onChange={(e) => this.updateField('expiry_reminder_due_days', e.target.value)}
                data-test='expiry_reminder_due_days'
              />
              <p className='error-msg'>{this.getExpiryReminderFieldError()}</p>
            </div>}
          </div>
        </Card>
        <ButtonGroup>
          <Button onClick={() => (window.location = '/webhooks')} >Cancel</Button>
          <Button primary onClick={() => this.createOrUpdateWebhook()}>Save</Button>
        </ButtonGroup>
      </Layout>
    );
  }
}

export default CreateWebhook;
