import React from 'react';
import validate from 'validate.js';
import { get } from 'lodash-es';

import { Card, TextFieldWithLabel, ButtonGroup, Button, StatusMessage } from '../components/library-components';

import { addMemberToSubscription } from '../api';

export default class AddOrgSubscriptionMember extends React.Component {
  constructor () {
    super();
    this.state = {
      name: '',
      email: '',
      serverError: false
    };
  }

  updateField (field, value) {
    this.setState({ [field]: value });
  }

  addMember () {
    const constraints = {
      name: { presence: { allowEmpty: false } },
      email: { presence: { allowEmpty: false }, email: true }
    };
    const errors = validate(this.state, constraints);
    if (errors) {
      this.setState({ errors });
      return;
    }

    const member = {
      name: this.state.name,
      provider: 'email',
      identity: this.state.email
    };
    addMemberToSubscription(this.props.primaryUser, this.props.subscriptionId, member)
      .then(() => this.setState({ name: '', email: '' }, () => this.props.onAddingMember()))
      .catch((error) => this.setState({ name: '', email: '', serverError: true, serverErrorMsg: error.message }));
  }

  render () {
    return (
      <Card title='Add new member'>
        {this.state.serverError && <StatusMessage
          statusMessage={this.state.serverErrorMsg}
          status='error'
          onCancel={() => { this.setState({ serverError: false }); }}
        />}
        <div className='form-row'>
          <TextFieldWithLabel
            label='Name'
            value={this.state.name}
            errors={get(this.state.errors, ['name', 0])}
            onChange={e => this.updateField('name', e.target.value)}
            dataTest='name'
          />
          <TextFieldWithLabel
            label='Email'
            value={this.state.email}
            errors={get(this.state.errors, ['email', 0])}
            onChange={e => this.updateField('email', e.target.value)}
            dataTest='email'
          />
        </div>
        <ButtonGroup>
          <Button
            primary
            onClick={() => this.addMember()}
            dataTest='save-member-btn'
          >
            Save
          </Button>
        </ButtonGroup>
      </Card>
    );
  }
}
