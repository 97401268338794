import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import './SelectField.scss';
import UpArrow from '../Icons/UpArrow';
import DownArrow from '../Icons/DownArrow';

const SelectField = ({
  value, onChange, options, clearable, className, placeholder, searchPromptText,
  displayArrow = true, searchable, loadOptions,
  noResultsText, async, disabled, cache, valueKey
}) => {
  const arrowRenderer = ({ isOpen }) =>
    (displayArrow && (isOpen ? <UpArrow width='16' height='16' color='#818A98' />
      : <DownArrow width='16' height='16' color='#818A98' />)
    );

  arrowRenderer.propTypes = {
    isOpen: PropTypes.bool
  };

  return (
    async
      ? <Select.Async
        className={className}
        placeholder={placeholder}
        searchPromptText={searchPromptText}
        value={value}
        onChange={onChange}
        clearable={clearable}
        arrowRenderer={arrowRenderer}
        searchable={searchable}
        loadOptions={loadOptions}
        noResultsText={noResultsText}
        disabled={disabled}
        cache={cache}
        valueKey={valueKey}
      />
      : <Select
        className={className}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        options={options}
        clearable={clearable}
        arrowRenderer={arrowRenderer}
        searchable={searchable}
        noResultsText={noResultsText}
        disabled={disabled}
        valueKey={valueKey}
      />
  );
};

SelectField.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array,
  clearable: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  searchPromptText: PropTypes.string,
  displayArrow: PropTypes.bool,
  searchable: PropTypes.bool,
  loadOptions: PropTypes.func,
  noResultsText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  async: PropTypes.bool,
  disabled: PropTypes.bool,
  cache: PropTypes.any,
  valueKey: PropTypes.string
};

export default SelectField;
