import React from 'react';
import TextFieldWithLabel from '../../components/library-components/TextFieldWithLabel/TextFieldWithLabel';
import currencyUtils, { ALL_CURRENCIES } from '../../helpers/currencyUtils';
import Select from 'react-select';
import { Link } from '../../components/library-components';
import { getDateFromISO } from '../../helpers/dateUtils';
import { DATE } from '../../constants';

class Invoice extends React.Component {
  invoiceDownloadUrl (invoice) {
    return `/api/v1/subscribers/${this.props.provider}/${this.props.identity}/subscriptions/${this.props.subscription.id}/invoices/${invoice.id}/download`;
  }

  render () {
    return <div>{this.props.invoices.map((invoice, index) =>
      <div key={index}>
        <div className='form-row'>
          <TextFieldWithLabel disabled label='Invoice number' value={invoice.sequenced_invoice_number} placeholder='- N/A -' dataTest='invoice-number' />
          <TextFieldWithLabel disabled label='Payment token' value={invoice.external_payment_id} placeholder='- N/A -' dataTest='invoice-payment-token' />
        </div>
        <div className='form-row'>
          {invoice.downloadable && <Link data-test-id='test-download-invoice-link' dataTest='download-invoice-link'
            url={this.invoiceDownloadUrl(invoice)}>Download</Link>}
        </div>
        <div className='form-row'>
          <TextFieldWithLabel disabled label='Created at' value={getDateFromISO(invoice.created_at, DATE.HR_MIN_FMT)} dataTest='invoice-created-at' />
          <div className='text-and-select'>
            <TextFieldWithLabel disabled label='Amount paid' value={currencyUtils.convertToCurrencyUnits(invoice.amount_cents)} dataTest='amount-paid' />
            <Select
              className='select-currency'
              dataTest='amount-paid-currency'
              data-test-id='currency-flag'
              value={invoice.amount_currency || ''}
              options={ALL_CURRENCIES}
              clearable={false}
              searchable={false}
              disabled
              arrowRenderer={currencyUtils.arrowRenderer} />
          </div>
        </div>
      </div>
    )}</div>;
  }
}

export default Invoice;
