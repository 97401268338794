import React from 'react';
import validate from 'validate.js';
import { get } from 'lodash-es';
import classnames from 'classnames';
import {
  Layout,
  TitleBar,
  Card,
  TextFieldWithLabel,
  TagsInputWithLabel,
  ButtonGroup,
  Button,
  Alert,
  Table
} from '../components/library-components';
import AddOrganizationSubscription from './add_organization_subscription';
import '../../stylesheets/pages/create_update_organization.scss';

import { getOrganization, createOrganization, updateOrganization, deleteOrganization, getSubscriberSubscriptions, getSubscriptionMembers } from '../api';

const OrganizationFields = ['name', 'primary_email', 'domains', 'address', 'phone_number'];

class CreateUpdateOrganization extends React.Component {
  constructor () {
    super();
    this.state = {
      name: '',
      primary_email: '',
      domains: [],
      address: '',
      phone_number: '',
      errors: {},
      isSaveDisabled: true,
      isAlertOpen: false,
      showNotification: false,
      orgSubscriptions: []
    };
  }

  componentDidMount () {
    if (this.props.organizationId !== 'new') {
      getOrganization(this.props.organizationId)
        .then(({ organization }) => {
          this.setState({ name: organization.name, primary_email: organization.primary_email, domains: organization.domains, address: organization.address, phone_number: organization.phone_number }, () => this.getOrganizationSubscriptions());
        });
    }
  }

  updateField (field, value) {
    if (field === 'domains' && !this.validateOrganizationDomain(value)) {
      const errors = Object.assign({}, this.state.errors, { domains: 'Please enter a unique and valid domain url' });
      this.setState({ errors: errors });
      return;
    }
    this.setState({ [field]: value, isSaveDisabled: false });
  }

  validateOrganizationDomain (domains) {
    const domainRegex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/; // source: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch08s15.html
    return domains.every(domain => domainRegex.test(domain));
  }

  validateOrganization (organization) {
    const constraints = {
      name: { presence: { allowEmpty: false } },
      primary_email: { presence: { allowEmpty: false }, email: true }
    };
    return validate(organization, constraints);
  }

  handlePageRedirect (fn, ...params) {
    return fn(...params)
      .then(() => { window.location = '/organizations'; })
      .catch(errors => this.setState({ errors }));
  }

  createOrUpdateOrganization () {
    const organization = {
      name: this.state.name,
      primary_email: this.state.primary_email,
      domains: this.state.domains,
      address: this.state.address,
      phone_number: this.state.phone_number
    };
    const errors = this.validateOrganization(organization);
    if (errors) {
      this.setState({ errors });
      return;
    }
    this.props.organizationId === 'new' ? this.handlePageRedirect(createOrganization, { organization }) : this.handlePageRedirect(updateOrganization, this.props.organizationId, { organization });
  }

  deleteOrganization () {
    this.handlePageRedirect(deleteOrganization, this.props.organizationId);
  }

  getOrganizationSubscriptions () {
    this.state.primary_email && getSubscriberSubscriptions('email', this.state.primary_email)
      .then(subscriptions => {
        const orgSubscriptions = subscriptions.filter(subscription => subscription.subscription_type === 'group_access');
        return this.addMembersCount(orgSubscriptions);
      })
      .then(orgSubscriptions => this.setState({ orgSubscriptions }));
  }

  addMembersCount (subscriptions) {
    const arr = subscriptions.map(async x => {
      const count = await this.getSubscriptionMembersCount(x);
      x.membersCount = count;
      return x;
    });
    return Promise.all(arr);
  }

  getSubscriptionMembersCount (subscription) {
    const primaryUser = { provider: 'email', identity: this.state.primary_email };
    if (subscription.access_type === 'ip_based') { return 'Unlimited'; }
    return getSubscriptionMembers(primaryUser, subscription.id)
      .then(({ members }) => members.length);
  }

  toggleAlert () {
    this.setState({ isAlertOpen: !this.state.isAlertOpen });
  }

  getSubscriptionAccessTypeLabel (accessType) {
    switch (accessType) {
      case 'user_limit':
        return 'Domain';
      case 'ip_based':
        return 'IP Address';
      default: return '';
    }
  }

  renderOrgSubscriptionDetails (subscription) {
    const statusClass = classnames('table-default__cell truncate-word organization-page-subscription__status', `table-default__cell truncate-word organization-page-subscription__status--${subscription['status']}`);

    return (
      <a href={`/organizations/${this.props.organizationId}/subscriptions/${subscription.id}`} className='table-default__row' data-test-id={`subscription-${subscription.id}`}>
        <div className='table-default__cell truncate-word' data-test-id='plan_name'>{subscription.plan_name}</div>
        <div className={statusClass} data-test-id='plan_status'>{subscription['status']}</div>
        <div className='table-default__cell truncate-word' data-test-id='plan_access_type'>{this.getSubscriptionAccessTypeLabel(subscription.access_type)}</div>
        <div className='table-default__cell truncate-word' data-test-id='plan_user_limit'>{subscription.access_type === 'user_limit' ? `${subscription.membersCount} / ${subscription.user_limit}` : 'Unlimited'}</div>
      </a>
    );
  }

  render () {
    return (
      <Layout>
        <TitleBar
          title={this.props.organizationId === 'new' ? 'Add new Organization' : 'Edit Organization'}
          breadcrumbTitle='Organizations'
          breadcrumbPath='/organizations'
        />
        <Card title='Organization overview'>
          {OrganizationFields.map((field, id) => (
            field !== 'domains'
              ? <TextFieldWithLabel
                key={id}
                label={field.split('_').join(' ')}
                placeholder={`Organization ${field.split('_').join(' ')}`}
                value={this.state[field] || ''}
                errors={get(this.state.errors, [field, '0'])}
                onChange={e => this.updateField(field, e.target.value)}
                dataTest={field}
              />
              : <>
                <TagsInputWithLabel
                  key={id}
                  label={'Organization Domains'}
                  dataTest={field}
                  value={this.state[field] || []}
                  onChange={(value) => this.updateField(field, value)}
                  inputProps= {{ placeholder: 'Enter domains to be whitelisted, like example.com' }}
                  onlyUnique
                />
                <p className='error-msg'>{get(this.state.errors, 'domains')}</p>
              </>
          ))}
          <ButtonGroup
            columns={this.props.organizationId !== 'new' ? 'two' : ''}
          >
            {this.props.organizationId !== 'new' && (
              <Button
                dataTest='delete_organization'
                onClick={() => this.toggleAlert()}
                destructive
              >
                Delete
              </Button>
            )}
            <Button
              dataTest='save_organization'
              disabled={this.state.isSaveDisabled}
              onClick={() => this.createOrUpdateOrganization()}
              primary
            >
              Save
            </Button>
          </ButtonGroup>
        </Card>
        <Alert
          title='Delete Organization'
          open={this.state.isAlertOpen}
          destructive
          onCancel={() => this.toggleAlert()}
          onConfirm={() => this.deleteOrganization()}
        >
          Deleted data cannot be recovered. Do you still want to continue?
        </Alert>
        {this.props.organizationId !== 'new' &&
          <>
            <AddOrganizationSubscription
              allowAddingSubscription={this.state.isSaveDisabled}
              orgPrimaryEmail={this.state.primary_email}
              getOrganizationSubscriptions={() => this.getOrganizationSubscriptions()}
              whiteListedDomains={this.state.domains}
            />
            {this.state.orgSubscriptions.length > 0 && (
              <Card title='Subscription List'>
                <Table
                  header={[
                    'Plan',
                    'Status',
                    'Access Type',
                    'Users / User limit'
                  ]}
                >
                  {this.state.orgSubscriptions.map(subscription => this.renderOrgSubscriptionDetails(subscription))}
                </Table>
              </Card>
            )}
          </>}
      </Layout>
    );
  }
}

export default CreateUpdateOrganization;
