import React from 'react';
import { Button, Card, Description, Table } from '../components/library-components';
import UpArrow from '../components/library-components/Icons/UpArrow';
import DownArrow from '../components/library-components/Icons/DownArrow';
import currencyUtils from '../helpers/currencyUtils';
import { getPPAPlanPageUrl } from '../helpers/utils';

const getDescriptionText = (plan) => {
  const currencies = Object.keys(plan.prices);
  const currencyTexts = currencies.map(currency => `${currencyUtils.convertToCurrencyUnits(plan.prices[currency]['amount_cents'])} ${currency}`);
  return currencyTexts.join(', ');
};

const AssetItem = ({ asset }) => {
  if (!asset) {
    return null;
  }
  return <div className='table-default__row'>
    <div className='table-default__cell'>{asset.type}</div>
    <div className='table-default__cell'>{asset.public ? 'Public' : 'Private'}</div>
    <div className='table-default__cell'>{asset.title}</div>
    <div className='table-default__cell'>{JSON.stringify(asset.metadata || {})}</div>
  </div>;
};

const PayPerAssetGroupPlanItem = ({ groupId, plan, expand, onExpandToggleClick }) => {
  if (!plan) {
    return null;
  }
  const editUrl = getPPAPlanPageUrl(groupId, plan.id);
  return <Card>
    <div className='card-header card-header--two-column' onClick={onExpandToggleClick}>
      <div>
        <h3 className='accordion-item-header__title'>{plan.title}</h3>
        <Description description={getDescriptionText(plan)} />
      </div>
      <div className='accordion-item-header__right'>
        <Button url={editUrl}>Edit</Button>
        <span className='accordion-item--collapse-icon'>{expand ? <UpArrow /> : <DownArrow />}</span>
      </div>
    </div>
    {
      expand && plan && plan.assets &&
      <Table header={['Asset Type', 'Visibility', 'Name', 'Conditions']} className='table-default--4cols'>
        {plan.assets.map((asset, index) => <AssetItem key={index} asset={asset} />)}
      </Table>
    }
  </Card>;
};

export default PayPerAssetGroupPlanItem;
