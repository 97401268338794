import React, { useState } from 'react';
import currencyUtils from '../helpers/currencyUtils';
import { Link } from './library-components';
import _ from 'lodash-es';
import './sub-group-plan-price-cell.scss';

const CurrencyItemWithPlanLink = ({ plan, currency, groupId, addLink, showDivider = false }) => {
  const { prices } = plan;
  const priceText = `${currency} ${currencyUtils.convertToCurrencyUnits(prices[currency]['amount_cents'])}${showDivider ? ',' : ''}`;
  if (addLink) {
    return <a href={`/subscription_groups/${groupId}/subscription_plans/${plan.id}`} className='sub_group_plan_price_cell__currency_item'>
      {priceText}
    </a>;
  }
  return <div className='sub_group_plan_price_cell__currency_item'>
    {priceText}
  </div>;
};

const SubGroupPlanPriceCell = ({ plan, groupId, addPlanLink = true }) => {
  const { prices } = plan;
  const [expanded, setExpanded] = useState(false);

  if (!prices || (typeof prices !== 'object' || _.isEmpty(prices))) {
    return <></>;
  }
  const currencies = Object.keys(prices);

  if (currencies.length === 1) {
    return <CurrencyItemWithPlanLink plan={plan} currency={currencies[0]} groupId={groupId} addLink={addPlanLink} />;
  }

  if (!expanded) {
    return <div className='sub_group_plan_price_cell__multiple_currencies'>
      <CurrencyItemWithPlanLink plan={plan} currency={currencies[0]} groupId={groupId} addLink={addPlanLink} />
      <div className='sub_group_plan_price_cell__currency_item'>
        <Link onClick={() => setExpanded(true)} > +{currencies.length - 1} more</Link>
      </div>
    </div>;
  }

  return <div className='sub_group_plan_price_cell__multiple_currencies'>
    {currencies.map((currency, index) =>
      <CurrencyItemWithPlanLink
        showDivider={index < currencies.length - 1}
        plan={plan}
        currency={currency}
        groupId={groupId}
        addLink={addPlanLink}
        key={currency}
      />)}
    <Link className="sub_group_plan_price_cell__show-less-button" onClick={() => setExpanded(false)} >Show less</Link>
  </div>;
};

export default SubGroupPlanPriceCell;
