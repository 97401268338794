import { isEmpty } from 'lodash-es';
import React, { useState } from 'react';
import { TagsInputWithLabel } from '../library-components';
import { domainRegex } from '../../constants';

function AllowedDomainsInput ({ allowedDomains, onDomainsChange, disabled }) {
  const [error, setError] = useState(null);

  const validateOrganizationDomain = (domains) => {
    return domains.every(domain => domainRegex.test(domain));
  };

  const handleAllowedDomainsChange = (allowedDomains) => {
    if (validateOrganizationDomain(allowedDomains) || isEmpty(allowedDomains)) {
      onDomainsChange(allowedDomains);
      setError(null);
    } else {
      setError('Please enter a valid domain address');
    }
  };

  return <div className="allowed-domains-input">
    <TagsInputWithLabel
      label={'Whitelisted Domains'}
      dataTest={'subscription-allowed-domains'}
      value={allowedDomains}
      onChange={handleAllowedDomainsChange}
      inputProps={{ placeholder: 'Enter domains to be whitelisted, like example.com', disabled: disabled }}
    />
    <p className="error-msg">
      {error}
    </p>
  </div>;
}

export default AllowedDomainsInput;
