import React from 'react';
import PropTypes from 'prop-types';

const USA = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g id='Symbols' fill='none' fillRule='evenodd'>
        <g id='Icons/Flags/USA' fillRule='nonzero'>
          <g id='united-states' transform='translate(0 4)'>
            <path d='M23.5402105,15.9869048 L0.413005848,15.9869048 C0.184935673,15.9869048 0,15.7986667 0,15.5665238 L0,0.43352381 C0,0.201380952 0.184935673,0.0131428571 0.413005848,0.0131428571 L23.5402573,0.0131428571 C23.7683275,0.0131428571 23.9532632,0.201380952 23.9532632,0.43352381 L23.9532632,15.5665238 C23.9532164,15.7987143 23.7682807,15.9869048 23.5402105,15.9869048 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M23.9532632,1.24195238 L0,1.24195238 L0,0.43352381 C0,0.201380952 0.184935673,0.0131428571 0.413005848,0.0131428571 L23.5402573,0.0131428571 C23.7683275,0.0131428571 23.9532632,0.201380952 23.9532632,0.43352381 L23.9532632,1.24195238 Z'
              id='Shape' fill='#FF4B55' />
            <rect id='Rectangle-path' fill='#FF4B55' y='4.928' width='23.953' height='1.229'
            />
            <rect id='Rectangle-path' fill='#FF4B55' y='2.471' width='23.953' height='1.229'
            />
            <path d='M23.9532164,8.61442857 L0.413005848,8.61442857 C0.184935673,8.61442857 0,8.42619048 0,8.19404762 L0,7.38561905 L23.9532164,7.38561905 L23.9532164,8.61442857 Z'
              id='Shape' fill='#FF4B55' />
            <rect id='Rectangle-path' fill='#FF4B55' y='12.301' width='23.953' height='1.229'
            />
            <path d='M23.5402105,15.9869048 L0.413005848,15.9869048 C0.184935673,15.9869048 0,15.7986667 0,15.5665238 L0,14.7581429 L23.9532164,14.7581429 L23.9532164,15.5665714 C23.9532164,15.7987143 23.7682807,15.9869048 23.5402105,15.9869048 Z'
              id='Shape' fill='#FF4B55' />
            <rect id='Rectangle-path' fill='#FF4B55' y='9.843' width='23.953' height='1.229'
            />
            <path d='M10.7376374,0.0131904762 L0.413005848,0.0131904762 C0.184935673,0.0131904762 0,0.201380952 0,0.43352381 L0,8.194 C0,8.42614286 0.184935673,8.61438095 0.413005848,8.61438095 L10.7376842,8.61438095 C10.9657544,8.61438095 11.1506901,8.42614286 11.1506901,8.194 L11.1506901,0.43352381 C11.1506433,0.201380952 10.9657076,0.0131904762 10.7376374,0.0131904762 Z'
              id='Shape' fill='#41479B' />
            <path d='M1.1597193,0.978 L1.2511345,1.25704762 L1.54011696,1.25928571 C1.57754386,1.25961905 1.59302924,1.30819048 1.56294737,1.33080952 L1.33047953,1.50557143 L1.41768421,1.786 C1.42900585,1.82228571 1.38835088,1.85238095 1.35789474,1.83019048 L1.12280702,1.65919048 L0.887719298,1.8302381 C0.857263158,1.85238095 0.816701754,1.82233333 0.827929825,1.78604762 L0.915134503,1.50561905 L0.682666667,1.33085714 C0.652584795,1.30819048 0.668070175,1.25966667 0.705497076,1.25933333 L0.994479532,1.25709524 L1.08589474,0.978047619 C1.09768421,0.941857143 1.14783626,0.941857143 1.1597193,0.978 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M1.1597193,2.43771429 L1.2511345,2.7167619 L1.54011696,2.719 C1.57754386,2.71933333 1.59302924,2.76790476 1.56294737,2.79052381 L1.33047953,2.96528571 L1.41768421,3.24571429 C1.42900585,3.282 1.38835088,3.31209524 1.35789474,3.28990476 L1.12280702,3.11890476 L0.887719298,3.28995238 C0.857263158,3.31209524 0.816701754,3.28204762 0.827929825,3.2457619 L0.915134503,2.96533333 L0.682666667,2.79057143 C0.652584795,2.76790476 0.668070175,2.71938095 0.705497076,2.71904762 L0.994479532,2.71680952 L1.08589474,2.4377619 C1.09768421,2.40161905 1.14783626,2.40161905 1.1597193,2.43771429 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M1.1597193,3.89742857 L1.2511345,4.17647619 L1.54011696,4.17871429 C1.57754386,4.17904762 1.59302924,4.22761905 1.56294737,4.2502381 L1.33047953,4.425 L1.41768421,4.70542857 C1.42900585,4.74171429 1.38835088,4.77180952 1.35789474,4.74961905 L1.12280702,4.57861905 L0.887719298,4.74966667 C0.857263158,4.77180952 0.816701754,4.7417619 0.827929825,4.70547619 L0.915134503,4.42504762 L0.682666667,4.25028571 C0.652584795,4.22761905 0.668070175,4.17909524 0.705497076,4.1787619 L0.994479532,4.17652381 L1.08589474,3.89747619 C1.09768421,3.86133333 1.14783626,3.86133333 1.1597193,3.89742857 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M1.1597193,5.35719048 L1.2511345,5.6362381 L1.54011696,5.63847619 C1.57754386,5.63880952 1.59302924,5.68738095 1.56294737,5.71 L1.33047953,5.8847619 L1.41768421,6.16519048 C1.42900585,6.20147619 1.38835088,6.23157143 1.35789474,6.20938095 L1.12280702,6.03838095 L0.887719298,6.20942857 C0.857263158,6.23157143 0.816701754,6.20152381 0.827929825,6.1652381 L0.915134503,5.88480952 L0.682666667,5.71004762 C0.652584795,5.68738095 0.668070175,5.63885714 0.705497076,5.63852381 L0.994479532,5.63628571 L1.08589474,5.3572381 C1.09768421,5.32104762 1.14783626,5.32104762 1.1597193,5.35719048 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M1.1597193,6.81690476 L1.2511345,7.09595238 L1.54011696,7.09819048 C1.57754386,7.09852381 1.59302924,7.14709524 1.56294737,7.16971429 L1.33047953,7.34447619 L1.41768421,7.62490476 C1.42900585,7.66119048 1.38835088,7.69128571 1.35789474,7.66909524 L1.12280702,7.49809524 L0.887719298,7.66914286 C0.857263158,7.69128571 0.816701754,7.6612381 0.827929825,7.62495238 L0.915134503,7.34452381 L0.682666667,7.1697619 C0.652584795,7.14709524 0.668070175,7.09857143 0.705497076,7.0982381 L0.994479532,7.096 L1.08589474,6.81695238 C1.09768421,6.78071429 1.14783626,6.78071429 1.1597193,6.81690476 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M2.27284211,1.69366667 L2.36425731,1.97271429 L2.65323977,1.97495238 C2.69066667,1.97528571 2.70615205,2.02385714 2.67607018,2.04647619 L2.44360234,2.2212381 L2.53080702,2.50166667 C2.54212865,2.53795238 2.50147368,2.56804762 2.47101754,2.54585714 L2.23592982,2.37480952 L2.00084211,2.54585714 C1.97038596,2.568 1.92982456,2.53795238 1.94105263,2.50166667 L2.02825731,2.2212381 L1.79578947,2.04647619 C1.7657076,2.02380952 1.78119298,1.97528571 1.81861988,1.97495238 L2.10760234,1.97271429 L2.19901754,1.69366667 C2.21076023,1.65757143 2.26100585,1.65757143 2.27284211,1.69366667 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M2.27284211,3.15342857 L2.36425731,3.43247619 L2.65323977,3.43471429 C2.69066667,3.43504762 2.70615205,3.48361905 2.67607018,3.5062381 L2.44360234,3.681 L2.53080702,3.96142857 C2.54212865,3.99771429 2.50147368,4.02780952 2.47101754,4.00561905 L2.23592982,3.83457143 L2.00084211,4.00561905 C1.97038596,4.0277619 1.92982456,3.9977619 1.94105263,3.96142857 L2.02825731,3.681 L1.79578947,3.5062381 C1.7657076,3.48357143 1.78119298,3.43504762 1.81861988,3.43471429 L2.10760234,3.43247619 L2.19901754,3.15342857 C2.21076023,3.11728571 2.26100585,3.11728571 2.27284211,3.15342857 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M2.27284211,4.61314286 L2.36425731,4.89219048 L2.65323977,4.89442857 C2.69066667,4.8947619 2.70615205,4.94333333 2.67607018,4.96595238 L2.44360234,5.14071429 L2.53080702,5.42114286 C2.54212865,5.45742857 2.50147368,5.48752381 2.47101754,5.46533333 L2.23592982,5.29428571 L2.00084211,5.46533333 C1.97038596,5.48747619 1.92982456,5.45742857 1.94105263,5.42114286 L2.02825731,5.14071429 L1.79578947,4.96595238 C1.7657076,4.94328571 1.78119298,4.8947619 1.81861988,4.89442857 L2.10760234,4.89219048 L2.19901754,4.61314286 C2.21076023,4.577 2.26100585,4.577 2.27284211,4.61314286 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M2.27284211,6.07285714 L2.36425731,6.35190476 L2.65323977,6.35414286 C2.69066667,6.35447619 2.70615205,6.40304762 2.67607018,6.42566667 L2.44360234,6.60042857 L2.53080702,6.88085714 C2.54212865,6.91714286 2.50147368,6.9472381 2.47101754,6.92504762 L2.23592982,6.754 L2.00084211,6.92504762 C1.97038596,6.94719048 1.92982456,6.91714286 1.94105263,6.88085714 L2.02825731,6.60042857 L1.79578947,6.42566667 C1.7657076,6.403 1.78119298,6.35447619 1.81861988,6.35414286 L2.10760234,6.35190476 L2.19901754,6.07285714 C2.21076023,6.0367619 2.26100585,6.0367619 2.27284211,6.07285714 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M3.38596491,0.978 L3.47738012,1.25704762 L3.76636257,1.25928571 C3.80378947,1.25961905 3.81927485,1.30819048 3.78919298,1.33080952 L3.55672515,1.50557143 L3.64392982,1.786 C3.65525146,1.82228571 3.61459649,1.85238095 3.58414035,1.83019048 L3.34905263,1.65914286 L3.11396491,1.83019048 C3.08350877,1.85233333 3.04294737,1.82228571 3.05417544,1.786 L3.14138012,1.50557143 L2.90891228,1.33080952 C2.87883041,1.30814286 2.89431579,1.25961905 2.93174269,1.25928571 L3.22072515,1.25704762 L3.31214035,0.978 C3.32392982,0.941857143 3.37412865,0.941857143 3.38596491,0.978 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M3.38596491,2.43771429 L3.47738012,2.7167619 L3.76636257,2.719 C3.80378947,2.71933333 3.81927485,2.76790476 3.78919298,2.79052381 L3.55672515,2.96528571 L3.64392982,3.24571429 C3.65525146,3.282 3.61459649,3.31209524 3.58414035,3.28990476 L3.34905263,3.11885714 L3.11396491,3.28990476 C3.08350877,3.31204762 3.04294737,3.282 3.05417544,3.24571429 L3.14138012,2.96528571 L2.90891228,2.79052381 C2.87883041,2.76785714 2.89431579,2.71933333 2.93174269,2.719 L3.22072515,2.7167619 L3.31214035,2.43771429 C3.32392982,2.40161905 3.37412865,2.40161905 3.38596491,2.43771429 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M3.38596491,3.89742857 L3.47738012,4.17647619 L3.76636257,4.17871429 C3.80378947,4.17904762 3.81927485,4.22761905 3.78919298,4.2502381 L3.55672515,4.425 L3.64392982,4.70542857 C3.65525146,4.74171429 3.61459649,4.77180952 3.58414035,4.74961905 L3.34905263,4.57857143 L3.11396491,4.74961905 C3.08350877,4.7717619 3.04294737,4.74171429 3.05417544,4.70542857 L3.14138012,4.425 L2.90891228,4.2502381 C2.87883041,4.22757143 2.89431579,4.17904762 2.93174269,4.17871429 L3.22072515,4.17647619 L3.31214035,3.89742857 C3.32392982,3.86133333 3.37412865,3.86133333 3.38596491,3.89742857 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M3.38596491,5.35719048 L3.47738012,5.6362381 L3.76636257,5.63847619 C3.80378947,5.63880952 3.81927485,5.68738095 3.78919298,5.71 L3.55672515,5.8847619 L3.64392982,6.16519048 C3.65525146,6.20147619 3.61459649,6.23157143 3.58414035,6.20938095 L3.34905263,6.03833333 L3.11396491,6.20938095 C3.08350877,6.23152381 3.04294737,6.20147619 3.05417544,6.16519048 L3.14138012,5.8847619 L2.90891228,5.71 C2.87883041,5.68733333 2.89431579,5.63880952 2.93174269,5.63847619 L3.22072515,5.6362381 L3.31214035,5.35719048 C3.32392982,5.32104762 3.37412865,5.32104762 3.38596491,5.35719048 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M3.38596491,6.81690476 L3.47738012,7.09595238 L3.76636257,7.09819048 C3.80378947,7.09852381 3.81927485,7.14709524 3.78919298,7.16971429 L3.55672515,7.34447619 L3.64392982,7.62490476 C3.65525146,7.66119048 3.61459649,7.69128571 3.58414035,7.66909524 L3.34905263,7.49804762 L3.11396491,7.66909524 C3.08350877,7.6912381 3.04294737,7.66119048 3.05417544,7.62490476 L3.14138012,7.34447619 L2.90891228,7.16971429 C2.87883041,7.14704762 2.89431579,7.09852381 2.93174269,7.09819048 L3.22072515,7.09595238 L3.31214035,6.81690476 C3.32392982,6.78071429 3.37412865,6.78071429 3.38596491,6.81690476 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M4.4991345,1.69366667 L4.59054971,1.97271429 L4.87953216,1.97495238 C4.91695906,1.97528571 4.93244444,2.02385714 4.90236257,2.04647619 L4.66989474,2.2212381 L4.75709942,2.50166667 C4.76842105,2.53795238 4.72776608,2.56804762 4.69730994,2.54585714 L4.46222222,2.37480952 L4.2271345,2.54585714 C4.19667836,2.568 4.15611696,2.53795238 4.16734503,2.50166667 L4.25454971,2.2212381 L4.02208187,2.04647619 C3.992,2.02380952 4.00748538,1.97528571 4.04491228,1.97495238 L4.33389474,1.97271429 L4.42530994,1.69366667 C4.43705263,1.65757143 4.48729825,1.65757143 4.4991345,1.69366667 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M4.4991345,3.15342857 L4.59054971,3.43247619 L4.87953216,3.43471429 C4.91695906,3.43504762 4.93244444,3.48361905 4.90236257,3.5062381 L4.66989474,3.681 L4.75709942,3.96142857 C4.76842105,3.99771429 4.72776608,4.02780952 4.69730994,4.00561905 L4.46222222,3.83457143 L4.2271345,4.00561905 C4.19667836,4.0277619 4.15611696,3.9977619 4.16734503,3.96142857 L4.25454971,3.681 L4.02208187,3.5062381 C3.992,3.48357143 4.00748538,3.43504762 4.04491228,3.43471429 L4.33389474,3.43247619 L4.42530994,3.15342857 C4.43705263,3.11728571 4.48729825,3.11728571 4.4991345,3.15342857 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M4.4991345,4.61314286 L4.59054971,4.89219048 L4.87953216,4.89442857 C4.91695906,4.8947619 4.93244444,4.94333333 4.90236257,4.96595238 L4.66989474,5.14071429 L4.75709942,5.42114286 C4.76842105,5.45742857 4.72776608,5.48752381 4.69730994,5.46533333 L4.46222222,5.29428571 L4.2271345,5.46533333 C4.19667836,5.48747619 4.15611696,5.45742857 4.16734503,5.42114286 L4.25454971,5.14071429 L4.02208187,4.96595238 C3.992,4.94328571 4.00748538,4.8947619 4.04491228,4.89442857 L4.33389474,4.89219048 L4.42530994,4.61314286 C4.43705263,4.577 4.48729825,4.577 4.4991345,4.61314286 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M4.4991345,6.07285714 L4.59054971,6.35190476 L4.87953216,6.35414286 C4.91695906,6.35447619 4.93244444,6.40304762 4.90236257,6.42566667 L4.66989474,6.60042857 L4.75709942,6.88085714 C4.76842105,6.91714286 4.72776608,6.9472381 4.69730994,6.92504762 L4.46222222,6.754 L4.2271345,6.92504762 C4.19667836,6.94719048 4.15611696,6.91714286 4.16734503,6.88085714 L4.25454971,6.60042857 L4.02208187,6.42566667 C3.992,6.403 4.00748538,6.35447619 4.04491228,6.35414286 L4.33389474,6.35190476 L4.42530994,6.07285714 C4.43705263,6.0367619 4.48729825,6.0367619 4.4991345,6.07285714 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M5.61225731,0.978 L5.70367251,1.25704762 L5.99265497,1.25928571 C6.03008187,1.25961905 6.04556725,1.30819048 6.01548538,1.33080952 L5.78301754,1.50557143 L5.87022222,1.786 C5.88154386,1.82228571 5.84088889,1.85238095 5.81043275,1.83019048 L5.57529825,1.65914286 L5.34021053,1.83019048 C5.30975439,1.85233333 5.26919298,1.82228571 5.28042105,1.786 L5.36762573,1.50557143 L5.13515789,1.33080952 C5.10507602,1.30814286 5.1205614,1.25961905 5.1579883,1.25928571 L5.44697076,1.25704762 L5.53838596,0.978 C5.55022222,0.941857143 5.60042105,0.941857143 5.61225731,0.978 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M5.61225731,2.43771429 L5.70367251,2.7167619 L5.99265497,2.719 C6.03008187,2.71933333 6.04556725,2.76790476 6.01548538,2.79052381 L5.78301754,2.96528571 L5.87022222,3.24571429 C5.88154386,3.282 5.84088889,3.31209524 5.81043275,3.28990476 L5.57529825,3.11885714 L5.34021053,3.28990476 C5.30975439,3.31204762 5.26919298,3.282 5.28042105,3.24571429 L5.36762573,2.96528571 L5.13515789,2.79052381 C5.10507602,2.76785714 5.1205614,2.71933333 5.1579883,2.719 L5.44697076,2.7167619 L5.53838596,2.43771429 C5.55022222,2.40161905 5.60042105,2.40161905 5.61225731,2.43771429 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M5.61225731,3.89742857 L5.70367251,4.17647619 L5.99265497,4.17871429 C6.03008187,4.17904762 6.04556725,4.22761905 6.01548538,4.2502381 L5.78301754,4.425 L5.87022222,4.70542857 C5.88154386,4.74171429 5.84088889,4.77180952 5.81043275,4.74961905 L5.57529825,4.57857143 L5.34021053,4.74961905 C5.30975439,4.7717619 5.26919298,4.74171429 5.28042105,4.70542857 L5.36762573,4.425 L5.13515789,4.2502381 C5.10507602,4.22757143 5.1205614,4.17904762 5.1579883,4.17871429 L5.44697076,4.17647619 L5.53838596,3.89742857 C5.55022222,3.86133333 5.60042105,3.86133333 5.61225731,3.89742857 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M5.61225731,5.35719048 L5.70367251,5.6362381 L5.99265497,5.63847619 C6.03008187,5.63880952 6.04556725,5.68738095 6.01548538,5.71 L5.78301754,5.8847619 L5.87022222,6.16519048 C5.88154386,6.20147619 5.84088889,6.23157143 5.81043275,6.20938095 L5.57529825,6.03833333 L5.34021053,6.20938095 C5.30975439,6.23152381 5.26919298,6.20147619 5.28042105,6.16519048 L5.36762573,5.8847619 L5.13515789,5.71 C5.10507602,5.68733333 5.1205614,5.63880952 5.1579883,5.63847619 L5.44697076,5.6362381 L5.53838596,5.35719048 C5.55022222,5.32104762 5.60042105,5.32104762 5.61225731,5.35719048 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M5.61225731,6.81690476 L5.70367251,7.09595238 L5.99265497,7.09819048 C6.03008187,7.09852381 6.04556725,7.14709524 6.01548538,7.16971429 L5.78301754,7.34447619 L5.87022222,7.62490476 C5.88154386,7.66119048 5.84088889,7.69128571 5.81043275,7.66909524 L5.57529825,7.49804762 L5.34021053,7.66909524 C5.30975439,7.6912381 5.26919298,7.66119048 5.28042105,7.62490476 L5.36762573,7.34447619 L5.13515789,7.16971429 C5.10507602,7.14704762 5.1205614,7.09852381 5.1579883,7.09819048 L5.44697076,7.09595238 L5.53838596,6.81690476 C5.55022222,6.78071429 5.60042105,6.78071429 5.61225731,6.81690476 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M6.72538012,1.69366667 L6.81679532,1.97271429 L7.10577778,1.97495238 C7.14320468,1.97528571 7.15869006,2.02385714 7.12860819,2.04647619 L6.89614035,2.2212381 L6.98334503,2.50166667 C6.99466667,2.53795238 6.9540117,2.56804762 6.92355556,2.54585714 L6.68846784,2.37480952 L6.45338012,2.54585714 C6.42292398,2.568 6.38236257,2.53795238 6.39359064,2.50166667 L6.48079532,2.2212381 L6.24832749,2.04647619 C6.21824561,2.02380952 6.23373099,1.97528571 6.27115789,1.97495238 L6.56014035,1.97271429 L6.65155556,1.69366667 C6.66339181,1.65757143 6.71354386,1.65757143 6.72538012,1.69366667 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M6.72538012,3.15342857 L6.81679532,3.43247619 L7.10577778,3.43471429 C7.14320468,3.43504762 7.15869006,3.48361905 7.12860819,3.5062381 L6.89614035,3.681 L6.98334503,3.96142857 C6.99466667,3.99771429 6.9540117,4.02780952 6.92355556,4.00561905 L6.68846784,3.83457143 L6.45338012,4.00561905 C6.42292398,4.0277619 6.38236257,3.9977619 6.39359064,3.96142857 L6.48079532,3.681 L6.24832749,3.5062381 C6.21824561,3.48357143 6.23373099,3.43504762 6.27115789,3.43471429 L6.56014035,3.43247619 L6.65155556,3.15342857 C6.66339181,3.11728571 6.71354386,3.11728571 6.72538012,3.15342857 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M6.72538012,4.61314286 L6.81679532,4.89219048 L7.10577778,4.89442857 C7.14320468,4.8947619 7.15869006,4.94333333 7.12860819,4.96595238 L6.89614035,5.14071429 L6.98334503,5.42114286 C6.99466667,5.45742857 6.9540117,5.48752381 6.92355556,5.46533333 L6.68846784,5.29428571 L6.45338012,5.46533333 C6.42292398,5.48747619 6.38236257,5.45742857 6.39359064,5.42114286 L6.48079532,5.14071429 L6.24832749,4.96595238 C6.21824561,4.94328571 6.23373099,4.8947619 6.27115789,4.89442857 L6.56014035,4.89219048 L6.65155556,4.61314286 C6.66339181,4.577 6.71354386,4.577 6.72538012,4.61314286 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M6.72538012,6.07285714 L6.81679532,6.35190476 L7.10577778,6.35414286 C7.14320468,6.35447619 7.15869006,6.40304762 7.12860819,6.42566667 L6.89614035,6.60042857 L6.98334503,6.88085714 C6.99466667,6.91714286 6.9540117,6.9472381 6.92355556,6.92504762 L6.68846784,6.754 L6.45338012,6.92504762 C6.42292398,6.94719048 6.38236257,6.91714286 6.39359064,6.88085714 L6.48079532,6.60042857 L6.24832749,6.42566667 C6.21824561,6.403 6.23373099,6.35447619 6.27115789,6.35414286 L6.56014035,6.35190476 L6.65155556,6.07285714 C6.66339181,6.0367619 6.71354386,6.0367619 6.72538012,6.07285714 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M7.83854971,0.978 L7.92996491,1.25704762 L8.21894737,1.25928571 C8.25637427,1.25961905 8.27185965,1.30819048 8.24177778,1.33080952 L8.00930994,1.50557143 L8.09651462,1.786 C8.10783626,1.82228571 8.06718129,1.85238095 8.03672515,1.83019048 L7.80163743,1.65914286 L7.56654971,1.83019048 C7.53609357,1.85233333 7.49553216,1.82228571 7.50676023,1.786 L7.59396491,1.50557143 L7.36149708,1.33080952 C7.3314152,1.30814286 7.34690058,1.25961905 7.38432749,1.25928571 L7.67330994,1.25704762 L7.76472515,0.978 C7.77646784,0.941857143 7.82671345,0.941857143 7.83854971,0.978 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M7.83854971,2.43771429 L7.92996491,2.7167619 L8.21894737,2.719 C8.25637427,2.71933333 8.27185965,2.76790476 8.24177778,2.79052381 L8.00930994,2.96528571 L8.09651462,3.24571429 C8.10783626,3.282 8.06718129,3.31209524 8.03672515,3.28990476 L7.80163743,3.11885714 L7.56654971,3.28990476 C7.53609357,3.31204762 7.49553216,3.282 7.50676023,3.24571429 L7.59396491,2.96528571 L7.36149708,2.79052381 C7.3314152,2.76785714 7.34690058,2.71933333 7.38432749,2.719 L7.67330994,2.7167619 L7.76472515,2.43771429 C7.77646784,2.40161905 7.82671345,2.40161905 7.83854971,2.43771429 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M7.83854971,3.89742857 L7.92996491,4.17647619 L8.21894737,4.17871429 C8.25637427,4.17904762 8.27185965,4.22761905 8.24177778,4.2502381 L8.00930994,4.425 L8.09651462,4.70542857 C8.10783626,4.74171429 8.06718129,4.77180952 8.03672515,4.74961905 L7.80163743,4.57857143 L7.56654971,4.74961905 C7.53609357,4.7717619 7.49553216,4.74171429 7.50676023,4.70542857 L7.59396491,4.425 L7.36149708,4.2502381 C7.3314152,4.22757143 7.34690058,4.17904762 7.38432749,4.17871429 L7.67330994,4.17647619 L7.76472515,3.89742857 C7.77646784,3.86133333 7.82671345,3.86133333 7.83854971,3.89742857 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M7.83854971,5.35719048 L7.92996491,5.6362381 L8.21894737,5.63847619 C8.25637427,5.63880952 8.27185965,5.68738095 8.24177778,5.71 L8.00930994,5.8847619 L8.09651462,6.16519048 C8.10783626,6.20147619 8.06718129,6.23157143 8.03672515,6.20938095 L7.80163743,6.03833333 L7.56654971,6.20938095 C7.53609357,6.23152381 7.49553216,6.20147619 7.50676023,6.16519048 L7.59396491,5.8847619 L7.36149708,5.71 C7.3314152,5.68733333 7.34690058,5.63880952 7.38432749,5.63847619 L7.67330994,5.6362381 L7.76472515,5.35719048 C7.77646784,5.32104762 7.82671345,5.32104762 7.83854971,5.35719048 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M7.83854971,6.81690476 L7.92996491,7.09595238 L8.21894737,7.09819048 C8.25637427,7.09852381 8.27185965,7.14709524 8.24177778,7.16971429 L8.00930994,7.34447619 L8.09651462,7.62490476 C8.10783626,7.66119048 8.06718129,7.69128571 8.03672515,7.66909524 L7.80163743,7.49804762 L7.56654971,7.66909524 C7.53609357,7.6912381 7.49553216,7.66119048 7.50676023,7.62490476 L7.59396491,7.34447619 L7.36149708,7.16971429 C7.3314152,7.14704762 7.34690058,7.09852381 7.38432749,7.09819048 L7.67330994,7.09595238 L7.76472515,6.81690476 C7.77646784,6.78071429 7.82671345,6.78071429 7.83854971,6.81690476 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M8.95167251,1.69366667 L9.04308772,1.97271429 L9.33207018,1.97495238 C9.36949708,1.97528571 9.38498246,2.02385714 9.35490058,2.04647619 L9.12243275,2.2212381 L9.20963743,2.50166667 C9.22095906,2.53795238 9.18030409,2.56804762 9.14984795,2.54585714 L8.91476023,2.37480952 L8.67967251,2.54585714 C8.64921637,2.568 8.60865497,2.53795238 8.61988304,2.50166667 L8.70708772,2.2212381 L8.47461988,2.04647619 C8.44453801,2.02380952 8.46002339,1.97528571 8.49745029,1.97495238 L8.78643275,1.97271429 L8.87784795,1.69366667 C8.88963743,1.65757143 8.93988304,1.65757143 8.95167251,1.69366667 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M8.95167251,3.15342857 L9.04308772,3.43247619 L9.33207018,3.43471429 C9.36949708,3.43504762 9.38498246,3.48361905 9.35490058,3.5062381 L9.12243275,3.681 L9.20963743,3.96142857 C9.22095906,3.99771429 9.18030409,4.02780952 9.14984795,4.00561905 L8.91476023,3.83457143 L8.67967251,4.00561905 C8.64921637,4.0277619 8.60865497,3.9977619 8.61988304,3.96142857 L8.70708772,3.681 L8.47461988,3.5062381 C8.44453801,3.48357143 8.46002339,3.43504762 8.49745029,3.43471429 L8.78643275,3.43247619 L8.87784795,3.15342857 C8.88963743,3.11728571 8.93988304,3.11728571 8.95167251,3.15342857 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M8.95167251,4.61314286 L9.04308772,4.89219048 L9.33207018,4.89442857 C9.36949708,4.8947619 9.38498246,4.94333333 9.35490058,4.96595238 L9.12243275,5.14071429 L9.20963743,5.42114286 C9.22095906,5.45742857 9.18030409,5.48752381 9.14984795,5.46533333 L8.91476023,5.29428571 L8.67967251,5.46533333 C8.64921637,5.48747619 8.60865497,5.45742857 8.61988304,5.42114286 L8.70708772,5.14071429 L8.47461988,4.96595238 C8.44453801,4.94328571 8.46002339,4.8947619 8.49745029,4.89442857 L8.78643275,4.89219048 L8.87784795,4.61314286 C8.88963743,4.577 8.93988304,4.577 8.95167251,4.61314286 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M8.95167251,6.07285714 L9.04308772,6.35190476 L9.33207018,6.35414286 C9.36949708,6.35447619 9.38498246,6.40304762 9.35490058,6.42566667 L9.12243275,6.60042857 L9.20963743,6.88085714 C9.22095906,6.91714286 9.18030409,6.9472381 9.14984795,6.92504762 L8.91476023,6.754 L8.67967251,6.92504762 C8.64921637,6.94719048 8.60865497,6.91714286 8.61988304,6.88085714 L8.70708772,6.60042857 L8.47461988,6.42566667 C8.44453801,6.403 8.46002339,6.35447619 8.49745029,6.35414286 L8.78643275,6.35190476 L8.87784795,6.07285714 C8.88963743,6.0367619 8.93988304,6.0367619 8.95167251,6.07285714 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M10.0648421,0.978 L10.1562573,1.25704762 L10.4452398,1.25928571 C10.4826667,1.25961905 10.498152,1.30819048 10.4680702,1.33080952 L10.2356023,1.50557143 L10.322807,1.786 C10.3341287,1.82228571 10.2934737,1.85238095 10.2630175,1.83019048 L10.0279298,1.65914286 L9.79284211,1.83019048 C9.76238596,1.85233333 9.72182456,1.82228571 9.73305263,1.786 L9.82025731,1.50557143 L9.58778947,1.33080952 C9.5577076,1.30814286 9.57319298,1.25961905 9.61061988,1.25928571 L9.89960234,1.25704762 L9.99101754,0.978 C10.002807,0.941857143 10.0529591,0.941857143 10.0648421,0.978 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M10.0648421,2.43771429 L10.1562573,2.7167619 L10.4452398,2.719 C10.4826667,2.71933333 10.498152,2.76790476 10.4680702,2.79052381 L10.2356023,2.96528571 L10.322807,3.24571429 C10.3341287,3.282 10.2934737,3.31209524 10.2630175,3.28990476 L10.0279298,3.11885714 L9.79284211,3.28990476 C9.76238596,3.31204762 9.72182456,3.282 9.73305263,3.24571429 L9.82025731,2.96528571 L9.58778947,2.79052381 C9.5577076,2.76785714 9.57319298,2.71933333 9.61061988,2.719 L9.89960234,2.7167619 L9.99101754,2.43771429 C10.002807,2.40161905 10.0529591,2.40161905 10.0648421,2.43771429 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M10.0648421,3.89742857 L10.1562573,4.17647619 L10.4452398,4.17871429 C10.4826667,4.17904762 10.498152,4.22761905 10.4680702,4.2502381 L10.2356023,4.425 L10.322807,4.70542857 C10.3341287,4.74171429 10.2934737,4.77180952 10.2630175,4.74961905 L10.0279298,4.57857143 L9.79284211,4.74961905 C9.76238596,4.7717619 9.72182456,4.74171429 9.73305263,4.70542857 L9.82025731,4.425 L9.58778947,4.2502381 C9.5577076,4.22757143 9.57319298,4.17904762 9.61061988,4.17871429 L9.89960234,4.17647619 L9.99101754,3.89742857 C10.002807,3.86133333 10.0529591,3.86133333 10.0648421,3.89742857 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M10.0648421,5.35719048 L10.1562573,5.6362381 L10.4452398,5.63847619 C10.4826667,5.63880952 10.498152,5.68738095 10.4680702,5.71 L10.2356023,5.8847619 L10.322807,6.16519048 C10.3341287,6.20147619 10.2934737,6.23157143 10.2630175,6.20938095 L10.0279298,6.03833333 L9.79284211,6.20938095 C9.76238596,6.23152381 9.72182456,6.20147619 9.73305263,6.16519048 L9.82025731,5.8847619 L9.58778947,5.71 C9.5577076,5.68733333 9.57319298,5.63880952 9.61061988,5.63847619 L9.89960234,5.6362381 L9.99101754,5.35719048 C10.002807,5.32104762 10.0529591,5.32104762 10.0648421,5.35719048 Z'
              id='Shape' fill='#F5F5F5' />
            <path d='M10.0648421,6.81690476 L10.1562573,7.09595238 L10.4452398,7.09819048 C10.4826667,7.09852381 10.498152,7.14709524 10.4680702,7.16971429 L10.2356023,7.34447619 L10.322807,7.62490476 C10.3341287,7.66119048 10.2934737,7.69128571 10.2630175,7.66909524 L10.0279298,7.49804762 L9.79284211,7.66909524 C9.76238596,7.6912381 9.72182456,7.66119048 9.73305263,7.62490476 L9.82025731,7.34447619 L9.58778947,7.16971429 C9.5577076,7.14704762 9.57319298,7.09852381 9.61061988,7.09819048 L9.89960234,7.09595238 L9.99101754,6.81690476 C10.002807,6.78071429 10.0529591,6.78071429 10.0648421,6.81690476 Z'
              id='Shape' fill='#F5F5F5' />
          </g>
        </g>
      </g>
    </svg>
  );
};

USA.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
};

USA.defaultProps = {
  width: '20',
  height: '20'
};

export default USA;
