import React from 'react';
import { connect } from 'react-redux';
import SubscriptionCreationGraph from '../components/subscription/subscription-creation-graph';
import SubscriptionsRevenueGraph from '../components/subscription/subscriptions-revenue-graph';
import { Card, TitleBar, Layout } from '../../javascript/components/library-components';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import '../../stylesheets/pages/analytical_reports.scss';

export class AnalyticalReports extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      tabIndex: 0
    };
  }

  render () {
    return (
      <Layout>
        <TitleBar
          title='Analytical Reports'
        />
        <Card>
          <div className='chart-container'>
            <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>
              <TabList>
                <Tab>{'Subscriptions'}</Tab>
                <Tab>{'Revenue'}</Tab>
              </TabList>

              <TabPanel>
                <SubscriptionCreationGraph />
              </TabPanel>
              <TabPanel>
                <SubscriptionsRevenueGraph />
              </TabPanel>
            </Tabs>

          </div>
        </Card>
      </Layout>
    );
  }
}

function mapStateToProps (state) {
  return {};
}

function mapDispatchToProps (dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticalReports);
