import React from 'react';
import PropTypes from 'prop-types';

const AddNew = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <defs>
        <path d='M8,16 C3.58886957,16 0,12.4111304 0,8 C0,3.58886957 3.58886957,0 8,0 C12.4111304,0 16,3.58886957 16,8 C16,12.4111304 12.4111304,16 8,16 Z M8,0.695652174 C3.97217391,0.695652174 0.695652174,3.97217391 0.695652174,8 C0.695652174,12.0278261 3.97217391,15.3043478 8,15.3043478 C12.0278261,15.3043478 15.3043478,12.0278261 15.3043478,8 C15.3043478,3.97217391 12.0278261,0.695652174 8,0.695652174 Z M11.1304348,8.34782609 L4.86956522,8.34782609 C4.67756522,8.34782609 4.52173913,8.192 4.52173913,8 C4.52173913,7.808 4.67756522,7.65217391 4.86956522,7.65217391 L11.1304348,7.65217391 C11.3217391,7.65217391 11.4782609,7.808 11.4782609,8 C11.4782609,8.192 11.3217391,8.34782609 11.1304348,8.34782609 Z M8,11.4782609 C7.808,11.4782609 7.65217391,11.3217391 7.65217391,11.1304348 L7.65217391,4.86956522 C7.65217391,4.67756522 7.808,4.52173913 8,4.52173913 C8.192,4.52173913 8.34782609,4.67756522 8.34782609,4.86956522 L8.34782609,11.1304348 C8.34782609,11.3217391 8.192,11.4782609 8,11.4782609 Z'
          id='path-add-new' />
      </defs>
      <g id='Symbols' fill='none' fillRule='evenodd'>
        <g id='Icons/Regular/Add-New'>
          <mask id='mask-add-new' fill='#fff'>
            <use xlinkHref='#path-add-new' />
          </mask>
          <use id='Combined-Shape' fill='#000' fillRule='nonzero' xlinkHref='#path-add-new'
          />
          <g id='Atoms/Colors/Gray' mask='url(#mask-add-new)' fill={fill}>
            <rect id='Rectangle-6' width='16' height='16' />
          </g>
        </g>
      </g>
    </svg>
  );
};
AddNew.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
};

AddNew.defaultProps = {
  width: '16',
  height: '16',
  fill: '#9BA8B4'
};

export default AddNew;
