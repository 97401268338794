/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';

import { MainMenu } from './MainMenu';
import { SubMenu } from './SubMenu';
import { StaticMenuLinks } from './StaticMenuLinks';

import './VerticalMenu.scss';

const VerticalMenu = ({ links, onClickMainMenuItem, clickedMainMenu, activeSubMenu, activeMainMenuButton, isSubMenuActive, isSideBarVisible, superUser }) => {
  return (<><nav className={`vertical-nav-menu`}>
    <MainMenu clickedMainMenu={clickedMainMenu} activeMainMenuButton={activeMainMenuButton} onClickMainMenuItem={onClickMainMenuItem} links={links} />
    <StaticMenuLinks />
  </nav>
  {isSubMenuActive && <SubMenu isSubMenuActive={isSubMenuActive} isSideBarVisible={isSideBarVisible} activeSubMenu={activeSubMenu} superUser={superUser} />}</>);
};

const headlinelinksShape = PropTypes.shape({
  headline: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    active: PropTypes.bool,
    dataTest: PropTypes.string,
    linkTo: PropTypes.string,
    text: PropTypes.string
  }))
});

VerticalMenu.propTypes = {
  activeMainMenuButton: PropTypes.string,
  activeSubMenu: PropTypes.arrayOf(headlinelinksShape),
  isSideBarVisible: PropTypes.bool,
  isSubMenuActive: PropTypes.bool,
  links: PropTypes.arrayOf(PropTypes.shape({
    dataTest: PropTypes.string,
    dropdown: PropTypes.bool,
    icon: PropTypes.string,
    items: PropTypes.arrayOf(headlinelinksShape),
    text: PropTypes.string
  })),
  superUser: PropTypes.bool
};

export default VerticalMenu;
