import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash-es';
import { getSubscriptionGroups, getSubscriberAssets, getSubscriberSubscriptions, postCreateSubscription, getSubscriberIdentities, createIdentity, postPreviewSubscription, getSubscriberSubscriptionAttempts } from '../api';
import { UPDATE_SUBSCRIBER_ASSETS, UPDATE_SUBSCRIBER_SUBSCRIPTIONS, UPDATE_SUBSCRIPTION_GROUPS, SET_IDENTITIES, UPDATE_SUBSCRIBER_SUBSCRIPTION } from '../actions';
import { Button, TitleBar, Table, Card, ButtonGroup, SelectFieldWithLabel, TextField, TextArea, SelectField, TextFieldWithLabel, NumberFieldWithLabel, Layout, Link, StatusMessage } from '../components/library-components';
import Subscription from '../components/subscription/subscription';
import '../../stylesheets/pages/subscriber.scss';
import Select from 'react-select';
import validate from 'validate.js';
import currencyUtils, { ALL_CURRENCIES } from '../helpers/currencyUtils';

function Asset (props) {
  return <pre>{JSON.stringify(props.asset)}</pre>;
}

const SubscriptionAttemptCustomPagination = ({ subscriptionAttempts, displayLoadMore, loadMoreSubscriptionAttempts }) => {
  return (<div>
    <Table header={['Identity', 'Subscription Plan', 'Subscription Type', 'Date', 'Status', 'Payment Type']} className='table-default--4cols'>
      {subscriptionAttempts.map((subscriptionAttempt, index) =>
        <div key={index} className='table-default__row'>
          <div className='table-default__cell truncate-word'>{subscriptionAttempt.preferred_identity.value}</div>
          <div className='table-default__cell'>{subscriptionAttempt.plan_name}</div>
          <div className='table-default__cell'>{_.capitalize(subscriptionAttempt.group_type)}</div>
          <div className='table-default__cell'>{_.replace(subscriptionAttempt.updated_at, 'T', ' ')}</div>
          <div className='table-default__cell'>{subscriptionAttempt.status}</div>
          <div className='table-default__cell'>{subscriptionAttempt.payment_type && subscriptionAttempt.payment_type.includes('recurring') ? subscriptionAttempt.payment_type.replace('_recurring', '') : subscriptionAttempt.payment_type }</div>
        </div>
      )}
    </Table>
    { displayLoadMore && <div onClick={(e) => loadMoreSubscriptionAttempts()}> <Link dataTest='load-more-subscription-attempts'> Load more </Link> </div> }
  </div>);
};

export class Subscriber extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      selectedSubscriptionGroup: null,
      selectedSubscriptionPlan: null,
      identityProvider: 'email',
      identityValue: null,
      subscriptionMetadata: {},
      showNotification: { isNotificationOpen: false, status: '', statusMessage: '' },
      subscriptionUpdated: false,
      addIdentity: false,
      errors: {},
      amountCents: 0,
      notes: '',
      subscriptionAttemptsPage: 1,
      subscriberSubscriptionAttempts: []
    };
    this.getCurrencies = this.getCurrencies.bind(this);
  }

  componentDidMount () {
    getSubscriptionGroups('all').then(allGroups => {
      const groups = allGroups.filter(group => !(['group_access', 'dynamic_asset'].includes(group.subscription_type)));
      this.props.setSubscriptionGroups(groups);
    });
    this.loadIdentities();
    this.loadSubscriptionData();
    this.loadSubscriberSubscriptionAttempts();
  }

  loadIdentities () {
    getSubscriberIdentities(this.props.provider, this.props.identity).then(identities => this.props.setIdentities(identities));
  }

  loadSubscriptionData () {
    getSubscriberAssets(this.props.provider, this.props.identity).then(assets => this.props.updateAssets(assets));
    getSubscriberSubscriptions(this.props.provider, this.props.identity).then(subscriptions => this.props.updateSubscriptions(subscriptions));
  }

  loadMoreSubscriptionAttempts () {
    var self = this;
    this.setState({ subscriptionAttemptsPage: this.state.subscriptionAttemptsPage + 1 }, () => self.loadSubscriberSubscriptionAttempts());
  }

  loadSubscriberSubscriptionAttempts () {
    getSubscriberSubscriptionAttempts(this.props.provider, this.props.identity, this.state.subscriptionAttemptsPage)
      .then(response => this.setState({
        subscriberSubscriptionAttempts: this.state.subscriberSubscriptionAttempts.concat(response.subscription_attempts),
        subscriptionAttemptsPageCount: response.meta.total_pages
      }));
  }

  updateSubscriptionMetadataFields (metadataFields) {
    const twoMetadataFieldGrouped = _.chunk(metadataFields, 2);
    return _.map(twoMetadataFieldGrouped, (fields, index) =>
      <div className='form-row' key={index} >
        {_.map(fields, (field, index) => <TextFieldWithLabel label={field} placeholder={field} key={index} value={this.state.subscriptionMetadata[field] || ''}
          onChange={(e) => this.updateSubscriptionMetadata(field, e.target.value)} />)}
      </div>
    );
  }

  updateSubscriptionMetadata (field, value) {
    this.setState({ subscriptionMetadata: Object.assign({}, this.state.subscriptionMetadata, { [field]: value }) });
  }

  allSubscriptionOptions () {
    return this.props.allSubscriptionGroups.map(subscriptionGroup => ({ label: subscriptionGroup.name, value: subscriptionGroup.id, subscriptionGroup: subscriptionGroup }));
  }

  plansInGroup () {
    return this.state.selectedSubscriptionGroup
      ? this.state.selectedSubscriptionGroup['subscription_plans'].filter(x => !x.recurring).map(subscriptionPlan => ({ label: `${subscriptionPlan.title}`, value: subscriptionPlan.id, subscriptionPlan: subscriptionPlan }))
      : null;
  }

  subscribe (payment, attemptToken) {
    postCreateSubscription(this.props.provider, this.props.identity, this.state.selectedSubscriptionPlan.id, payment, { metadata: this.state.subscriptionMetadata }, attemptToken, this.state.notes).then(() => {
      this.setState({ selectedSubscriptionGroup: null, selectedSubscriptionPlan: null, amountCents: 0, notes: '' });
      this.loadSubscriptionData();
    }, error => { this.setState({ showNotification: { isNotificationOpen: true, status: 'error', statusMessage: error.message || error.subscriber } }); }
    );
  }

  previewSubscription (paymentType) {
    const payment = this.paymentParamsManual();

    const subscription = {
      subscription: {
        notes: this.state.notes,
        subscription_plan_id: this.state.selectedSubscriptionPlan.id,
        metadata: this.state.subscriptionMetadata,
        payment: payment
      }
    };

    return postPreviewSubscription(this.props.provider, this.props.identity, subscription)
      .then(
        response => { return response['attempt_token']; },
        error => {
          this.setState({
            showNotification: {
              isNotificationOpen: true,
              status: 'error',
              statusMessage: Object.values(error.message) || error.subscriber || 'Preview failed'
            }
          });
          return Promise.reject(error);
        });
  }

  paymentParamsManual () {
    return {
      payment_type: 'manual',
      amount_cents: this.getAmountCents(),
      amount_currency: this.state.amountCurrency
    };
  }

  getAmountCents () {
    return this.state.amount * 100;
  }

  subscribeManual () {
    this.previewSubscription('manual')
      .then((attemptToken) => {
        this.subscribe(
          { payment_type: 'manual',
            amount_cents: this.getAmountCents(),
            amount_currency: this.state.amountCurrency
          }, attemptToken
        );
      });
  }

  saveIdentity (e) {
    e.preventDefault();
    createIdentity(this.props.provider, this.props.identity, this.state.identityProvider, this.state.identityValue.trim())
      .then(() => {
        this.setState({ identityProvider: null, identityValue: null, addIdentity: false, errors: {} });
        this.loadIdentities();
      },
      errors => this.setState({ errors: errors })
      );
  }

  updateAmount (amount) {
    const constraintsAmount = {
      amount: { presence: true, numericality: { lessThanOrEqualTo: 9999999, greaterThanOrEqualTo: 0, message: 'Value not within acceptable range' } }
    };
    this.setState({ amount: amount });
    const errors = validate({ amount: amount }, constraintsAmount);
    const disabled = !!errors;
    this.setState({ errors: errors, disabled: disabled });
  }

  getCurrencies () {
    if (this.state.selectedSubscriptionGroup.subscription_type === 'campaign') {
      return ALL_CURRENCIES.filter(currency => currency.value === this.state.selectedSubscriptionGroup.currency);
    }
    return ALL_CURRENCIES;
  }

  setNotification (isNotificationOpen, status, statusMessage) {
    this.setState({ showNotification: { isNotificationOpen: isNotificationOpen, status: status, statusMessage: statusMessage } });
  }

  render () {
    const IDENTITY_PROVIDER = ['quintype', 'email', 'phone_number'].map((k) => ({ label: k, value: k }));

    return (
      <Layout>
        <TitleBar title='Subscriber Profile'
          breadcrumbTitle='Subscribers' breadcrumbPath='/subscribers'
        />

        <Card title='Subscriber Identities'>
          {this.props.identities.map((identity, index) => <TextField key={identity.value.toString()} disabled defaultValue={`${identity.value} (${identity.provider})`} />)}

          {this.state.addIdentity && <div className='add-subscriber-identity'>
            <SelectField options={IDENTITY_PROVIDER}
              value={this.state.identityProvider}
              onChange={(e) => this.setState({ identityProvider: e.value })}
              className='add-subscriber-identity__provider'
              placeholder='Select'
              searchable={false}
              valueKey='value'
              clearable={false} />

            <TextField className='add-subscriber-identity__identity' value={this.state.identityValue || ''} onChange={(e) => this.setState({ identityValue: e.target.value })} placeholder='Add Identity' />
          </div>}
          {_.get(this.state.errors, 'provider') && <p className='error-msg'>Provider {this.state.errors.provider[0]} &nbsp;</p>}
          {_.get(this.state.errors, 'value') && <p className='error-msg'>Identity {this.state.errors.value[0]}</p>}
          {this.state.addIdentity &&
            <ButtonGroup>
              <Button primary onClick={(e) => this.saveIdentity(e)}>Save</Button>
            </ButtonGroup>}
          <Link iconLeft='add-new' onClick={(e) => this.setState({ addIdentity: true })}>Add Identity</Link>
        </Card>

        {this.state.subscriberSubscriptionAttempts.length !== 0 && <Card title='Subscription Attempts'>
          <SubscriptionAttemptCustomPagination displayLoadMore={this.state.subscriptionAttemptsPage < this.state.subscriptionAttemptsPageCount} loadMoreSubscriptionAttempts={(e) => this.loadMoreSubscriptionAttempts()} subscriptionAttempts={this.state.subscriberSubscriptionAttempts} />
        </Card>}

        <Card title='Add New Subscription'>
          <div className='form-row'>
            <SelectFieldWithLabel label='Subscription group'
              value={_.get(this.state, ['selectedSubscriptionGroup', 'id'])}
              options={this.allSubscriptionOptions()}
              onChange={(e) => this.setState({ selectedSubscriptionGroup: e && e.subscriptionGroup, selectedSubscriptionPlan: null, subscriptionMetadata: {} })}
              clearable={false} />

            <SelectFieldWithLabel label='Subscription plan'
              value={_.get(this.state, ['selectedSubscriptionPlan', 'id'])}
              options={this.plansInGroup()}
              onChange={(e) => this.setState({ selectedSubscriptionPlan: e.subscriptionPlan, amount: currencyUtils.convertToCurrencyUnits(e.subscriptionPlan.price_cents), amountCurrency: e.subscriptionPlan.price_currency })}
              clearable={false}
              disabled={this.state.selectedSubscriptionGroup === null} />
          </div>
          {this.state.selectedSubscriptionPlan && this.updateSubscriptionMetadataFields(this.state.selectedSubscriptionGroup['metadata_fields'])}

          {this.state.selectedSubscriptionPlan &&
            <div className='form-row'>
              <div className='text-and-select'>
                <NumberFieldWithLabel
                  label='Amount Paid'
                  onChange={(e) => this.updateAmount(e.target.value)}
                  placeholder={'Amount Paid'}
                  dataTest='amount-paid'
                  value={this.state.amount}
                  errors={_.get(this.state.errors, ['amount', '0'])}
                />

                <Select
                  className='select-currency'
                  options={this.getCurrencies()}
                  value={this.state.amountCurrency}
                  onChange={(e) => this.setState({ amountCurrency: e.value })}
                  clearable={false}
                  searchable={false}
                  arrowRenderer={currencyUtils.arrowRenderer}
                />
              </div>
            </div>
          }

          { this.state.selectedSubscriptionPlan &&
            <TextArea label='Notes'
              value={this.state.notes}
              placeholder="Enter subscription's notes"
              onChange={(e) => this.setState({ notes: e.target.value })}
              dataTest='notes'
            />
          }

          {this.state.selectedSubscriptionPlan &&
            <ButtonGroup>
              <Button primary disabled={this.state.disabled} onClick={(e) => { e.preventDefault(); this.subscribeManual(); }}>Save</Button>
            </ButtonGroup>
          }
        </Card>

        {false && <h3>Assets currently accessible</h3>}
        {false && <ul>
          {this.props.assets.map((asset, index) =>
            <li key={index}><Asset key={index} asset={asset} /></li>
          )}
        </ul>}

        {this.props.subscriptions.map((subscription, index) =>
          <Subscription
            allSubscriptionGroups={this.props.allSubscriptionGroups}
            identity={this.props.identity}
            provider={this.props.provider}
            subscription={subscription}
            key={subscription.id}
            isOnSaveSuccessful={this.state.subscriptionUpdated}
            setNotification={this.setNotification.bind(this)}
            updateSubscription={(subscription) => {
              this.setState({ subscriptionUpdated: true });
              this.props.updateSubscription(subscription);
            }}
            loadSubscriptionData={this.loadSubscriptionData.bind(this)} />
        )}
        {this.state.showNotification.isNotificationOpen &&
          <StatusMessage statusMessage={this.state.showNotification.statusMessage}
            status={this.state.showNotification.status}
            onCancel={() => this.setState({ showNotification: { isNotificationOpen: false, status: '', statusMessage: '' } })} />}
      </Layout>
    );
  }
}

function mapStateToProps (state) {
  var subscriber = state.subscriber || { subscriptions: [], assets: [] };
  return {
    assets: subscriber.assets,
    subscriptions: subscriber.subscriptions,
    allSubscriptionGroups: state.subscriptionGroups || [{}],
    identities: state.identities || [],
    appName: state.config.appName,
    razorpayKey: state.config.razorpayKey
  };
}

function mapDispatchToProps (dispatch) {
  return {
    updateAssets: (assets) => dispatch({ type: UPDATE_SUBSCRIBER_ASSETS, assets: assets }),
    updateSubscriptions: (subscriptions) => dispatch({ type: UPDATE_SUBSCRIBER_SUBSCRIPTIONS, subscriptions: subscriptions }),
    setSubscriptionGroups: (plans) => dispatch({ type: UPDATE_SUBSCRIPTION_GROUPS, subscriptionGroups: plans }),
    setIdentities: (identities) => dispatch({ type: SET_IDENTITIES, identities: identities }),
    updateSubscription: (subscription) => dispatch({ type: UPDATE_SUBSCRIBER_SUBSCRIPTION, subscription: subscription })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscriber);
