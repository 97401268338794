import React from 'react';

function Icon () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path d="M0 0L24 0 24 24 0 24z"></path>
        <path
          fill="#5F6978"
          fillRule="nonzero"
          d="M5 19c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V4H5v15zM7 6h10v13H7V6zm8.5-2l-1-1h-5l-1 1H4v2h16V4h-4.5z"
        ></path>
        <path
          fill="#5F6978"
          stroke="#5F6978"
          strokeWidth="2"
          d="M10 8L10 17"
        ></path>
        <path
          fill="#5F6978"
          stroke="#5F6978"
          strokeWidth="2"
          d="M14 8L14 17"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
