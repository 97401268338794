import React from 'react';
import { get, reject } from 'lodash-es';

import { Dialog } from '@quintype/em/components/dialog';
import Select from '@quintype/em/components/select';
import { Button } from '@quintype/em/components/button';
import Reports from '../reports/reports';
import FileDownload from '../library-components/Icons/file-download';

import { getSubscriptionGroupsLite, createSubsciptionsFromCSV, getSubscriptionImportsReports } from '../../api';

import './import-subscriptions.scss';

export default class ImportSubscriptions extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      selectedGroup: '',
      subscriptionsCsv: '',
      subscriptionGroups: [],
      importInProgress: false
    };
  }

  componentDidMount () {
    this.loadSubscriptionGroups();
  }

  loadSubscriptionImportStatusReport () {
    getSubscriptionImportsReports(this.state.selectedGroup).then(
      subscriptionsReports => {
        this.setState({ importSubscriptionsReports: subscriptionsReports });
        if (get(subscriptionsReports, ['0', 'status']) === 'in_progress') {
          this.setState({ importInProgress: true, showReports: false });
        } else if (get(subscriptionsReports, ['0', 'status']) === 'generated') {
          this.setState({ showReports: true, importInProgress: false });
        } else {
          this.setState({ showReports: false, importInProgress: false });
        }
      }
    );
  }

  loadSubscriptionGroups () {
    getSubscriptionGroupsLite('all').then(subscriptionGroups => {
      this.setState({ subscriptionGroups: this.createGroupOptions(reject(subscriptionGroups, { subscription_type: 'Pay Per Asset' })) });
    });
  }

  createGroupOptions (subscriptionGroups) {
    return subscriptionGroups.map(group => ({
      label: group.name,
      value: group.id.toString()
    }));
  }

  handleGroupSelection (option) {
    this.setState({ selectedGroup: option.value }, () => this.loadSubscriptionImportStatusReport());
  }

  importCsv () {
    createSubsciptionsFromCSV(this.state.selectedGroup, this.state.subscriptionsCsv)
      .then(message => this.props.onImport(message, true))
      .catch(err => this.props.onImport(err.message, false));
  }

  handleImportClick () {
    if (this.state.importInProgress) {
      return;
    }
    if (!!this.state.selectedGroup && !!this.state.subscriptionsCsv) {
      this.setState({ importInProgress: true });
      this.importCsv();
      return;
    }
    this.props.displayNotification('error', 'Please select group and upload csv to import subscriptions');
    this.props.onClose();
  }

  render () {
    return (
      <Dialog
        isOpen
        onClose={this.props.onClose}
        onAction={() => this.handleImportClick()}
        actionButtonLabel='Import'
      >
        <div className='wrapper'>
          <h3 className='heading'>Import Subscriptions</h3>
          <p className='description' data-test-id='dialog-description'>Allows you to import all the existing subscription to AccessType through a CSV. Please check the Guidelines before uploading the CSV.</p>
          <div className='group-template'>
            <div className='group-select'>
              <Select
                label='Select group'
                options={this.state.subscriptionGroups}
                value={this.state.selectedGroup}
                onChange={value => this.handleGroupSelection(value)}
                clearable={false}
              />
            </div>
            <a
              href={!this.state.selectedGroup ? 'javascript:void(0)' : `/api/v1/management/subscription_groups/${this.state.selectedGroup}/subscription_imports/csv_template.csv`}
              target={!this.state.selectedGroup ? undefined : '_blank'}
              rel='noopener noreferrer'
            >
              <Button type='link' disabled={!this.state.selectedGroup}>
                <FileDownload fill={!this.state.selectedGroup ? '#c6ccd5' : '#2f81e4'} />
                CSV Template
              </Button>
            </a>
          </div>
          <div>
            <label>
              <input
                type='file'
                onChange={e => this.setState({ subscriptionsCsv: e.target.files[0] })}
                className='input-file__input'
              />
              <span className='input-file-text' data-test-id='choose-csv-btn'>Choose CSV file</span>
              <p className='input-csv-files' data-test-id='files-choosen-text'>{this.state.subscriptionsCsv.name || 'No file choosen'}</p>
            </label>
          </div>
          <p className='margin-top-20' data-test-id='know-more'>To know more about the subscriptions importing feature please check our {''}
            <a
              href='/subscriptions/csv_guidelines'
              target='_blank'
              rel='noopener noreferrer'
              className='guidelines-text'
              data-test-id='detailed-guidelines-link'
            >
              Detailed Guidelines
            </a>
          </p>
          {
            this.state.importInProgress &&
              <p className='margin-top-20 import-in-progress' data-test-id='import-in-progress-msg'>Subscription import is in progress. Please wait for it to finish before importing again.</p>
          }
          {
            this.state.showReports &&
              <div className='margin-top-20'>
                <h4 className='reports-heading'>Subscription Imports Report</h4>
                <Reports reports={this.state.importSubscriptionsReports} showFilters={false} />
              </div>
          }
        </div>
      </Dialog>
    );
  }
}
