import React from 'react';

const Copy = () => (
  <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='24' height='24'>
    <defs>
      <path id='copy' d='M19.23 4.45c.347.346.52.766.52 1.26v9.946c0 .495-.173.916-.52 1.262-.346.346-.766.52-1.261.52H15v1.78c0 .496-.173.916-.52 1.262-.346.347-.766.52-1.261.52H4.906c-.495 0-.915-.173-1.262-.52a1.718 1.718 0 0 1-.519-1.261V7.344c0-.495.173-.916.52-1.262.346-.346.766-.52 1.261-.52h2.969v-1.78c0-.496.173-.916.52-1.262A1.71 1.71 0 0 1 9.655 2h6.383c.495 0 .915.173 1.262.52l1.93 1.93zm-3.043-1.263v2.376h2.375a.99.99 0 0 0-.185-.26l-1.93-1.93a.99.99 0 0 0-.26-.186zM13.812 19.22v-1.782H9.656a1.72 1.72 0 0 1-1.262-.519 1.718 1.718 0 0 1-.519-1.262V6.75H4.906a.578.578 0 0 0-.427.167.578.578 0 0 0-.167.427v11.875c0 .173.056.315.167.427a.578.578 0 0 0 .427.166h8.313a.578.578 0 0 0 .426-.166.578.578 0 0 0 .167-.427zm4.75-3.563V6.75h-2.671a.859.859 0 0 1-.631-.26.859.859 0 0 1-.26-.63V3.186H9.656a.578.578 0 0 0-.427.167.578.578 0 0 0-.167.427v11.875a.58.58 0 0 0 .167.427.578.578 0 0 0 .427.167h8.313a.578.578 0 0 0 .426-.167.578.578 0 0 0 .167-.427z' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <mask id='copy-mask' fill='#fff'>
        <use xlinkHref='#copy' />
      </mask>
      <use fill='#132437' xlinkHref='#copy' />
      <g fill='#9AA6B9' mask='url(#copy-mask)'>
        <path d='M0 0h24v24H0z' />
      </g>
    </g>
  </svg>
);

export default Copy;
