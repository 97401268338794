export const updateDialogContent = (type, setDialogContent, actionCallBack, attributeName) => {
  if (type === 'delete') {
    setDialogContent({
      title: 'Delete Attribute?',
      textContent: 'Are you sure you want to delete "' + attributeName + '"?',
      firstButtonLabel: 'Delete',
      secondButtonLabel: 'Cancel',
      variant: 'danger',
      actionButtonCallBack: () => {
        actionCallBack();
      }
    });
  }

  if (type === 'update') {
    setDialogContent({
      title: 'Save Changes?',
      textContent: 'Do you want to save changes to "' + attributeName + '"?',
      firstButtonLabel: 'Save',
      secondButtonLabel: 'Cancel',
      actionButtonCallBack: () => {
        actionCallBack();
      }
    });
  }

  if (type === 'duplicate_error') {
    setDialogContent({
      title: 'Attribute already exists',
      textContent: 'An attribute by the name "' + attributeName + '" already exists. Two or more attributes cannot have the same name.',
      firstButtonLabel: 'Okay',
      secondButtonLabel: null,
      actionButtonCallBack: () => {
        actionCallBack();
      }
    });
  }

  if (type === 'discard') {
    setDialogContent({
      title: 'Discard Changes?',
      textContent: "Unsaved changes have been made. Please confirm if you'd like to discard your changes.",
      firstButtonLabel: 'Discard',
      secondButtonLabel: 'Cancel',
      actionButtonCallBack: () => {
        actionCallBack();
      }
    });
  }
};

export const constraints = () => {
  return Object.assign(
    {},
    {
      name: {
        presence: true,
        length: {
          maximum: 100,
          minimum: 1,
          tooShort: "can't be blank",
          tooLong: 'is too long (maximum is %{count} characters)'
        }
      },
      type: {
        presence: { allowEmpty: false }
      },
      value: {
        presence: { allowEmpty: false }
      }
    }
  );
};
