import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import './pagination.scss';

const Pagination = ({ pageCount, containerClassName, onPageChange, pageRangeDisplayed,
  marginPagesDisplayed, pageClassName, breakClassName, breakLabel, totalEnteries }) => {
  return (
    <div className='pagination-conatiner'>
      {totalEnteries && <span>{totalEnteries}</span> }
      <ReactPaginate
        previousLabel='<'
        nextLabel='>'
        pageCount={pageCount}
        containerClassName={containerClassName}
        onPageChange={(data) => onPageChange(data.selected + 1)}
        pageRangeDisplayed={pageRangeDisplayed}
        marginPagesDisplayed={marginPagesDisplayed}
        pageClassName={pageClassName}
        breakLabel={breakLabel}
        breakClassName={breakClassName}
      />
    </div>
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number
};

export default Pagination;
