import React from 'react';
import PropTypes from 'prop-types';
import { BackIcon } from '../Icons';
import './Breadcrumb.scss';

const Breadcrumb = ({ title, path }) => (
  <div className='breadcrumb'>
    <a href={path} className='breadcrumb__item'>
      <BackIcon />
      <span className='title'>{title}</span>
    </a>
  </div>
);

Breadcrumb.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string
};

export default Breadcrumb;
