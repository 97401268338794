import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ButtonGroup.scss';

const ButtonGroup = ({ children, columns }) => {
  const className = classNames('button-group', { 'button-group--two-column-left-and-right': columns === 'two' });

  return (
    <div className={className}>{children}</div>
  );
};

ButtonGroup.propTypes = {
  children: PropTypes.node,
  columns: PropTypes.string
};

export default ButtonGroup;
