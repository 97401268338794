import React, { useState } from 'react';
import { Inspector } from '@quintype/em/components/inspector';
import Select from '@quintype/em/components/select';
import { get } from 'lodash-es';
import validate from 'validate.js';
import { Checkbox } from '@quintype/em/components/checkbox';
import { DateRangePicker } from '@quintype/em/components/date-range-picker';
import { ErrorMessage } from '@quintype/em/components/error-message';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';
import parseISO from 'date-fns/parseISO';
import endOfDay from 'date-fns/endOfDay';
import { customValidation } from '../components/subscription-filter/index';

import './invoice_filters.scss';

export function InvoiceFilters (props) {
  const { invoiceDateRange, onActionButtonClick, setInvoiceDates } = props;

  const [isDatePopupOpen, setDatePopupState] = useState(false);

  const [errors, setErrors] = useState([]);

  const [isDateSelected, setDateSelected] = useState(
    !!invoiceDateRange.start
  );

  const { start, end } = invoiceDateRange;

  function timeStampConditions () {
    return isDateSelected
      ? {
        start_timestamp: {
          presence: true
        },
        end_timestamp: {
          presence: true
        }
      }
      : {};
  }

  function constraints () {
    return Object.assign({}, timeStampConditions());
  }

  function handleActionButtonClick () {
    const currentData = {
      start_timestamp: start,
      end_timestamp: end
    };

    const validatedData = validate(currentData, constraints());

    if (validatedData) {
      setErrors(validatedData);
    } else {
      if (errors.length !== 1) {
        setErrors([]);
      }
      onActionButtonClick();
    }
  }

  const dateRangePickerProps =
    start && end
      ? {
        startTimeStamp: getUnixTime(parseISO(start)),
        endTimeStamp: getUnixTime(parseISO(end))
      }
      : {};

  const isTimestampNotSelected = get(errors, ['start_timestamp', 0], false);
  return (
    <div className="invoice-filter-container">
      <Inspector
        title="Filters"
        isActive={props.opened}
        onClose={() => props.onClose()}
        actionButtonLabel="Apply Filters"
        onActionButtonClick={handleActionButtonClick}
      >
        <Select
          label="Subscription Groups"
          options={props.groups}
          onChange={(e) => props.onChangeGroup(e)}
          value={props.groupValue}
        />
        <Select
          label="Subscription Plans"
          options={props.plans}
          onChange={(e) => props.onChangePlan(e)}
          value={props.planValue}
        />
        <div className="checkbox-container">
          <Checkbox
            id="date-range-checkbox"
            label="Apply Date Range"
            onChange={(checked) => {
              setDateSelected(checked);
              if (!checked) {
                setInvoiceDates(null, null);
              }
            }}
            checked={isDateSelected}
          />
        </div>
        {isDateSelected && (
          <>
            <DateRangePicker
              {...dateRangePickerProps}
              dateLabel="Invoices Created Date"
              dateFieldClass={isTimestampNotSelected ? 'red-border' : ''}
              customValidation={customValidation}
              onDateRangeSelect={(start, end) => {
                setInvoiceDates(
                  fromUnixTime(start).toISOString(),
                  endOfDay(fromUnixTime(end)).toISOString()
                );
              }}
              onDatePopupOpenOrClose={(popupState) => {
                if (popupState) {
                  setDatePopupState(true);
                } else {
                  setDatePopupState(false);
                }
              }}
            />
            <div className="error-message-container">
              <ErrorMessage
                message={
                  isTimestampNotSelected ? 'Please select date range' : ''
                }
              />
            </div>
            {/* This increases the height of parent container when date popup is open
             * as normal flow parent (not absolute/ fixed positioned) does not account for
             * height of absolutely/fixed positioned element (date popup). Although a fixed positioned
             * parent accounts height  for fixed positioned child, still we need some space below
             * This can be removed if there are enough items below the date range picker */}
            {isDatePopupOpen && (
              <div className="sub-coupon-parent-ht-increase"></div>
            )}
          </>
        )}
      </Inspector>
    </div>
  );
}
