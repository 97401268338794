import React from 'react';
import { connect } from 'react-redux';
import { TitleBar, Card, Table, CardHeader, Layout, StatusMessage, User } from '../components/library-components';
import { getAccountUsers, deleteAccountUser, postCreateUpdateAccountUser, getCurrentAccountUserDetails } from '../api';
import { SET_ACCOUNT_USERS } from '../actions';
import CustomSelect from '../components/coupons/custom-select/custom-select';
import TableRowWithActionDropdown from '../components/table-row-with-action-dropdown/table-row-with-action-dropdown';
import _ from 'lodash-es';
import '../../stylesheets/pages/users_roles.scss';
import { AddUser } from '../components/add-user/add-user';

const ROLES = [
  { label: 'Owner', value: 'superuser' },
  { label: 'Admin', value: 'user' }
];

export class UsersAndRoles extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showNotification: { isNotificationOpen: false, status: '', statusMessage: '' },
      showSearchUser: false
    };

    this.deleteAccountUser = this.deleteAccountUser.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.addAccountUser = this.addAccountUser.bind(this);
    this.callbackAfterGrantAccess = this.callbackAfterGrantAccess.bind(this);
  }

  toggleSearch () {
    this.setState({ showSearchUser: !this.state.showSearchUser });
  }

  addAccountUser (value) {
    postCreateUpdateAccountUser({ user_id: value.value, role: 'user' })
      .then((response) => {
        this.setState({ showNotification: { isNotificationOpen: true, status: 'success', statusMessage: 'User added successfully!' } });
        this.loadAccountUsers();
      },
      (errors) => { this.setState({ showNotification: { isNotificationOpen: true, status: 'error', statusMessage: errors.error.message } }); });
  }

  callbackAfterGrantAccess (status, statusMessage = '') {
    if (status === 'success') {
      this.setState({ showNotification: { isNotificationOpen: true, status: 'success', statusMessage: 'User added successfully!' } });
      this.loadAccountUsers();
    }

    if (status === 'error') {
      this.setState({ showNotification: { isNotificationOpen: true, status: 'error', statusMessage: statusMessage } });
    }
    this.toggleSearch();
  }

  loadAccountUsers (pageNum = 1) {
    getAccountUsers().then((response) => {
      return this.props.setAccountUsers(response.account_users);
    },
    (errors) => this.setState({ showNotification: { isNotificationOpen: true, status: 'error', statusMessage: errors.error.message } }));
  }

  componentDidMount () {
    this.loadAccountUsers();
    getCurrentAccountUserDetails()
      .then(accountUser => {
        this.setState({
          currentAccountUser: accountUser });
      }).catch((error) => {
        console.log(error);
      });
  }

  deleteAccountUser (id) {
    deleteAccountUser(id)
      .then((response) => {
        this.setState({ showNotification: { isNotificationOpen: true, status: 'success', statusMessage: 'User removed successfully!' } });
        this.loadAccountUsers();
      },
      (errors) => this.setState({ showNotification: { isNotificationOpen: true, status: 'error', statusMessage: errors.error.message } }));
  }

  onChangeRole (selectedItem, accountUserId, userId) {
    postCreateUpdateAccountUser({ id: accountUserId, role: selectedItem, user_id: userId })
      .then((response) => {
        this.setState({ showNotification: { isNotificationOpen: true, status: 'success', statusMessage: 'Role updated successfully!' } });
        this.loadAccountUsers();
      },
      (errors) => this.setState({ showNotification: { isNotificationOpen: true, status: 'error', statusMessage: errors.error.message } }));
  }

  customSelect (accountUser) {
    return <CustomSelect
      id={accountUser.id}
      value={accountUser.role}
      onChangeActive={(selectedItem) => this.onChangeRole(selectedItem, accountUser.id, accountUser.user_id)}
      options={ROLES} />;
  }

  findRoleObj (userRole) {
    return ROLES.find((obj) => {
      return obj.value === userRole;
    });
  }

  userRole ({ accountUser }) {
    const getRoleLabel = this.findRoleObj(accountUser.role);
    const validateCurrentAccountUser = (accountUser.user_id === JSON.parse(_.get(this.state, ['currentAccountUser', 'user_id'])));

    if (_.get(this.state, ['currentAccountUser', 'superuser'], false)) {
      return validateCurrentAccountUser ? getRoleLabel.label : this.customSelect(accountUser);
    } else {
      return getRoleLabel.label;
    }
  }

  showVerticalMenu ({ accountUserId }) {
    return ((accountUserId !== JSON.parse(_.get(this.state, ['currentAccountUser', 'user_id']))) && (_.get(this.state, ['currentAccountUser', 'superuser'], false)));
  }

  render () {
    return (
      <Layout>
        <TitleBar
          title='Users And Roles' />
        <Card>
          <CardHeader title='Account Users List' buttonText='Add User' buttonOnClick={() => this.toggleSearch()} isButton />
          {this.state.showSearchUser &&
            <AddUser callbackAfterGrantAccess={this.callbackAfterGrantAccess}/> }
          <Table header={['Name', 'Email', 'Role', 'Added by']} className='table-default--6cols users-roles-table'>
            {this.props.accountUsers && this.state.currentAccountUser && this.props.accountUsers.map((accountUser, index) => {
              const actionsForCoupon = [{ action: 'Remove', actionHandler: this.deleteAccountUser, id: accountUser.id }];
              return <TableRowWithActionDropdown id={accountUser.id} dropdownActions={actionsForCoupon} key={accountUser.id} showVerticalMenu={this.showVerticalMenu({ accountUserId: accountUser.user_id })}>
                <div className='table-default__cell'> <User userName={accountUser.name} userAvatar={accountUser.avatar} /> </div>
                <div className='table-default__cell'> {accountUser.email} </div>
                <div className='table-default__cell user-roles'> {this.userRole({ accountUser: accountUser })} </div>
                <div className='table-default__cell'> {accountUser.added_by_user_name || 'Accesstype System'} </div>
              </TableRowWithActionDropdown>;
            })}
          </Table>
        </Card>
        {this.state.showNotification.isNotificationOpen &&
        <StatusMessage statusMessage={this.state.showNotification.statusMessage}
          status={this.state.showNotification.status}
          onCancel={() => this.setState({ showNotification: { isNotificationOpen: false, status: '', statusMessage: '' } })} />}
      </Layout>
    );
  }
}

function mapStateToProps (state) {
  return {
    accountUsers: state.accountUsers || {}
  };
}

function mapDispatchToProps (dispatch) {
  return {
    setAccountUsers: (accountUsers) => dispatch({ type: SET_ACCOUNT_USERS, accountUsers: accountUsers })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersAndRoles);
