import React from 'react';
import PropTypes from 'prop-types';

const Selected = ({ width, height, fill, uniqueId }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'
      width={width} height={height}>
      <defs>
        <path id={`a-${uniqueId}`} d='M17.553 7l.849.849L9.96 16.29 6 12.33l.849-.849 3.11 3.112z' />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id={`b-${uniqueId}`} fill='#fff'>
          <use xlinkHref={`#a-${uniqueId}`} />
        </mask>
        <use fill={fill} fillRule='nonzero' xlinkHref={`#a-${uniqueId}`} />
        <g fill='#9AA6B9' mask={`url(#b-${uniqueId})`}>
          <path d='M0 0h24v24H0z' />
        </g>
        <path d='M0 0h24v24H0z' mask={`url(#b-${uniqueId})`} />
      </g>
    </svg>

  );
};

Selected.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
};

Selected.defaultProps = {
  width: '24',
  height: '24',
  fill: '#818A98',
  uniqueId: 'b'
};

export default Selected;
