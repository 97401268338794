import React from 'react';
import _ from 'lodash-es';
import { updateNotificationSettings, getNotificationSettings } from '../api';
import { Card, Button, ButtonGroup, StatusMessage, Layout, Description, TagsInput, TitleBar } from '../../javascript/components/library-components';
import { validateEmail } from '../helpers/emailUtils';

import '../../stylesheets/components/react-tagsinput.scss';
import '../../stylesheets/pages/email-configurations.scss';

export class AdminEmailNotifications extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showNotification: { isNotificationOpen: false, status: '', statusMessage: '' },
      inputProps: {
        placeholder: 'Enter an email address'
      },
      isSaveDisabled: true,
      errors: {},
      settings: []
    };
    this.handleEmailAddressesChange = this.handleEmailAddressesChange.bind(this);
  }

  componentDidMount () {
    getNotificationSettings().then(settings => this.setState({ settings: settings }));
  }

  render () {
    return (
      <Layout>
        <TitleBar title='Admin Email Notifications'/>
        <Card title='Admin Email Addresses' >
          <Description>
            These email addresses will be used to communicate information useful for the administrators, such as webhook failure notifications.
          </Description>
          <div className='email-configurations'>
            <TagsInput
              dataTest={'admin_email_addresses'}
              value={_.get(this.state.settings, 'admin_email_addresses', [])}
              onChange={this.handleEmailAddressesChange}
              inputProps={this.state.inputProps}
              onlyUnique
            />
            <p className='error-msg'>{this.state.errors.email_addresses}</p>
          </div>
        </Card>

        <ButtonGroup>
          <Button dataTest={'save'} disabled={this.state.isSaveDisabled} onClick={(e) => this.updateAccountEmailSettings()} primary>Save</Button>
        </ButtonGroup>

        {this.state.showNotification.isNotificationOpen &&
        <StatusMessage statusMessage={this.state.showNotification.statusMessage}
          status={this.state.showNotification.status}
          onCancel={() => this.setState({ showNotification: { isNotificationOpen: false, status: '', statusMessage: '' } })} />}
      </Layout>
    );
  }

  updateErrors (key, value) {
    const errors = this.state.errors;
    errors[key] = value;
    const hasErrors = _.keys(errors).some(key => errors[key] != null);
    this.setState({ errors, isSaveDisabled: hasErrors });
  }

  validateEmailAddresses (emails) {
    return (emails.length > 0) && emails.every(email => validateEmail(email));
  }

  handleEmailAddressesChange (emailAddresses) {
    const emails = _.uniq(emailAddresses.map(str => str.trim()));
    if (this.validateEmailAddresses(emails) || _.isEmpty(emails)) {
      const accountSettings = Object.assign({}, this.state.settings, { admin_email_addresses: emails });
      this.setState({ settings: accountSettings });
      this.updateErrors('email_addresses', null);
    } else {
      this.updateErrors('email_addresses', 'Please enter a unique and valid email address');
    }
  }

  handleValueChange (key, value) {
    const notificationSettings = Object.assign({}, this.state.settings, { [key]: value });
    this.setState({ settings: notificationSettings });
    this.updateErrors(key, null);
  }

  updateAccountEmailSettings () {
    updateNotificationSettings(this.state.settings)
      .then((response) => {
        this.setState({ showNotification: { isNotificationOpen: true, status: 'success', statusMessage: 'Email settings saved Successfully!' } });
        this.setState({ error: '', isSaveDisabled: true });
      },
      (errors) => { this.setState({ showNotification: { isNotificationOpen: true, status: 'error', statusMessage: errors.message } }); });
  }
}

export default AdminEmailNotifications;
