import React from 'react';
import { Logo, Button, Link } from '../library-components';
import { loginUser, signupUser, forgotPassword, resetPassword } from '../../api';
import UserForm from './user_form';
import ContentDescription from './content_description';
import SocialLogins from './social_logins';
import { Snackbar } from '@quintype/em/components/snackbar';
import { Chevron } from '@quintype/em/icons/chevron';
import { AnimatedSpinner } from '@quintype/em/icons/animated-spinner';
import { ROUTES } from '../../constants';
import '../ssoLogin/ssoLogin.scss';

function FormFootnote (props) {
  const { currentUserId, pageType } = props;
  if (pageType === 'create_user') {
    return <p className="foot--cta">Already have an account? <Link external={false} url={ROUTES.DASHBOARD}>Sign in</Link></p>;
  } else if (pageType === 'forgot-password') {
    return <p className="reset--cta"><Link external={false} url={ROUTES.DASHBOARD}><Chevron variant="left" /> Back to login</Link></p>;
  }
  return !currentUserId
    ? <p className="foot--cta">Don&apos;t have an account? <Link external={false} url="/create_user">Create Account</Link></p>
    : '';
}

class SsoLogin extends React.Component {
  handleSsoClick () {
    window.location.href = decodeURI(this.props.authUri);
  }

  handleAuthClick () {
    const params = {
      email: this.state.email,
      password: this.state.password
    };
    const valid = this.validate(['email', 'password']);
    if (!valid) {
      return;
    }
    loginUser(params).then(data => {
      if (data.user) {
        window.location.href = '/auth/bridgekeeper/callback';
      } else {
        this.setState({ error: data.message });
      }
    },
    e => {
      console.error(e);
      this.setState({ error: e });
    });
  }

  handleClick (e) {
    if (this.isSso()) {
      this.handleSsoClick();
    } else {
      this.handleAuthClick();
    }
  }

  handleForgotPassword () {
    const params = { email: this.state.email };
    const valid = this.validate(['email']);
    if (!valid) {
      return;
    }
    this.setState({ isLoading: true });
    forgotPassword(params).then(data => {
      if (data.message) {
        this.setState({ info: data.message + ' ' + params.email, error: '', isLoading: false, email: '' });
      }
    },
    e => {
      if (e.error) {
        this.setState({ error: e.error.message, isLoading: false, email: '' });
      } else {
        this.setState({ error: e.message, isLoading: false, email: '' });
      }
    });
  }

  handleResetPassword () {
    const params = {
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      token: this.props.token
    };
    const valid = this.validate(['password', 'confirmPassword']);
    if (!valid) {
      return;
    }
    this.setState({ isLoading: true });
    resetPassword(params).then(data => {
      if (data.message) {
        this.setState({ info: data.message, error: '', isLoading: false });
      }
    },
    e => {
      this.setState({ error: e.message, isLoading: false });
    });
  }

  showSnackBar () {
    if (this.props.pageType === 'forgot-password' || this.props.pageType === 'reset-password') {
      if (this.state.info !== '') {
        return <Snackbar notification={{ message: this.state.info, level: 'success', position: 'bc' }} resetNotification={() => this.resetSnackBarFn(this.state.info)}/>;
      } else if (this.state.error !== '') {
        return <Snackbar notification={{ message: this.state.error, level: 'error', position: 'bc' }} resetNotification={() => this.resetSnackBarFn(this.state.error)}/>;
      }
    }
  }

  resetSnackBarFn (message) {
    if (message === 'Password reset successful.') {
      this.setState({ info: '', error: '' });
      return window.open(ROUTES.DASHBOARD, '_self');
    } else {
      this.setState({ info: '', error: '' });
    }
  }

  showAnimatedSpinner () {
    if (this.state.isLoading) {
      return <div align='center'><AnimatedSpinner width="48" height="48" /></div>;
    } else {
      return '';
    }
  }

  getUsername () {
    return this.state.email ? this.state.email.split('@')[0] : '';
  }

  formatSignupError (message) {
    return message.replace(/body./g, '').replace(/"+\S/g, match => match.toUpperCase());
  }

  handleSignup (e) {
    const params = {
      email: this.state.email,
      name: this.state.name,
      password: this.state.password,
      username: this.getUsername()
    };
    const valid = this.validate(['name', 'email', 'password']);
    if (!valid) {
      return;
    }
    signupUser(params).then(data => {
      if (data.user) {
        this.setState({ info: 'User created successfully. Please login.', error: '' });
      } else {
        this.setState({ error: this.formatSignupError(data.message), info: '' });
      }
    },
    e => {
      console.error(e);
      this.setState({ error: this.formatSignupError(e), info: '' });
    });
  }

  constructor (props) {
    super(props);
    this.state = {
      email: '',
      name: '',
      password: '',
      emailError: '',
      passwordError: '',
      nameError: '',
      error: '',
      info: ''
    };
  }

  validate (keys) {
    const error = { error: '' };
    let valid = true;
    const me = this;
    keys.forEach(k => {
      if (!me.state[k]) {
        error[k + 'Error'] = 'Cannot be empty';
        valid = false;
      }
    });
    this.setState(error);
    return valid;
  }

  handleFormValueChange (key, newValue) {
    const newState = { error: '' };
    newState[key] = newValue;
    newState[key + 'Error'] = '';
    this.setState(newState);
  }

  isSso () {
    return this.props.authProvider === 'sso';
  }

  titleText () {
    if (this.props.pageType === 'create_user') {
      return 'Create account';
    } else if (this.props.pageType === 'forgot-password') {
      return 'Reset Password';
    } else if (this.props.pageType === 'reset-password') {
      return 'New Password';
    } else {
      return !this.props.currentUserId ? `Sign in` : `Sign out`;
    }
  }

  actionButton () {
    if (this.props.pageType === 'create_user') {
      return <Button customClass='signup-button' onClick={(e) => this.handleSignup(e)}> Create account </Button>;
    } else if (this.props.pageType === 'forgot-password') {
      return <Button customClass='signup-button' onClick={(e) => this.handleForgotPassword(e)}> Send </Button>;
    } else if (this.props.pageType === 'reset-password') {
      return <Button customClass='signup-button' onClick={(e) => this.handleResetPassword(e)}> Update </Button>;
    } else {
      return !this.props.currentUserId
        ? <Button customClass='signin-button' onClick={(e) => this.handleClick(e)}> Sign in </Button>
        : <Button customClass='signout-button' onClick={(e) => { window.location = '/logout'; }}> Sign out </Button>;
    }
  }

  render () {
    const showLoginOptions = !this.isSso();
    const showUserForm = showLoginOptions && (!this.props.currentUserId || this.props.pageType === 'reset-password');
    return (
      <div className='login-main-wrapper'>
        <div className='logo-wrapper'>
          <Logo
            bgColor={false}
          />
          <span className='logo-text'>Accesstype</span>
        </div>
        <div className={'wrapper' + (showLoginOptions ? '' : ' no-login-options')}>
          <div className='content-wrapper'>
            <div className='title'>
              <h2 className='title-text'>{this.titleText()}</h2>
            </div>
            <ContentDescription pageType={this.props.pageType} currentUserId={this.props.currentUserId} />
          </div>
          <div className="signin-wrapper">
            <div className='signin-fields'>
              {showUserForm &&
                <UserForm
                  pageType={this.props.pageType}
                  data={this.state}
                  onFormValueChange={(key, newValue) => this.handleFormValueChange(key, newValue)}
                />
              }
              {this.state.error && this.props.pageType !== 'forgot-password' && this.props.pageType !== 'reset-password' &&
                <p className="message-error">{this.state.error}</p>}
              {this.state.info && this.props.pageType !== 'forgot-password' && this.props.pageType !== 'reset-password' &&
                <p className="message-info">{this.state.info}</p>}
              <div className='button-wrapper'>{this.actionButton()}</div>
              {!this.props.currentUserId && showLoginOptions && this.props.pageType !== 'forgot-password' && this.props.pageType !== 'reset-password'
                ? <div>
                  <div className='social-divider'>
                    <div className='social-divider--line' />
                    <span>OR</span>
                    <div className='social-divider--line' />
                  </div>
                  <SocialLogins authUri={this.props.authUri} />
                  <FormFootnote pageType={this.props.pageType} currentUserId={this.props.currentUserId} />
                </div>
                : ''
              }
              {
                this.props.pageType === 'forgot-password' &&
                <FormFootnote pageType={this.props.pageType} currentUserId={this.props.currentUserId} />
              }
              {this.showAnimatedSpinner()}
            </div>
          </div>
          {this.showSnackBar()}
        </div>
      </div>
    );
  }
}

export default SsoLogin;
