import React from 'react';
import PropTypes from 'prop-types';

const INR = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g id='Symbols' fill='none' fillRule='evenodd'>
        <g id='Icons/Flags/India' fillRule='nonzero'>
          <g id='india' transform='translate(0 4)'>
            <path d='M23.9532632,5.3377619 L0,5.3377619 L0,0.43352381 C0,0.201380952 0.184935673,0.0131428571 0.413005848,0.0131428571 L23.5402573,0.0131428571 C23.7683275,0.0131428571 23.9532632,0.201380952 23.9532632,0.43352381 L23.9532632,5.3377619 Z'
              id='Shape' fill='#FAB446' />
            <path d='M23.5402105,15.9869048 L0.413005848,15.9869048 C0.184935673,15.9869048 0,15.7986667 0,15.5665238 L0,10.6623333 L23.9532164,10.6623333 L23.9532164,15.5665238 C23.9532164,15.7987143 23.7682807,15.9869048 23.5402105,15.9869048 Z'
              id='Shape' fill='#73AF00' />
            <rect id='Rectangle-path' fill='#F5F5F5' y='5.338' width='23.953' height='5.324'
            />
            <g id='Group' transform='translate(9.731 5.714)' fill='#41479B'>
              <path d='M2.24561404,4.54519048 C1.02156725,4.54519048 0.0258245614,3.53166667 0.0258245614,2.2857619 C0.0258245614,1.03985714 1.02156725,0.0263333333 2.24561404,0.0263333333 C3.46966082,0.0263333333 4.46540351,1.03985714 4.46540351,2.2857619 C4.46540351,3.53166667 3.46966082,4.54519048 2.24561404,4.54519048 Z M2.24561404,0.446666667 C1.24921637,0.446666667 0.438783626,1.27157143 0.438783626,2.2857619 C0.438783626,3.29995238 1.24921637,4.12485714 2.24561404,4.12485714 C3.2420117,4.12485714 4.05244444,3.29995238 4.05244444,2.2857619 C4.05244444,1.27157143 3.2420117,0.446666667 2.24561404,0.446666667 Z'
                id='Shape' />
              <ellipse id='Oval' cx='2.246' cy='2.286' rx='1' ry='1' />
              <polygon id='Shape' points='2.21894737 2.31290476 1.72107602 1.89914286 0.795321637 0.863904762 0.848701754 0.809571429 1.86577778 1.75185714 2.2722807 2.25861905'
              />
              <polygon id='Shape' points='2.21894737 2.31290476 2.62545029 2.81966667 3.64252632 3.76195238 3.69590643 3.70761905 2.77015205 2.67238095 2.2722807 2.25861905'
              />
              <polygon id='Shape' points='2.21894737 2.25861905 2.62545029 1.75185714 3.64252632 0.809571429 3.69590643 0.863904762 2.77015205 1.89914286 2.2722807 2.31290476'
              />
              <polygon id='Shape' points='2.21894737 2.25861905 1.72107602 2.67238095 0.795321637 3.70761905 0.848701754 3.76195238 1.86577778 2.81966667 2.2722807 2.31290476'
              />
              <polygon id='Shape' points='2.26007018 2.32128571 1.69389474 2.63109524 0.400046784 3.10547619 0.371134503 3.03447619 1.61562573 2.43866667 2.23115789 2.2502381'
              />
              <polygon id='Shape' points='2.26007018 2.32128571 2.87560234 2.13285714 4.12009357 1.53704762 4.09118129 1.46604762 2.79733333 1.94042857 2.23115789 2.2502381'
              />
              <polygon id='Shape' points='2.21071345 2.30047619 1.90633918 1.72419048 1.4402807 0.407238095 1.51003509 0.377809524 2.09539181 1.64452381 2.28051462 2.27104762'
              />
              <polygon id='Shape' points='2.21071345 2.30047619 2.39583626 2.927 2.98119298 4.19371429 3.05094737 4.16428571 2.58488889 2.84733333 2.28051462 2.27104762'
              />
              <polygon id='Shape' points='2.23115789 2.32128571 1.61562573 2.13285714 0.371134503 1.53704762 0.400046784 1.46604762 1.69389474 1.94042857 2.26007018 2.2502381'
              />
              <polygon id='Shape' points='2.23115789 2.32128571 2.79733333 2.63109524 4.09118129 3.10547619 4.12009357 3.03447619 2.87560234 2.43866667 2.26007018 2.2502381'
              />
              <polygon id='Shape' transform='rotate(-24 3.277 2.28)' points='2.33252639 1.92263996 2.89870183 2.23244949 4.19254978 2.70683044 4.22146206 2.63583044 2.97697083 2.04002091 2.36143867 1.85159234'
              />
              <polygon id='Shape' transform='rotate(-112 2.273 1.244)' points='1.32865567 0.887137278 1.89483111 1.1969468 3.18867906 1.67132775 3.21759134 1.60032775 1.97310011 1.00451823 1.35756795 0.816089659'
              />
              <polygon id='Shape-Copy' transform='rotate(155 1.071 2.31)' points='0.126891738 1.95397086 0.693067177 2.26378039 1.98691513 2.73816134 2.01582741 2.66716134 0.771336182 2.07135181 0.155804019 1.88292324'
              />
              <polygon id='Shape' points='2.21071345 2.27104762 2.39583626 1.64452381 2.98119298 0.377809524 3.05094737 0.407238095 2.58488889 1.72419048 2.28051462 2.30047619'
              />
              <polygon id='Shape' points='2.21071345 2.27104762 1.90633918 2.84733333 1.4402807 4.16428571 1.51003509 4.19371429 2.09539181 2.927 2.28051462 2.30047619'
              />
              <polygon id='Shape' transform='rotate(-21 2.231 3.307)' points='2.58132579 2.34571884 2.27695152 2.92200455 1.81089304 4.23895693 1.88064743 4.26838551 2.46600415 3.00167122 2.65112696 2.37514741'
              />
            </g>
            <ellipse id='Oval' fill='#F5F5F5' cx='11.977' cy='8' rx='1' ry='1' />
          </g>
        </g>
      </g>
    </svg>
  );
};

INR.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
};

INR.defaultProps = {
  width: '20',
  height: '20'
};

export default INR;
