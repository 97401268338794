import React from 'react';
import { Chart } from 'react-google-charts';

const AreaChart = ({
  width,
  height,
  loader,
  values,
  colors,
  pointSize,
  chartTitle,
  hAxisLabel,
  vAxisLabel,
  isStacked = '',
  hAxisGridColor = 'transparent',
  htmlTooltip = false,
  hAxisFormat = '',
  vAxisFormat = '',
  chartEvents,
  chartType = 'AreaChart'
}) => {
  const textStyle = {
    color: '#757575',
    fontSize: 11,
    fontName: 'sans-serif',
    italic: false
  };
  const titleTextStyle = {
    color: '#132437',
    fontSize: 12,
    fontName: 'sans-serif',
    italic: false
  };
  return (
    <Chart
      width={width}
      height={height}
      chartType={chartType}
      loader={loader}
      data={values}
      chartEvents={chartEvents}

      options={{
        tooltip: {
          textStyle: titleTextStyle
        },
        crosshair: {
          orientation: 'vertical',
          trigger: 'focus',
          color: '#4862c5',
          opacity: 0.05
        },
        isStacked: isStacked,
        legend: 'none',
        colors: colors,
        pointSize: pointSize,
        chart: {
          title: chartTitle
        },
        focusTarget: 'category',
        chartArea: {
          left: 80,
          top: 40,
          bottom: 70,
          right: 60,
          width: `${width}`,
          height: `${height}`
        },
        hAxis: {
          title: `${hAxisLabel}`,
          format: `${hAxisFormat}`,
          gridlines: {
            color: `${hAxisGridColor}`
          },
          textStyle: textStyle,
          titleTextStyle: titleTextStyle
        },
        vAxis: {
          title: `${vAxisLabel}`,
          format: `${vAxisFormat}`,
          gridlines: {
            color: `#e0e0e0`
          },
          textStyle: textStyle,
          titleTextStyle: titleTextStyle
        }
      }}
    />
  );
};

export default AreaChart;
