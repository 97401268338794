import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash-es';

import { getSubscriptionAttempts, getSubscriptionAttemptsCsv, getSubscriptionAttemptsReports, getDownloadUrl } from '../api';
import { Button, TitleBar, Card, Layout, Link, StatusMessage, Table, Pagination, Description } from '../components/library-components';
import { getDateFromISO } from '../helpers/dateUtils';
import { DATE } from '../constants';
import PhoneIdentity from '../components/subscriber-identity/phone-identity';

import '../../stylesheets/pages/subscriptions.scss';

export class PayPerAssetAttempts extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      payPerAssetGroupId: this.props.payPerAssetGroupId,
      total_pages: 1,
      total_entries: 0,
      showNotification: { isNotificationOpen: false, status: '', statusMessage: '' },
      isDownloadDisabled: false
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.updateSubscriptionsReport = this.updateSubscriptionsReport.bind(this);
  }

  onPageChange (page) {
    this.loadPayPerAssetAttempts(page);
  }

  updateSubscriptionsReport (data) {
    this.loadPayPerAssetReports();
    this.setState({ showNotification: { isNotificationOpen: true, status: data.description.text.status, statusMessage: data.description.text.message } });
  }

  setupChannelSubscription () {
    this.props.cableApp.notify_user = this.props.cableApp.cable.subscriptions.create('NotifyUsersChannel', {
      connected: function () {
        // Called when the subscription is ready for use on the server
      },

      disconnected: function () {
        // Called when the subscription has been terminated by the server
      },

      received: function (data) {
        // Called when there's incoming data on the websocket for this channel
        this.updateSubscriptionsReport(data);
      },

      updateSubscriptionsReport: this.updateSubscriptionsReport
    });
  }

  render () {
    return (
      <Layout>
        <TitleBar title='Pay Per Asset Attempts' />

        <Card>
          <div className='Subscription-group-list'>
            <div />
            <Description>

              {_.get(this.state.subscriptionsReports, ['0', 'status']) === 'generated' && this.state.subscriptionsReports[0].url && !this.state.subscriptionsReports[0].filename &&
                <Link iconLeft='download-arrow' url={this.state.subscriptionsReports[0].url}>
                  {this.state.subscriptionsReports[0] != null &&
                    this.state.subscriptionsReports[0]['status'] === 'generated' &&
                    getDateFromISO(_.get(this.state.subscriptionsReports, ['0', 'created_at']), DATE.DATE_SEC_FMT)}
                </Link>
              }

              {_.get(this.state.subscriptionsReports, ['0', 'status']) === 'generated' && this.state.subscriptionsReports[0].filename &&
                <Link iconLeft='download-arrow' onClick={() => this.downloadReport(this.state.subscriptionsReports[0].filename)}>
                  {this.state.subscriptionsReports[0] != null &&
                    this.state.subscriptionsReports[0]['status'] === 'generated' &&
                    getDateFromISO(_.get(this.state.subscriptionsReports, ['0', 'created_at']), DATE.DATE_SEC_FMT)}
                </Link>
              }

              <Button disabled={this.state.isDownloadDisabled} onClick={(e) => { e.preventDefault(); this.generateReport(); }}>Generate CSV</Button>

            </Description>
          </div>
          {this.state.payPerAssetAttempts && <Table header={['Identity', 'Date', 'Status']} className='table-default--4cols'>
            {this.state.payPerAssetAttempts.map((payPerAssetAttempt, index) =>
              <div key={index} className='table-default__row'>
                <div className='table-default__cell truncate-word'>
                  {payPerAssetAttempt.preferred_identity.value}
                  {payPerAssetAttempt.preferred_identity.provider !== 'phone_number' && < PhoneIdentity phoneNum={payPerAssetAttempt.subscriber_phone_number} />}
                </div>
                <div className='table-default__cell'>{_.replace(payPerAssetAttempt.updated_at, 'T', ' ')}</div>
                <div className='table-default__cell'>{payPerAssetAttempt.status}</div>
              </div>
            )}
          </Table> }

          <Pagination
            onPageChange={this.onPageChange}
            pageCount={this.state.total_pages}
            containerClassName='pagination'
            pageRangeDisplayed={0}
            marginPagesDisplayed={1}
            pageClassName='pagination__item'
            breakClassName='pagination__item'
            breakLabel='...'
            totalEnteries={`${this.state.total_entries} rows`}
          />

        </Card>

        {this.state.showNotification.isNotificationOpen &&
          <StatusMessage statusMessage={this.state.showNotification.statusMessage}
            status={this.state.showNotification.status}
            onCancel={() => this.setState({ showNotification: { isNotificationOpen: false, status: '', statusMessage: '' } })} />}
      </Layout>
    );
  }

  loadPayPerAssetAttempts (pageNum = 1) {
    getSubscriptionAttempts(this.state.payPerAssetGroupId, pageNum, 'dynamic_asset').then(resp => {
      this.setState({
        total_pages: resp.total_pages,
        total_entries: resp.total_entries,
        payPerAssetAttempts: resp.subscription_attempts
      });
    });
  }

  loadPayPerAssetReports () {
    getSubscriptionAttemptsReports(this.state.payPerAssetGroupId).then(subscriptionsReports => {
      this.setState({
        subscriptionsReports: subscriptionsReports
      });
      if (_.get(subscriptionsReports, ['0', 'status']) === 'in_progress') {
        this.setState({ isDownloadDisabled: true });
      } else {
        this.setState({ isDownloadDisabled: false });
      }
    });
  }

  componentDidMount () {
    this.loadPayPerAssetAttempts();
    this.loadPayPerAssetReports();
    this.setupChannelSubscription();
  }

  generateReport () {
    getSubscriptionAttemptsCsv(this.state.payPerAssetGroupId).then((message) => {
      this.setState({ showNotification: { isNotificationOpen: true, status: 'success', statusMessage: message }, isDownloadDisabled: true });
      this.setState({
        subscriptionsReports: []
      });
    });
  }

  downloadReport (filename) {
    getDownloadUrl(filename).then(downloadUrl => (window.location.href = downloadUrl));
  }

  isDownloadDisabled () {
    return _.get(this.state.subscriptionsReports, ['0', 'status']) === 'in_progress';
  }
}

function mapStateToProps (state) {
  return {};
}

function mapDispatchToProps (dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PayPerAssetAttempts);
