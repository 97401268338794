import React from 'react';
import SelectField from '../components/library-components/SelectField/SelectField';
import '../../stylesheets/components/currency-filter-style';

class CurrencyFilter extends React.Component {
  render () {
    return (
      <div className='currency-filter' data-test={'currency-filter'}>
        <div className='chart-footer-filter__curency'>
          <h4 className='currency-heading'>Currency</h4>
          <SelectField
            options={this.props.options}
            clearable={false}
            className='select-currency-filter'
            placeholder='Currency'
            searchable={false}
            valueKey='value'
            labelKey='label'
            value={this.props.value}
            onChange={this.props.changeCurrency}
          />
        </div>
      </div>
    );
  }
}

export default CurrencyFilter;
