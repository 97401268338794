import React from 'react';

const withToggle = (Component) => {
  class Enhanced extends React.Component {
    constructor (props) {
      super(props);
      this.state = {
        isToggled: this.props.isToggled || false
      };
    }

    componentWillreceiveProps (nextProps) {
      if (nextProps.isToggled !== this.props.isToggled) {
        this.setState({ isToggled: nextProps.isToggled });
      }
    }

    toggle (e) {
      e.stopPropagation();
      this.setState({ isToggled: !this.state.isToggled });
    }

    render () {
      return (<Component {...this.props} isToggled={this.state.isToggled} toggle={(e) => this.toggle(e)} />);
    }
  }

  return Enhanced;
};

export default withToggle;
