import React from 'react';
import currencyUtils, {
  ALL_CURRENCIES,
  getOption
} from '../helpers/currencyUtils';
import { NumberFieldWithLabel } from './library-components';
import Select from 'react-select';
import Trash from '../pages/Icons/trash';

function getPriceError (obj, type = 'amount_cents') {
  const error = obj.error;
  const errorMessage = [];
  if (error[type]) {
    errorMessage.push(error[type][0]);
  }
  if (error.currency) {
    errorMessage.push(error.currency[0]);
  }

  return errorMessage.join(' & ');
}

function getCurrencyOptions (
  multiCurrencyEnabled,
  subscriptionGroupType,
  currentCurrency,
  allCurrencies,
  subscriptionGroupCurrency
) {
  const excludedCurrencies = multiCurrencyEnabled
    ? allCurrencies.filter((c) => c !== currentCurrency)
    : [];
  if (multiCurrencyEnabled) {
    return currencyUtils.getRemainingCurrencies(excludedCurrencies);
  }
  if (subscriptionGroupType === 'campaign') {
    return getOption(subscriptionGroupCurrency);
  }
  return ALL_CURRENCIES;
}

function PriceSelectorForCurrency ({
  type,
  currency,
  multiCurrencyEnabled,
  onPriceItemUpdate,
  subscriptionGroupType,
  subscriptionGroupCurrency,
  priceErrors,
  onDeleteClick,
  subscriptionPlan,
  fallbackCurrency
}) {
  const prices = subscriptionPlan.prices;
  const currencies = Object.keys(subscriptionPlan.prices || []);

  const currencyOptions = getCurrencyOptions(
    multiCurrencyEnabled,
    subscriptionGroupType,
    currency,
    currencies,
    subscriptionGroupCurrency
  );

  const price = prices[currency];
  const currentOption = getOption(currency)[0];
  const showDeleteIcon = currencies.length > 1;
  const isFallbackCurrency = fallbackCurrency === currency;

  const errorsPresent = priceErrors
    ? !!priceErrors[0].errors[currency]
    : false;

  const error = errorsPresent ? type === 'paid-trial' ? getPriceError(priceErrors[0].errors[currency], 'paid_trial_amount_cents') : getPriceError(priceErrors[0].errors[currency]) : '';

  return (
    <div
      className={`sub_plan__price-container ${type === 'paid-trial' ? 'trial-price-container' : ''} ${isFallbackCurrency ? 'sub_plan__price-container--highlighted' : ''
      } `}
      key={price.id}
    >
      <div className="text-and-select">
        <NumberFieldWithLabel
          label="price"
          value={Number(
            currencyUtils.convertToCurrencyUnits(type === 'paid-trial' ? price.paid_trial_amount_cents : price.amount_cents)
          )}
          onChange={(e) =>
            onPriceItemUpdate(price.id, type === 'paid-trial' ? 'paid_trial_amount' : 'amount', e.target.value)
          }
          errors={error}
          showErrorMessage={false}
          placeholder={type === 'paid-trial' ? 'Enter trial price' : 'Enter price'}
          dataTest={type === 'paid-trial' ? 'subscription-trial-price' : 'subscription-plan-price'}
        />
        <Select
          className={`select-currency ${error ? 'sub_plan_select-error--highlight' : ''
          }`}
          value={currentOption}
          options={currencyOptions}
          onChange={(m) => m && onPriceItemUpdate(price.id, 'currency', m.value)}
          clearable={false}
          disabled={isFallbackCurrency}
          searchable
          arrowRenderer={currencyUtils.arrowRenderer}
          autosize={false}
        />
        {showDeleteIcon && !isFallbackCurrency && (
          <div
            className="sub_plan__delete_icon"
            onClick={() => onDeleteClick(price.id)}
          >
            <Trash />
          </div>
        )}
      </div>
      {error && (
        <div className="sub_plan__price-error-message">
          {error}
        </div>
      )}
    </div>
  );
}

function PriceSelector ({
  type,
  multiCurrencyEnabled,
  onPriceItemUpdate,
  subscriptionGroupCurrency,
  subscriptionGroupType,
  priceErrors,
  onDeleteClick,
  subscriptionPlan,
  fallbackCurrency
}) {
  const currencies = Object.keys(subscriptionPlan.prices || []);
  return <>{currencies.map((currency) => <PriceSelectorForCurrency
    key={currency}
    type={type}
    currency={currency}
    multiCurrencyEnabled={multiCurrencyEnabled}
    onPriceItemUpdate={onPriceItemUpdate}
    subscriptionGroupCurrency={subscriptionGroupCurrency}
    subscriptionGroupType={subscriptionGroupType}
    priceErrors={priceErrors}
    onDeleteClick={onDeleteClick}
    subscriptionPlan={subscriptionPlan}
    fallbackCurrency={fallbackCurrency}
  />)}</>;
}

export default PriceSelector;
