import React, { useEffect, useState } from 'react';
import SubscriptionsDueToRenew from './subscriptions_due_renew';
import {
  TitleBar,
  Layout,
  SelectFieldWithLabel,
  StatusMessage
} from '../../components/library-components';
import './index.scss';
import SummaryDataGraph from './summary_data_graph';
import DataCardsContainer from './data_cards_container';
import TopPlans from './top_plans';
import CurrencyFilter from '../../components/currency-filter';
import { getUniqueCurrencies } from '../../api';

function DashBoard (props) {
  const [intervalDuration, setIntervalDuration] = useState(7);
  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [notification, setNotification] = useState({
    isNotificationOpen: false,
    status: '',
    statusMessage: ''
  });
  useEffect(() => {
    const toDate = new Date();
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - intervalDuration);
    getUniqueCurrencies(fromDate, toDate).then((data) => {
      setCurrencies(data);
      setSelectedCurrency(data[0]);
    });
  }, [intervalDuration]);
  const intervalUnit = 'days';

  const handleDownloadClick = (message, success) => {
    setNotification(
      {
        isNotificationOpen: true,
        status: success ? 'success' : 'error',
        statusMessage: message
      }
    );
  };
  return (
    <div className="dash-anlys__container">
      <Layout>
        <TitleBar title="Dashboard">
          <CurrencyFilter
            options={currencies.map((currency) => ({
              value: currency,
              label: currency
            }))}
            value={selectedCurrency}
            changeCurrency={(c) => setSelectedCurrency(c.value)}
          />
          <SelectFieldWithLabel
            options={[
              { label: 'Last 7 days', value: 7 },
              { label: 'Last 15 days', value: 15 },
              { label: 'Last 30 days', value: 30 },
              { label: 'Last 60 days', value: 60 },
              { label: 'Last 90 days', value: 90 }
            ]}
            value={intervalDuration}
            onChange={(e) => setIntervalDuration(e.value)}
            clearable={false}
            searchable={false}
            className="select-interval"
            valueKey="value"
            label={<h4>Interval</h4>}
          />
        </TitleBar>
        <DataCardsContainer
          intervalDuration={intervalDuration}
          intervalUnit={intervalUnit}
          currency={selectedCurrency}
        />
        <div className="dash-grid-container">
          <SummaryDataGraph
            dataApiPath="daily_subscribers"
            dataTypeTitles={{
              active_subscribers: 'Active',
              inactive_subscribers: 'Inactive',
              new_subscribers: 'New',
              total_subscribers: 'Total'
            }}
            intervalDuration={intervalDuration}
            intervalUnit={intervalUnit}
            title="Subscribers"
            defaultOptions={['active_subscribers']}
          />
          <SummaryDataGraph
            dataApiPath="daily_revenue"
            dataTypeTitles={{
              onetime: 'Onetime',
              recurring: 'Recurring',
              revenue: 'Total'
            }}
            intervalDuration={intervalDuration}
            intervalUnit={intervalUnit}
            title="Revenue"
            currency={selectedCurrency}
            defaultOptions={['revenue']}
          />
          <SummaryDataGraph
            dataApiPath="daily_subscriptions"
            dataTypeTitles={{
              active_subscriptions: 'Active',
              expired_subscriptions: 'Expired'
            }}
            intervalDuration={intervalDuration}
            intervalUnit={intervalUnit}
            title="Subscriptions"
            defaultOptions={['active_subscriptions']}
          />
          <SubscriptionsDueToRenew
            cableApp={props.cableApp}
            onDownloadClick={handleDownloadClick}
          />
        </div>
        <TopPlans
          intervalDuration={intervalDuration}
          intervalUnit={intervalUnit}
          currency={selectedCurrency}
        />
        {notification.isNotificationOpen && (
          <StatusMessage
            statusMessage={notification.statusMessage}
            status={notification.status}
            onCancel={() =>
              setNotification({
                isNotificationOpen: false,
                status: '',
                statusMessage: ''
              })
            }
          />
        )}
      </Layout>
    </div>
  );
}

export default DashBoard;
