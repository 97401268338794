import React, { useEffect, useState } from 'react';
import { getAnalyticalReportsData } from '../../api';
import AreaChart from '../../components/library-components/chart/area_chart';
import OptionsFilter from '../../components/options-filter';
import chartEvents from '../../helpers/chartUtils';
import { getRandomColor } from '../../helpers/colorUtils';
import { getLastUpdatedString } from '../../helpers/dateUtils';

import './summary_data_graph.scss';

function summaryDataToChartData (
  summaryData,
  dataTypeTitles,
  selectedDataTypes
) {
  const dates = Object.keys(summaryData);
  const chartData = [
    [
      { label: 'Date', type: 'date' },
      ...selectedDataTypes.map((type) => dataTypeTitles[type])
    ]
  ];
  dates.forEach((date) => {
    chartData.push([
      new Date(date),
      ...selectedDataTypes.map((type) => summaryData[date][type] || 0)
    ]);
  });
  return chartData;
}

function getDataColors (dataTypeKeys) {
  return dataTypeKeys.reduce((dataColors, dataType) => {
    return { ...dataColors, [dataType]: getRandomColor() };
  }, {});
}

function getChartFilterOptions (dataTypeTitles, dataTypeColors) {
  return Object.keys(dataTypeTitles).reduce((dataColors, dataType) => {
    dataColors[dataTypeTitles[dataType]] = {
      color: dataTypeColors[dataType],
      key: dataType
    };
    return dataColors;
  }, {});
}

function getCurrentChartOptions (chartOptions, selectedDataTypes) {
  const currentOptions = {};
  Object.keys(chartOptions).forEach((option) => {
    if (selectedDataTypes.includes(chartOptions[option].key)) {
      currentOptions[option] = chartOptions[option];
    }
  });
  return currentOptions;
}

function SummaryDataGraph ({
  title,
  dataApiPath,
  dataTypeTitles = {},
  intervalDuration,
  intervalUnit,
  currency,
  defaultOptions = []
}) {
  const [summaryData, setSummaryData] = useState([]);
  const [selectedDataTypes, setSelectedDataTypes] = useState(defaultOptions);
  const [dataTypeColors, setDataTypeColors] = useState({});
  const [lastUpdateSeconds, setLastUpdateSeconds] = useState(0);

  useEffect(() => {
    if (dataApiPath && dataApiPath !== '') {
      getAnalyticalReportsData(dataApiPath, {
        interval_duration: intervalDuration,
        interval_unit: intervalUnit,
        currency: currency
      }).then((res) => {
        setSummaryData(res.data);
        setLastUpdateSeconds(
          parseInt(
            (new Date().getTime() - new Date(res.last_updated_at).getTime()) /
              1000
          )
        );
      });
    }
  }, [dataApiPath, intervalDuration, intervalUnit, currency]);

  useEffect(() => {
    setDataTypeColors(getDataColors(Object.keys(dataTypeTitles)));
  }, [dataTypeTitles]);

  const chartData = summaryDataToChartData(
    summaryData,
    dataTypeTitles,
    selectedDataTypes
  );
  const chartOptions = getChartFilterOptions(dataTypeTitles, dataTypeColors);
  const chartColors = chartData[0]
    .slice(1)
    .map((title) => chartOptions[title]['color']);

  const handleOptionClick = (e, option) => {
    const optionType = chartOptions[option].key;
    setSelectedDataTypes(
      selectedDataTypes.includes(optionType)
        ? selectedDataTypes.filter((type) => type !== optionType)
        : [...selectedDataTypes, optionType]
    );
  };

  const handleSelectAllOptions = () => {
    setSelectedDataTypes(Object.keys(dataTypeTitles));
  };

  const handleDeselectAllOptions = () => {
    setSelectedDataTypes([]);
  };

  return (
    <div className="sdg__container">
      <div className="sdg__header_container">
        <h3 className="sdg__title">{title}</h3>
        <p className="sdg__last_updated_message">
          {getLastUpdatedString(lastUpdateSeconds)}
        </p>
      </div>
      <div className="sdg__content_container">
        <div className="sdg__chart_container">
          {selectedDataTypes.length === 0 && (
            <p className="analytical-graph-wrap__no-data-message">
              No data to display
            </p>
          )}
          {selectedDataTypes.length > 0 && (
            <AreaChart
              height={'100%'}
              width={'100%'}
              loader={<div className="loader-custom" />}
              values={chartData}
              legendPosition="top"
              pointSize={0}
              seriesAxis0="Count"
              yAxisLabel="Count"
              yAxisKey="Count"
              hAxisLabel=" "
              vAxisLabel=" "
              hAxisFormat={'dd/MM'}
              vAxisFormat={'short'}
              isStacked
              chartEvents={chartEvents}
              chartType="LineChart"
              colors={chartColors}
            />
          )}
        </div>

        <OptionsFilter
          options={chartOptions}
          currentOptions={getCurrentChartOptions(
            chartOptions,
            selectedDataTypes
          )}
          clickCheckBox={handleOptionClick}
          selectAll={handleSelectAllOptions}
          deselectAll={handleDeselectAllOptions}
        />
      </div>
    </div>
  );
}

export default SummaryDataGraph;
