import React from 'react';

import USA from '../components/library-components/Icons/USA';
import INR from '../components/library-components/Icons/INR';
import UpArrow from '../components/library-components/Icons/UpArrow';
import DownArrow from '../components/library-components/Icons/DownArrow';

const convertToCurrencyUnits = (input) => {
  return (input / 100).toFixed(2).toString();
};

const convertToLowestDenomination = (input) => {
  return parseInt(parseFloat(input).toFixed(2) * 100);
};

const arrowRenderer = ({ isOpen }) => {
  return isOpen ? <UpArrow width='16' height='16' color='#818A98' /> : <DownArrow width='16' height='16' color='#818A98' />;
};

const getAllOptions = (option) => {
  if (option.label === 'USD') {
    return <span className='select-currency__option-with-icon'> <USA /> {option.label} </span>;
  } else {
    return <span className='select-currency__option-with-icon'> <INR /> {option.label} </span>;
  }
};

const renderSelectedCurrency = (option) => {
  return getAllOptions(option);
};

const renderCurrencyOptions = (option) => {
  return getAllOptions(option);
};

export const getOption = option => {
  return new Array(ALL_CURRENCIES.find(currency => currency.value === option));
};

const convertToCurrencyWithSymbol = (cents, currency) => {
  const amount = convertToCurrencyUnits(cents);
  return Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(amount);
};

const getCurrencySymbol = (currency) => {
  return Intl.NumberFormat('en-US', { style: 'currency', currency: currency })
    .formatToParts(0)
    .filter(part => part.type === 'currency')[0].value;
};

export const ALL_CURRENCIES = [
  { value: 'INR', label: 'INR - Indian Rupee' },
  { value: 'USD', label: 'USD - US Dollar' },
  { value: 'AED', label: 'AED - United Arab Emirates Dirham' },
  { value: 'AFN', label: 'AFN - Afghan Afghani' },
  { value: 'ALL', label: 'ALL - Albanian Lek' },
  { value: 'AMD', label: 'AMD - Armenian Dram' },
  { value: 'ANG', label: 'ANG - Netherlands Antillean Guilder' },
  { value: 'AOA', label: 'AOA - Angolan Kwanza' },
  { value: 'ARS', label: 'ARS - Argentine Peso' },
  { value: 'AUD', label: 'AUD - Australian Dollar' },
  { value: 'AWG', label: 'AWG - Aruban Florin' },
  { value: 'AZN', label: 'AZN - Azerbaijani Manat' },
  { value: 'BAM', label: 'BAM - Bosnia-Herzegovina Convertible Mark' },
  { value: 'BBD', label: 'BBD - Barbadian Dollar' },
  { value: 'BDT', label: 'BDT - Bangladeshi Taka' },
  { value: 'BGN', label: 'BGN - Bulgarian Lev' },
  { value: 'BIF', label: 'BIF - Burundian Franc' },
  { value: 'BMD', label: 'BMD - Bermudan Dollar' },
  { value: 'BND', label: 'BND - Brunei Dollar' },
  { value: 'BOB', label: 'BOB - Bolivian Boliviano' },
  { value: 'BRL', label: 'BRL - Brazilian Real' },
  { value: 'BSD', label: 'BSD - Bahamian Dollar' },
  { value: 'BWP', label: 'BWP - Botswanan Pula' },
  { value: 'BZD', label: 'BZD - Belize Dollar' },
  { value: 'CAD', label: 'CAD - Canadian Dollar' },
  { value: 'CDF', label: 'CDF - Congolese Franc' },
  { value: 'CHF', label: 'CHF - Swiss Franc' },
  { value: 'CLP', label: 'CLP - Chilean Peso' },
  { value: 'CNY', label: 'CNY - Chinese Yuan' },
  { value: 'COP', label: 'COP - Colombian Peso' },
  { value: 'CRC', label: 'CRC - Costa Rican Colón' },
  { value: 'CVE', label: 'CVE - Cape Verdean Escudo' },
  { value: 'CZK', label: 'CZK - Czech Koruna' },
  { value: 'DJF', label: 'DJF - Djiboutian Franc' },
  { value: 'DKK', label: 'DKK - Danish Krone' },
  { value: 'DOP', label: 'DOP - Dominican Peso' },
  { value: 'DZD', label: 'DZD - Algerian Dinar' },
  { value: 'EGP', label: 'EGP - Egyptian Pound' },
  { value: 'ETB', label: 'ETB - Ethiopian Birr' },
  { value: 'EUR', label: 'EUR - Euro' },
  { value: 'FJD', label: 'FJD - Fijian Dollar' },
  { value: 'FKP', label: 'FKP - Falkland Islands Pound' },
  { value: 'GBP', label: 'GBP - British Pound' },
  { value: 'GEL', label: 'GEL - Georgian Lari' },
  { value: 'GIP', label: 'GIP - Gibraltar Pound' },
  { value: 'GMD', label: 'GMD - Gambian Dalasi' },
  { value: 'GNF', label: 'GNF - Guinean Franc' },
  { value: 'GTQ', label: 'GTQ - Guatemalan Quetzal' },
  { value: 'GYD', label: 'GYD - Guyanaese Dollar' },
  { value: 'HKD', label: 'HKD - Hong Kong Dollar' },
  { value: 'HNL', label: 'HNL - Honduran Lempira' },
  { value: 'HRK', label: 'HRK - Croatian Kuna' },
  { value: 'HTG', label: 'HTG - Haitian Gourde' },
  { value: 'HUF', label: 'HUF - Hungarian Forint' },
  { value: 'IDR', label: 'IDR - Indonesian Rupiah' },
  { value: 'ILS', label: 'ILS - Israeli New Shekel' },
  { value: 'ISK', label: 'ISK - Icelandic Króna' },
  { value: 'JMD', label: 'JMD - Jamaican Dollar' },
  { value: 'JPY', label: 'JPY - Japanese Yen' },
  { value: 'KES', label: 'KES - Kenyan Shilling' },
  { value: 'KGS', label: 'KGS - Kyrgystani Som' },
  { value: 'KHR', label: 'KHR - Cambodian Riel' },
  { value: 'KMF', label: 'KMF - Comorian Franc' },
  { value: 'KRW', label: 'KRW - South Korean Won' },
  { value: 'KYD', label: 'KYD - Cayman Islands Dollar' },
  { value: 'KZT', label: 'KZT - Kazakhstani Tenge' },
  { value: 'LAK', label: 'LAK - Laotian Kip' },
  { value: 'LBP', label: 'LBP - Lebanese Pound' },
  { value: 'LKR', label: 'LKR - Sri Lankan Rupee' },
  { value: 'LRD', label: 'LRD - Liberian Dollar' },
  { value: 'LSL', label: 'LSL - Lesotho Loti' },
  { value: 'MAD', label: 'MAD - Moroccan Dirham' },
  { value: 'MDL', label: 'MDL - Moldovan Leu' },
  { value: 'MGA', label: 'MGA - Malagasy Ariary' },
  { value: 'MKD', label: 'MKD - Macedonian Denar' },
  { value: 'MMK', label: 'MMK - Myanmar Kyat' },
  { value: 'MNT', label: 'MNT - Mongolian Tugrik' },
  { value: 'MOP', label: 'MOP - Macanese Pataca' },
  { value: 'MRO', label: 'MRO - Mauritanian Ouguiya' },
  { value: 'MUR', label: 'MUR - Mauritian Rupee' },
  { value: 'MVR', label: 'MVR - Maldivian Rufiyaa' },
  { value: 'MWK', label: 'MWK - Malawian Kwacha' },
  { value: 'MXN', label: 'MXN - Mexican Peso' },
  { value: 'MYR', label: 'MYR - Malaysian Ringgit' },
  { value: 'MZN', label: 'MZN - Mozambican Metical' },
  { value: 'NAD', label: 'NAD - Namibian Dollar' },
  { value: 'NGN', label: 'NGN - Nigerian Naira' },
  { value: 'NIO', label: 'NIO - Nicaraguan Córdoba' },
  { value: 'NOK', label: 'NOK - Norwegian Krone' },
  { value: 'NPR', label: 'NPR - Nepalese Rupee' },
  { value: 'NZD', label: 'NZD - New Zealand Dollar' },
  { value: 'PAB', label: 'PAB - Panamanian Balboa' },
  { value: 'PEN', label: 'PEN - Peruvian Sol' },
  { value: 'PGK', label: 'PGK - Papua New Guinean Kina' },
  { value: 'PHP', label: 'PHP - Philippine Peso' },
  { value: 'PKR', label: 'PKR - Pakistani Rupee' },
  { value: 'PLN', label: 'PLN - Polish Zloty' },
  { value: 'PYG', label: 'PYG - Paraguayan Guarani' },
  { value: 'QAR', label: 'QAR - Qatari Rial' },
  { value: 'RON', label: 'RON - Romanian Leu' },
  { value: 'RSD', label: 'RSD - Serbian Dinar' },
  { value: 'RUB', label: 'RUB - Russian Rouble' },
  { value: 'RWF', label: 'RWF - Rwandan Franc' },
  { value: 'SAR', label: 'SAR - Saudi Riyal' },
  { value: 'SBD', label: 'SBD - Solomon Islands Dollar' },
  { value: 'SCR', label: 'SCR - Seychellois Rupee' },
  { value: 'SEK', label: 'SEK - Swedish Krona' },
  { value: 'SGD', label: 'SGD - Singapore Dollar' },
  { value: 'SHP', label: 'SHP - St. Helena Pound' },
  { value: 'SLL', label: 'SLL - Sierra Leonean Leone' },
  { value: 'SOS', label: 'SOS - Somali Shilling' },
  { value: 'SRD', label: 'SRD - Surinamese Dollar' },
  { value: 'STD', label: 'STD - São Tomé & Príncipe Dobra' },
  { value: 'SZL', label: 'SZL - Swazi Lilangeni' },
  { value: 'THB', label: 'THB - Thai Baht' },
  { value: 'TJS', label: 'TJS - Tajikistani Somoni' },
  { value: 'TOP', label: 'TOP - Tongan Paʻanga' },
  { value: 'TRY', label: 'TRY - Turkish Lira' },
  { value: 'TTD', label: 'TTD - Trinidad & Tobago Dollar' },
  { value: 'TWD', label: 'TWD - New Taiwan Dollar' },
  { value: 'TZS', label: 'TZS - Tanzanian Shilling' },
  { value: 'UAH', label: 'UAH - Ukrainian Hryvnia' },
  { value: 'UGX', label: 'UGX - Ugandan Shilling' },
  { value: 'UYU', label: 'UYU - Uruguayan Peso' },
  { value: 'UZS', label: 'UZS - Uzbekistani Som' },
  { value: 'VND', label: 'VND - Vietnamese Dong' },
  { value: 'VUV', label: 'VUV - Vanuatu Vatu' },
  { value: 'WST', label: 'WST - Samoan Tala' },
  { value: 'XAF', label: 'XAF - Central African CFA Franc' },
  { value: 'XCD', label: 'XCD - East Caribbean Dollar' },
  { value: 'XOF', label: 'XOF - West African CFA Franc' },
  { value: 'XPF', label: 'XPF - CFP Franc' },
  { value: 'YER', label: 'YER - Yemeni Rial' },
  { value: 'ZAR', label: 'ZAR - South African Rand' },
  { value: 'ZMW', label: 'ZMW - Zambian Kwacha' }
];

const getRemainingCurrencies = (excludedCurrencies) => {
  return ALL_CURRENCIES.filter(
    (currency) => !excludedCurrencies.includes(currency.value)
  );
};

export default {
  convertToCurrencyUnits,
  renderSelectedCurrency,
  renderCurrencyOptions,
  arrowRenderer,
  convertToLowestDenomination,
  convertToCurrencyWithSymbol,
  getRemainingCurrencies,
  getCurrencySymbol
};
