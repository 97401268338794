import React from 'react';
import PropTypes from 'prop-types';
import './TextArea.scss';

const TextArea = ({
  placeholder, value, onChange, name, rows, cols
}) => (
  <textarea
    className='text-area'
    rows={rows}
    cols={cols}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    name={name}
  />
);

TextArea.propTypes = {
  placeholder: PropTypes.string,
  rows: PropTypes.string,
  cols: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
};

export default TextArea;
