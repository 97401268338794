/* eslint-disable no-tabs */

import React from 'react';
import { Card, Table } from '../../components/library-components';
import './csv_guidelines.scss';

function CsvGuidelines () {
  return (
    <div>
      <Card title="Guidelines for uploading CSV">
        <Table
          header={['Field', 'Required', 'Description']}
          className="table-default--7cols subscriptions-details"
        >
          <a className="table-default__row">
            <div className="table-default__cell width-15">
              <span className="code"> id </span>
            </div>
            <div className="table-default__cell width-15">Optional</div>
            <div className="table-default__cell">
              When the id is supplied, only the metadata columns for the
              subscription will be updated. You can get the id of a subscription
              when you download the subscription import report. Or when you
              generate csv for a subscription group, the download report also
              has subscription id&#39;s
            </div>
          </a>
          <a className="table-default__row">
            <div className="table-default__cell width-15">
              <span className="code"> subscription_plan_id </span>
            </div>
            <div className="table-default__cell width-15">
              <span className="bold-text">Mandatory</span>
            </div>
            <div className="table-default__cell">
              Subscription plan id has to be supplied. Based on{' '}
              <span className="code">subscription_plan_id</span>{' '}
              <em>subscription</em> will be created.
              <span className="bold-text">Subscription&#39;s amount</span> will
              be defaulted to <em>subscription plan&#39;s pricing</em>
            </div>
          </a>
          <a className="table-default__row">
            <div className="table-default__cell width-15">
              <span className="code"> email </span>
            </div>
            <div className="table-default__cell width-15">
              Optional if phone_number is present
            </div>
            <div className="table-default__cell">
              It is used to create a <em>Subscriber</em> and further
              identification of subscriber for <em>login</em> purposes
            </div>
          </a>
          <a className="table-default__row">
            <div className="table-default__cell width-15">
              <span className="code"> phone_number </span>
            </div>
            <div className="table-default__cell width-15">
              Optional if email is present
            </div>
            <div className="table-default__cell">
              It is used to create a <em>Subscriber</em> and further
              identification of subscriber for <em>login</em> purposes. Include{' '}
              <em>country code</em> in the phone number.
            </div>
          </a>
          <a className="table-default__row">
            <div className="table-default__cell width-15">
              <span className="code"> start_date </span>
            </div>
            <div className="table-default__cell width-15">
              <span className="bold-text">Mandatory</span>
            </div>
            <div className="table-default__cell">
              It is used to determine when the subscription has to start. It can
              be either in <span className="code">YYYY-MM-DD</span> format or{' '}
              <span className="code">YYYY-MM-DD hh:mm: ss UTC</span>. For
              example <span className="code">2020-02-13</span> or{' '}
              <span className="code">2020-02-13 12:00:00 UTC </span>. If you do
              not mention the time, by default time will be taken as{' '}
              <span className="code">00:00:00 UTC</span>
            </div>
          </a>
          <a className="table-default__row">
            <div className="table-default__cell width-15">
              <span className="code"> payment_type </span>
            </div>
            <div className="table-default__cell width-15">
              <span className="bold-text">Mandatory</span>
            </div>
            <div className="table-default__cell">
              If the payment type is left blank, it will be taken as a{' '}
              <span className="code"> manual </span> payment by default. The
              accepted values for this column are{' '}
              <span className="code">razorpay</span>,{' '}
              <span className="code">razorpay_recurring</span>,
              <span className="code"> stripe_recurring</span> and{' '}
              <span className="code">manual</span>. When{' '}
              <span className="code">razorpay,</span>{' '}
              <span className="code">razorpay_recurring,</span>
              <span className="code"> stripe_recurring</span> is supplied as{' '}
              <span className="code">payment_type</span>, it is mandatory to
              supply the <span className="code">payment_token</span>. When
              entering <span className="code">manual</span> as payment_type,
              <em> there is no need to enter the payment_token</em>
            </div>
          </a>
          <a className="table-default__row">
            <div className="table-default__cell width-15">
              <span className="code"> payment_token </span>
            </div>
            <div className="table-default__cell width-15">
              <span className="bold-text">Mandatory</span>
            </div>
            <div className="table-default__cell">
              This is necessary for payment_types
              <span className="code"> razorpay</span>,
              <span className="code"> razorpay_recurring,</span>
              <span className="code"> stripe_recurring</span>. You can get the
              razorpay payment token from razorpay admin panel. For{' '}
              <span className="code"> stripe_recurring</span>, pass stripe
              subscription id as payment token. For one time razorpay
              subscription, payment token is Payment Id. You can get Payment Id
              from payments page of razorpay dashboard. For razorpay recurring
              subscription, payment token is Subscription Id. You can get
              Subscription Id from subscriptions page of razorpay dashboard. To
              get the stripe subscription id, navigate to payments section on
              stripe dashboard and click on subscriptions
            </div>
          </a>
          <a className="table-default__row">
            <div className="table-default__cell width-15">
              <span className="code"> notes </span>
            </div>
            <div className="table-default__cell width-15">Optional</div>
            <div className="table-default__cell">
              If you want to add notes to the subscription. For example, the
              user wants a particular color of the Tshirt to be sent
            </div>
          </a>
          <a className="table-default__row">
            <div className="table-default__cell width-15">
              <span className="code"> external_id </span>
            </div>
            <div className="table-default__cell width-15">Optional</div>
            <div className="table-default__cell">
              When migrating from an external system, this can be used to
              maintain the relationship between the external system&#39;s
              subscription&#39;s and access type&#39;s subscriptions. This can
              be also used to verify if all the records have been migrated
              successfully
            </div>
          </a>
          <a className="table-default__row">
            <div className="table-default__cell width-15">
              <span className="code"> skip_price_validation </span>
            </div>
            <div className="table-default__cell width-15">Optional</div>
            <div className="table-default__cell">
              Sometimes we want to capture a value which is not exactly equal to
              the plan amount. For example, a payment token which is done
              offline and has a slightly lesser value or more value than the
              subscription plan price. you need to give this column the value of{' '}
              <span className="code">true</span> in those cases. For all the
              other cases, this column can be left empty
            </div>
          </a>
          <a className="table-default__row">
            <div className="table-default__cell width-15">
              <span className="code"> amount_cents </span>
            </div>
            <div className="table-default__cell width-15">Optional</div>
            <div className="table-default__cell">
              When the payment type is <span className="code">manual</span>, and
              you do not want the subscription&#39;s price to default to
              subscription plan&#39;s price. You need to populate this column
              with postive integer value in cents. For example, to represent{' '}
              <span className="code">100 USD</span>, you need to input{' '}
              <span className="code">10000</span>
            </div>
          </a>
          <a className="table-default__row">
            <div className="table-default__cell width-15">
              <span className="code"> amount_currency </span>
            </div>
            <div className="table-default__cell width-15">Optional</div>
            <div className="table-default__cell">
              Applicable only for <span className="code">manual</span>{' '}
              payment_type. You can specify the short form for the currency of
              your choice. For example <span className="code">USD</span> for US
              dollar or <span className="code">INR</span> for Indian rupee.
            </div>
          </a>
          <a className="table-default__row">
            <div className="table-default__cell width-15">
              <span className="code"> end_date </span>
            </div>
            <div className="table-default__cell width-15">Optional</div>
            <div className="table-default__cell">
              This will override the plan duration to set expiry time of the subscription. Not recommended.
            </div>
          </a>
        </Table>
        Note:
        <ul>
          <li>
            When a subscriber has an ongoing subscription or a pending
            subscription, a new subscription with overlapped dates of existing
            subscription will be added after the current or pending
            subscription.
          </li>
          <li>
            We only support importing stripe recurring subscriptions created
            through stripe{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://stripe.com/docs/billing/subscriptions/overview"
              className="code"
            >
              subscriptions
            </a>{' '}
            integration.
          </li>
        </ul>
      </Card>
    </div>
  );
}
export default CsvGuidelines;
