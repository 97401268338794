import React from 'react';
import SelectField from '../components/library-components/SelectField/SelectField';
import { DateRangePicker } from '@quintype/em/components/date-range-picker';
import { ErrorMessage } from '@quintype/em/components/error-message';

import toDate from 'date-fns/toDate';
import set from 'date-fns/set';
import getUnixTime from 'date-fns/getUnixTime';
import fromUnixTime from 'date-fns/fromUnixTime';

const calendarGroupFilter = [
  { value: 'DAY', label: 'Daily' },
  { value: 'MONTH', label: 'Monthly' }
];

const validation = (fromTimestamp, toTimestamp) => {
  const currentTimestamp = getUnixTime(toDate(new Date()));
  if (fromTimestamp > currentTimestamp && toTimestamp > currentTimestamp) {
    return {
      message: 'Dates cannot be in the future',
      type: 'both'
    };
  }

  if (fromTimestamp > currentTimestamp) {
    return {
      message: 'From date cannot be in the future',
      type: 'from'
    };
  }

  if (toTimestamp > currentTimestamp) {
    return {
      message: 'To date cannot be in the future',
      type: 'to'
    };
  }
};

const onDateRangeSelect = (start, end, { handleDateRangeChange, from, to }) => {
  const startDate = start ? fromUnixTime(start) : from;
  const formattedEndDate = end
    ? set(fromUnixTime(end), { hours: 11, minutes: 59, seconds: 59 })
    : null;
  const endDate = end ? formattedEndDate : to;
  return handleDateRangeChange(startDate, endDate);
};

class AnalyticsFilterBar extends React.Component {
  render () {
    return (
      <div className="analytics-filters">
        <div className="granularity">
          <h4 className="granularity-label">Granularity</h4>
          <SelectField
            options={calendarGroupFilter}
            value={this.props.dateType}
            onChange={this.props.handleDateTypeChange}
            clearable={false}
            className="select-provider"
            placeholder="Select"
            searchable={false}
            valueKey="value"
            labelKey="label"
          />
        </div>

        <div className="date-range-picker-container">
          <DateRangePicker
            dateLabel="Select Date Range"
            startTimeStamp={getUnixTime(toDate(this.props.from))}
            endTimeStamp={getUnixTime(toDate(this.props.to))}
            onDateRangeSelect={(startStamp, endStamp) =>
              onDateRangeSelect(startStamp, endStamp, this.props)
            }
            customValidation={({ startTimeStamp, endTimeStamp }) =>
              validation(startTimeStamp, endTimeStamp)
            }
          />

          {this.props.errorMessage && (
            <ErrorMessage message={this.props.errorMessage} truncated={true} />
          )}
        </div>
      </div>
    );
  }
}

export default AnalyticsFilterBar;
