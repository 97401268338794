import React from 'react';
import { get, capitalize, split } from 'lodash-es';

import {
  TitleBar,
  Card,
  Layout,
  StatusMessage,
  Table,
  Pagination
} from '../components/library-components';
import { Button } from '@quintype/em/components/button';
import SubscriptionListKebabMenu from '../components/subscription-list-kebab-menu';
import ImportSubscriptions from '../components/import-subscriptions';
import SubscriptionFilter from '../components/subscription-filter';
import SubscriptionDownloadReports from '../components/subscription-download-reports';
import { SearchBar } from '@quintype/em/components/search-bar';
import SubscriptionDetails from '../components/subscription-details/subscription-details';

import { getAnalyticsSubscriptions, getDownloadUrl, subscriptionSearchApi } from '../api';

import '../../stylesheets/pages/subscriptions.scss';

export class AnalyticsSubscriptions extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      filters: {
        group: this.props.subscriptionGroupId || 'all',
        plans: ['all'],
        status: ['all'],
        cancelled: 'all',
        'sub-date': {
          start: null,
          end: null
        }
      },
      term: '',
      total_pages: 1,
      total_entries: 0,
      showNotification: {
        isNotificationOpen: false,
        status: '',
        statusMessage: ''
      },
      subscriptions: [],
      showImportDialog: false,
      showFilters: false,
      showDownloadDialog: false,
      showSubscriptionDialog: false,
      selectedSubscription: null
    };
  }

  componentDidMount () {
    this.loadSubscriptions();
    this.setupChannelSubscription(
      'NotifyUsersChannel',
      data => this.displayReportStatus(data)
    );
  }

  handlePageChange (page) {
    this.loadSubscriptions(page);
  }

  updateSubscriptionsReport (data) {
    this.loadSubscriptionsReports();
    this.setState({
      showNotification: {
        isNotificationOpen: true,
        status: data.description.text.status,
        statusMessage: data.description.text.message
      }
    });
  }

  setupChannelSubscription (channel, callback) {
    this.props.cableApp.notify_user = this.props.cableApp
      .cable
      .subscriptions.create(channel, {
        received: function (data) {
          callback(data);
        }
      });
  }

  loadSubscriptions (pageNum = 1) {
    getAnalyticsSubscriptions(this.state.filters, pageNum).then(resp => {
      this.setState({
        total_pages: resp.total_pages,
        total_entries: resp.total_entries,
        subscriptions: resp.subscriptions
      });
    });
  }

  searchSubscriptions (pageNum = 1) {
    subscriptionSearchApi(this.state.filters, this.state.term, pageNum).then(res => {
      this.setState({
        total_pages: res.total_pages,
        total_entries: res.total_entries,
        subscriptions: res.subscriptions
      });
    });
  }

  handleExportClick (message, success) {
    this.setState({
      showNotification: {
        isNotificationOpen: true,
        status: success ? 'success' : 'error',
        statusMessage: message
      }
    }, this.toggleDownloadDialog);
  }

  handleImportClick (message, success) {
    this.setState({
      showNotification: {
        isNotificationOpen: true,
        status: success ? 'success' : 'error',
        statusMessage: message
      }
    }, this.toggleImportDialog);
  }

  displayReportStatus (data) {
    this.setState({
      showNotification: {
        isNotificationOpen: true,
        status: data.description.text.status,
        statusMessage: data.description.text.message
      }
    });

    getDownloadUrl(data.description.text.filename).then(
      (downloadUrl) => {
        this.loadSubscriptions();
        window.location.href = downloadUrl;
      }
    );
  }

  toggleImportDialog () {
    this.setState({ showImportDialog: !this.state.showImportDialog });
  }

  toggleFilterInspector () {
    this.setState({ showFilters: !this.state.showFilters });
  }

  toggleDownloadDialog () {
    this.setState({ showDownloadDialog: !this.state.showDownloadDialog });
  }

  toggleSubscriptionDialog () {
    this.setState((state) => {
      return { showSubscriptionDialog: !state.showSubscriptionDialog };
    });
  }

  showNotification (status, statusMessage) {
    this.setState({
      showNotification: {
        isNotificationOpen: true,
        status,
        statusMessage
      }
    });
  }

  applyFilters (filters) {
    this.setState({ filters, showFilters: false }, this.loadSubscriptions);
  }

  getSubscriberLink (subscription) {
    return `/subscribers/${get(subscription, ['preferred_identity', 'provider'])}/${get(subscription, ['preferred_identity', 'value'])}.html`;
  }

  setSearchTerm (value) {
    this.setState({ term: value });
  }

  showSubscriptionDetails (subscription) {
    this.setState({ selectedSubscription: subscription, showSubscriptionDialog: true });
  }

  renderSubscriptions () {
    return this.state.subscriptions.map((subscription, index) => (
      <div
        onClick={() => this.showSubscriptionDetails(subscription)}
        key={index}
        className='table-default__row'
      >
        <div className='table-default__cell truncate-word' data-test-id='subscriber-name'>
          {subscription.subscriber_name}
        </div>
        <div className='table-default__cell' data-test-id='subscription-plan'>
          {subscription.plan_name}
        </div>
        <div className='table-default__cell truncate-word' data-test-id='subscription-type'>
          {capitalize(subscription.subscription_type)}
        </div>
        <div className='table-default__cell truncate-word' data-test-id='subscriber-identity'>
          {get(subscription, ['preferred_identity', 'value'])}
        </div>
        <div className='table-default__cell' data-test-id='subscription-end-timestamp'>
          {split(subscription.end_timestamp, 'T', 1)}
        </div>
      </div>
    ));
  }

  render () {
    return (
      <Layout>
        <TitleBar title='Subscription List'>
          <SubscriptionListKebabMenu
            onImportClick={() => this.toggleImportDialog()}
            onExportClick={() => this.toggleDownloadDialog()}
          />
        </TitleBar>

        <div className='search_and_filter'>
          <div className='search_bar'>
            <SearchBar hasBorder onChange={(e) => this.setSearchTerm(e)} placeholder='Search...' />
          </div>
          <Button className='search_button' testId='search' type='primary' onClick={() => this.searchSubscriptions()}>Search</Button>
          <div className='filters'>
            <Button testId='filter-btn' customClass='margin-right-10' primary onClick={() => this.toggleFilterInspector()}>Filters</Button>
          </div>
        </div>

        <Card>
          <p className='description'>Marketing teams can use this to generate the report using the filters and plan their marketing activities.</p>
          {this.state.showFilters &&
            <SubscriptionFilter
              filters={this.state.filters}
              onClose={() => this.toggleFilterInspector()}
              onApplyFilters={filters => this.applyFilters(filters)}
              statusList={['active', 'pending', 'expired', 'all']}
            />}
          <Table
            header={[
              'Name',
              'Plan',
              'Type',
              'Email ID',
              'Expiry date'
            ]}
            className='table-default--5cols subscriptions-details'
          >
            {this.renderSubscriptions()}
          </Table>
          <Pagination
            onPageChange={page => this.handlePageChange(page)}
            pageCount={this.state.total_pages}
            containerClassName='pagination'
            pageRangeDisplayed={0}
            marginPagesDisplayed={1}
            pageClassName='pagination__item'
            breakClassName='pagination__item'
            breakLabel='...'
            totalEnteries={`${this.state.total_entries} rows`}
          />
        </Card>
        {this.state.showImportDialog &&
          <ImportSubscriptions
            onClose={() => this.toggleImportDialog()}
            onImport={(message, success) => this.handleImportClick(message, success)}
            displayNotification={(status, statusMessage) => this.showNotification(status, statusMessage)}
          />}
        {this.state.showDownloadDialog &&
          <SubscriptionDownloadReports
            onClose={() => this.toggleDownloadDialog()}
            filters={this.state.filters}
            totalEnteries={this.state.total_entries}
            displayNotification={(status, statusMessage) => this.showNotification(status, statusMessage)}
            onExport={(message, success) => this.handleExportClick(message, success)}
          />}
        {this.state.showSubscriptionDialog &&
          <SubscriptionDetails
            selectedSubscription={this.state.selectedSubscription}
            onClose={() => this.toggleSubscriptionDialog()}
            subscriberLink={this.getSubscriberLink(this.state.selectedSubscription)}
          />}
        {this.state.showNotification.isNotificationOpen && (
          <StatusMessage
            statusMessage={this.state.showNotification.statusMessage}
            status={this.state.showNotification.status}
            onCancel={() =>
              this.setState({
                showNotification: {
                  isNotificationOpen: false,
                  status: '',
                  statusMessage: ''
                }
              })}
          />
        )}
      </Layout>
    );
  }
}

export default AnalyticsSubscriptions;
