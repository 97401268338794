import React from 'react';
import PropTypes from 'prop-types';
import DefaultUser from '../Icons/DefaultUser';
import './User.scss';
import _ from 'lodash';

const User = ({ userName, accountName, alt, userAvatar, imageWidth, imageHeight, children }) => {
  const userImage = (_.isEmpty(userAvatar)) ? <div className='user__avatar'>
    <DefaultUser width={imageWidth} height={imageHeight} />
  </div>
    : <img className='user__avatar' src={userAvatar} alt={alt} />;
  return (<div className='user'>
    {userImage}
    {userName && <div className='user__detail'>
      <span className='user__account-name'>{accountName}</span>
      <span className='user__name'>{userName}</span>
    </div>}
    {children && <div className='user__children'> {children} </div>}
  </div>
  );
};

User.propTypes = {
  userName: PropTypes.string,
  accountName: PropTypes.string,
  alt: PropTypes.string,
  userAvatar: PropTypes.string,
  children: PropTypes.node
};

User.defaultProps = {
  userName: 'Default user',
  alt: 'Default Avatar',
  userAvatar: 'default/url'
};

export default User;
