import React from 'react';
import { ButtonGroup, Link } from '../../javascript/components/library-components/index';

const OptionsFilter = ({ options, currentOptions, clickCheckBox, selectAll, deselectAll }) => {
  return (
    <div className='chart-footer-filter'>
      <div className="select-actions">
        <ButtonGroup>
          <Link onClick={() => selectAll()} className='select-actions__button'>Select All</Link>
          <Link onClick={() => deselectAll()} className='select-actions__button'>Clear All</Link>
        </ButtonGroup>
      </div>
      <div className='chart-footer-filter__checkbox-selections'>
        {Object.keys(options).map((optionKey, index) => <label key={index} style={{ color: `${options[optionKey]['color']}` }} className='checkbox-inline'>
          <input onClick={(e) => clickCheckBox(e, optionKey)} type='checkbox' value='' checked={Object.keys(currentOptions).includes(optionKey)} />
          <span className='checkmark' /><span className='checkmark-label'>{optionKey}</span>
        </label>)}
      </div>
    </div>
  );
};

export default OptionsFilter;
