import React from 'react';
import Google from '../../pages/Icons/google';
import Linkedin from '../../pages/Icons/linkedin';
import { Button } from '../library-components';
import './socialLogins.scss';

function handleSocialAuthClick (authUri, provider) {
  window.location.href = decodeURI(authUri + '&auth-provider=' + provider);
}

function SocialLogins (props) {
  const { authUri } = props;
  return <div className="social-logins button-wrapper">
    <Button customClass="google-login-button social-login-button" onClick={() => handleSocialAuthClick(authUri, 'google')}>
      <Google />
      <span className="social-login--cta">Continue with Google</span>
    </Button>
    <Button customClass="linkedin-login-button social-login-button" onClick={() => handleSocialAuthClick(authUri, 'linkedin')}>
      <Linkedin />
      <span className="social-login--cta">Continue with Linkedin</span>
    </Button>
  </div>;
}

export default SocialLogins;
