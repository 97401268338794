import React from 'react';
import classnames from 'classnames';
import './collapsible-sub-group-listing.scss';
import UpArrow from './library-components/Icons/UpArrow';
import DownArrow from './library-components/Icons/DownArrow';
import { Table, Description, Button, ButtonGroup, ProgressBar } from './library-components';
import PriceCell from './sub-group-plan-price-cell';

const CellWithPlanLink = ({ plan, groupData, children }) => {
  const link = `/subscription_groups/${groupData.id}/subscription_plans/${plan.id}`;
  return <a href={link} className='table-default__cell'>{children}</a>;
};

const Collapsible = ({ collapsible, toggle, active, groupData }) => {
  const cardClass = classnames('accordion-item accordion-item--white', { 'accordion-item--collapsed accordion__item--min_height_80': collapsible, 'accordion-item--expand': active });
  const iconClass = classnames('accordion-item--collapse-icon');

  return (
    <div className={cardClass}>
      <div className='accordion-item__header accordion-item-header' onClick={toggle}>
        <div className='accordion-item-header__left'>
          <h3 className='accordion-item-header__title' >{groupData.name} ({groupData.subscription_plans.length})</h3>
          <ul className=' accordion-item-header__description subscription-group-details'>
            <li>{groupData.subscription_type.toUpperCase()}</li>
            <li>{groupData.public ? 'Public' : 'Private'}</li>
          </ul>
        </div>
        <div className='accordion-item-header__right'>
          {groupData.subscription_type === 'campaign' &&
             <div className="accordion-item__campaign-details">
               <ProgressBar progressPercent={`${Math.round(groupData.collected_amount_percentage)}%`} progressText='funded' />
               <div className={`accordion-item__campaign-details__status  ${groupData.campaign_active ? 'at-campaign-active' : 'at-campaign-ended'}`}>
                 {groupData.campaign_active ? 'Active' : 'Ended'}
               </div>

             </div>
          }
          <div className='custom-align'>
            <Button url={`/subscription_groups/${groupData.id}`} > Edit </Button>
          </div>
          { collapsible && <span className={iconClass}>{active ? <UpArrow /> : <DownArrow />}</span> }
        </div>
      </div>
      <div className='accordion-item__body'>
        <Description description={groupData.description} />

        {active && groupData.subscription_plans.length > 0 &&
        <Table header={['Id', 'Name', 'Duration', 'Price', 'Visibility']} className='table-default--5cols'>
          {groupData.subscription_plans.map((plan, index) =>
            <div key={index} className='table-default__row'>
              <CellWithPlanLink plan={plan} groupData={groupData}> {plan.id} </CellWithPlanLink>
              <CellWithPlanLink plan={plan} groupData={groupData}> {plan.title} </CellWithPlanLink>
              <CellWithPlanLink plan={plan} groupData={groupData}> {plan.duration_length} {plan.duration_unit} </CellWithPlanLink>
              <div className='table-default__cell'><PriceCell plan={plan} groupId={groupData.id} addPlanLink={true} /></div>
              <CellWithPlanLink plan={plan} groupData={groupData}> {groupData.public ? 'Public' : 'Private'} </CellWithPlanLink>
            </div>
          )}
        </Table>}

      </div>
      {active && <div className='accordion-item__footer'>
        <ButtonGroup>
          <Button url={`/analytics_subscriptions?subscription_group_id=${groupData.id}`} >Subscriptions</Button>
          <Button disabled={groupData.subscription_type === 'campaign' && !groupData.campaign_active } url={`/subscription_groups/${groupData.id}/subscription_plans/new`} primary> Add New Plan </Button>
        </ButtonGroup>
      </div>}
    </div>
  );
};

export default Collapsible;
