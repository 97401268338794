import React from 'react';
import _ from 'lodash-es';

import { Dialog } from '@quintype/em/components/dialog';
import DownloadReports from './reports/download_reports';

import { downloadSubscriptionsCsv, getAccountSubscriptionsReports } from '../api';

export default class SubscriptionDownloadReports extends React.Component {
  constructor () {
    super();
    this.state = {
      reports: []
    };
  }

  componentDidMount () {
    getAccountSubscriptionsReports().then(data => this.setState({ reports: _.filter(data.subscriptions_reports, function (o) { return !!o.generated_by_user_name; }) }));
  }

  generateReport () {
    if (this.props.totalEnteries && this.props.totalEnteries > 60000) {
      this.props.displayNotification('error', 'Number of records too high for download, please apply filters to reduce the count');
      this.props.onClose();
      return;
    }
    downloadSubscriptionsCsv(this.props.filters)
      .then(message => {
        this.props.onExport(message, true);
      });
  }

  render () {
    return (
      <Dialog
        isOpen
        onClose={this.props.onClose}
      >
        <DownloadReports generateReport={() => this.generateReport()} reports={this.state.reports} />
      </Dialog>
    );
  }
}
