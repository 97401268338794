import React from 'react';
import { AccesstypeLogo } from '../index';
import './Logo.scss';

const Logo = ({ handleBrandLogoClick, bgColor = false, fullLogo = false }) => {
  return (

    <div className={`site-logo ${bgColor ? `site-logo--bg` : ''}  ${fullLogo ? `site-logo--large` : ''}`}>
      {!fullLogo
        ? <button onClick={handleBrandLogoClick} className='site-logo__button'>
          <AccesstypeLogo />
        </button>
        : <a herf="./"><svg
          xmlns="http://www.w3.org/2000/svg"
          width="234"
          height="100"
          viewBox="0 0 1200 385"
        >
          <g fill="none" fillRule="evenodd">
            <g>
              <g transform="translate(150 125)">
                <path
                  fill="#1D3355"
                  d="M158.489 124.83V7.466l-22.292-4.443v27.333C122.314 12.278 100.457.586 75.901.586c-16.028 0-31.296 4.934-44.25 14.22L21.497 5.882l3.243 32.684 32.827-.995-8.007-7.033A52.571 52.571 0 0175.9 23.507c29.17 0 52.902 23.65 52.902 52.716 0 29.07-23.732 52.717-52.902 52.717-29.171 0-52.898-23.647-52.898-52.717 0-5.66.89-11.224 2.65-16.536a52.36 52.36 0 016.11-12.535 53.318 53.318 0 012.894-3.943L18.81 44.764l-.758-17.305c-1.463 1.813-4.171 5.094-5.462 7.033a75.408 75.408 0 00-8.778 18.011A75.317 75.317 0 000 76.223c0 41.705 34.05 75.635 75.9 75.635 24.557 0 46.414-11.689 60.297-29.769v27.548h22.292l13.374-24.436-13.374-.37z"
                ></path>
                <g fill="#4A4A4A" transform="translate(236.793 23.183)">
                  <path d="M42.438 70.193V56.41H36c-10.545 0-15.752 3.819-15.752 11.736 0 6.416 3.29 10.102 9.315 10.102 5.615 0 9.86-2.861 12.875-8.055m24.382 8.875l-4.108 12.83c-8.221-.682-13.698-3.41-16.713-10.646-5.201 7.917-13.146 10.916-21.775 10.916C9.703 92.168.659 82.886.659 69.786.66 53.95 12.574 45.35 34.084 45.35h8.354v-3.548c0-9.012-3.832-11.874-13.013-11.874-4.793 0-11.782 1.36-19.175 3.819L5.866 20.78c9.038-3.55 18.628-5.321 26.85-5.321 20.135 0 28.764 8.87 28.764 25.249v29.21c0 5.876 1.778 7.923 5.34 9.15"></path>
                  <path d="M135.298 24.195l-8.491 11.599c-4.66-3.682-8.906-5.326-14.25-5.326-9.723 0-15.476 6.97-15.476 23.752 0 16.792 6.167 22.393 15.338 22.393 5.344 0 9.043-1.64 14.112-5.464l8.767 12.556c-6.575 5.326-14.25 8.467-23.835 8.467-21.642 0-34.932-14.745-34.932-37.81 0-22.932 13.428-38.904 34.657-38.904 9.728 0 17.259 2.867 24.11 8.737"></path>
                  <path d="M199.394 24.195l-8.492 11.599c-4.66-3.682-8.905-5.326-14.25-5.326-9.723 0-15.476 6.97-15.476 23.752 0 16.792 6.167 22.393 15.338 22.393 5.345 0 9.044-1.64 14.112-5.464l8.768 12.556c-6.576 5.326-14.25 8.467-23.835 8.467-21.643 0-34.932-14.745-34.932-37.81 0-22.932 13.427-38.904 34.656-38.904 9.728 0 17.26 2.867 24.11 8.737"></path>
                  <path d="M251.16 46.714c0-10.92-3.7-18.013-13.013-18.013-7.675 0-12.462 5.183-13.428 18.833h26.44v-.82zm18.9 12.969h-45.34c1.374 13.925 7.95 18.019 17.259 18.019 6.166 0 11.373-1.91 17.673-6.278l8.082 10.921c-7.122 5.733-16.165 9.827-27.533 9.827-23.283 0-35.48-15.285-35.48-37.947 0-21.838 11.783-38.768 33.012-38.768 20.55 0 32.74 13.65 32.74 36.716 0 2.464-.27 5.468-.414 7.51z"></path>
                  <path d="M335.244 24.195l-7.393 11.329c-5.891-3.961-11.782-6.008-17.535-6.008-6.985 0-10.546 2.596-10.546 6.553 0 4.23 2.463 6.14 15.2 9.831 14.52 3.956 22.742 9.965 22.742 22.79 0 15.015-14.107 23.482-31.366 23.482-12.605 0-22.737-4.23-29.588-10.514l9.723-10.779c5.344 4.364 12.053 7.236 19.18 7.236 7.393 0 12.048-3.004 12.048-7.78 0-5.601-2.596-7.378-16.023-11.06-14.658-4.098-21.505-11.333-21.505-22.25 0-12.29 11.23-21.567 28.765-21.567 10.546 0 19.451 3.274 26.298 8.737"></path>
                  <path d="M400.846 24.195l-7.393 11.329c-5.891-3.961-11.782-6.008-17.535-6.008-6.985 0-10.546 2.596-10.546 6.553 0 4.23 2.463 6.14 15.2 9.831 14.521 3.956 22.742 9.965 22.742 22.79 0 15.015-14.107 23.482-31.366 23.482-12.605 0-22.736-4.23-29.588-10.514l9.723-10.779c5.344 4.364 12.053 7.236 19.18 7.236 7.393 0 12.048-3.004 12.048-7.78 0-5.601-2.596-7.378-16.023-11.06-14.658-4.098-21.505-11.333-21.505-22.25 0-12.29 11.23-21.567 28.766-21.567 10.545 0 19.45 3.274 26.297 8.737"></path>
                  <path d="M438.502 91.49c-10.822 0-17.535-6.416-17.535-18.84V25.01h-12.053v-6.41h12.053V1.531l7.945-.953V18.6h18.49l-.955 6.41h-17.535v47.234c0 8.325 3.29 12.418 10.545 12.418 3.975 0 7.404-1.09 10.96-3.274l3.152 5.596c-4.384 2.872-9.314 4.506-15.067 4.506"></path>
                  <path d="M461.23 117.832l-.823-6.14c14.383-3.549 17.944-9.145 22.46-21.705h-2.738L455.2 18.597h8.63l21.642 65.524 21.23-65.523h8.358l-24.52 71.663c-5.344 15.565-11.776 24.16-29.311 27.571"></path>
                  <path d="M578.883 53.95c0-20.337-6.576-30.3-19.043-30.3-9.17 0-15.2 5.457-20.135 12.285v37.947c4.383 6.69 10.688 10.922 18.904 10.922 13.146 0 20.274-10.382 20.274-30.855m8.491 0c0 22.525-9.447 37.54-27.258 37.54-9.038 0-15.89-4.099-20.411-9.832v35.219l-7.945 1.094V18.598h6.851l.685 10.372c5.206-7.235 12.875-11.874 22.051-11.874 17.668 0 26.027 13.788 26.027 36.853"></path>
                  <path d="M654.34 49.176c0-15.565-6.3-25.53-19.86-25.53-11.236 0-19.59 8.193-20.412 27.571h40.271v-2.041zm7.536 8.187h-47.808c.96 19.383 9.999 27.438 22.328 27.438 7.812 0 13.013-2.454 19.318-7.097l3.694 5.05c-6.709 5.459-13.969 8.738-23.421 8.738-18.904 0-30.41-13.65-30.41-36.583 0-22.8 11.097-37.815 28.632-37.815 18.623 0 27.937 13.518 27.937 34.536 0 2.184-.132 4.368-.27 5.733z"></path>
                </g>
              </g>
            </g>
          </g>
        </svg></a>}
    </div>
  );
};

export default Logo;
