export const API_ROUTES = {
  SUBSCRIPTION_GROUPS: '/api/v1/management/subscription_groups',
  SUBSCRIPTIONS: '/api/v1/management/subscriptions',
  SUBSCRIBERS: '/api/v1/management/subscribers',
  SUBSCRIPTION_SEARCH: '/api/v1/management/subscriptions/search',
  INVOICES: '/api/v1/management/invoices'
};

export const ROUTES = {
  DASHBOARD: '/dashboard'
};

export const DATE = {
  // FMT - format
  HR_MIN_FMT: 'HH:mm, dd MMM yyyy',
  DEFAULT_FMT: 'dd/MM/yyyy',

  // month - 3 letters ex: jan feb
  MONTH_EXP_FMT: 'dd MMM yyyy',

  YEAR_FIRST_FMT: 'yyyy-MM-dd',

  YEAR_FIRST_SEC_FMT: 'yyyy-MM-dd hh:mm:ss',

  DATE_SEC_FMT: 'dd-MMM hh:mm:ss'
};

export const PAYMENT_GATEWAY_CONSTRAINTS = {
  androidpay: {
    jwt: { presence: { message: 'Cannot be empty', allowEmpty: false } }
  },
  googlepay: {
    jwt: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    package_name: { presence: { message: 'Cannot be empty' } }
  },
  razorpay: {
    app_key: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    secret: { presence: { message: 'Cannot be empty', allowEmpty: false } }
  },
  simpl: {
    client_id: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    client_secret: { presence: { message: 'Cannot be empty', allowEmpty: false } }
  },
  paytm: {
    client_id: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    client_secret: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    mid: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    merchant_key: { presence: { message: 'Cannot be empty', allowEmpty: false } }
  },
  juspay: {
    api_key: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    response_key: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    webhook_username: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    webhook_password: { presence: { message: 'Cannot be empty', allowEmpty: false } }
  },
  stripe: {
    api_key: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    publishable_key: { presence: { message: 'Cannot be empty', allowEmpty: false } }
  },
  paypal: {
    client_id: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    secret: { presence: { message: 'Cannot be empty', allowEmpty: false } }
  },
  omise: {
    secret_key: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    publishable_key: { presence: { message: 'Cannot be empty', allowEmpty: false } }
  },
  adyen: {
    api_key: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    client_key: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    merchant_account: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    environment: { presence: { message: 'Cannot be empty', allowEmpty: false } }
  },
  applepay: {
    api_key: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    key_id: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    issuer_id: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    bundle_id: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    shared_password: { presence: { message: 'Cannot be empty', allowEmpty: false } }
  },
  paytrail: {
    api_key: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    checkout_account: { presence: { message: 'Cannot be empty', allowEmpty: false } }
  },
  payflow: {
    client_id: { presence: { message: 'Cannot be empty', allowEmpty: false } },
    client_secret: { presence: { message: 'Cannot be empty', allowEmpty: false } }
  }
};

export const DESCRIPTION_TOOLBAR_OPTIONS = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],

    ['link'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],

    [{ align: [] }],

    ['clean']
  ]
};

export const QUILL_EDITOR_FORMATS = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'align',
  'script',
  'direction',
  'code-block',
  'background',
  'color',
  'font',
  'code'
];

export const ATTRIBUTE_TYPE = [
  { label: 'Plain Text', value: 'plain_text' },
  { label: 'Rich Text', value: 'rich_text' },
  { label: 'Image', value: 'image' }
];

export const PAID_TRIAL_PAYMENT_GATEWAYS = ['paytrail', 'stripe'];
export const domainRegex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/;
