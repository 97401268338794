import React from 'react';
import PropTypes from 'prop-types';
import './alert.scss';
import classnames from 'classnames';
import { Close } from '../Icons';
import { Button } from '../../library-components';

const Alert = ({ title = '', confirmContent = '', open, onConfirm, destructive, onCancel, children, buttonText = 'Delete' }) => {
  const alertClass = classnames('alert', { 'alert--open': open });

  return (
    <div className={alertClass}>
      <div className='alert__content'>
        <div className='alert__header'>
          <h3 className='alert__title'>{title}</h3>
          <a onClick={onCancel} className='alert__close-button' >
            <Close width='13px' height='13px' color='#818a98' />
          </a>
        </div>
        <div className='alert__body'>
          {children}
        </div>
        <div className='alert__footer'>
          {destructive && <Button dataTest={'delete_confirmation'} destructive onClick={onConfirm}> { buttonText } </Button>}
        </div>
      </div>
    </div>
  );
};

Alert.propTypes = {
  title: PropTypes.string,
  confirmContent: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  destructive: PropTypes.bool,
  open: PropTypes.bool,
  children: PropTypes.node,
  buttonText: PropTypes.string
};

export default Alert;
