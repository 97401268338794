import React from 'react';

import {
  Card,
  TableWithCheckboxAndDelete,
  CheckBox,
  Pagination,
  StatusMessage
} from '../components/library-components';

import { Trash } from '@quintype/em/icons/trash';
import ImportSubscriptionMembers from '../components/import-subscription-members';

export default class OrganizationSubscriptionMembers extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      allSelected: false,
      showImportDialog: false,
      showNotification: {
        isNotificationOpen: false,
        status: '',
        statusMessage: ''
      }
    };

    this.toggleImportDialog = this.toggleImportDialog.bind(this);
    this.handleImportClick = this.handleImportClick.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
  }

  toggleAllSelected () {
    this.setState({ allSelected: !this.state.allSelected });
    this.props.toggleAllMemberSelection(!this.state.allSelected);
  }

  deleteAllMembers () {
    this.props.deleteMembers();
    this.setState({ allSelected: false });
  }

  toggleImportDialog () {
    this.setState({ showImportDialog: !this.state.showImportDialog });
  }

  handleNotification (message, success, callback) {
    this.setState(
      {
        showNotification: {
          isNotificationOpen: true,
          status: success ? 'success' : 'error',
          statusMessage: message
        }
      },
      callback
    );
  }

  handleImportClick (message, success) {
    this.handleNotification(message, success, this.toggleImportDialog);
  }

  render () {
    return (
      <div>
        <Card title="Members" titleSideButtonText="Import CSV" onButtonClick={this.toggleImportDialog}>
          <TableWithCheckboxAndDelete
            header={['Name', 'Email', 'Enlisted By']}
            onCheckboxClick={() => this.toggleAllSelected()}
            showDeleteButton={
              this.state.allSelected || this.props.someMembersSelected
            }
            onDeleteMembers={() => this.deleteAllMembers()}
            checked={this.state.allSelected}
            checkboxDataTest="all-members-checkbox"
            deleteDataTest="all-members-delete"
          >
            {this.props.members.map((member, i) => (
              <div
                className="table-default__row"
                key={i}
                data-test-id={`member-${i}`}
              >
                <div className="table-default__cell table-default__checkbox">
                  <CheckBox
                    checked={member.checked}
                    onChange={() => this.props.toggleMemberSelection(member, i)}
                    dataTest="member-checkbox"
                  />
                </div>
                <div
                  className="table-default__cell truncate-word"
                  data-test-id="member-name"
                >
                  {member.name}
                </div>
                <div
                  className="table-default__cell truncate-word"
                  data-test-id="member-email"
                >
                  {member.email}
                </div>
                <div
                  className="table-default__cell truncate-word"
                  data-test-id="member-enlisted-by"
                >
                  {member.enlistedBy ? member.enlistedBy : 'Admin'}
                </div>
                {
                  <div
                    onClick={() => this.props.deleteMembers(member.id)}
                    style={{
                      cursor: 'pointer',
                      visibility: member.checked ? 'visible' : 'hidden'
                    }}
                    data-test-id="delete-member"
                  >
                    <Trash />
                  </div>
                }
              </div>
            ))}
          </TableWithCheckboxAndDelete>
          <Pagination
            onPageChange={(page) => this.props.onPageChange(page)}
            pageCount={this.props.pageCount}
            containerClassName="pagination"
            pageRangeDisplayed={0}
            marginPagesDisplayed={1}
            pageClassName='pagination__item'
            breakClassName='pagination__item'
            breakLabel='...'
            totalEnteries={`${this.props.totalEntries} rows`}
          />
        </Card>
        {this.state.showImportDialog && (
          <ImportSubscriptionMembers
            onClose={() => this.toggleImportDialog()}
            onImport={(message, success) =>
              this.handleImportClick(message, success)
            }
            handleNotification={(message, success) => this.handleNotification(message, success)}
            reloadMembers={this.props.getSubscriptionMembers}
            subscriptionId={this.props.subscriptionId}
          />
        )}
        {this.state.showNotification.isNotificationOpen && (
          <StatusMessage
            statusMessage={this.state.showNotification.statusMessage}
            status={this.state.showNotification.status}
            onCancel={() =>
              this.setState({
                showNotification: {
                  isNotificationOpen: false,
                  status: '',
                  statusMessage: ''
                }
              })
            }
          />
        )}
      </div>
    );
  }
}
