import React from 'react';

const Close = ({ width = '16', height = '16', color = '#818a98' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
      <title>icon-close</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='icon-close' fill={color}>
          <g transform='translate(3.000000, 3.000000)' id='Fill-1'>
            <path d='M5.9485,5.10025 L10.0245,1.02425 C10.2585,0.79025 10.2585,0.41025 10.0245,0.17625 C9.7895,-0.05875 9.4095,-0.05875 9.1755,0.17625 L5.0995,4.25125 L1.0245,0.17625 C0.7895,-0.05875 0.4095,-0.05875 0.1755,0.17625 C-0.0585,0.41025 -0.0585,0.79025 0.1755,1.02425 L4.2515,5.10025 L0.1755,9.17625 C-0.0585,9.41025 -0.0585,9.79025 0.1755,10.02425 C0.4095,10.25925 0.7895,10.25925 1.0245,10.02425 L5.0995,5.94925 L9.1755,10.02425 C9.4095,10.25925 9.7895,10.25925 10.0245,10.02425 C10.2585,9.79025 10.2585,9.41025 10.0245,9.17625 L5.9485,5.10025 Z' />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Close;
