import awesomePhonenumber from 'awesome-phonenumber';
import { get } from 'lodash-es';
import { validateEmail } from './emailUtils';

// for now handling validation of email and phone
export const identityValidate = (provider, input) => {
  if (input !== '') {
    if (provider === 'email') {
      return validateEmail(input);
    }

    if (provider === 'phone_number') {
      return validatePhone(input);
    }
  }
  return input;
};

export const validatePhone = (input) => {
  const phoneValidationRes = awesomePhonenumber(input);
  return (
    get(phoneValidationRes, ['g', 'valid'], false) &&
    get(phoneValidationRes, ['g', 'regionCode'], 'NO') !== 'NO'
  );
};

export const getPPAPlanPageUrl = (ppaGroupId, planId) => {
  return `/pay_per_asset_groups/${ppaGroupId}/pay_per_asset_plans/${planId || 'new'}`;
};
