import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button/Button';

import './Card.scss';

const Card = ({ children, title, scroll = false, titleSideButtonText = null, onButtonClick }) => {
  return (
    <div className="card card--white">
      <div className={scroll ? 'card__inner' : ''}>
        {title && (
          <div className='card__title-wrapper'>
            <h3 className="card__title">{title}</h3>
            {titleSideButtonText && <Button
              onClick={() => onButtonClick()}
              dataTest='import-csv-btn'
            >
              {titleSideButtonText}
            </Button>}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  scroll: PropTypes.bool
};

export default Card;
