/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';

const SubMenu = ({
  activeSubMenu,
  superUser,
  isSubMenuActive,
  isSideBarVisible
}) => {
  const classname = () => {
    if (isSideBarVisible && isSubMenuActive) {
      return 'is-open';
    } else {
      return '';
    }
  };
  return (
    <ul className={`vertical-nav-sub-menu ${classname()}`}>
      {activeSubMenu &&
        activeSubMenu.map((item, index) => (
          <div key = {index} >
            {item && (
              <h5
                className='vertical-nav-sub-menu__headline'
                key={`headline-${index}`}
              >
                {item.headline}
              </h5>
            )}
            {item &&
              item.links &&
              item.links.map((item, index) => {
                const show = () => {
                  if (!item.superUserOnly) {
                    return true;
                  } else {
                    return superUser && item.superUserOnly;
                  }
                };

                if (!show()) {
                  return null;
                } else {
                  return (
                    <li key={index} className='vertical-nav-sub-menu__item'>
                      <a
                        key={index}
                        href={item.linkTo}
                        data-test={item.dataTest}
                        className={`vertical-nav-sub-menu__link ${
                          item.active ? 'is-active' : ''
                        }`}
                      >
                        {item.text}
                      </a>
                    </li>
                  );
                }
              })}
          </div>
        ))}
    </ul>
  );
};

SubMenu.propTypes = {
  activeSubMenu: PropTypes.any,
  isSideBarVisible: PropTypes.bool,
  isSubMenuActive: PropTypes.bool,
  superUser: PropTypes.bool
};

export { SubMenu };
