import React from 'react';
import actionCable from 'actioncable';

import { Dialog } from '@quintype/em/components/dialog';
import { Button } from '@quintype/em/components/button';

import FileDownload from '../library-components/Icons/file-download';
import { StatusMessage } from '../library-components';

import {
  addSubscriptionMembersFromCSV
} from '../../api';

import './import-subscription-members.scss';

export default class ImportSubscriptionMembers extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      membersCsv: {},
      importInProgress: false,
      isImportDisabled: false,
      showNotification: false,
      notification: {}
    };
    this.notifyUser = this.notifyUser.bind(this);
  }

  handleImportClick () {
    if (this.state.importInProgress) {
      return;
    }
    if (this.state.membersCsv) {
      this.setState({ importInProgress: true });
      this.importCsv();
      return;
    }
    this.setState({
      notification: {
        status: 'error',
        statusMessage: 'Please upload csv to import subscription members'
      }
    });
    this.props.onClose();
  }

  cableApp () {
    return {
      cable: function () {
        return actionCable.createConsumer(
          window.actionCableNotifier.websocketServerUrl
        );
      }
    };
  }

  setupChannelSubscription (channel, callback) {
    this.cableApp().notify_user = this.cableApp()
      .cable()
      .subscriptions.create(channel, {
        received: function (data) {
          callback(data);
        }
      });
  }

  notifyUser (data) {
    const { message, status } = data.description.text;
    const isSuccess = status !== 'failed';

    this.props.handleNotification(message, isSuccess);
    this.afterMemberImport();
  }

  afterMemberImport () {
    this.props.reloadMembers();
  }

  importCsv () {
    addSubscriptionMembersFromCSV(this.props.subscriptionId, this.state.membersCsv)
      .then(message => this.props.onImport(message, true))
      .catch(err => this.props.onImport(err.message, false));

    this.setupChannelSubscription(
      'NotifyUsersChannel',
      this.notifyUser
    );
  }

  render () {
    return (
      <Dialog
        isOpen
        onClose={this.props.onClose}
        onAction={() => this.handleImportClick()}
        actionButtonLabel="Import"
      >
        <div className="wrapper">
          <h3 className="heading">Import Members</h3>
          <p className="description" data-test-id="dialog-description">
            Allows you to import all the members to the current group
            subscription through a CSV. Please check the template before
            uploading the CSV.
          </p>
          <div className="csv-template-wrapper">
            <a
              href={`/api/v1/management/subscriptions/${this.props.subscriptionId}/members_csv_template.csv`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button
                type="link"
                dataTest="member-csv-template"
              >
                <FileDownload fill={'#c6ccd5'} />
                CSV Template
              </Button>
            </a>
          </div>
          <div>
            <label>
              <input
                className="csv-file__input"
                type="file"
                onChange={(e) =>
                  this.setState({ membersCsv: e.target.files[0] })
                }
              />
              <span className="input-file-text" data-test-id="choose-csv-btn">
                Choose CSV file
              </span>
              <p className="input-csv-files" data-test-id="files-choosen-text">
                {this.state.membersCsv.name || 'No file choosen'}
              </p>
            </label>
          </div>
          {this.state.showNotification && (
            <StatusMessage
              statusMessage={this.state.notification.statusMessage}
              status={this.state.notification.status}
              onCancel={() => this.setState({ showNotification: false, notification: {} })}
            />
          )}
        </div>
      </Dialog>
    );
  }
}
