import React from 'react';
import _ from 'lodash-es';
import { Table, Link, CardHeader, SelectFieldWithLabel } from '../library-components';
import { Inspector } from '@quintype/em/components/inspector';
import { PublishedAt } from './published_at';
import Select from '@quintype/em/components/select';
import { TextField } from '@quintype/em/components/text-field';
import './assets.scss';
import validate from 'validate.js';
import AssetMetaFields from './asset-meta-fields';
import { Radio, RadioOption } from '@quintype/em/components/radio-button';

const INHERITANCE_CATEGORY_NAME = 'assets';
const VISIBILITY = [
  {
    label: 'Public',
    value: true
  },
  {
    label: 'Private',
    value: false
  }
];

function immutableSplice (arr, start, deleteCount, ...items) {
  return [...arr.slice(0, start), ...items, ...arr.slice(start + deleteCount)];
}

const ASSET_TYPE_LABELS_MAP = {
  dynamic_asset: {
    story: {
      site: 'Site',
      id: 'Story ID',
      access_level: 'Story Access Level',
      section: 'Stories in a Section'
    },
    collection: {
      id: 'Collection ID',
      magazine: 'Issues of a Magazine'
    }
  },
  default: {
    story: {
      id: 'Story ID',
      access_level: 'Story Access Level',
      section: 'Story Section',
      magazine: 'Story Magazine'
    },
    collection: {
      id: 'Collection ID'
    },
    site: 'Site'
  }
};

const maximumPublishedAtDuration = {
  days: 36524,
  weeks: 5217,
  months: 1200,
  years: 100
};

const publishedAtToConstraints = (durationUnit) => {
  return {
    duration_length: { presence: true, numericality: { strict: true, onlyInteger: true, greaterThan: 0, lessThanOrEqualTo: maximumPublishedAtDuration[durationUnit] } }
  };
};

export default class Assets extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      asset: { asset_category_id: null, id: null, title: null, metadata: {}, asset_parentable_id: null, published_at: {} },
      isCreateAssetOpen: false,
      isSaveAssetsEnabled: false,
      errors: {}
    };
    this.updateAssetMetadata = this.updateAssetMetadata.bind(this);
    this.updateAsset = this.updateAsset.bind(this);
    this.getAssetTypeOptionLabel = this.getAssetTypeOptionLabel.bind(this);
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.errors && nextProps.errors.length === 0) {
      this.setState({ isCreateAssetOpen: false, asset: { asset_category_id: null, id: null, title: null, metadata: {}, asset_parentable_id: null } });
    }
    return true;
  }

  updateAssetMetadata (field, value) {
    const metadata = this.state.asset.metadata;
    let isSaveAssetsEnabled = false;
    if (value) {
      metadata[field] = value;
      isSaveAssetsEnabled = true;
    } else {
      metadata[field] = '';
    }
    this.setState({ asset: Object.assign({}, this.state.asset, { metadata }), isSaveAssetsEnabled: isSaveAssetsEnabled });
  }

  updateAsset (field, value) {
    if (field === 'asset_category_id') {
      const newAsset = Object.assign({}, this.state.asset);
      delete newAsset.asset_parentable_id;
      const isSaveAssetsEnabled = value && !_.isEmpty(value) && (value === 'story site' || !_.startsWith(value, 'story ')) && value !== this.getInheritanceCategoryId();
      let assetCategoryId;
      const valueParts = value.split(' ');
      const assetType = valueParts[0];
      const metadataKey = valueParts[1];
      if (['story', 'collection'].includes(assetType)) {
        assetCategoryId = _.find(this.props.assetCategories, { name: assetType })['id'];
        const metadata = metadataKey === 'site' ? {} : { [metadataKey]: '' };
        this.setState({ asset: Object.assign({}, newAsset, { [field]: assetCategoryId, type: assetType, metadata: metadata, published_at: {} }) }, () => {
          this.setState({ isSaveAssetsEnabled: isSaveAssetsEnabled && this.enableSaveAssets(field, value) });
        });
      } else {
        assetCategoryId = _.find(this.props.assetCategories, { name: value })['id'];
        this.setState({ asset: Object.assign({}, newAsset, { [field]: assetCategoryId, type: assetType, metadata: {}, published_at: {} }) }, () => {
          this.setState({ isSaveAssetsEnabled: isSaveAssetsEnabled && this.enableSaveAssets(field, value) });
        });
      }
      return;
    } else if (field === 'asset_parentable_id') {
      const isSaveAssetsEnabled = !!value;
      this.setState({ asset: Object.assign({}, this.state.asset, { [field]: value, metadata: {} }) }, () => {
        this.setState({ isSaveAssetsEnabled: isSaveAssetsEnabled && this.enableSaveAssets(field, value) });
      });
      return;
    } else if (field === 'exclusive') {
      const isSaveAssetsEnabled = !!value;
      this.setState({ asset: Object.assign({}, this.state.asset, { [field]: value === 'exclusive' }) }, () => {
        this.setState({ isSaveAssetsEnabled: isSaveAssetsEnabled && this.enableSaveAssets(field, value) });
      });
      return;
    }
    if (field === 'published_at' && this.getAssetTypeValue() === 'story magazine' && value && value.from && !value.to) {
      value.to = {
        duration_length: this.props.subscriptionPlan.duration_length,
        duration_unit: this.props.subscriptionPlan.duration_unit
      };
    }
    this.setState({
      asset: Object.assign({}, this.state.asset, { [field]: value })
    }, () => {
      this.setState({ isSaveAssetsEnabled: this.enableSaveAssets(field, value) });
    });
  }

  enableSaveAssets (field, value) {
    if (this.state.asset.asset_category_id === this.getInheritanceCategoryId() && !this.state.asset.asset_parentable_id) {
      return false;
    } else if (_.startsWith(_.toString(this.state.asset.asset_category_id), '999') && _.isEmpty(this.state.asset.metadata)) {
      return false;
    } else if (!this.state.asset.asset_category_id) {
      return false;
    } else if (field === 'published_at') {
      if (value.to && value.to.duration_length && value.to.duration_unit) {
        const errors = validate(value.to, publishedAtToConstraints(value.to.duration_unit));
        if (errors) {
          this.setState({ errors: errors });
          return false;
        } else {
          this.setState({ errors: {} });
        }
      }
      if (!Object.keys(value).every(key => value[key] !== null)) {
        return false;
      }
    }
    return true;
  }

  addNewAsset (e) {
    this.setState({ isCreateAssetOpen: true, isSaveAssetsEnabled: false, asset: { asset_category_id: null, id: null, title: null, metadata: {}, asset_parentable_id: null } });
  }

  onSaveAsset (e) {
    const assets = [...this.props.assets];

    if (_.includes(_.keys(this.state.asset), 'index')) {
      const index = this.state.asset.index;
      assets.splice(index, 1);
      assets.push(this.state.asset);
      this.props.onChange(assets); // for edit
    } else {
      assets.push(this.state.asset);
      this.props.onChange(assets); // for new asset
    }
    this.setState({ isCreateAssetOpen: false });
  }

  getAssetCategoryMetafields () {
    return this.props.assetCategories.map((k) => ({ id: k['id'], metadata: k['metadata_fields'] }));
  }

  getCategoryId (asset) {
    const category = this.props.assetCategories.find(category => category.id === asset.asset_category_id);
    if (!category) {
      const categoryName = Object.keys(asset.metadata)[0];
      const category = this.props.assetCategories.find(category => category.name === 'story ' + categoryName);
      return category.id;
    } else {
      return asset.asset_category_id;
    }
  }

  getAssetMetadata (asset) {
    const category = this.props.assetCategories.find(category => category.id === asset.asset_category_id);
    if (!category && !asset.asset_parentable_id) {
      const metadataValue = asset.metadata && Object.values(asset.metadata)[0];
      return metadataValue ? { id: metadataValue } : {};
    } else {
      return asset.metadata || {};
    }
  }

  deleteAsset (e, index) {
    e.preventDefault();
    this.props.onChange(immutableSplice(this.props.assets, index, 1));
  }

  onEdit (asset, index) {
    this.setState({
      isCreateAssetOpen: true,
      isSaveAssetsEnabled: false,
      asset: {
        id: asset.id,
        asset_category_id: asset.asset_category_id ? this.getCategoryId(asset) : this.getInheritanceCategoryId(),
        metadata: this.getAssetMetadata(asset),
        published_at: asset.published_at,
        title: asset.title,
        asset_parentable_id: asset.asset_parentable_id,
        asset_parentable_type: asset.asset_parentable_type,
        index: index,
        public: asset.public,
        exclusive: asset.exclusive
      }
    });
  }

  getInheritanceCategoryId () {
    const inheritanceCategory = _.find(this.props.assetCategories, (item) => item.name === INHERITANCE_CATEGORY_NAME);
    return inheritanceCategory ? inheritanceCategory.id : null;
  }

  getAssetTypeOptionLabel (assetCategory, metadataField) {
    const subscriptionType = this.props.subscriptionGroupType;
    if (metadataField) {
      return _.get(ASSET_TYPE_LABELS_MAP, [subscriptionType, assetCategory, metadataField]) ||
        _.get(ASSET_TYPE_LABELS_MAP, ['default', assetCategory, metadataField]) ||
        `${assetCategory} ${metadataField}`;
    }
    return _.get(ASSET_TYPE_LABELS_MAP, [subscriptionType, assetCategory]) ||
      _.get(ASSET_TYPE_LABELS_MAP, ['default', assetCategory]) ||
      assetCategory;
  }

  getAssetTypeOptions (assetCategories) {
    if (assetCategories) {
      const temp = assetCategories.map(k => {
        const subCategories = (k && Object.keys(k.metadata_fields)) || [];
        if (k.name === 'story' && this.props.subscriptionGroupType === 'dynamic_asset') {
          subCategories.unshift('site');
        }
        if (subCategories.length > 0) {
          return subCategories.map((key) => ({ label: this.getAssetTypeOptionLabel(k.name, key), value: `${k.name} ${key}` }));
        } else {
          return { label: this.getAssetTypeOptionLabel(k.name), value: k.name };
        }
      });

      return _.flatten(temp);
    }
  }

  getAssetTypeValue () {
    if (!this.state.asset.asset_category_id) {
      return '';
    }
    const assetCategory = _.find(this.props.assetCategories, (item) => item.id === this.state.asset.asset_category_id);

    if (_.isEmpty(this.state.asset.metadata)) {
      if (assetCategory.name === 'story') {
        return 'story site';
      } else {
        return assetCategory.name;
      }
    } else {
      return `${assetCategory.name} ${_.keys(this.state.asset.metadata)[0]}`;
    }
  }

  render () {
    const options = this.getAssetTypeOptions(this.props.assetCategories);
    const isStoryMagazine = this.getAssetTypeValue() === 'story magazine';
    const showPublishedAt = this.props.showPublishedAt;
    const isPPA = this.props.subscriptionGroupType === 'dynamic_asset';
    return (
      <div>
        <CardHeader
          title='Assets'
          dataTest='add_assets'
          buttonText='Add Assets'
          buttonOnClick={() => this.addNewAsset()}
          isButton={this.props.id !== 'new'}
        />
        {this.state.isCreateAssetOpen && (
          <Inspector
            title={this.state.asset.id || this.state.asset.asset_parentable_id ? 'Edit asset' : 'Add asset'}
            isActive={this.state.isCreateAssetOpen}
            onClose={() => this.setState({ isCreateAssetOpen: false })}
            actionButtonLabel='Save'
            onActionButtonClick={e => this.onSaveAsset(e)}
            isActionButtonDisabled={!this.state.isSaveAssetsEnabled}
          >
            <TextField
              value={this.state.asset.title || ''}
              label='Asset Name'
              placeholder='Asset Name'
              onChange={e => this.updateAsset('title', e)}
            />
            {isPPA &&
              <SelectFieldWithLabel
                label="Visibility"
                value={!!this.state.asset.public}
                options={VISIBILITY}
                onChange={e => this.updateAsset('public', e.value)}
                id="asset-public"
                clearable={false}
              />
            }
            <Select
              value={this.getAssetTypeValue()}
              label='Type'
              options={options}
              onChange={e => this.updateAsset('asset_category_id', e.value)}
            />
            {this.props.assetCategories && this.state.asset.asset_category_id && (
              <AssetMetaFields asset={this.state.asset}
                metaFields={this.getAssetCategoryMetafields()}
                assetType={this.getAssetTypeValue()}
                updateAssetMetadata={this.updateAssetMetadata}
                updateAsset={this.updateAsset}
                inheritanceCategoryId={this.getInheritanceCategoryId()}
                assetFrom={this.props.assetFrom}
                subscriptionGroupType={this.props.subscriptionGroupType}
              />
            )}
            {showPublishedAt &&
              <PublishedAt updateAsset={(field, value) => this.updateAsset(field, value)}
                hideToField={isStoryMagazine}
                published_at_field={this.state.asset.published_at}
                errors={this.state.errors}
              />
            }
            {this.props.assetCategories && this.state.asset.asset_category_id && (this.getAssetTypeValue() === 'story id' || this.getAssetTypeValue() === 'collection id') &&
              <div className='radio-container'>
                <Radio
                  align="vertical"
                  selected={this.state.asset.exclusive ? 'exclusive' : 'inclusive'}
                  onChange={e => this.updateAsset('exclusive', e.target.value)}
                >
                  <RadioOption value="exclusive">
                    <div>
                      <p className='heading-radio-button'>Exclusive Access</p>
                      <p className='radio-button-description'>Restrict access from plan</p>
                    </div>
                  </RadioOption>
                  <RadioOption value="inclusive">
                    <div>
                      <p className='heading-radio-button'>Inclusive Access</p>
                      <p className='radio-button-description'>Allow subscribers with a plan to access. Click on the button below to see the list of plans that includes the above selected assets</p>
                    </div>
                  </RadioOption>
                </Radio>
              </div>
            }
          </Inspector>
        )}
        <Table header={['Type', 'Name', 'Conditions', 'Published At', '']}>
          {
            _.map(this.props.assets, (asset, index) => (
              <div className='table-default__row' key={index}>
                <div className='table-default__cell'>
                  {_.get(asset, 'type') || 'assets'}
                </div>
                <div className='table-default__cell'>{_.get(asset, 'title')}</div>
                <div className='table-default__cell'>
                  {JSON.stringify(_.get(asset, ['metadata'])) ||
                    _.get(asset, 'asset_parentable_name') ||
                    '_'}
                </div>
                <div className='table-default__cell'>
                  {_.get(asset, ['published_at', 'from']) !== undefined ? 'Custom Duration' : 'Anytime'}
                </div>
                <div className='table-default__cell'>
                  <Link onClick={() => this.onEdit(asset, index)}>Edit</Link>
                  <Link onClick={e => this.deleteAsset(e, index)} destructive>
                    Delete
                  </Link>
                </div>
              </div>
            ))}
        </Table>
      </div>
    );
  }
}
