const Promise = require('bluebird');
const agent = require('superagent-promise')(require('superagent'), Promise);

function getCsrfToken () {
  var content = document.getElementsByName('csrf-token')[0].content;
  return content;
}

export function getPromise (url, params = {}) {
  return new Promise((resolve, reject) => {
    agent.get(url).query(params).end().then(resolve, reject);
  });
}

export function postPromise (url, body) {
  return new Promise((resolve, reject) => {
    agent.post(url).send(body).set('X-CSRF-Token', getCsrfToken()).end().then(resolve, reject);
  });
}

export function postPromiseWithAttachment (url, fileName, file) {
  return new Promise((resolve, reject) => {
    agent.post(url).attach(fileName, file).set('X-CSRF-Token', getCsrfToken()).end().then(resolve, reject);
  });
}

export function putPromise (url, body) {
  return new Promise((resolve, reject) => {
    agent.put(url).send(body).set('X-CSRF-Token', getCsrfToken()).end().then(resolve, reject);
  });
}

export function patchPromise (url, body) {
  return new Promise((resolve, reject) => {
    agent.patch(url).send(body).set('X-CSRF-Token', getCsrfToken()).end().then(resolve, reject);
  });
}

export function deletePromise (url, params = {}) {
  return new Promise((resolve, reject) => {
    agent.del(url).set('X-CSRF-Token', getCsrfToken()).end().then(resolve, reject);
  });
}
