import React, { useEffect, useState } from 'react';
import { get } from 'lodash-es';
import validate from 'validate.js';
import { Inspector } from '@quintype/em/components/inspector';
import { Button as EmButton } from '@quintype/em/components/button';
import { TextField } from '@quintype/em/components/text-field';
import {
  TextAreaWithLabel,
  SelectFieldWithLabel,
  QuillEditorWithLabel
} from '../library-components';
import './custom-attributes-inspector.scss';
import { ModifiedEmDialog } from '../modified-em-dialog/index';
import { updateDialogContent, constraints } from './utils';
import { ATTRIBUTE_TYPE } from '../../constants';
import UploadImage from '../../components/upload-image';
import { ErrorMessage } from '@quintype/em/components/error-message';
import { imagePreviewCdnUrl } from '../../components/upload-image/utils';

const CustomAttributesInspector = ({
  activeAttributeIndex,
  isInspectorActive,
  onClose,
  addCustomAttribute,
  deleteCustomAttribute,
  updateAttribute,
  isNewAttribute,
  activeAttributeObj,
  usedAttributeNames,
  imageFiles,
  disabled
}) => {
  const [errors, setErrors] = useState({});
  const [isActionButtonDisabled, setActionButtonDisabled] = useState(true);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isAttributeEdited, setAttributeEdited] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [attribute, setAttribute] = useState(activeAttributeObj);
  const [activeAttributeImageFile, setActiveAttributeImageFile] = useState(
    imageFiles[activeAttributeObj.name]
  );

  useEffect(() => {
    setAttribute(activeAttributeObj);
    setAttributeEdited(false);
    setActionButtonDisabled(true);
    setErrors({});
    setActiveAttributeImageFile(imageFiles[activeAttributeObj.name]);
  }, [activeAttributeObj]);

  function addImageDataFile () {
    if (activeAttributeImageFile) { imageFiles[attribute.name] = activeAttributeImageFile; }
  }

  function getSavedImageUrl () {
    if (!activeAttributeImageFile && attribute.value) {
      return imagePreviewCdnUrl(activeAttributeObj.value);
    }
    return null;
  }

  function handleImageTrash () {
    setActiveAttributeImageFile(null);
    setAttribute(
      { ...attribute, value: '' }
    );
    setAttributeEdited(true);
    setActionButtonDisabled(false);
  }

  function updateAttributeDialogAction () {
    addImageDataFile();
    updateAttribute(attribute, activeAttributeIndex);
    setErrors({});
    setDialogOpen(false);
    onClose();
  }

  function deleteAttributeDialogAction () {
    deleteCustomAttribute(activeAttributeIndex);
    setErrors({});
    setDialogOpen(false);
    onClose();
  }

  function duplicateNameErrorDialogAction () {
    setDialogOpen(false);
  }

  function discardChangesDialogAction () {
    setDialogOpen(false);
    onClose();
  }

  function createAttribute () {
    const validationError = validate(attribute, constraints());

    if (validationError) {
      setErrors(validationError);
    } else if (usedAttributeNames.includes(attribute.name.toLowerCase())) {
      setErrors({});
      updateDialogContent(
        'duplicate_error',
        setDialogContent,
        duplicateNameErrorDialogAction,
        attribute.name
      );
      setDialogOpen(true);
    } else if (!isNewAttribute && isAttributeEdited) {
      setErrors({});
      updateDialogContent(
        'update',
        setDialogContent,
        updateAttributeDialogAction,
        attribute.name
      );
      setDialogOpen(true);
    } else {
      addImageDataFile();
      addCustomAttribute(attribute);
      setErrors({});
      onClose();
    }
  }

  function updateAttributeField (attributeObject) {
    if (attributeObject.type !== undefined) {
      setActiveAttributeImageFile(null);
      const { type, value, ...err } = errors;
      setErrors(err);
      setAttribute(
        Object.assign({}, attribute, attributeObject, {
          value: ''
        })
      );
    } else {
      setAttribute(Object.assign({}, attribute, attributeObject));
    }

    setActionButtonDisabled(false);
    setAttributeEdited(true);
  }

  function onCloseInspector () {
    if (isAttributeEdited) {
      updateDialogContent(
        'discard',
        setDialogContent,
        discardChangesDialogAction
      );
      setDialogOpen(true);
    } else {
      onClose();
    }
  }

  const updateRichTextValue = (value, delta, source) => {
    if (source === 'user') {
      updateAttributeField({ value: value });
    }
    return null;
  };

  const updateImageValue = (s3key) => {
    updateAttributeField({ value: s3key });
  };

  return (
    <div className='custom_attributes_inspector_container'>
      <Inspector
        title={isNewAttribute ? 'New Attribute' : 'Edit Attribute'}
        isActive={isInspectorActive}
        onClose={() => onCloseInspector()}
        onActionButtonClick={() => createAttribute()}
        actionButtonLabel={isNewAttribute ? 'Add' : 'Update'}
        isActionButtonDisabled={isActionButtonDisabled}
      >
        <TextField
          label="Name"
          placeholder="Attribute name"
          value={attribute.name || ''}
          onChange={(e) => updateAttributeField({ name: e })}
          errorMessage={get(errors, ['name', '0'], '')}
          disabled={disabled}
        />
        <SelectFieldWithLabel
          label="Type"
          value={attribute.type || 'Select Attribute Type'}
          onChange={(e) => updateAttributeField({ type: e ? e.value : '' })}
          errors={get(errors, ['type', '0'], '')}
          isClearable={true}
          options={ATTRIBUTE_TYPE}
          disabled={disabled}
        />
        {attribute.type === 'rich_text' && (
          <QuillEditorWithLabel
            errors={get(errors, ['value', '0'])}
            label="Text"
            placeholder="Add your text here"
            value={attribute.value || ''}
            onChange={updateRichTextValue.bind(this)}
            disabled={disabled}
          />
        )}
        {attribute.type === 'plain_text' && (
          <TextAreaWithLabel
            errors={get(errors, ['value', '0'])}
            label="Text"
            placeholder="Add your text here"
            rows="3"
            value={attribute.value || ''}
            onChange={(e) => updateAttributeField({ value: e.target.value })}
            disabled={disabled}
          />
        )}
        {attribute.type === 'image' && (
          <>
            <UploadImage
              id="custom-attribute-inspector-image"
              onFileUpload={updateImageValue}
              savedImageUrl={getSavedImageUrl()}
              imageFile={activeAttributeImageFile || ''}
              handleImageTrash={handleImageTrash}
              setActiveAttributeImageFile={setActiveAttributeImageFile}
              disabled={disabled}
            />
            <div className="error-message-container">
              <ErrorMessage
                message={
                  get(errors, ['value', '0']) ? 'Please upload image' : ''
                }
              />
            </div>
          </>
        )}
        {isNewAttribute === false && (
          <EmButton
            label="Delete Attribute Button"
            type="secondary"
            variant="danger"
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              updateDialogContent(
                'delete',
                setDialogContent,
                deleteAttributeDialogAction,
                activeAttributeObj.name
              );
              setDialogOpen(true);
            }}
          >
            Delete Attribute
          </EmButton>
        )}
      </Inspector>
      <ModifiedEmDialog
        isOpen={isDialogOpen}
        title={dialogContent.title}
        content={dialogContent.textContent}
        onClose={() => {
          setDialogOpen(false);
        }}
        firstButton={{
          type: 'primary',
          onClick: () => {
            dialogContent.actionButtonCallBack();
          },
          content: dialogContent.firstButtonLabel,
          variant: dialogContent.variant
        }}
        secondButton={{
          type: 'secondary',
          onClick: () => {
            setErrors({});
            setDialogOpen(false);
          },
          content: dialogContent.secondButtonLabel,
          greyed: true
        }}
      />
    </div>
  );
};

export default CustomAttributesInspector;
