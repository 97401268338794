const defaultPaywallMeteringRules = {
  any: [
    {
      fact: 'accessLevels',
      operator: 'in',
      value: []
    }
  ]
};

export { defaultPaywallMeteringRules };
