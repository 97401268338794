import React from 'react';
import DownArrow from '../../components/library-components/Icons/DownArrow';
import { Chevron as UpArrow } from '@quintype/em/icons/chevron';
import { InfoCircle } from '@quintype/em/icons/info-circle';
import currencyUtils from '../../helpers/currencyUtils';
import { ToolTip } from '@quintype/em/components/tooltip';
import { nanoid } from 'nanoid';
import './data_card.scss';

function formatValueWithSuffix (value, suffix) {
  let formattedValue = parseInt(value);
  if (formattedValue !== value) {
    formattedValue = parseFloat(value).toFixed(1);
  }
  return `${formattedValue}${suffix || ''}`;
}

function getFormattedCurrentValue (currentValue) {
  currentValue = parseFloat(currentValue);
  if (currentValue < 1000) {
    return formatValueWithSuffix(currentValue);
  }
  if (currentValue < 1000000) {
    return formatValueWithSuffix(currentValue / 1000, 'K');
  }
  return formatValueWithSuffix(currentValue / 1000000, 'M');
}

function DataCard ({
  id,
  title,
  currentValue,
  percentageDiff,
  intervalDuration,
  intervalUnit,
  info,
  currency,
  type
}) {
  const showCurrency = type === 'currency' && currency;
  const valueTooltipText = `${showCurrency ? currencyUtils.getCurrencySymbol(currency) : ''}${currentValue}`;
  const uid = id || nanoid(5);
  const valueTooltipId = `current-value-tooltip-${uid}`;
  const infoTooltipId = `info-tooltip-${uid}`;
  return (
    <div className="data_card__container">
      <div className="data_card__header_container">
        <h3 className="data_card__title">{title}</h3>
        {info && (
          <div
            className="data_card__info_icon"
            data-tip
            data-for={infoTooltipId}
          >
            <InfoCircle />
          </div>
        )}
      </div>
      {info && <ToolTip type="info" id={infoTooltipId} value={info} />}
      <div className="data_card__current" data-tip data-for={valueTooltipId}>
        {showCurrency && currencyUtils.getCurrencySymbol(currency)}
        {getFormattedCurrentValue(currentValue)}
      </div>
      <ToolTip type="info" id={valueTooltipId} value={valueTooltipText} />
      <div className="data_card__footer">
        <span
          className={`data_card__percent_diff data_card__percent_diff-${
            percentageDiff === 0 ? 'neutral' : percentageDiff < 0 ? 'negative' : 'positive'
          }`}
        >
          {percentageDiff < 0 ? <DownArrow /> : <UpArrow />}
          {percentageDiff}%
        </span>
        &nbsp;vs prev. {intervalDuration} {intervalUnit}
      </div>
    </div>
  );
}

export default DataCard;
