import React from 'react';
import PropTypes from 'prop-types';

const DownArrowFilled = ({ width, height, fill, uniqueId }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width={width} height={height}>
      <defs>
        <path id={`downArrow-${uniqueId}`} d='M8.707 11.707l2.586 2.586a1 1 0 0 0 1.414 0l2.586-2.586A1 1 0 0 0 14.586 10H9.414a1 1 0 0 0-.707 1.707z' />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id={`caret-down-${uniqueId}`} fill='#fff'>
          <use xlinkHref={`#downArrow-${uniqueId}`} />
        </mask>
        <use fill='#000' xlinkHref={`#downArrow-${uniqueId}`} />
        <g fill={fill} mask={`url(#caret-down-${uniqueId})`}>
          <path d='M0 0h24v24H0z' />
        </g>
        <path d='M0 0h24v24H0z' mask={`url(#caret-down-${uniqueId})`} />
      </g>
    </svg>
  );
};

DownArrowFilled.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
};

DownArrowFilled.defaultProps = {
  width: '16',
  height: '16',
  fill: '#9AA6B9'
};

export default DownArrowFilled;
