import React from 'react';
import PropTypes from 'prop-types';

const UnstyledLink = ({
  url, external, className, dataTest, children
}) => {
  const target = external ? '_blank' : undefined;
  const rel = external ? 'noopener noreferrer' : undefined;

  return (
    <a
      target={target}
      href={url}
      rel={rel}
      onClick={e => e.stopPropagation()}
      className={className}
      data-test-id={dataTest}
    >{children}
    </a>
  );
};

UnstyledLink.propTypes = {
  url: PropTypes.string,
  external: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
};

export { UnstyledLink };
