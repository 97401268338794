import React from 'react';
import PropTypes from 'prop-types';

const DownArrow = ({ width, height, fill }) => (
  <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width={width} height={height} viewBox='0 0 16 16'>
    <title>icon-chevron-down</title>
    <defs />
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='icon-chevron-down' fill={fill} fillRule='nonzero'>
        <path
          d='M8,10.1514719 L2.42426407,4.57573593 C2.18994949,
              4.34142136 1.81005051,4.34142136 1.57573593,4.57573593 C1.34142136,
              4.81005051 1.34142136,5.18994949 1.57573593,5.42426407 L7.57573593,
              11.4242641 C7.81005051,11.6585786 8.18994949,11.6585786 8.42426407,
              11.4242641 L14.4242641,5.42426407 C14.6585786,5.18994949 14.6585786,
              4.81005051 14.4242641,4.57573593 C14.1899495,4.34142136 13.8100505,
              4.34142136 13.5757359,4.57573593 L8,10.1514719 Z'
        />
      </g>
    </g>
  </svg>
);

DownArrow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
};

DownArrow.defaultProps = {
  width: '16',
  height: '16',
  fill: '#818A98'
};

export default DownArrow;
