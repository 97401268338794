import React from 'react';
import { connect } from 'react-redux';

import { SET_SETTINGS } from '../actions';
import { getQuintypeSettings, updateQuintypeSettings } from '../api';
import { Button, TitleBar, Card, Layout, SliderCheckbox } from '../components/library-components';

export class Settings extends React.Component {
  updateField (fieldPath, value) {
    this.props.setSettings(Object.assign({}, this.props.settings, { [fieldPath]: value }));
  }

  updateQuintypeSettings (e) {
    e.preventDefault();
    updateQuintypeSettings(this.props.settings)
      .then((response) => global.location.reload());
  }

  render () {
    return (
      <Layout>
        <TitleBar
          title='Settings'
        />
        <form>
          <Card title='Settings'>

            <SliderCheckbox
              label='Allow simultaneous subscriptions' name='allow_simultaneous_subscriptions'
              dataTest='secret-settings-allow-simultaneous-subscriptions'
              enabled={this.props.settings['allow_simultaneous_subscriptions']}
              onChange={(e) => this.updateField('allow_simultaneous_subscriptions', e.target.checked)}
            />

            <SliderCheckbox
              label='Deny if already recurring subscription present' name='deny_if_already_recurring_subscription_present'
              dataTest='secret-settings-deny-if-already-recurring-subscription-present'
              enabled={this.props.settings['deny_if_already_recurring_subscription_present']}
              onChange={(e) => this.updateField('deny_if_already_recurring_subscription_present', e.target.checked)}
            />

            <SliderCheckbox
              label='Campaign Subscriptions'
              name='campaign_subscriptions_enabled'
              dataTest='secret-settings-campaign-subscriptions'
              enabled={this.props.settings['campaign_subscriptions_enabled']}
              onChange={(e) => this.updateField('campaign_subscriptions_enabled', e.target.checked)}
            />

            <SliderCheckbox
              label='Group Subscriptions'
              name='group_subscriptions_enabled'
              dataTest='secret-settings-group-subscriptions'
              enabled={this.props.settings['group_subscriptions_enabled']}
              onChange={(e) => this.updateField('group_subscriptions_enabled', e.target.checked)}
            />

            <SliderCheckbox
              label='Individual Asset Purchase'
              name='pay_per_asset_enabled'
              dataTest='secret-settings-individual-asset-purchase'
              enabled={this.props.settings['pay_per_asset_enabled']}
              onChange={(e) => this.updateField('pay_per_asset_enabled', e.target.checked)}
            />

            <SliderCheckbox
              label='Metered Paywall'
              name='metered_paywall_enabled'
              dataTest='secret-settings-metered-paywall'
              enabled={this.props.settings['metered_paywall_enabled']}
              onChange={(e) => this.updateField('metered_paywall_enabled', e.target.checked)}
            />

            <SliderCheckbox
              label='Voucherify'
              name='voucherify_enabled'
              dataTest='secret-settings-voucherify'
              enabled={this.props.settings['voucherify_enabled']}
              onChange={(e) => this.updateField('voucherify_enabled', e.target.checked)}
            />

            <SliderCheckbox
              label='Voucherify Vouchers Enabled'
              name='voucherify_vouchers_enabled'
              dataTest='secret-settings-voucherify-vouchers-enabled'
              enabled={this.props.settings['voucherify_vouchers_enabled']}
              onChange={(e) => this.updateField('voucherify_vouchers_enabled', e.target.checked)}
            />

            <SliderCheckbox
              label='Paid Trial Enabled'
              name='paid_trial_enabled'
              dataTest='secret-settings-paid-trial-enabled'
              enabled={this.props.settings['paid_trial_enabled']}
              onChange={(e) => this.updateField('paid_trial_enabled', e.target.checked)}
            />

            <SliderCheckbox
              label='Rich Text Enabled'
              name='rich_text_enabled'
              dataTest='secret-settings-rich-text-enabled'
              enabled={this.props.settings['rich_text_enabled']}
              onChange={(e) => this.updateField('rich_text_enabled', e.target.checked)}
            />

            <SliderCheckbox
              label='Deny if identical active/pending OT subscription present'
              name='block_identical_simultaneous_ot_subs'
              dataTest='secret-settings-block_identical_simultaneous_ot_subs'
              enabled={this.props.settings['block_identical_simultaneous_ot_subs']}
              onChange={(e) => this.updateField('block_identical_simultaneous_ot_subs', e.target.checked)}
            />
            <Button primary onClick={(e) => this.updateQuintypeSettings(e)}>Update</Button>
          </Card>
        </form>
      </Layout>
    );
  }

  componentDidMount () {
    getQuintypeSettings().then(settings => this.props.setSettings(settings));
  }
}

function mapStateToProps (state) {
  return {
    settings: state.settings || {}
  };
}

function mapDispatchToProps (dispatch) {
  return {
    setSettings: (settings) => dispatch({ type: SET_SETTINGS, settings: settings })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
