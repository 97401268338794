import React from 'react';

import PhoneIdentity from './phone-identity';
import { getIdentity } from './utils';

const SubscriberIdentity = ({ subscriber }) => {
  if (!subscriber) {
    return null;
  }

  const subscriberIdentities = subscriber['subscriber_identities'];
  if (!subscriberIdentities) {
    return null;
  }

  const subscriberIdentity = getIdentity(subscriberIdentities);

  if (!subscriberIdentity) {
    return null;
  }

  const subscriberPhoneIdentity =
    subscriberIdentity.provider !== 'phone_number'
      ? subscriberIdentities.find(
        (identity) => identity.provider === 'phone_number'
      )
      : null;

  return (
    <>
      {subscriberIdentity.value || ''}
      {subscriberPhoneIdentity && (
        <PhoneIdentity phoneNum={subscriberPhoneIdentity.value} />
      )}
    </>
  );
};

export default SubscriberIdentity;
