import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Sidebar.scss';

const Sidebar = ({ children, isSideBarVisible }) => {
  return (
    <div className={classnames('sidebar-wrapper', { 'sidebar-wrapper--is-open': isSideBarVisible })}>
      {children}
    </div>
  );
};

Sidebar.propTypes = {
  children: PropTypes.node,
  isSideBarVisible: PropTypes.bool
};

export default Sidebar;
