import React from 'react';
import parse from 'html-react-parser';

function descriptionMarkup (richText) {
  return <div className='content'>
    <p className='description'>
      {parse(richText)}
    </p>
  </div>;
}

function ContentDescription (props) {
  const { currentUserId, pageType } = props;
  if (pageType === 'create_user') {
    return descriptionMarkup('You must have an accesstype account to be able to access the Dashboard.\n<br /><br />Enter your credentials in the given fields and click on “CREATE ACCOUNT” to continue');
  } else if (pageType === 'forgot-password') {
    return descriptionMarkup('Enter your email address and we will send you an email to reset your password');
  } else if (pageType === 'reset-password') {
    return descriptionMarkup('Create a new Password');
  }
  return !currentUserId
    ? descriptionMarkup('Sign in to your accesstype account to access your dashboard')
    : descriptionMarkup('You may contact your admin to provide access to an Accesstype account or write to us as at support@quintype.com to get access');
}

export default ContentDescription;
