import React from 'react';
import { Card } from '../library-components';
import SwitchPlanConfigure from './switch-plan-config';
import { get } from 'lodash-es';
import './switch-plan.scss';

export default function SwitchPlansCard ({ planConfig, updateConfig }) {
  const {
    upgrade_plan_config: upgradePlanConfig = null,
    crossgrade_plan_config: crossgradePlanConfig = null,
    recurring
  } = planConfig;

  function warningMessage () {
    const supportedPaymentGateways = get(window.qtConfig, 'switchPlansSupportedPaymentGateways', []);
    if (supportedPaymentGateways.length < 1) return null;
    return `This feature is only supported for plans associated with 
      ${supportedPaymentGateways.slice(0, -1).join(', ')}
      ${supportedPaymentGateways.length > 1 ? ' and ' : ''}
      ${supportedPaymentGateways.slice(-1)}`;
  }

  const planType = recurring ? 'recurring' : 'onetime';

  return (
    <Card title="Plan Switching">
      <div className="pg-warning"> {warningMessage()} </div>
      <SwitchPlanConfigure
        switchInfo={{
          switchType: 'Upgrading',
          fieldName: 'upgrade_plan_config'
        }}
        updateConfig={updateConfig}
        planConfig={planConfig}
        prorated={true}
        planType={planType}
      />
      {!(upgradePlanConfig && upgradePlanConfig.enabled) && <hr className='divide-switch-plan-config' />}
      <SwitchPlanConfigure
        switchInfo={{
          switchType: 'Crossgrading',
          fieldName: 'crossgrade_plan_config'
        }}
        updateConfig={updateConfig}
        planConfig={planConfig}
        planType={planType}
      />
      {!(crossgradePlanConfig && crossgradePlanConfig.enabled) && <hr className='divide-switch-plan-config' />}
      <SwitchPlanConfigure
        switchInfo={{
          switchType: 'Downgrading',
          fieldName: 'downgrade_plan_config'
        }}
        updateConfig={updateConfig}
        planConfig={planConfig}
        planType={planType}
      />
    </Card>
  );
}
