import React from 'react';

const Omise = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="34"fill="none"viewBox="0 0 104 38">
    <path fill="#2E374D" d="M50.4 26.406c-3.361 0-5.927-2.133-5.927-5.928s2.693-5.982 6.1-5.982c3.407 0 5.9 2.17 5.9 5.928s-2.747 5.982-6.073 5.982zm.055-9.1c-1.536 0-2.313 1.23-2.313 3.145s.795 3.1 2.332 3.1 2.313-1.193 2.313-3.127c0-1.934-.795-3.127-2.332-3.127v.01zm21.38 2.168c0-1.337-.578-1.807-1.518-1.807-.67.018-1.32.27-1.807.723v7.663h-3.705v-6.58c0-1.337-.596-1.807-1.536-1.807a2.76 2.76 0 00-1.807.67v7.716h-3.705l.018-11.314h3.705v1.446c.994-1.03 2.097-1.663 3.56-1.645 1.4 0 2.603.67 3.145 2.006 1.03-1.23 2.332-1.988 3.868-1.988 2.187 0 3.488 1.464 3.47 3.868l-.018 7.663H71.8l.034-6.614zm7.43-6.153c-1.356 0-2.187-.705-2.187-1.97 0-1.32.85-1.988 2.187-1.988 1.337 0 2.187.67 2.187 2.006 0 1.265-.813 1.952-2.187 1.952zm-1.844 1.446h3.705l-.018 11.314h-3.705l.018-11.314zm9.65 11.692c-1.536 0-3.56-.343-4.735-.994l.723-2.675a9.2 9.2 0 004.085 1.066c1.012 0 1.518-.307 1.518-.904 0-.65-.633-.868-1.898-1.157-2.603-.524-4.247-1.464-4.247-3.65 0-2.097 1.825-3.58 4.934-3.58 1.482 0 3.434.434 4.536.976l-.74 2.64c-1.265-.65-2.783-1.012-3.85-1.012-.904 0-1.374.3-1.374.83 0 .596.687.85 1.916 1.102 2.62.578 4.2 1.446 4.193 3.687.018 2.006-1.753 3.67-5.06 3.67l-.001.001zm12.346-2.7c1.23 0 2.53-.434 3.65-1.084l.36 2.856c-1.12.578-2.7.904-4.356.904-3.777 0-6.235-2.097-6.235-5.856 0-3.65 2.512-6.037 6.055-6.02 3.163 0 4.898 1.898 4.898 4.7 0 .596-.072 1.482-.235 1.97l-7.085.108v.054c.108 1.536 1.12 2.368 2.946 2.368h.002zm.94-4.482c0-1.337-.542-2.133-1.7-2.15-1.265 0-2.042 1.102-2.17 2.368l3.868-.018.002-.2z"></path>
    <path fill="#2176FF" d="M32.292 5.71c-7.332-7.332-19.25-7.332-26.582 0-7.332 7.332-7.332 19.25 0 26.582 7.332 7.332 19.25 7.332 26.582 0 7.332-7.332 7.332-19.25 0-26.582zM29.36 19.413l-9.945 9.945a.595.595 0 01-.853 0l-9.945-9.945a.595.595 0 010-.853l9.945-9.945c.24-.24.613-.24.853 0l9.945 9.945c.24.24.24.613 0 .853z"></path>
    <path fill="#1A53F0" d="M37.8 18.96c0-4.986-1.972-9.785-5.491-13.305-2.986-2.986-7.812-3.013-10.798-.027l-2.933 2.906a.59.59 0 01.4-.16.63.63 0 01.427.187l12.85 12.878a7.63 7.63 0 010 10.798A18.707 18.707 0 0037.8 18.96zM5.685 32.2c2.986 2.986 7.812 2.986 10.825.027l2.906-2.96c-.213.24-.613.24-.853.027l-9.918-9.918c-.133-.107-.187-.293-.187-.453 0-.16.053-.32.16-.427l-2.88 2.88a7.684 7.684 0 00-.053 10.825z"></path>
  </svg>
);

export default Omise;
