import React from 'react';
import classnames from 'classnames';
import { Button } from '../../library-components';
import './CardHeader.scss';

const CardHeader = ({ title, isButton, buttonText, buttonOnClick, description, dataTest, isButtonDisabled = false }) => {
  return (
    <div data-test={dataTest} className={classnames('card-header', { 'card-header--two-column': isButton })}>
      {title && <h3 className='card-header__title'>{title}</h3>}
      {isButton && <div className='card-header__button-container'><Button disabled={isButtonDisabled}onClick={buttonOnClick}>{buttonText}</Button></div>}
      {description && <p className='card-header__description'>{description}</p>}
    </div>
  );
};

export default CardHeader;
