import React from 'react';
import classnames from 'classnames';
import './SliderCheckbox.scss';

const SliderCheckbox = ({ onChange, enabled, label, dataTest, name }) => {
  return (<div className='slider-checkbox-container'>
    <label className='switch-container' htmlFor={name}>
      {label && <div>{label}</div>}
      <div className={`switch ${classnames({ 'switch-enabled': enabled })}`}>
        <input type='checkbox' id={name} name={name}
          className='checkbox' onChange={onChange}
          data-test={dataTest} checked={enabled || false} />
        <div className='slider round' />
      </div>
    </label>
  </div>);
};

export default SliderCheckbox;
