import React, { useEffect, useState, useCallback } from 'react';

import { Inspector } from '@quintype/em/components/inspector';
import Select from '@quintype/em/components/select';
import { Button } from '@quintype/em/components/button';
import { getSubscriptionGroupsLite } from '../../api';
import { Checkbox } from '@quintype/em/components/checkbox';

import './switch-plans-config-inspector.scss';
import { ModifiedEmDialog } from '../modified-em-dialog';

function getPlanIdToDetailsMap (subscriptionGroups) {
  const plans = {};
  subscriptionGroups.forEach(group => {
    group.subscription_plans.forEach(plan => {
      plans[plan.id] = plan;
    });
  });
  return plans;
}

export default function SwitchPlanConfigInspector ({
  isActive,
  onClose,
  planType,
  planConfig,
  initSelectedPlansDetails,
  onPlansSelect = () => {}
}) {
  const groupTypes = [
    { label: 'Standard', value: 'standard' },
    { label: 'Campaign', value: 'campaign' }
  ];

  const [groupType, setGroupType] = useState('standard');
  const [group, setGroup] = useState(null);
  const [groups, setGroups] = useState(null);
  const [plans, setPlans] = useState(null);
  const [showPlans, setShowPlans] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [subscriptionGroups, setSubscriptionGroups] = useState([]);
  const [showCloseWarning, setShowCloseWarning] = useState(false);

  const onCloseInspector = () => {
    onClose();
  };

  useEffect(() => {
    if (initSelectedPlansDetails && initSelectedPlansDetails.length && isActive) {
      const initPlans = {};
      initSelectedPlansDetails.forEach(plan => {
        initPlans[plan.id] = true;
      });
      setSelectedPlans(initPlans);
    } else {
      setSelectedPlans([]);
    }
  }, [initSelectedPlansDetails, isActive]);

  const PlanList = ({ plans }) => {
    const shouldRenderPlanList = !(plans === null || plans.length === 0 || plans[group] === null || showPlans === false);

    if (!shouldRenderPlanList) return null;

    const planList = plans[group];

    const handlePlanSelect = (checked, planId) => {
      if (allSelected === true) {
        setSelectedPlans({ ...selectedPlans, [planId]: checked });
        setAllSelected(false);
      } else {
        setSelectedPlans({ ...selectedPlans, [planId]: checked });
      }
    };

    const onAllSelect = (selected) => {
      const allPlansInGroup = {};

      for (const plan of planList) {
        allPlansInGroup[plan.value] = selected;
      }

      setSelectedPlans({ ...selectedPlans, ...allPlansInGroup });

      setAllSelected(selected);
    };

    return (
      <div className='select-plans-list'>
        {planList.length > 0 && <Checkbox label='All' key='all' onChange={onAllSelect} checked={allSelected} />}
        {
          shouldRenderPlanList && planList.map(plan => {
            return (
              <Checkbox
                label={plan.label}
                id={plan.value}
                key={plan.value}
                onChange={(checked) => handlePlanSelect(checked, plan.value)}
                checked={selectedPlans[plan.value] || allSelected}
              />
            );
          })
        }
      </div >);
  };

  const onGroupTypeSelection = (option) => {
    setGroupType(option.value);
    setGroup(null);
    setShowPlans(false);
  };

  const onGroupSelection = (option) => {
    setGroup(option.value);
    setShowPlans(false);
    setAllSelected(false);
  };

  const supportedPaymentProviderCurrencies = (plan, paymentProvider) => {
    // TODO: handle for paytrail recurring plans
    if (plan.recurring && plan.metadata[paymentProvider] && plan.metadata[paymentProvider]['plan_id']) { return Object.keys(plan.metadata[paymentProvider]['plan_id']); }
    return Object.keys(plan.prices) || [];
  };

  const hasCommonPaymentProvider = useCallback(
    (subscriptionGroupPlan) => {
      if (!planConfig['supported_payment_providers']) return false;

      const commonPaymentProviders = subscriptionGroupPlan[
        'supported_payment_providers'
      ].filter((pg) => planConfig['supported_payment_providers'].includes(pg));

      for (const paymentProvider of commonPaymentProviders) {
        const subscriptionGroupPlanSupportedCurrencies =
          supportedPaymentProviderCurrencies(
            subscriptionGroupPlan,
            paymentProvider
          );
        const planConfigSupportedCurrencies =
          supportedPaymentProviderCurrencies(planConfig, paymentProvider);

        if (
          subscriptionGroupPlanSupportedCurrencies.filter((x) =>
            planConfigSupportedCurrencies.includes(x)
          ).length > 0
        ) {
          return true;
        }
      }
      return false;
    },
    [planConfig]
  );

  useEffect(() => {
    getSubscriptionGroupsLite(groupType).then(subscriptionGroups => {
      setSubscriptionGroups(subscriptionGroups);
      const planOptions = {};
      const groupOptions = [];

      for (const subscriptionGroup of subscriptionGroups) {
        const subscriptionGroupPlans = [];
        for (const subscriptionGroupPlan of subscriptionGroup.subscription_plans) {
          if (subscriptionGroupPlan.id === planConfig.id) continue;
          const subscriptionPlanType = subscriptionGroupPlan.recurring ? 'recurring' : 'onetime';
          if (subscriptionPlanType === planType && hasCommonPaymentProvider(subscriptionGroupPlan)) {
            subscriptionGroupPlans.push(
              {
                label: subscriptionGroupPlan.name || subscriptionGroupPlan.title,
                value: subscriptionGroupPlan.id.toString()
              }
            );
          }
        }

        if (subscriptionGroupPlans.length !== 0) {
          planOptions[subscriptionGroup.id.toString()] = subscriptionGroupPlans;
          groupOptions.push(
            { label: subscriptionGroup.name || subscriptionGroup.title, value: subscriptionGroup.id.toString() }
          );
        }
      }

      setGroups(groupOptions);

      setPlans(planOptions);
    });
  }, [groupType, planType, planConfig, hasCommonPaymentProvider]);

  const handleAddSelected = () => {
    const planIdDetailsMap = getPlanIdToDetailsMap(subscriptionGroups);
    const initPlanIds = (initSelectedPlansDetails || []).map(plan => ('' + plan.id));
    const existingPlanIds = [];
    const newPlanIds = [];
    Object.keys(selectedPlans).filter(id => selectedPlans[id]).forEach(planId => {
      if (initPlanIds.includes(planId)) {
        existingPlanIds.push(planId);
      } else {
        newPlanIds.push(planId);
      }
    });
    onPlansSelect([...newPlanIds, ...existingPlanIds].map(id => planIdDetailsMap[id]));
  };

  const closeWarningDialogContent = 'Selected plans have not been added. Click on Cancel to head back and add them.';

  const handleInspectorCloseClick = () => {
    const selectedPlanIds = Object.keys(selectedPlans).filter(id => selectedPlans[id]);
    const initPlanIds = initSelectedPlansDetails ? initSelectedPlansDetails.map(plan => ('' + plan.id)) : [];
    const noChangesPresent = (selectedPlanIds.length === initPlanIds.length) &&
      (selectedPlanIds.every((val, index) => initPlanIds.includes(val)));
    if (noChangesPresent) {
      onCloseInspector();
    } else {
      setShowCloseWarning(true);
    }
  };

  return (
    <Inspector
      isActive={isActive}
      onClose={handleInspectorCloseClick}
      title='FILTER'
      actionButtonLabel='Add Selected'
      isActionButtonDisabled={false}
      onActionButtonClick={handleAddSelected}
    >
      <Select
        label="Group Type"
        options={groupTypes}
        onChange={onGroupTypeSelection}
        value={groupType}
        clearable={false}
      />
      <Select
        label="Group"
        options={groups}
        onChange={onGroupSelection}
        value={group}
        clearable={false}
      />
      {group &&
        <div className="show-plans-button-container">
          <Button classname="show-plans-button" onClick={() => setShowPlans(true)}> Show Plans </Button>
        </div>
      }

      <PlanList plans={plans} />
      <ModifiedEmDialog
        isOpen={showCloseWarning}
        title="Discard Changes"
        content={closeWarningDialogContent}
        onClose={() => {
          setShowCloseWarning(false);
        }}
        secondButton={{
          type: 'primary',
          onClick: () => {
            setShowCloseWarning(false);
          },
          content: 'Cancel'
        }}
        firstButton={{
          type: 'secondary',
          onClick: () => {
            setShowCloseWarning(false);
            onCloseInspector();
          },
          content: 'Discard and Close'
        }}
      />
    </Inspector>
  );
}
