import React from 'react';

import Trash from '../../pages/Icons/trash';

import './upload-image.scss';

const Preview = ({ label, imagePreviewUrl, fileName, handleTrash, disabled }) => {
  if (!imagePreviewUrl) {
    return null;
  }

  return (
    <div className="upload-container">
      <div className="upload-heading">{label || 'Image'}</div>
      <div className="image-preview-wrapper">
        <div className="preview-details">
          <img src={imagePreviewUrl} className="preview-image" />
          <figcaption className="file-name">{fileName}</figcaption>
        </div>
        {!disabled &&
        <button className="trash-button" onClick={handleTrash}>
          <Trash />
        </button>
        }
      </div>
    </div>
  );
};

export default Preview;
