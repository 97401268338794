import React from 'react';
import _ from 'lodash-es';
import { getAnalyticalReportSubscriptionCreated } from '../../api';
import AnalyticsFilterBar from '../analytics-filter-bar';
import chartEvents from '../../helpers/chartUtils';
import { months } from '../../helpers/dateUtils';
import AreaChart from '../../components/library-components/chart/area_chart';
import { getRandomColor } from '../../helpers/colorUtils';
import OptionsFilter from '../options-filter';
import SelectField from '../../components/library-components/SelectField/SelectField';

const DAY = 'DAY';

class SubscriptionCreationGraph extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      dateType: 'DAY',
      from: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      to: new Date(),
      subscriptionsCreated: {},
      currentSubscriptionsCreated: { initial: 'data' }, // to not trigger no data message
      currentSubscriptionsCreatedByPlan: { initial: 'data' }, // to not trigger no data message
      currentSubscriptionsCreatedByGroup: { initial: 'data' }, // to not trigger no data message
      groupBy: 'group',
      colors: []
    };
    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    this.handleDateTypeChange = this.handleDateTypeChange.bind(this);
    this.transformData = this.transformData.bind(this);
    this.dataByGranularity = this.dataByGranularity.bind(this);
    this.dataByDuration = this.dataByDuration.bind(this);
    this.clickCheckBox = this.clickCheckBox.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.deselectAll = this.deselectAll.bind(this);
  }

  componentDidMount () {
    this.getFilteredData();
  }

  updateSubscriptionsData (data, type) {
    const newColors = Object.keys(data).map((plan) => data[plan]['color']);
    let x;
    (type === 'group') ? x = 'currentSubscriptionsCreatedByGroup' : x = 'currentSubscriptionsCreatedByPlan';
    this.setState({
      [x]: data,
      currentSubscriptionsCreated: data,
      colors: newColors
    }, () => this.transformData());
  }

  selectSubscriptionsData (data) {
    let newData = {};
    const newObj = {
      group: this.state.currentSubscriptionsCreatedByGroup,
      plan: this.state.currentSubscriptionsCreatedByPlan
    };
    // eslint-disable-next-line no-prototype-builtins
    if (!newObj[this.state.groupBy].hasOwnProperty('initial')) {
      newData = newObj[this.state.groupBy];
    } else {
      newData = data;
    }
    return newData;
  }

  clickCheckBox (e, planKey) {
    var currentSubscriptionsCreated = Object.keys(this.state.currentSubscriptionsCreated);
    if (e.target.checked) {
      currentSubscriptionsCreated = currentSubscriptionsCreated.concat(planKey);
    } else {
      currentSubscriptionsCreated = currentSubscriptionsCreated.filter((plan) => plan !== planKey);
    }

    const newCurrentSubscriptionsCreated = _.pick(this.state.subscriptionsCreated, currentSubscriptionsCreated); // eslint-disable-line no-undef
    this.updateSubscriptionsData(newCurrentSubscriptionsCreated, this.state.groupBy);
  }

  selectAll () {
    this.updateSubscriptionsData(this.state.subscriptionsCreated, this.state.groupBy);
  }

  deselectAll () {
    this.updateSubscriptionsData({}, this.state.groupBy);
  }

  getTypeForAxis (dateType) {
    return dateType === DAY ? 'date' : 'string';
  }

  randomColorGenerate (data) {
    const colors = Array.from(Array(Object.keys(data).length), getRandomColor);
    Object.keys(data).map((planName, index) => data[planName]['color'] = colors[index]); // eslint-disable-line no-return-assign
    return { data, colors };
  }

  updateCurrentPlansBasedOnIntersection (data) {
    const newData = this.selectSubscriptionsData(data);
    const allPlanKeys = Object.keys(data);
    const currentPlanKeys = Object.keys(newData);
    const intersection = _.intersection(allPlanKeys, currentPlanKeys);

    let newObj = {};
    intersection.map(item => {
      newObj = Object.assign(newObj, { [item]: newData[item] });
    });
    this.setState({
      currentSubscriptionsCreated: newObj
    }, () => this.transformData());
  }

  getFilteredData () {
    getAnalyticalReportSubscriptionCreated(this.state.from, this.state.to, this.state.dateType, this.state.groupBy).then(data => {
      const dataWithColors = this.randomColorGenerate(data);

      this.setState({ subscriptionsCreated: dataWithColors.data, errorMessage: null, colors: dataWithColors.colors }, () => this.updateCurrentPlansBasedOnIntersection(data));
    }).catch((e) => this.setState({ errorMessage: e.response.body.error.message }));
  }

  transformData () {
    const values = this.dataByGranularity(this.state.currentSubscriptionsCreated, this.state.dateType);
    this.setState({ values: values });
  }

  dataByGranularity (subs, filter) {
    const z = Object.keys(subs).map(function (key) {
      return { type: 'number', label: key };
    });

    const x = this.dataByDuration(subs, filter);
    const y = [[{ type: this.getTypeForAxis(this.state.dateType), label: 'Date' }, ...z]];

    return y.concat(x);
  }

  dataByDuration (subs, filter) {
    const planKeys = Object.keys(subs);
    const finalArray = [];

    Object.keys(subs[planKeys[0]] || {}).map(function (key) {
      let dateFormat = new Date(key.replace(/-/g, '/'));

      if (filter !== DAY) {
        dateFormat = `${months[new Date(key).getMonth()]} ${new Date(key).getFullYear()}`;
      }
      const tempArray = [dateFormat];
      planKeys.map(function (planKey) {
        tempArray.push(subs[planKey][key]);
      });
      finalArray.push(tempArray);
    });
    finalArray.pop();
    return finalArray;
  }

  handleDateTypeChange (selectedDateType) {
    const self = this;
    this.setState({ dateType: selectedDateType.value }, () => self.getFilteredData());
  }

  handleDateRangeChange (from, to) {
    var self = this;
    this.setState({ from, to }, () => self.getFilteredData());
  }

  setFilter (value) {
    const self = this;
    this.setState({ groupBy: value }, () => self.getFilteredData());
  }

  render () {
    return (
      <div>
        <AnalyticsFilterBar
          dateType={this.state.dateType}
          handleDateTypeChange={(e) => this.handleDateTypeChange(e)}
          from={this.state.from}
          to={this.state.to}
          errorMessage={this.state.errorMessage}
          handleDateRangeChange={this.handleDateRangeChange}
        />
        <div className='analytical-graph-wrap'>
          {Object.keys(this.state.currentSubscriptionsCreated).length === 0 && <p className='analytical-graph-wrap__no-data-message'>{this.state.deselectAllCheckBoxes ? `Select ${this.state.groupBy}s to see data` : 'No data to display'}</p> }
          {Object.keys(this.state.currentSubscriptionsCreated).length !== 0 && <AreaChart
            height={'400px'}
            width={'100%'}
            loader={<div className='loader-custom' />}
            values={this.state.values}
            legendPosition='top'
            pointSize={0}
            colors={this.state.colors}
            seriesAxis0='Count'
            yAxisLabel='Count'
            yAxisKey='Count'
            hAxisLabel='Time'
            vAxisLabel='Subscription Count'
            hAxisFormat={'dd/MM'}
            vAxisFormat={'short'}
            isStacked
            chartEvents={chartEvents}
          />}

        </div>
        <div className='filters'>
          <div className='compare'>
            <h4 className='compare-heading'>Compare</h4>
            <SelectField
              options={[{ label: 'Groups', value: 'group' }, { label: 'Plans', value: 'plan' }]}
              clearable={false}
              className='select-provider'
              placeholder='Plans/Groups'
              searchable={false}
              valueKey='value'
              labelKey='label'
              value={this.state.groupBy}
              onChange={(e) => this.setFilter(e.value)}
            />
          </div>
        </div>
        <OptionsFilter
          options={this.state.subscriptionsCreated}
          currentOptions={this.state.currentSubscriptionsCreated}
          selectAll={this.selectAll}
          deselectAll={this.deselectAll}
          clickCheckBox={(e, optionKey) => this.clickCheckBox(e, optionKey)}
        />
      </div>
    );
  }
}

export default SubscriptionCreationGraph;
