import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash-es';
import Select from 'react-select';
import { nanoid } from 'nanoid';
import {
  getSubscriptionPlan,
  getPaymentGateways,
  createOrUpdateSubscriptionPlan,
  postCreateVoucherifyProduct,
  updateVoucherifyProduct,
  getAssetCategoriesCors,
  getAcccountConfig,
  destroySubscriptionPlan,
  getSubscriptionGroups
} from '../api';
import currencyUtils, { ALL_CURRENCIES } from '../helpers/currencyUtils';
import {
  Button,
  ButtonGroup,
  Link,
  SelectFieldWithLabel,
  TextFieldWithLabel,
  NumberFieldWithLabel,
  Alert,
  StatusMessage,
  Layout,
  TitleBar,
  Card
} from '../components/library-components';
import { ModifiedEmDialog } from '../components/modified-em-dialog/index';
import { getOmiseDetails } from './create_update_subscription_plan';
import validate from 'validate.js';
import { Button as EmButton } from '@quintype/em/components/button';

import './ppa.css';
import PriceSelector from '../components/price-selector';
import ConfigurePlanFallbackCurrency from '../components/configure-plan-fallback-currency';
import Assets from '../components/assets/assets';

const LIFETIMEVALUE = 'lifetime';
const LIFETIMELABEL = 'lifetime (100 Years)';
const DURATION_UNITS_ONE_TIME = ['weeks', 'months', 'years', LIFETIMELABEL].map((k) => ({ label: k, value: k === LIFETIMELABEL ? LIFETIMEVALUE : k }));
const HIDDEN = false;

const getOptions = (item) => {
  return item.map((k) => ({ label: k['name'], value: k['id'] }));
};

export class PayPerAssetPlan extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      errors: {},
      priceCents: '0',
      showNotification: { isNotificationOpen: false, status: '', statusMessage: '' },
      voucherifyEnabled: _.get(window.qtConfig, 'voucherifyEnabled', false) === 'true',
      enabledPaymentProviders: [],
      showGatewayWarning: false,
      assetCategories: [],
      showDialog: false,
      dialogContext: ''
    };

    this.updateField = this.updateField.bind(this);
    this.enableSave = this.enableSave.bind(this);
    this.onSave = this.onSave.bind(this);
    this.updateSubscriptionPlan = this.updateSubscriptionPlan.bind(this);
    this.updatePriceWithPlanItems = this.updatePriceWithPlanItems.bind(this);
  }

  componentDidMount () {
    getAssetCategoriesCors(true).then(assetCategories => {
      this.setState({ assetCategories: assetCategories });
      this.setState({ assetCategoryOptions: getOptions(assetCategories) });
      this.loadSubscriptionPlan(assetCategories);
    });
    getPaymentGateways().then(config => {
      const pg = config.payment_gateways;
      const actualEnabledPaymentGateways = [];
      for (const key in pg) {
        const pgDetails = pg[key];
        if (pgDetails.enabled) {
          actualEnabledPaymentGateways.push(key);
        }
      }
      this.setState({
        enabledPaymentProviders: [...actualEnabledPaymentGateways]
      });
    });
    getAcccountConfig().then(data => {
      this.setState({ accountFallbackCurrency: data.account.fallback_currency });
    });
    getSubscriptionGroups().then((groups) => {
      this.setState({ subscriptionGroups: groups });
    }
    );
  }

  loadSubscriptionPlan (assetCategories) {
    const defaults = { title: '', price_currency: 'INR', duration_unit: LIFETIMEVALUE, duration_length: 1, display_assets: [{ asset_category_id: assetCategories.filter(category => category.name === 'story')[0].id, title: 'An individually purchasable asset', type: 'story', public: true }] };

    getSubscriptionPlan(this.props.subscriptionGroupId, this.props.subscriptionPlanId)
      .then(plan => {
        const currencies = Object.keys(
          plan.prices || []
        );
        const isMultipleCurrency = currencies.length > 1;
        const selectedFallbackCurrency = plan.fallback_currency
          ? 'local'
          : isMultipleCurrency
            ? 'account'
            : null;
        this.setState({
          priceCents: currencyUtils.convertToCurrencyUnits(_.get(plan, 'price_cents', 0)),
          selectedFallbackCurrency,
          subscriptionPlan: this.props.subscriptionPlanId === 'new' ? Object.assign({}, plan, defaults) : plan
        });
      });
  }

  sendData () {
    const currencies = Object.keys(this.state.subscriptionPlan.prices || {});
    const currenciesLength = currencies.length;
    const fallbackCurrencyValid = this.state.selectedFallbackCurrency &&
      (this.state.selectedFallbackCurrency !== 'local' || this.state.subscriptionPlan.fallback_currency);

    if (
      this.props.config.multipleCurrencyEnabled && currenciesLength > 1 && !fallbackCurrencyValid
    ) {
      this.setState(
        {
          showDialog: true,
          dialogContext: 'fallback-currency-unset'
        },
        () => {
          this.fallbackCurrencyUnsetDialogData();
        }
      );

      return;
    }
    createOrUpdateSubscriptionPlan(this.props.subscriptionGroupId, this.state.subscriptionPlan).then((response) => {
      window.location = `/pay_per_asset_groups`;
    }, (errorResponse) => {
      this.setState({ showGatewayWarning: false });
      this.props.afterSave(errorResponse.errors);
    });
  }

  fallbackCurrencyUnsetDialogData () {
    return {
      title: 'Set Fallback Currency',
      content:
        'This plan supports multiple currencies. Set a Fallback Currency to Save.',
      firstButton: {
        type: 'primary',
        onClick: (e) => {
          e.preventDefault();
          this.setState({
            showDialog: false,
            dialogContext: ''
          });
        },
        content: 'Ok'
      },
      secondButton: {}
    };
  }

  currencyRemoveDialogData () {
    return {
      title: 'Remove Currency?',
      content: 'Are you sure you want to remove this currency?',
      firstButton: {
        type: 'primary',
        variant: 'danger',
        onClick: (e) => {
          e.preventDefault();
          this.updatePriceWithPlanItems(this.priceId, 'delete');
          this.setState({
            showDialog: false,
            dialogContext: ''
          });
        },
        content: 'Remove'
      },
      secondButton: {
        type: 'default',
        onClick: (e) => {
          e.preventDefault();
          this.setState({ showDialog: false, dialogContext: '' });
        },
        content: 'Cancel'
      }
    };
  }

  updateSubscriptionPlan (updatedPlanItems) {
    this.setState({ subscriptionPlan: Object.assign({}, this.state.subscriptionPlan, updatedPlanItems) });
  }

  handleAddCurrency (currency) {
    const prices = this.state.subscriptionPlan.prices;
    const id = `provisional_${nanoid(10)}`;
    const newPrices = {
      ...prices,
      [currency || id]: {
        amount_cents: 0,
        id
      }
    };

    this.updateSubscriptionPlan({ prices: newPrices });
  }

  openDialog () {
    const omiseDetails = getOmiseDetails(this.state.enabledPaymentProviders);
    const showDialog = !omiseDetails.noOmise;
    if (showDialog) {
      this.setState({
        showGatewayWarning: true
      });
    } else {
      this.sendData();
    }
  }

  onSave () {
    const errors = validate(this.state.subscriptionPlan, constraints);
    if (errors) {
      this.setState({ errors });
      return;
    }
    this.openDialog();
  }

  enableSave () {
    if ((this.state.subscriptionPlan.price_cents !== '') && !_.isEmpty(this.state.subscriptionPlan.duration_length) && (this.state.subscriptionPlan.duration_length !== '') && !_.isEmpty(this.state.subscriptionPlan.duration_unit) && !_.isEmpty(this.state.subscriptionPlan.display_assets)) {
      this.setState({ showSave: true });
    } else {
      this.setState({ showSave: true });
    }

    const errors = validate(this.state.subscriptionPlan, constraints);
    this.setState({ errors });
  }

  updateField (field, value) {
    let asset = this.state.subscriptionPlan.display_assets[0];
    switch (field) {
      case 'asset_category_id':
        if (asset === undefined) {
          asset = {
            asset_category_id: value,
            title: 'An individually purchasable asset'
          };
        } else {
          asset = Object.assign({}, asset, { [field]: value });
        }
        this.setState({ subscriptionPlan: Object.assign({}, this.state.subscriptionPlan, { display_assets: [asset] }) }, this.enableSave);
        break;
      case 'asset_metadata':
        if (value.trim() !== '') {
          asset = Object.assign({}, asset, { metadata: { collection_id: value } });
        } else {
          asset = Object.assign({}, asset, { metadata: {} });
        }
        this.setState({ subscriptionPlan: Object.assign({}, this.state.subscriptionPlan, { display_assets: [asset] }) });
        break;
      case 'duration_unit':
        let durationLength = this.state.subscriptionPlan.duration_length;
        if (value === LIFETIMEVALUE) {
          durationLength = 1;
        }
        this.setState({ subscriptionPlan: Object.assign({}, this.state.subscriptionPlan, { [field]: value, duration_length: durationLength }) }, this.enableSave);
        break;
      case 'price_cents':
        this.setState({
          priceCents: value,
          subscriptionPlan: Object.assign({}, this.state.subscriptionPlan, { [field]: currencyUtils.convertToLowestDenomination(value) })
        }, this.enableSave);
        break;
      case 'fallback_currency':
        this.setState({
          subscriptionPlan: Object.assign({}, this.state.subscriptionPlan, { [field]: value })
        }, () => {
          this.enableSave();
          this.addAutomaticPrice();
        });
        break;
      default:
        this.setState({
          subscriptionPlan: Object.assign({}, this.state.subscriptionPlan, { [field]: value })
        }, this.enableSave);
    }
  }

  updatePriceWithPlanItems (id, type, value, planItems = {}) {
    const prices = this.state.subscriptionPlan.prices;
    const newPrices = {};

    for (const currency in prices) {
      if (Object.hasOwnProperty.call(prices, currency)) {
        const price = prices[currency];
        if (price.id === id) {
          switch (type) {
            case 'amount':
              newPrices[currency] = {
                amount_cents: currencyUtils.convertToLowestDenomination(value),
                paid_trial_amount_cents: price.paid_trial_amount_cents,
                id
              };
              break;
            case 'paid_trial_amount':
              newPrices[currency] = {
                amount_cents: price.amount_cents,
                paid_trial_amount_cents: value === null ? null : currencyUtils.convertToLowestDenomination(value),
                id
              };
              break;
            case 'currency':
              newPrices[value] = price;
          }
        } else {
          newPrices[currency] = prices[currency];
        }
      }
    }

    this.updateSubscriptionPlan({ prices: newPrices, ...planItems });
  }

  dialogOptions () {
    if (this.state.dialogContext === 'currency-remove') {
      return this.currencyRemoveDialogData();
    } else if (this.state.dialogContext === 'fallback-currency-unset') {
      return this.fallbackCurrencyUnsetDialogData();
    }
  }

  addAutomaticPrice () {
    const fallbackCurrency =
      this.state.selectedFallbackCurrency === 'local'
        ? this.state.subscriptionPlan.fallback_currency
        : this.state.accountFallbackCurrency;
    const isPriceSetForFallbackCurrency = !!this.state.subscriptionPlan.prices[fallbackCurrency];

    if (fallbackCurrency && !isPriceSetForFallbackCurrency) {
      this.handleAddCurrency(fallbackCurrency);
    }
  }

  openAlert () {
    this.setState({ openAlertPopup: true });
  }

  closeAlert () {
    this.setState({ openAlertPopup: false });
  }

  deleteSubscriptionPlan (e) {
    e.preventDefault();
    destroySubscriptionPlan(
      this.props.subscriptionGroupId,
      this.props.subscriptionPlanId
    ).then(
      (response) => {
        window.location = `/pay_per_asset_groups`;
      },
      (error) => {
        console.log(error, 'error');
      }
    );
    this.setState({ openAlertPopup: false });
  }

  render () {
    const plan = this.state.subscriptionPlan;
    if (!plan) {
      return <div />;
    }
    const errors = this.state.errors || {};
    const priceErrors = errors.prices;
    const omiseData = getOmiseDetails(this.state.enabledPaymentProviders);
    const showOmiseRelatedMessages = !omiseData.noOmise;
    const multiCurrencyEnabled = this.props.config.multipleCurrencyEnabled;
    const currencies = Object.keys(this.state.subscriptionPlan.prices || []);
    const isMultipleCurrency = currencies.length > 1;
    const dialogOptions = this.dialogOptions();
    const fallbackCurrency =
      this.state.selectedFallbackCurrency === 'local'
        ? this.state.subscriptionPlan.fallback_currency
        : this.state.selectedFallbackCurrency === 'account'
          ? this.state.accountFallbackCurrency
          : null;

    return (<Layout>
      <TitleBar
        title={
          this.props.subscriptionPlanId === 'new'
            ? 'Add Product'
            : 'Edit Product'
        }
        breadcrumbTitle={`Pay Per Asset Pricing`}
        breadcrumbPath={`/pay_per_asset_groups`}
      />
      <div className='assets-container'>
        <Card title="Rule Details">
          <TextFieldWithLabel
            label="Name"
            placeholder="Name"
            value={plan.title || ''}
            onChange={(e) => this.updateField('title', e.target.value)}
            errors={_.get(errors, ['title', '0'])}
            dataTest="pay-per-asset-plan-name"
          />
          {HIDDEN &&
            <div className='form-row'>
              <SelectFieldWithLabel
                label='Asset Type'
                value={_.get(plan, ['display_assets', '0', 'asset_category_id'], '')}
                onChange={(e) => this.updateField('asset_category_id', e.value)}
                clearable={false}
                options={this.state.assetCategoryOptions}
                disabled={plan.id}
                errors={_.get(errors, ['display_assets', '0'])}
                dataTest='asset_type'
              />

              {(_.get(plan, ['display_assets', '0', 'asset_category_id'], '') === this.getIdForStoryAssetCategory()) &&
              <TextFieldWithLabel
                label='Belongs to Collection'
                value={_.get(plan, ['display_assets', '0', 'metadata', 'collection_id'], '')}
                onChange={(e) => this.updateField('asset_metadata', e.target.value)}
                placeholder='Collection Id here, leave blank if not required'
                dataTest='collection_id'
              />}
            </div>
          }

          <div className='form-row'>
            <TextFieldWithLabel
              label='Duration'
              value={_.get(plan, 'duration_unit') === LIFETIMEVALUE ? 1 : _.get(plan, 'duration_length')}
              onChange={(e) => this.updateField('duration_length', e.target.value)}
              errors={_.get(errors, ['duration_length', '0'])}
              disabled={_.get(plan, 'duration_unit') === LIFETIMEVALUE}
              dataTest='duration' />
            <SelectFieldWithLabel
              label='Time Unit'
              value={_.get(plan, 'duration_unit', '')}
              options={DURATION_UNITS_ONE_TIME}
              onChange={(m) => this.updateField('duration_unit', m.value)}
              clearable={false}
              errors={_.get(errors, ['duration_unit', '0'])}
              dataTest='duration_unit' />
          </div>

          <div className='form-row'>
            <div className='text-and-select sub_plan_page'>
              {!multiCurrencyEnabled &&
                <div className='ppa__price_container'>
                  <NumberFieldWithLabel
                    label='price'
                    value={this.state.priceCents}
                    onChange={(e) => this.updateField('price_cents', e.target.value)}
                    placeholder={'Enter price'}
                    errors={_.get(errors, ['price_cents', '0'])}
                    dataTest='price_cents' />
                  <Select
                    className='select-currency'
                    value={_.get(plan, 'price_currency', '')}
                    options={ALL_CURRENCIES}
                    onChange={(m) => this.updateField('price_currency', m.value)}
                    clearable={false}
                    searchable={true}
                    dataTest='price_currency'
                    arrowRenderer={currencyUtils.arrowRenderer}
                  />
                </div>}
              {showOmiseRelatedMessages && <p className="ppa__plan_notes">Stricter minimum and maximum amounts for charges may be enforced for specific payment methods. See the relevant payment method <a target="_blank" rel="noopener noreferrer" className='sub_plan-link' href="https://www.omise.co/currency-and-amount#charge-limits">documentation</a> for details.</p>}
            </div>

          </div>
          <h3 className="plan__price-header"> Price per Asset</h3>
          {multiCurrencyEnabled && (
            <div className="plan__add_currency_btn">
              <EmButton
                onClick={() => {
                  this.handleAddCurrency();
                }}
              >
                + Add New Currency
              </EmButton>
            </div>
          )}
          <PriceSelector
            multiCurrencyEnabled={multiCurrencyEnabled}
            onPriceItemUpdate={this.updatePriceWithPlanItems}
            subscriptionGroupType={'dynamic_asset'}
            subscriptionGroupCurrency={this.state.subscriptionGroupCurrency}
            priceErrors={priceErrors}
            onDeleteClick={(priceId) => {
              this.priceId = priceId;
              this.setState({
                showDialog: true,
                dialogContext: 'currency-remove'
              });
            }}
            subscriptionPlan={this.state.subscriptionPlan}
            fallbackCurrency={fallbackCurrency}
          />

          {showOmiseRelatedMessages && (
            <p className="sub_plan-notes sub_plan__strict-message">
              Stricter minimum and maximum amounts for charges may be enforced
              for specific payment methods. See the relevant payment method{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="sub_plan-link"
                href="https://www.omise.co/currency-and-amount#charge-limits"
              >
                documentation
              </a>{' '}
              for details.
            </p>
          )}
          {/*  fb - fallback */}
          <ConfigurePlanFallbackCurrency
            multiCurrencyEnabled={multiCurrencyEnabled}
            isMultipleCurrency={isMultipleCurrency}
            accountFallbackCurrency={this.state.accountFallbackCurrency}
            selectedFallbackCurrency={this.state.selectedFallbackCurrency}
            onSelectedFallbackCurrencyUpdate={type => {
              this.setState(
                {
                  selectedFallbackCurrency: type
                },
                () => {
                  this.addAutomaticPrice();
                }
              );
              if (type === 'account') {
                this.updateField('fallback_currency', null);
              }
            }}
            fallbackCurrency={this.state.subscriptionPlan.fallback_currency || ''}
            onFallbackCurrencyChange={(currency) => {
              this.updateField('fallback_currency', currency);
            }} />

          {this.state.voucherifyEnabled &&
            <div className='form-row'>
              <TextFieldWithLabel label='Voucherify Product ID'
                placeholder='This plan is not linked with Voucherify'
                disabled
                dataTest='voucherify-product-id'
                value={_.get(plan.metadata, ['voucherify', 'product_id'], '')} />
            </div>
          }

          {this.state.voucherifyEnabled && plan.id && _.get(plan, ['metadata', 'voucherify', 'product_id']) &&
            <Link onClick={(e) => this.updateVoucherifyPlan(e, plan.id)} dataTest='voucherify-update'>
              Update Voucherify
            </Link>
          }

          {this.state.voucherifyEnabled && plan.id &&
            !_.get(plan, ['metadata', 'voucherify', 'product_id']) &&
            <Link onClick={(e) => this.createVoucherifyPlan(e, plan.id)} dataTest='voucherify-connect' >
              Connect Voucherify
            </Link>
          }

        </Card>

        <Card>
          <Assets
            assets={this.state.subscriptionPlan.display_assets || []}
            onChange={(assets) => this.updateField('display_assets', assets)}
            onSave={(e) => this.saveAssets(e)}
            assetCategories={this.state.assetCategories}
            errors={this.props.errors}
            id={this.props.subscriptionPlanId}
            assetsFor='Subscription Plan'
            subscriptionPlan={this.state.subscriptionPlan}
            showPublishedAt={
              this.state.subscriptionPlan.duration_unit === 'lifetime'
            }
            subscriptionGroupType={'dynamic_asset'}
            assetFrom={[]}
          />
        </Card>

        <ButtonGroup columns={this.props.subscriptionPlanId !== 'new' ? 'two' : ''}>
          {this.props.subscriptionPlanId !== 'new' && (
            <Button onClick={(e) => this.openAlert(e)} destructive>
              Delete
            </Button>
          )}
          <Button primary onClick={(e) => this.onSave(e)}>Save</Button>
        </ButtonGroup>
        <ModifiedEmDialog
          isOpen={this.state.showGatewayWarning}
          onClose={() => {
            this.setState({ showGatewayWarning: false });
          }}
          title={'Warning'}
          content={`Some of the selected Payment Gateways do not support transactions above or below the specificied Min-Max caps. Are you sure you want to ${this.props.subscriptionPlanId === 'new' ? 'create' : 'update'} the plan?`}
          firstButton={{
            type: 'primary',
            onClick: (e) => {
              e.preventDefault();
              this.sendData();
            },
            content: `${this.props.subscriptionPlanId === 'new' ? 'Create' : 'Update'} Plan`
          }
          }
          secondButton={{
            type: 'default',
            onClick: (e) => {
              e.preventDefault();
              this.setState({ showGatewayWarning: false });
            },
            content: 'Cancel'
          }
          }
        />

        {this.state.showNotification.isNotificationOpen &&
          <StatusMessage statusMessage={this.state.showNotification.statusMessage}
            status={this.state.showNotification.status}
            onCancel={() => this.setState({ showNotification: { isNotificationOpen: false, status: '', statusMessage: '' } })} />}
      </div>
      {this.state.showDialog && (
        <ModifiedEmDialog
          isOpen={this.state.showDialog}
          onClose={() => {
            this.setState({ showDialog: false, dialogContext: '' });
          }}
          title={dialogOptions.title}
          content={dialogOptions.content}
          firstButton={dialogOptions.firstButton}
          secondButton={dialogOptions.secondButton}
        />
      )}
      <Alert
        title="Delete Subscription Plan"
        open={this.state.openAlertPopup}
        destructive
        onCancel={() => this.closeAlert()}
        onConfirm={(e) => this.deleteSubscriptionPlan(e)}
      >
          Deleted data cannot be recovered. Do you still want to continue?
      </Alert>
    </Layout>);
  }

  createVoucherifyPlan (e, subscriptionPlanId) {
    e.preventDefault();
    const createPlan = window.confirm('Do you want to create this plan in Voucherify?');
    if (createPlan === false) return;
    postCreateVoucherifyProduct(subscriptionPlanId)
      .then(response => {
        this.setState({ showNotification: { isNotificationOpen: true, status: 'success', statusMessage: 'Voucherify Product Created Successfully' } });
        this.setState({ subscriptionPlan: Object.assign({}, this.state.subscriptionPlan, { metadata: { voucherify: { product_id: response.voucherify_product_id } } }) });
      },
      (errors) => this.setState({ showNotification: { isNotificationOpen: true, status: 'error', statusMessage: _.get(errors, ['error', 'message']) || _.get(errors, 'errors') } }));
  }

  updateVoucherifyPlan (e, subscriptionPlanId) {
    e.preventDefault();
    const createPlan = window.confirm('Do you want to update this plan in Voucherify?');
    if (createPlan === false) return;
    updateVoucherifyProduct(subscriptionPlanId)
      .then(response => {
        this.setState({ showNotification: { isNotificationOpen: true, status: 'success', statusMessage: 'Voucherify Product Updated Successfully' } });
      },
      (errors) => this.setState({ showNotification: { isNotificationOpen: true, status: 'error', statusMessage: _.get(errors, ['error', 'message']) || _.get(errors, 'errors') } }));
  }

  getIdForStoryAssetCategory () {
    const x = _.find(this.state.assetCategoryOptions, function (o) { return o.label === 'story'; });
    return x['value'];
  }
}

const getPricesConstraints = (message, greaterThanOrEqualTo, shouldValidatePaidTrialPrice = false) => {
  const priceConstraints = {
    currency: {
      presence: { allowEmpty: false },
      length: { is: 3, message: '^Choose a currency' }
    },
    amount_cents: {
      presence: true,
      numericality: {
        strict: true,
        lessThanOrEqualTo: 1000000000,
        greaterThanOrEqualTo: greaterThanOrEqualTo,
        message: message
      }
    }
  };

  if (shouldValidatePaidTrialPrice) {
    const paidTrialPriceConstraints = {
      presence: true,
      numericality: {
        strict: true,
        lessThanOrEqualTo: 1000000000,
        greaterThanOrEqualTo: greaterThanOrEqualTo,
        message: message
      }
    };
    return {
      priceValidation: { ...priceConstraints, paid_trial_amount_cents: paidTrialPriceConstraints }
    };
  }

  return { priceValidation: priceConstraints };
};

const constraints = {
  title: { presence: { allowEmpty: false } },
  display_assets: { presence: { allowEmpty: false, message: 'Required' } },
  price_cents: { presence: true, numericality: { strict: true, lessThanOrEqualTo: 1000000000, greaterThanEqualTo: 0, message: 'Value too large' } },
  duration_length: { presence: true, numericality: { strict: true, onlyInteger: true, greaterThan: 0, lessThanOrEqualTo: 999999 } },
  duration_unit: { presence: { message: 'Cannot be empty' } },
  prices: getPricesConstraints('^Amount not within acceptable range')
};

function mapStateToProps (state) {
  return {
    config: state.config || []
  };
}

export default connect(mapStateToProps, null)(PayPerAssetPlan);
