import React from 'react';
import { connect } from 'react-redux';
import { getSubscriptionGroups, updateOrder } from '../api';
import { UPDATE_SUBSCRIPTION_GROUPS } from '../actions';
import { TitleBar, Accordion, Layout, Button, StatusMessage } from '../components/library-components';
import validate from 'validate.js';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const PAY_PER_ASSET_TYPE = 'dynamic_asset';

export class SubscriptionGroups extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showNotification: { isNotificationOpen: false, status: '', statusMessage: '' },
      ordering: {},
      typeIndex: 0,
      groupTypes: [],
      orderingUpdated: false
    };
    this.setOrdering = this.setOrdering.bind(this);
    this.saveUpdatedOrder = this.saveUpdatedOrder.bind(this);
  }

  componentDidMount () {
    getSubscriptionGroups().then(subscriptionGroups => {
      const filteredGroups = subscriptionGroups.filter(group => group.subscription_type !== PAY_PER_ASSET_TYPE);
      this.setOrdering(filteredGroups);
      this.props.setSubscriptionGroups(filteredGroups);
    });
  }

  setOrdering (groups) {
    const ordering = groups.reduce((acc, group) => {
      return { ...acc, [group.subscription_type]: { ...acc[group.subscription_type], [group.id]: group.api_ordering } };
    }, {});
    const groupTypes = Object.keys(ordering).sort();
    this.setState({ ordering, groupTypes });
  }

  updateTypeOrdering (orderType, newOrdering) {
    this.setState({ ordering: { ...this.state.ordering, [orderType]: newOrdering }, orderingUpdated: true });
  }

  validateApiOrder (groups) {
    var validationPassed = true;

    const constraints = {
      api_order: {
        presence: true, numericality: { strict: true, onlyInteger: true, greaterThanOrEqualTo: 0, lessThanOrEqualTo: 10000, message: 'must be a positive number(integer) greater than or equal to zero' }
      }
    };

    for (const key of Object.keys(groups)) {
      const orderValue = groups[key] ? groups[key] : 0; // on group creation api_ordering is null, we cannot change that automatically.
      const errors = validate({ api_order: orderValue }, constraints);
      if (errors) {
        const groupName = this.props.subscriptionGroups.filter(group => group.id === parseInt(key)).map(group => group.name).join(',');
        const errorMessage = `${errors.api_order[0]} for ${groupName}`;
        this.setState({ showNotification: { isNotificationOpen: true, status: 'failed', statusMessage: errorMessage } });
        validationPassed = false;
        break;
      }
    }

    return validationPassed;
  }

  validateApiOrders (orderGroups, groupTypes) {
    const validations = groupTypes.map(groupType => this.validateApiOrder(orderGroups[groupType]));

    return !validations.some((v) => v === false);
  }

  saveUpdatedOrder () {
    if (this.validateApiOrders(this.state.ordering, this.state.groupTypes)) {
      // this is a hack since the api throws error if campaign is missing even when the publisher does not have any campaign subscriptions. API needs to be fixed
      const finalOrdering = this.state.ordering.campaign ? this.state.ordering : { ...this.state.ordering, campaign: {} };
      updateOrder(finalOrdering)
        .then(() => {
          getSubscriptionGroups().then(groups => {
            const filteredGroups = groups.filter(group => group.subscription_type !== PAY_PER_ASSET_TYPE);
            this.setOrdering(filteredGroups);
            this.props.setSubscriptionGroups(filteredGroups);
          });
          this.setState({ showNotification: { isNotificationOpen: true, status: 'success', statusMessage: 'Updated Api Ordering' }, orderingUpdated: false });
        })
        .catch(error => {
          const statusMessage = this.orderingErrorMessage(error);
          this.setState({
            showNotification: { isNotificationOpen: true, status: 'failed', statusMessage: statusMessage }
          });
        });
    }
  }

  orderingErrorMessage (error) {
    const groupIds = error.errors.keys.map(val => parseInt(val));
    const groupNames = this.props.subscriptionGroups
      .filter(group => groupIds.includes(group.id))
      .map(group => group.name)
      .join(', ');
    const value = error.errors.value;
    const category = error.errors.category;
    return `Api Order number ${value} is same for ${groupNames} belonging to ${category} category. Please enter a different api order number for either of the groups`;
  }

  updateGroupOrder (groupType, groups) {
    const allGroups = this.props.subscriptionGroups;
    const diffTypeGroups = allGroups.filter(group => group.subscription_type !== groupType);
    const updatedGroup = [...groups, ...diffTypeGroups];

    this.props.setSubscriptionGroups(updatedGroup);
  }

  render () {
    return (
      <Layout>
        <TitleBar title='Subscription Groups'
          button primary buttonText='Add Subscription Group' buttonUrl='/subscription_groups/new'>
          {this.state.orderingUpdated &&
          <Button dataTest={'save_api_order'} customClass={'margin-right-10'} primary onClick={() => this.saveUpdatedOrder()}> Save Api Order </Button>}
        </TitleBar>
        {this.state.groupTypes.length > 1
          ? <Tabs selectedIndex={this.state.typeIndex} onSelect={typeIndex => this.setState({ typeIndex })}>
            <TabList>
              {this.state.groupTypes.map((groupType, index) => <Tab key={index}>{groupType}</Tab>)}
            </TabList>
            {this.state.groupTypes.map((groupType, index) =>
              <TabPanel key={index}>
                <Accordion
                  data={this.props.subscriptionGroups.filter(group => group.subscription_type === groupType)}
                  updateGroups = {(groups => this.updateGroupOrder(groupType, groups))}
                  updateTypeOrdering = {(value) => this.updateTypeOrdering(groupType, value)}
                />
              </TabPanel>
            )}
          </Tabs>
          : <Accordion
            data={this.props.subscriptionGroups}
            updateGroups = {(groups => this.updateGroupOrder(this.state.groupTypes[0], groups))}
            updateTypeOrdering = {(value) => this.updateTypeOrdering(this.state.groupTypes[0], value)} />
        }
        {this.state.showNotification.isNotificationOpen &&
          <StatusMessage
            statusMessage={this.state.showNotification.statusMessage}
            status={this.state.showNotification.status}
            onCancel={() => this.setState({ showNotification: { isNotificationOpen: false, status: '', statusMessage: '' } })}
          />}
      </Layout>
    );
  }
}

function mapStateToProps (state) {
  return {
    subscriptionGroups: state.subscriptionGroups || []
  };
}

function mapDispatchToProps (dispatch) {
  return {
    setSubscriptionGroups: (groups) => dispatch({ type: UPDATE_SUBSCRIPTION_GROUPS, subscriptionGroups: groups })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionGroups);
