import React from 'react';
import _ from 'lodash-es';
import { Alert, CardHeader, StatusMessage, Card, Table } from '../library-components';
import TableRowWithActionDropdown from '../table-row-with-action-dropdown/table-row-with-action-dropdown';
import { getTaxRates, destroyTaxRate } from '../../api';
import TaxRate from './tax_rate';

export default class TaxRates extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showTaxRate: false,
      showNotification: { isNotificationOpen: false, status: '', statusMessage: '' }
    };

    this.onDelete = this.onDelete.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount () {
    this.loadTaxRates();
    this.setState({ errros: {} });
  }

  loadTaxRates () {
    getTaxRates()
      .then((taxRates) => this.setState({ taxRates: taxRates }))
      .catch(() => this.setState({ showNotification: { isNotificationOpen: true, status: 'error', statusMessage: 'Error occurred' } }));
  }

  onTaxRateUpdate (errors) {
    if (errors) {
      return this.setState({ showNotification: { isNotificationOpen: true, status: 'error', statusMessage: errors.map((error, index) => <span key={index}>{error}</span>) } });
    }
    this.setState({ showNotification: { isNotificationOpen: true, status: 'success', statusMessage: 'Tax rate saved successfully' } });
    this.loadTaxRates();
    this.setState({ showTaxRate: !this.state.showTaxRate });
  }

  addTaxRate () {
    this.setState({
      selectedTaxRate: {},
      showTaxRate: true
    });
  }

  onCancel () {
    this.setState({
      showTaxRate: false
    });
  }

  onDelete (taxRate) {
    this.setState({ selectedTaxRate: taxRate, showDeleteConfirm: true });
  }

  onDeleteCancel () {
    this.setState({ selectedTaxRate: null, showDeleteConfirm: false });
  }

  onDeleteConfirm () {
    destroyTaxRate(this.state.selectedTaxRate.id)
      .then(() => this.loadTaxRates())
      .then(() => this.setState({ showNotification: { isNotificationOpen: true, status: 'success', statusMessage: 'Tax rate deleted successfully' } }))
      .catch(() => this.setState({ showNotification: { isNotificationOpen: true, status: 'error', statusMessage: 'Unable to delete tax rate, please try again or contact support@quintype.com' } }))
      .finally(() => this.setState({ showDeleteConfirm: false }));
  }

  updateTaxRateField (field, value) {
    this.setState({ selectedTaxRate: Object.assign({}, this.state.selectedTaxRate, { [field]: value }) });
  }

  render () {
    return <Card>
      {<CardHeader
        title='Tax Rates'
        description='Subscription plan prices will be inclusive of these taxes'
        buttonText='Add'
        buttonOnClick={() => this.addTaxRate()}
        isButton
      />}

      { this.state.showTaxRate &&
        <TaxRate
          taxRate={this.state.selectedTaxRate}
          afterSave={(e) => this.onTaxRateUpdate(e)}
          onCancel={(e) => this.onCancel(e)}
          updateTaxRateField={(field, value) => this.updateTaxRateField(field, value)}
        /> }

      <Table header={['Tax name', 'Tax rate %']} className='table-default--4cols'>
        { _.map(this.state.taxRates, (taxRate, index) => {
          const actionsForTaxRate = [{ action: 'Delete', actionHandler: this.onDelete, id: taxRate }];
          return <TableRowWithActionDropdown id={taxRate.id} dropdownActions={actionsForTaxRate} key={taxRate.id} showVerticalMenu>
            <div className='table-default__cell'>{_.get(taxRate, ['name'], '')} </div>
            <div className='table-default__cell'>{_.get(taxRate, ['percentage'], '')} %</div>
          </TableRowWithActionDropdown>;
        }) }
      </Table>
      {this.state.showNotification.isNotificationOpen &&
        <StatusMessage statusMessage={this.state.showNotification.statusMessage}
          status={this.state.showNotification.status}
          onCancel={() => this.setState({ showNotification: { isNotificationOpen: false, status: '', statusMessage: '' } })} />}
      {this.state.showDeleteConfirm && <Alert title='Delete Tax Rate'
        open
        destructive
        onCancel={() => this.onDeleteCancel()}
        onConfirm={() => this.onDeleteConfirm()}>
            This action will delete the tax rate permanantly. Do you still want to continue?
      </Alert>}
    </Card>;
  }
}
