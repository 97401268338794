import React, { useState } from 'react';

import { Checkbox } from '@quintype/em/components/checkbox';
import { Button, SliderCheckbox, Table } from '../library-components';
import { ModifiedEmDialog } from '../modified-em-dialog/index';
import SubGroupPlanPriceCell from '../../components/sub-group-plan-price-cell';
import Trash from '../../pages/Icons/trash';
import SwitchPlanConfigInspector from './switch-plans-config-inspector';

import './switch-plan-config.scss';

const switchPlanConfigBuilder = (switchType, enabled = false, proration_enabled = false, selected_plans = []) => {
  switch (switchType) {
    case ('Upgrading'):
      return {
        enabled,
        proration_enabled,
        selected_plans
      };
    default:
      return {
        enabled,
        selected_plans
      };
  }
};

const tableHeaders = ['Plan Name', 'Occurence', 'Price', ''];

export default function SwitchPlanConfigure ({
  switchInfo,
  updateConfig,
  prorated = false,
  planType,
  planConfig
}) {
  const config = planConfig[switchInfo.fieldName];
  const [planIndexToRemove, setPlanIndexToRemove] = useState(-1);
  const [isInspectorActive, setIsInspectorActive] = useState(false);

  const closeInspector = () => {
    setIsInspectorActive(false);
  };

  const toggleInspector = () => {
    setIsInspectorActive(!isInspectorActive);
  };

  const planListNumberOfCols = 4;

  const { switchType, fieldName } = switchInfo;
  const switchConfig = config || switchPlanConfigBuilder(switchType);

  const onToggle = (isSwitchTypeEnabled) => {
    updateConfig(fieldName, {
      ...switchConfig,
      enabled: isSwitchTypeEnabled
    });
  };

  const onToggleProration = (isProrationEnabled) => {
    updateConfig(fieldName, {
      ...switchConfig,
      proration_enabled: isProrationEnabled
    });
  };

  function removePlanAction () {
    if (planIndexToRemove > -1) {
      const plans = [...switchConfig.selected_plans];
      plans.splice(planIndexToRemove, 1);

      const planDetails = [...switchConfig.selected_plan_details];
      planDetails.splice(planIndexToRemove, 1);

      updateConfig(fieldName, {
        ...switchConfig,
        selected_plans: [...plans],
        selected_plan_details: [...planDetails]
      });
    }
    setPlanIndexToRemove(-1);
  }

  const handlePlansSelect = (plans) => {
    updateConfig(fieldName, {
      ...switchConfig,
      selected_plan_details: plans,
      selected_plans: plans.map(plan => plan.id)
    });
  };

  return (
    <>
      <div className="switch-plan-config-state">
        <SliderCheckbox enabled={switchConfig.enabled} onChange={() => onToggle(!switchConfig.enabled)} />
        <p className="switch-type"> {switchType} </p>
        {switchConfig.enabled &&
          (<Button
            onClick={toggleInspector}
            customClass="add-plan-button" secondary={true}>
            Add Plans
          </Button>)
        }
      </div >
      {switchConfig.enabled && prorated && (
        <Checkbox label='Enable Proration'
          checked={switchConfig.proration_enabled}
          onChange={(checked) => onToggleProration(checked)}
        />)
      }
      {switchConfig.enabled && (
        <div className="switch-plan-list">
          <Table
            header={tableHeaders}
            className={`table-default--${planListNumberOfCols}cols`}
          >
            {switchConfig.selected_plan_details && switchConfig.selected_plan_details.length > 0 ? (
              switchConfig.selected_plan_details.map((plan, index) => (
                <div className="table-default__row" key={index}>
                  <div className="table-default__cell">{plan.title}</div>
                  <div className="table-default__cell">
                    {plan.recurring ? 'Recurring' : 'One Time'}
                  </div>
                  <div className="table-default__cell">
                    <SubGroupPlanPriceCell plan={plan} addPlanLink={false} />
                  </div>
                  <div className="table-default__cell">
                    <button
                      className="switch-plan-list__trash-button"
                      onClick={() => setPlanIndexToRemove(index)}
                    >
                      <Trash />
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="table-default__row" key={'empty-row'}>
                {new Array(planListNumberOfCols - 1).fill(<div className="table-default__cell">-</div>)}
                <div className="table-default__cell" />
              </div>
            )}
          </Table>
        </div>
      )}

      <SwitchPlanConfigInspector
        isActive={isInspectorActive}
        onClose={closeInspector}
        planType={planType}
        planConfig={planConfig}
        onPlansSelect={handlePlansSelect}
        initSelectedPlansDetails={switchConfig && switchConfig.selected_plan_details}
      />

      <ModifiedEmDialog
        isOpen={planIndexToRemove > -1}
        title={'Remove Plan?'}
        content={'Are you sure you want to remove this plan?'}
        onClose={() => setPlanIndexToRemove(-1)}
        firstButton={{
          type: 'primary',
          onClick: () => removePlanAction(),
          content: 'Remove',
          variant: 'danger'
        }}
        secondButton={{
          type: 'secondary',
          onClick: () => setPlanIndexToRemove(-1),
          content: 'Cancel',
          greyed: true
        }}
      />
    </>
  );
}
