import React from 'react';
import PropTypes from 'prop-types';

const Integrations = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox='0 0 97 73' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <title>Integrations</title>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='integrations' transform='translate(-3.000000, -17.000000)' fill={fill}>
        <path d='M59.0569565,84.8062 L70.5134783,84.8062 C74.1613043,84.8062 77.1265217,81.8331412 77.1265217,
            78.1853765 L77.1265217,62.5365529 L80.0613043,63.6247882 C87.9395652,66.5717294 95.2613043,60.5820824 95.2613043,
            53.0819647 C95.2613043,45.5774941 87.8917391,39.6052588 80.0656522,42.5347882 L77.1265217,43.6360824 L77.1265217,
            27.9742 C77.1265217,24.3220824 74.1613043,21.3533765 70.5134783,21.3533765 L59.0569565,21.3533765 C57.996087,
            28.9536118 51.4613043,34.8257294 43.5786957,34.8257294 C35.7004348,34.8257294 29.1656522,28.9536118 28.1047826,
            21.3533765 L13.9656522,21.3533765 C10.3178261,21.3533765 7.34826087,24.3220824 7.34826087,27.9742 L7.34826087,
            37.6377294 C14.8221739,38.7999647 20.5917391,45.3250235 20.5917391,53.0819647 C20.5917391,60.8345529 14.8221739,
            67.3596118 7.34826087,68.5218471 L7.34826087,78.1853765 C7.34826087,81.8331412 10.3178261,84.8062 13.9656522,
            84.8062 L28.1047826,84.8062 C29.1656522,77.2059647 35.7004348,71.3382 43.5786957,71.3382 C51.4569565,
            71.3382 57.996087,77.2059647 59.0569565,84.8062 M70.5134783,89.1591412 L54.8613043,89.1591412 L54.8613043,
            86.9826706 C54.8613043,80.7536118 49.8004348,75.6911412 43.5786957,75.6911412 C37.3613043,75.6911412 32.3047826,
            80.7536118 32.3047826,86.9826706 L32.3047826,89.1591412 L13.9656522,89.1591412 C7.91782609,89.1591412 3.00043478,
            84.2359647 3.00043478,78.1853765 L3.00043478,64.3865529 L5.13956522,64.3517294 C11.2613043,64.2559647 16.243913,
            59.1978471 16.243913,53.0819647 C16.243913,46.9617294 11.2613043,41.9036118 5.13956522,41.8078471 L3.00043478,
            41.7730235 L3.00043478,27.9742 C3.00043478,21.9236118 7.91782609,17.0004353 13.9656522,17.0004353 L32.3047826,
            17.0004353 L32.3047826,19.1769059 C32.3047826,25.4059647 37.3613043,30.4727882 43.5786957,30.4727882 C49.8004348,
            30.4727882 54.8613043,25.4059647 54.8613043,19.1769059 L54.8613043,17.0004353 L70.5134783,17.0004353 C76.5569565,
            17.0004353 81.4743478,21.9236118 81.4743478,27.9742 L81.4743478,37.6551412 C91.0656522,35.9444353 99.6047826,
            43.6230235 99.6091304,53.0819647 C99.6047826,62.5365529 91.0656522,70.1890235 81.4743478,68.5044353 L81.4743478,
            78.1853765 C81.4743478,84.2359647 76.5569565,89.1591412 70.5134783,89.1591412' id='Fill-1' />
      </g>
    </g>
  </svg>
);

Integrations.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
};

Integrations.defaultProps = {
  width: '16',
  height: '16',
  fill: '#818A98'
};

export default Integrations;
