import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const randomId = () => Math.random().toString(36).substr(2, 6);

function wrapLabelAndError (Component) {
  function WrapComponent (props) {
    const id = `${Component.name}_${randomId()}`;
    const className = classnames('input-with-label', {
      'field-has-error': props.errors ? props.errors !== '' : null
    });
    const { showErrorMessage = true } = props;
    return (
      <div className={className}>
        <label htmlFor={id} className="label">
          {props.label}
        </label>
        <Component {...props} id={id} />
        {showErrorMessage && <p className="error-msg">{props.errors}</p>}
      </div>
    );
  }

  WrapComponent.propTypes = {
    label: PropTypes.string,
    errors: PropTypes.string
  };

  return WrapComponent;
}

export default wrapLabelAndError;
