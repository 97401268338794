import React, { useEffect, useState } from 'react';
import { getAnalyticalReportsData } from '../../api';
import DataCard from './data_card';
import './data_cards_container.scss';

const CARDS_TITLE = {
  total_revenue: 'Revenue',
  new_subscribers: 'New Subscribers',
  active_subscribers: 'Active Subscribers',
  inactive_subscribers: 'Inactive Subscribers',
  recurring_revenue: 'Recurring Revenue',
  onetime_revenue: 'OneTime Revenue',
  recurring_subscriptions: 'Recurring Subscriptions',
  onetime_subscriptions: 'OneTime Subscriptions'
};

const CARDS_INFO = {
  total_revenue:
    'Total Revenue earned  from both One-Time and Recurring subscriptions in the selected interval',
  new_subscribers:
    'Total number of new users who bought a subscription for the first time in the selected interval',
  active_subscribers:
    'Subscribers with at least one active subscription in the selected interval',
  inactive_subscribers:
    'Subscribers with no active subscription in the selected interval',
  recurring_revenue:
    'Total Revenue earned via recurring subscriptions only, in the selected interval',
  onetime_revenue:
    'Total Revenue earned via One-Time subscriptions only, in the selected interval',
  recurring_subscriptions:
    'Total Number of Recurring Subscriptions in the selected interval',
  onetime_subscriptions:
    'Total Number of One-Time Subscriptions in the selected interval'
};

function DataCardsContainer ({ intervalDuration, intervalUnit, currency }) {
  // const [cardsData, setCardsData] = useState(CARDS_TITLE);
  const [totalRevenue, setTotalRevenue] = useState({});
  const [newSusbcribers, setNewSubscribers] = useState({});
  const [activeSusbcribers, setActiveSubscribers] = useState({});
  const [inactiveSusbcribers, setInactiveSubscribers] = useState({});
  const [recurringRevenue, setRecurringRevenue] = useState({});
  const [onetimeRevenue, setOnetimeRevenue] = useState({});
  const [recurringSubscriptions, setRecurringSubscriptions] = useState({});
  const [onetimeSubscriptions, setOnetimeSubscriptions] = useState({});

  useEffect(() => {
    getAnalyticalReportsData('total_revenue_on_interval', {
      interval_duration: intervalDuration,
      interval_unit: intervalUnit,
      currency: currency
    }).then(({ data }) => {
      if (!data) {
        return;
      }
      setTotalRevenue({
        currentValue: data.revenue,
        percentage: data.revenue_change_percentage
      });
      setRecurringRevenue({
        currentValue: data.recurring_revenue,
        percentage: data.recurring_revenue_change_percentage
      });
      setOnetimeRevenue({
        currentValue: data.onetime_revenue,
        percentage: data.onetime_revenue_change_percentage
      });
    });

    getAnalyticalReportsData('new_subscribers', {
      interval_duration: intervalDuration,
      interval_unit: intervalUnit
    }).then(({ data }) => {
      if (!data) {
        return;
      }
      setNewSubscribers({
        currentValue: data.current,
        percentage: data.percentage
      });
    });

    getAnalyticalReportsData('active_inactive_subscribers', {
      interval_duration: intervalDuration,
      interval_unit: intervalUnit
    }).then(({ data }) => {
      if (!data) {
        return;
      }
      setActiveSubscribers({
        currentValue: data.active.current,
        percentage: data.active.percentage
      });
      setInactiveSubscribers({
        currentValue: data.inactive.current,
        percentage: data.inactive.percentage
      });
    });

    getAnalyticalReportsData('ot_subscriptions_count', {
      interval_duration: intervalDuration,
      interval_unit: intervalUnit
    }).then(({ data }) => {
      if (!data) {
        return;
      }
      setOnetimeSubscriptions({
        currentValue: data.current,
        percentage: data.percentage
      });
    });

    getAnalyticalReportsData('recurring_subscriptions_count', {
      interval_duration: intervalDuration,
      interval_unit: intervalUnit
    }).then(({ data }) => {
      if (!data) {
        return;
      }
      setRecurringSubscriptions({
        currentValue: data.current,
        percentage: data.percentage
      });
    });
  }, [intervalDuration, intervalUnit, currency]);

  const cardsData = [
    { key: 'total_revenue', type: 'currency', ...totalRevenue },
    { key: 'new_subscribers', ...newSusbcribers },
    { key: 'active_subscribers', ...activeSusbcribers },
    { key: 'inactive_subscribers', ...inactiveSusbcribers },
    { key: 'recurring_revenue', type: 'currency', ...recurringRevenue },
    { key: 'onetime_revenue', type: 'currency', ...onetimeRevenue },
    { key: 'recurring_subscriptions', ...recurringSubscriptions },
    { key: 'onetime_subscriptions', ...onetimeSubscriptions }
  ];

  return (
    <div className="dcc__container">
      {cardsData.map((cardData) => (
        <DataCard
          key={cardData.key}
          currentValue={cardData.currentValue || 0}
          percentageDiff={cardData.percentage || 0}
          intervalDuration={intervalDuration}
          intervalUnit={intervalUnit}
          title={CARDS_TITLE[cardData.key]}
          type={cardData.type}
          currency={currency}
          info={CARDS_INFO[cardData.key]}
          id={cardData.key}
        />
      ))}
    </div>
  );
}

export default DataCardsContainer;
