import { useState } from 'react';

export const useAccordionState = (initialState, hasSingleActivePanel) => {
  const [expanded, setExpanded] = useState(initialState);

  const onToggle = (id) => {
    const toggledState = hasSingleActivePanel
      ? Object.assign([], [], {
        [id]: !expanded[id]
      })
      : Object.assign([], expanded, {
        [id]: !expanded[id]
      });

    setExpanded(toggledState);
  };
  return { expanded, onToggle };
};
