import React from 'react';

import './phone-identity.scss';

const PhoneIdentity = ({ phoneNum }) => {
  if (!phoneNum) {
    return null;
  }

  return <div className="phone-number-identity">{phoneNum}</div>;
};

export default PhoneIdentity;
