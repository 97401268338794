import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';
import { Chevron } from '@quintype/em/icons/chevron';

import { useAccordionContext } from '../accordion';
import './accordion-section.scss';

const AccordionSection = ({ children, header, index, excludeSelector }) => {
  const { focusRef, selected, expandedAll, onToggle, id } =
    useAccordionContext();

  const sectionId = `${id}-${index}-section`;
  const labelId = `${id}-${index}-label`;
  const expanded = expandedAll[index];
  const labelRef = useRef(null);

  useEffect(() => {
    if (index === selected[0] && labelRef && labelRef.current) {
      labelRef.current.focus();
    }
  }, [index, selected]);

  const panelClasses = classnames('accordion-content', {
    'is-expanded': expanded
  });

  return (
    <div className="accordion-section">
      <div
        role="button"
        aria-expanded={expanded}
        aria-controls={sectionId}
        id={labelId}
        tabIndex={0}
        className={'accordion-header'}
        onClick={(e) => {
          if (!e.target.closest(excludeSelector)) {
            onToggle && onToggle(index);
          }
        }}
        onKeyDown={(e) => {
          switch (e.key) {
            case ' ':
            case 'Enter':
              e.preventDefault();
              onToggle && onToggle(index);
              break;
            default:
          }
        }}
        onFocus={() => {
          focusRef.current = index;
        }}
        onBlur={() => {
          focusRef.current = null;
        }}
        ref={labelRef}
      >
        <div className="accordion-item-header">
          {React.cloneElement(header, { expanded })}
        </div>
        <div aria-hidden={true} className={'accordion-arrow'}>
          <Chevron variant={expanded ? 'up' : 'down'} width={24} height={24} />
        </div>
      </div>
      <div
        role="region"
        aria-labelledby={labelId}
        id={sectionId}
        className={panelClasses}
      >
        {children}
      </div>
    </div>
  );
};

export default AccordionSection;
