import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import get from 'lodash-es/get';
import {
  updateNotificationSettings,
  getNotificationSettings,
  getAcccountConfig,
  updateAcccountConfig,
  getCurrentAccountUserDetails
} from '../api';
import { SET_SETTINGS } from '../actions';
import {
  Button,
  Card,
  ButtonGroup,
  SliderCheckbox,
  Layout,
  StatusMessage,
  TitleBar
} from '../components/library-components';
import { TextField } from '@quintype/em/components/text-field';
import { AnimatedSpinner } from '@quintype/em/icons/animated-spinner';
import * as Promise from 'bluebird';

import validate from 'validate.js';

import '../../stylesheets/components/react-tagsinput.scss';
import '../../stylesheets/pages/email-configurations.scss';

export function CustomerEmailNotifications (props) {
  const [showNotification, updateNotification] = useState({
    isNotificationOpen: false,
    status: '',
    statusMessage: ''
  });
  const [isSuperUser, setSuperUser] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [subscriptionUrl, updateSubscriptionUrl] = useState('');

  // This ref is for initially setting button to disabled state
  // unless there are engagements by user like toggling switch
  // or typing url , the button will be disabled
  const userInitiatedActions = useRef(false);

  const renewalReminderEnabled = get(
    props.settings,
    ['renewal_reminder'],
    false
  );

  useEffect(() => {
    (async () => {
      const data = await Promise.all([
        getNotificationSettings(),
        getAcccountConfig(),
        getCurrentAccountUserDetails()
      ]);
      if (data[0]) {
        props.setSettings(data[0]);
      }

      if (data[1]) {
        updateSubscriptionUrl(data[1].account.subscription_url);
      }
      if (data[2] && data[2].superuser) {
        setSuperUser(true);
      }
      setLoading(false);
    })();
    // eslint was adding props in dependecy array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateFields = useCallback(() => {
    const constraints = renewalReminderEnabled
      ? {
        subscriptionUrl: {
          presence: { allowEmpty: false, message: 'is required' },
          url: {
            allowLocal: true
          }
        },
        renewal_reminder_due_days: {
          presence: true,
          numericality: {
            onlyInteger: true,
            greaterThan: 0,
            lessThanOrEqualTo: 30
          }
        }
      }
      : {};

    return validate(
      {
        subscriptionUrl,
        renewal_reminder_due_days: props.settings['renewal_reminder_due_days']
      },
      constraints
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.settings.renewal_reminder_due_days,
    renewalReminderEnabled,
    subscriptionUrl
  ]);

  useEffect(() => {
    function handleErrors () {
      const validationResult = validateFields();

      if (validationResult) {
        setErrors(validationResult);
        userInitiatedActions.current && setSaveButtonDisabled(true);
      } else {
        setErrors({});
        userInitiatedActions.current && setSaveButtonDisabled(false);
      }
    }
    handleErrors();
  }, [
    subscriptionUrl,
    props.settings.renewal_reminder_due_days,
    renewalReminderEnabled,
    validateFields
  ]);

  function handleSettingsValueChange (key, value) {
    if (!userInitiatedActions.current) {
      userInitiatedActions.current = true;
    }
    const notificationSettings = Object.assign({}, props.settings, {
      [key]: value
    });
    setSaveButtonDisabled(false);
    props.setSettings(notificationSettings);
  }

  function updateAccountEmailSettings () {
    const validationResult = validateFields();
    if (!validationResult) {
      (async () => {
        try {
          await updateNotificationSettings(props.settings);
          if (isSuperUser) {
            await updateAcccountConfig({
              subscription_url: subscriptionUrl
            });
          }
          updateNotification({
            isNotificationOpen: true,
            status: 'success',
            statusMessage: 'Email settings saved Successfully!'
          });
          setSaveButtonDisabled(true);
        } catch (errors) {
          updateNotification({
            isNotificationOpen: true,
            status: 'error',
            statusMessage: errors.message
          });
        }
      })();
    } else {
      setErrors(validationResult);
    }
  }

  return (
    <Layout>
      <TitleBar title="Customer Email Notifications" />
      {isLoading ? (
        <div className="center">
          <AnimatedSpinner width="32" height="32" />
        </div>
      ) : (
        <>
          <Card title="Subscriber Email Settings">
            <SliderCheckbox
              label="Subscription Creation"
              id="subscription_creation"
              name="subscription_creation"
              enabled={get(props.settings, ['subscription_creation'], false)}
              onChange={(event) => {
                handleSettingsValueChange(
                  'subscription_creation',
                  event.target.checked
                );
              }}
            />
            <div className="form-row">
              <SliderCheckbox
                label="Subscription Renewal Reminder"
                id="renewal_reminder_switch"
                name="renewal_reminder"
                enabled={renewalReminderEnabled}
                onChange={(event) => {
                  handleSettingsValueChange(
                    'renewal_reminder',
                    event.target.checked
                  );
                }}
              />

              {renewalReminderEnabled && (
                <div className="cust-email-row-item">
                  <span className="label email-configurations__renewal-days-before-expiry">
                    Days before expiry
                  </span>
                  <input
                    type="number"
                    id="renewal_reminder_due_days"
                    name="renewal_reminder_due_days"
                    min="1"
                    max="30"
                    onKeyDown={(event) =>
                      event.which === 13 ? event.preventDefault() : null
                    }
                    value={get(props.settings, 'renewal_reminder_due_days', 1)}
                    onChange={(e) =>
                      handleSettingsValueChange(
                        'renewal_reminder_due_days',
                        e.target.value
                      )
                    }
                  />
                  <p className="error-msg">
                    {get(errors, ['renewal_reminder_due_days', '0'], '')}
                  </p>
                </div>
              )}
            </div>
            {renewalReminderEnabled && (
              <TextField
                value={subscriptionUrl || ''}
                label="Subscription Url"
                placeholder="https://example.com"
                onChange={(value) => {
                  if (!userInitiatedActions.current) {
                    userInitiatedActions.current = true;
                  }

                  updateSubscriptionUrl(value);
                }}
                errorMessage={get(errors, ['subscriptionUrl', '0'], '')}
              />
            )}
          </Card>

          <ButtonGroup>
            <Button
              disabled={isSaveButtonDisabled}
              onClick={updateAccountEmailSettings}
              primary
            >
              Save
            </Button>
          </ButtonGroup>

          {showNotification.isNotificationOpen && (
            <StatusMessage
              statusMessage={showNotification.statusMessage}
              status={showNotification.status}
              onCancel={() =>
                updateNotification({
                  isNotificationOpen: false,
                  status: '',
                  statusMessage: ''
                })
              }
            />
          )}
        </>
      )}
    </Layout>
  );
}

function mapStateToProps (state) {
  return {
    settings: state.settings || []
  };
}

function mapDispatchToProps (dispatch) {
  return {
    setSettings: (settings) =>
      dispatch({ type: SET_SETTINGS, settings: settings })
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerEmailNotifications);
