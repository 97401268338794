import React from 'react';
import PropTypes from 'prop-types';

import './Description.scss';

const Description = ({ description, children }) => {
  if (!children) {
    return (<div className="card-description">
      <p className="description ql-editor" dangerouslySetInnerHTML={{ __html: description }} />
    </div>);
  }
  return (<div className="card-description">
    <p className="description">{children}</p>
  </div>);
};

Description.propTypes = {
  description: PropTypes.string,
  children: PropTypes.node
};

export default Description;
