import React from 'react';
import PropTypes from 'prop-types';

const Pencil = ({ width, height, fill }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width={width} height={height} viewBox='0 0 16 16'>
      <defs><path id='a' d='M14.955 4.51l-1.273 1.273-.806-.81-2.665-2.666 1.273-1.274c.22-.22.602-.22.823 0l2.648 2.65a.587.587 0 0 1 0 .828zm-9.772 9.796l-1.42-1.421 7.893-7.904 1.421 1.42-7.894 7.905zm-3.793-2.566l2.873 2.877-3.321.438.448-3.315zm8.195-8.83l1.454 1.455-7.895 7.904-1.452-1.456 7.893-7.903zm5.988.155l-2.647-2.65c-.55-.553-1.51-.553-2.061 0l-1.355 1.355a.762.762 0 0 0-.092.076l-8.65 8.658a.43.43 0 0 0-.123.249l-.642 4.753c-.02.136.029.27.122.364.082.082.195.13.307.13l.058-.004 4.734-.627a.441.441 0 0 0 .254-.136l.006-.004.028-.026.001-.003 8.622-8.63.016-.018 1.421-1.423a1.45 1.45 0 0 0 .428-1.032c0-.39-.151-.756-.427-1.032z' />
      </defs><g fill='none'><mask id='b'><use xlinkHref='#a' /></mask><use fill={fill} xlinkHref='#a' /><path d='M0 0h16v16h-16z' fill={fill} mask='url(#b)' /></g></svg>
  );
};

Pencil.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
};

Pencil.defaultProps = {
  width: '16',
  height: '16',
  fill: '#818A98'
};

export default Pencil;
