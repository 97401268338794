import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Button as EmButton } from '@quintype/em/components/button';

import MetadataFields from '../components/metadata-fields/metadata-fields';
import { ModifiedEmDialog } from '../components/modified-em-dialog/index';
import { getSubscriptionGroup, createOrUpdateSubscriptionGroup, destroySubscriptionGroup, getAcccountConfig } from '../api';
import { SET_SUBSCRIPTION_GROUP, SET_ERRORS } from '../actions';
import {
  Button, TitleBar, Card, Table, CardHeader, ButtonGroup,
  SelectFieldWithLabel, TextFieldWithLabel, TextAreaWithLabel, Link,
  Layout, Alert, StatusMessage, NumberFieldWithLabel, CheckBox, QuillEditorWithLabel
} from '../components/library-components';
import validate from 'validate.js';
import _ from 'lodash-es';
import currencyUtils, { ALL_CURRENCIES } from '../helpers/currencyUtils';
import '../../stylesheets/pages/create_update_subscription_group.scss';
import SubGroupPlanPriceCell from '../components/sub-group-plan-price-cell';
import CustomAttributesCard from '../components/custom-attributes-card';
import UploadImage from '../components/upload-image';

const VISIBILITY = ['Public', 'Private'].map((k) => ({ label: k, value: k === 'Public' }));

function isCampaignClosed (subscriptionGroup) {
  const isCamapign = subscriptionGroup.subscription_type === 'campaign';
  if (isCamapign && !subscriptionGroup.campaign_active) return true;
  return false;
}

export class CreateUpdateSubscriptionGroup extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      isAlertOpen: false,
      isSaveDisabled: true,
      isCampaignEndDialogOpen: false,
      campaignSubscriptionsEnabled: _.get(window.qtConfig, 'campaignSubscriptionsEnabled', false) === 'true',
      groupSubscriptionsEnabled: _.get(window.qtConfig, 'groupSubscriptionsEnabled', false) === 'true',
      showNotification: { isNotificationOpen: false, status: '', statusMessage: '' },
      assetCategories: null,
      isCustomFieldDialogOpen: false,
      savePendingFromMetadataFields: false,
      richTextEnabled: false,
      descriptionText: ''
    };
  }

  componentDidMount () {
    getSubscriptionGroup(this.props.subscriptionGroupId).then(group => {
      this.setState({ targetAmount: currencyUtils.convertToCurrencyUnits(group.target_amount), goal_amount_editable: group.goal_amount_editable });
      this.props.setSubscriptionGroup(group);
      this.oldData = {
        name: group.name,
        description: group.description,
        target_amount: group.target_amount,
        target_amount_validation_enabled: group.target_amount_validation_enabled,
        public: group.public,
        metadata_fields: Array.from(group.metadata_fields),
        currency: group.currency,
        image_s3_key: group.image_s3_key,
        custom_attributes: Array.from(group.custom_attributes)
      };
      this.setState({ descriptionText: group.description });
    });
    try {
      getAcccountConfig().then(config => {
        this.setState({ richTextEnabled: config.account.rich_text_enabled });
      });
    } catch (exception) {
      console.error(exception);
    }
  }

  componentDidUpdate (prevProps) {
    if (this.state.savePendingFromMetadataFields &&
        this.props.subscriptionGroup.metadata_fields.length !== prevProps.subscriptionGroup.metadata_fields.length) {
      this.setState({ savePendingFromMetadataFields: false });
      this.saveOrUpdateGroup(null, false);
    }
  }

  checkIfAnyFieldChanged () {
    if (!this.oldData) return false;
    let hasDataChanged = false;
    Object.keys(this.oldData).forEach(attribute => {
      if (attribute !== 'metadata_fields' && attribute !== 'custom_attributes' && this.oldData[attribute] !== this.props.subscriptionGroup[attribute]) {
        hasDataChanged = true;
      }
      if (attribute === 'metadata_fields') {
        if (this.oldData.metadata_fields.length !== this.props.subscriptionGroup.metadata_fields.length) {
          hasDataChanged = true;
          // https://stackoverflow.com/questions/34653612/what-does-return-keyword-mean-inside-foreach-function/34653650
        }
        this.oldData.metadata_fields.forEach((metadata, index) => {
          if (metadata !== this.props.subscriptionGroup.metadata_fields[index]) {
            hasDataChanged = true;
          }
        });
      }
      if (attribute === 'custom_attributes') {
        if (this.oldData.custom_attributes.length !== this.props.subscriptionGroup.custom_attributes.length) {
          hasDataChanged = true;
        } else {
          this.oldData.custom_attributes.forEach((attr, index) => {
            if (attr !== this.props.subscriptionGroup.custom_attributes[index]) {
              hasDataChanged = true;
            }
          });
        }
      }
    });

    return hasDataChanged;
  }

  updateField (field, value) {
    if (field === 'target_amount') {
      this.setState({ targetAmount: value });
      this.props.setSubscriptionGroup(Object.assign({}, this.props.subscriptionGroup, { [field]: currencyUtils.convertToLowestDenomination(value) }));
    } else {
      this.setState({ isSaveDisabled: false });
      this.props.setSubscriptionGroup(Object.assign({}, this.props.subscriptionGroup, { [field]: value }));
    }
  }

  subscriptionTypeOptions (type) {
    return ({ label: _.capitalize(type), value: type.split(' ').join('_') });
  }

  getSubscriptionTypeOptions () {
    const subscriptionTypes = ['standard'];

    if (this.state.campaignSubscriptionsEnabled) {
      subscriptionTypes.push('campaign');
    }
    if (this.state.groupSubscriptionsEnabled) {
      subscriptionTypes.push('group access');
    }
    return subscriptionTypes.map(x => this.subscriptionTypeOptions(x));
  }

  filterEmptyMetadataAndSave () {
    this.setState({ savePendingFromMetadataFields: true });
    this.updateField('metadata_fields', _.get(this.props.subscriptionGroup, 'metadata_fields').filter(value => value !== ''));
  }

  saveOrUpdateGroup (e, endCampaign = false) {
    if (e) {
      e.preventDefault();
    }
    const constraintsSubscriptionGroup = {
      name: { presence: true },
      description: { presence: true },
      metadata_fields: (fields) => {
        if (fields && fields.filter(field => field && field !== '').length === fields.length) {
          return null;
        }
        return {
          format: {
            message: 'cannot be empty'
          }
        };
      }
    };

    var errors = validate(this.props.subscriptionGroup, constraintsSubscriptionGroup);

    if (errors) {
      this.props.setErrors(errors);
      if (errors.metadata_fields) {
        this.setCustomFieldsDialogState(true);
      }
    } else {
      const subscriptionGroup = Object.assign(
        {},
        this.props.subscriptionGroup,
        endCampaign ? { campaign_active: false } : {}
      );
      delete subscriptionGroup.subscription_plans;

      createOrUpdateSubscriptionGroup(subscriptionGroup).then(
        (response) => {
          window.location = '/subscription_groups/';
        },
        (errors) => {
          this.props.setErrors(errors.error);
          if (errors.error['image']) {
            this.setState({
              showNotification: {
                isNotificationOpen: true,
                status: 'error',
                statusMessage: 'Some image uploads have failed. Kindly reload the page and try again'
              }
            });
          }
        }
      );
    }
  }

  deleteGroup (e) {
    e.preventDefault();
    destroySubscriptionGroup(this.props.subscriptionGroupId)
      .then(() => { (window.location = '/subscription_groups'); });
  }

  openAlert () {
    this.setState({ isAlertOpen: true });
  }

  closeAlert () {
    this.setState({ isAlertOpen: false });
  }

  setEndCampaignDialogState (isCampaignEndDialogOpen) {
    this.setState({
      isCampaignEndDialogOpen
    });
  }

  setCustomFieldsDialogState (isCustomFieldDialogOpen) {
    this.setState({
      isCustomFieldDialogOpen
    });
  }

  createNewPlan () {
    if (this.state.isSaveDisabled) {
      window.location = '/subscription_groups/' + this.props.subscriptionGroupId + '/subscription_plans/new';
    } else {
      this.setState({
        showNotification: {
          isNotificationOpen: true,
          status: 'error',
          statusMessage: 'Please save the data before creating a new plan'
        }
      });
    }
  }

  editLink (subscriptionPlan) {
    return (`/subscription_groups/${this.props.subscriptionGroupId}/subscription_plans/${subscriptionPlan.id}`);
  }

  updateRichTextDescription (value, delta, source) {
    if (source === 'user') {
      this.setState({ descriptionText: value });
      this.updateField('description', value);
    }
  }

  render () {
    const isDisabled = this.props.subscriptionGroupId === 'new' ? false : isCampaignClosed(this.props.subscriptionGroup);
    const emDialogContent = this.state.isCampaignEndDialogOpen && this.checkIfAnyFieldChanged() ? 'We noticed some changes made  in the campaign! Click on cancel if you wish to edit further. Campaigns once ended, cannot be reactivated.'
      : 'Once ended, you cannot reactivate it';
    const metadataErrorsPresent = this.props.errors.metadata_fields && this.props.errors.metadata_fields.length > 0;

    const onFileUpload = (s3key) => {
      this.updateField('image_s3_key', s3key);
    };

    const handleImageTrash = () => {
      this.updateField('image_s3_key', null);
    };

    return (
      <Layout>
        {this.props.subscriptionGroupId === 'new' ? (
          <TitleBar
            title="Add Subscription Group"
            breadcrumbTitle="Subscription Group"
            breadcrumbPath="/subscription_groups"
          />
        ) : (
          <TitleBar
            title="Edit Subscription Group"
            buttonText="Subscriptions"
            button
            buttonUrl={`/analytics_subscriptions?subscription_group_id=${this.props.subscriptionGroupId}`}
            breadcrumbTitle="Subscription Group"
            breadcrumbPath="/subscription_groups"
          />
        )}

        <Card title="Subscription Group Overview">
          <TextFieldWithLabel
            errors={_.get(this.props.errors, ['name', '0'])}
            label="Name"
            placeholder="Subscription Group Name"
            disabled={isDisabled}
            value={this.props.subscriptionGroup.name || ''}
            onChange={e => this.updateField('name', e.target.value)}
            id="subscription-group-name"
          />

          {this.state.richTextEnabled
            ? <QuillEditorWithLabel
              errors={_.get(this.props.errors, ['description', '0'])}
              label="Description"
              placeholder="Description"
              disabled={isDisabled}
              value={this.state.descriptionText}
              onChange={this.updateRichTextDescription.bind(this)}
              id="subscription-group-description"
            />
            : <TextAreaWithLabel
              errors={_.get(this.props.errors, ['description', '0'])}
              label="Description"
              placeholder="Description"
              disabled={isDisabled}
              rows="3"
              value={this.props.subscriptionGroup.description || ''}
              onChange={e => this.updateField('description', e.target.value)}
              id="subscription-group-description"
            />
          }

          {this.props.subscriptionGroupId === 'new' &&
              <div className="form-row">
                <SelectFieldWithLabel
                  label="Group Type"
                  value={this.props.subscriptionGroup.subscription_type}
                  options={this.getSubscriptionTypeOptions()}
                  onChange={e => this.updateField('subscription_type', e.value)}
                  id="subscription-group-catgeory"
                  clearable={false}
                />
              </div>
          }

          {this.props.subscriptionGroup.subscription_type === 'campaign' &&
              <div className='text-and-select'>
                <NumberFieldWithLabel
                  label='Target Amount'
                  value={this.state.targetAmount}
                  onChange={(e) => this.updateField('target_amount', e.target.value)}
                  errors={_.get(this.props.errors, ['target_amount', '0']) || _.get(this.props.errors, ['target_amount', '0'])}
                  placeholder="Enter target amount"
                  dataTest='campaign-target-amount'
                  disabled={!this.state.goal_amount_editable || isDisabled}
                />
                <Select
                  className='select-currency'
                  value={this.props.subscriptionGroup.currency}
                  options={ALL_CURRENCIES}
                  onChange={(m) => m !== null ? this.updateField('currency', m.value) : null}
                  clearable={false}
                  searchable
                  arrowRenderer={currencyUtils.arrowRenderer}
                  autosize={false}
                  disabled={!this.state.goal_amount_editable || isDisabled}
                />
              </div>
          }
          {this.props.subscriptionGroup.subscription_type === 'campaign' &&
            <div className='form-row'>
              <CheckBox
                label="Stop subscriptions if target amount is reached"
                disabled={isDisabled}
                checked={_.get(this.props.subscriptionGroup, 'target_amount_validation_enabled', false)}
                value={_.get(this.props.subscriptionGroup, 'target_amount_validation_enabled', false)}
                onChange={(e) => this.updateField('target_amount_validation_enabled', !!e.target.checked)}
                errors={_.get(this.state.errors, ['target_amount_validation_enabled', '0'])}
              />
            </div>
          }
          <div className="form-row">
            <SelectFieldWithLabel
              label="Visibility"
              value={this.props.subscriptionGroup.public}
              options={VISIBILITY}
              onChange={e => this.updateField('public', e.value)}
              id="subscription-group-public"
              clearable={false}
            />
          </div>

          <UploadImage
            id='group-optional-image'
            onFileUpload={onFileUpload}
            savedImageUrl={_.get(this.props, ['subscriptionGroup', 'image_url'])}
            handleImageTrash={handleImageTrash}
            disabled={isDisabled}
          />
        </Card>

        <MetadataFields
          metadata_fields={this.props.subscriptionGroup.metadata_fields || []}
          onChange={(metadataFields) =>
            this.updateField('metadata_fields', metadataFields)
          }
          isCampaignClosed={isDisabled}
        />

        <CustomAttributesCard
          customAttributes={_.get(this.props, ['subscriptionGroup', 'custom_attributes'], [])}
          onChange={(attributes) =>
            this.updateField('custom_attributes', attributes)
          }
          isCampaignClosed={isDisabled}
        />

        {this.props.subscriptionGroupId !== 'new' && (
          <Card>
            <CardHeader
              dataTest="add_plan"
              title="Subscription Plans"
              buttonText="Add Plan"
              isButtonDisabled={isCampaignClosed(this.props.subscriptionGroup)}
              buttonOnClick={() => this.createNewPlan()}
              isButton={this.props.subscriptionGroupId !== 'new'}
            />

            {this.props.subscriptionGroup.subscription_plans && (
              <Table
                header={['Name', 'Duration', 'Price', '']}
                className="table-default--4cols"
              >
                {this.props.subscriptionGroup.subscription_plans.map(
                  (subscriptionPlan, index) => (
                    <div className="table-default__row" key={index}>
                      <div className="table-default__cell">
                        {subscriptionPlan.title}
                      </div>
                      <div className="table-default__cell">
                        {subscriptionPlan.duration_length}{' '}
                        {subscriptionPlan.duration_unit}
                      </div>
                      <div className="table-default__cell">
                        <SubGroupPlanPriceCell plan={subscriptionPlan} addPlanLink={false} />
                      </div>
                      <div className="table-default__cell">
                        <Link url={this.editLink(subscriptionPlan)}>
                            Edit
                        </Link>
                      </div>
                    </div>
                  )
                )}
              </Table>
            )}
          </Card>
        )}

        <ButtonGroup
          columns={this.props.subscriptionGroupId !== 'new' ? 'two' : ''}
        >
          {this.props.subscriptionGroupId !== 'new' && (
            <div>
              <EmButton
                testId="delete_subscription_group"
                onClick={e => {
                  e.preventDefault();
                  this.openAlert(e);
                }
                }
                type="primary"
                variant="danger"
                classname="em-delete-button"
              >
                Delete Group
              </EmButton>
              {this.props.subscriptionGroup.subscription_type === 'campaign' && <Button
                dataTest="end_campaign_subscription_group"
                disabled={isCampaignClosed(this.props.subscriptionGroup)}
                onClick={e => {
                  this.setEndCampaignDialogState(true);
                }
                }
                destructive
              >
                End Campaign
              </Button>}
            </div>
          )}
          <Button
            dataTest="save_subscription_group"
            disabled={this.state.isSaveDisabled}
            onClick={e => this.saveOrUpdateGroup(e)}
            primary
          >
              Save
          </Button>
        </ButtonGroup>

        <Alert
          title="Delete Subscription Group"
          open={this.state.isAlertOpen}
          destructive
          onCancel={() => this.closeAlert()}
          onConfirm={e => this.deleteGroup(e)}
        >
            Deleted data cannot be recovered. Do you still want to continue?
        </Alert>
        <ModifiedEmDialog
          isOpen={this.state.isCampaignEndDialogOpen}
          onClose={() => {
            this.setEndCampaignDialogState(false);
          }}
          title={'End this Campaign?'}
          content={emDialogContent}
          firstButton={{
            type: 'primary',
            onClick: (e) => {
              this.saveOrUpdateGroup(e, true);
              this.setEndCampaignDialogState(false);
            },
            content: `${this.checkIfAnyFieldChanged() ? 'Save & End' : 'End Campaign'}`,
            variant: 'danger'
          }
          }
          secondButton={{
            type: 'default',
            onClick: (e) => {
              e.preventDefault();
              this.setEndCampaignDialogState(false);
            },
            content: 'Cancel'
          }
          }
        />

        {this.state.isCustomFieldDialogOpen && (
          <ModifiedEmDialog
            isOpen={this.state.isCustomFieldDialogOpen && metadataErrorsPresent}
            onClose={() => {
              this.setState({ isCustomFieldDialogOpen: false });
            }}
            title={'Remove empty custom fields?'}
            content={'Some custom fields were found empty and hence will be removed. Click on "Save" to continue'}
            firstButton={{
              type: 'primary',
              onClick: (e) => {
                this.filterEmptyMetadataAndSave();
              },
              content: 'Save'
            }}
            secondButton={{
              type: 'default',
              onClick: (e) => {
                this.setCustomFieldsDialogState(false);
              },
              content: 'Cancel'
            }}
          />
        )}

        {this.state.showNotification.isNotificationOpen && (
          <StatusMessage
            statusMessage={this.state.showNotification.statusMessage}
            status={this.state.showNotification.status}
            onCancel={() => {
              this.setState({
                showNotification: {
                  isNotificationOpen: false,
                  status: '',
                  statusMessage: ''
                }
              });
            }}
          />
        )}
      </Layout>
    );
  }
}

function mapStateToProps (state) {
  return {
    subscriptionGroup: state.subscriptionGroup || {},
    errors: state.errors || []
  };
}

function mapDispatchToProps (dispatch) {
  return {
    setSubscriptionGroup: (plan) => dispatch({ type: SET_SUBSCRIPTION_GROUP, subscriptionGroup: plan }),
    setErrors: (errors) => dispatch({ type: SET_ERRORS, errors: errors })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdateSubscriptionGroup);
