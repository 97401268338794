import React from 'react';
import _ from 'lodash-es';
import { NumberFieldWithLabel, TextFieldWithLabel } from '../library-components';
import Select, { AsyncSelect } from '@quintype/em/components/select';
import './assets.scss';
import { getSearchCustomAttributes } from '../../api';
import { DateRangePicker } from '@quintype/em/components/date-range-picker';
import getUnixTime from 'date-fns/getUnixTime';

const getOptions = items => {
  if (items) {
    return items.map(k => ({ label: k.name, value: k.id }));
  }
};

const AssetMetaFields = ({ asset, metaFields, updateAssetMetadata, updateAsset, inheritanceCategoryId, assetFrom }) => {
  var assetCategory = _.find(metaFields, ['id', asset.asset_category_id]);
  const metadataFieldName = _.keys(_.get(asset, ['metadata']))[0];
  const value = asset['metadata'][metadataFieldName];
  const options = _.get(assetCategory['metadata'], [metadataFieldName, 'values'], []);
  const valueForSelect = _.find(options, { value: value });
  const publishedAtTo = asset.published_at && asset.published_at.to;
  const publishedAtFrom = asset.published_at && asset.published_at.from;

  const promiseOptions = async inputValue => {
    if (assetCategory['metadata'][metadataFieldName]['search_url']) {
      const url = `${assetCategory['metadata'][metadataFieldName]['search_url']}?q=${inputValue}`;

      const res = await getSearchCustomAttributes(url);
      return res;
    } else {
      return [];
    }
  };

  const updateMagazinePublishedDateType = (value) => {
    if (['since_the_beginning', 'subscription_activation'].includes(value)) {
      updateAsset('published_at', { from: value });
      return;
    }

    updateAsset('published_at', { from: new Date().toISOString(), to: new Date().toISOString() });
  };

  const updateMagazinePublishedDateRange = (fromStamp, toStamp) => {
    updateAsset('published_at', { from: new Date(fromStamp * 1000).toISOString(), to: new Date(toStamp * 1000).toISOString() });
  };

  const magazinePublishedDateOptions = [
    {
      label: 'Anytime',
      value: 'since_the_beginning'
    },
    {
      label: 'Date Range',
      value: 'dateRange'
    },
    {
      label: 'Published since subscription activation',
      value: 'subscription_activation'
    }
  ];

  let magazinePublishedDateValue = 'dateRange';

  if (!publishedAtFrom || publishedAtFrom === 'since_the_beginning') {
    magazinePublishedDateValue = 'since_the_beginning';
  }

  if (publishedAtFrom === 'subscription_activation') {
    magazinePublishedDateValue = 'subscription_activation';
  }

  if (assetCategory) {
    if (!_.isEmpty(assetCategory.metadata) && metadataFieldName) {
      switch (metadataFieldName) {
        case 'access_level':
          return (
            <>
              <NumberFieldWithLabel
                value={Number(value)}
                label='Value'
                placeholder='Access Level Value'
                min={1}
                onChange={e => updateAssetMetadata(metadataFieldName, Number(e.target.value))} />
              <div className='access-level-note'>Note: Access level should be greater than 0</div>
            </>
          );
        case 'id':
          return (
            <>
              <TextFieldWithLabel
                value={value}
                label='Value'
                placeholder='Id'
                onChange={e => updateAssetMetadata(metadataFieldName, e.target.value)} />
            </>
          );
        case 'magazine':
          return (
            <>
              <AsyncSelect
                value={valueForSelect}
                label='Value'
                defaultOptions={options}
                key='magazine'
                loadOptions={promiseOptions}
                onChange={e => updateAssetMetadata(metadataFieldName, e.value)}
              />
              <Select
                label='Issue Published Date'
                value={magazinePublishedDateValue}
                options={magazinePublishedDateOptions}
                onChange={(e) => updateMagazinePublishedDateType(e ? e.value : 'since_the_beginning')}
              />
              {magazinePublishedDateValue === 'dateRange' &&
                <div className="date-range-picker-container">
                  <DateRangePicker
                    dateLabel="Select Date Range"
                    startTimeStamp={getUnixTime(new Date(publishedAtFrom))}
                    endTimeStamp={getUnixTime(new Date(publishedAtTo))}
                    onDateRangeSelect={updateMagazinePublishedDateRange}
                  />
                </div>
              }
            </>
          );
      }
      return (
        <>
          <AsyncSelect
            value={valueForSelect}
            label='Value'
            key={metadataFieldName}
            defaultOptions={options}
            loadOptions={promiseOptions}
            onChange={e => updateAssetMetadata(metadataFieldName, e.value)}
          />
        </>
      );
    } else if (assetCategory.id === inheritanceCategoryId) {
      return (
        <Select
          label='Assets From'
          value={asset.asset_parentable_id || ''}
          options={getOptions(assetFrom)}
          onChange={(e) => updateAsset('asset_parentable_id', e ? e.value : null)}
        />
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default AssetMetaFields;
