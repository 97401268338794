import React from 'react';
import { get } from 'lodash-es';
import classnames from 'classnames';

import { Layout, TitleBar, Card, TextFieldWithLabel, Button, ButtonGroup, StatusMessage } from '../components/library-components';
import OrganizationSubscriptionMembers from './organization_subscription_members';
import AddOrgSubscriptionMember from './add_org_subscription_member';
import AllowedIpsInput from '../components/allowed-ips-input/allowed-ips-input';
import AllowedDomainsInput from '../components/allowed-domains-input/allowed-domains-input';

import { getOrganization, getSubscriptionById, getSubscriptionMembers, deleteMembersFromSubscription, patchSubscriptionForSubscriber } from '../api';
import { getDateFromISO } from '../helpers/dateUtils';
import { DATE } from '../constants';

class OrganizationSubscriptions extends React.Component {
  constructor () {
    super();
    this.state = {
      organization: {},
      subscriptionMembers: [],
      showNotification: {
        isNotificationOpen: false,
        status: '',
        statusMessage: ''
      },
      orgSubscriptions: {}
    };
    this.primaryUser = this.primaryUser.bind(this);
    this.getSubscriptionMembers = this.getSubscriptionMembers.bind(this);
    this.handleAllowedIpsChange = this.handleAllowedIpsChange.bind(this);
    this.handleAllowedIpsSave = this.handleAllowedIpsSave.bind(this);
    this.handleAllowedDomainsChange = this.handleAllowedDomainsChange.bind(this);
    this.handleAllowedDomainsSave = this.handleAllowedDomainsSave.bind(this);
    this.getAmountPaidText = this.getAmountPaidText.bind(this);
  }

  componentDidMount () {
    getOrganization(this.props.organizationId)
      .then(({ organization }) => this.setState({ organization }, () => { this.getOrganizationSubscriptions(); this.getSubscriptionMembers(); }));
  }

  getOrganizationSubscriptions () {
    getSubscriptionById(this.props.subscriptionId)
      .then(({ subscription }) => this.setState({
        orgSubscriptions: subscription
      }));
  }

  getSubscriptionMembers (page = 1) {
    getSubscriptionMembers(this.primaryUser(), this.props.subscriptionId, page)
      .then((response) => {
        const subscriptionMembers = response.members.map(subscriber => {
          const subscriberEmailIdentities = subscriber.subscriber_identities.filter(identity => identity.provider === 'email');
          return { id: subscriber.id, name: get(subscriber, 'name', ''), email: get(subscriberEmailIdentities, [0, 'value'], ''), checked: false, enlistedBy: get(subscriber, ['enlisted_by'], 'Admin') };
        });
        this.setState({ subscriptionMembers, totalPages: response.total_pages, totalEntries: response.total_entries });
      });
  }

  handlePageChange (page) {
    this.getSubscriptionMembers(page);
  }

  primaryUser () {
    return ({ provider: 'email', identity: this.state.organization.primary_email });
  }

  deleteMembers (id) {
    const memberIds = id ? [id] : this.state.subscriptionMembers.filter(member => member.checked).map(member => member.id);
    deleteMembersFromSubscription(this.primaryUser(), this.props.subscriptionId, memberIds)
      .then(() => this.getSubscriptionMembers());
  }

  toggleAllMemberSelection (checked) {
    this.setState({ subscriptionMembers: this.state.subscriptionMembers.map(x => (Object.assign({}, x, { checked }))) });
  }

  toggleMemberSelection (member, i) {
    const subscriptionMembers = this.state.subscriptionMembers;
    subscriptionMembers[i] = (Object.assign({}, member, { checked: !member.checked }));
    this.setState({ subscriptionMembers });
  }

  isAnyMemberSelected () {
    return this.state.subscriptionMembers.some(member => member.checked);
  }

  handleAllowedIpsChange (allowedIps) {
    const updatedSubscription = Object.assign({}, this.state.orgSubscriptions, { allowed_ips: allowedIps });
    this.setState({ orgSubscriptions: updatedSubscription });
  }

  handleAllowedDomainsChange (allowedDomains) {
    const updatedSubscription = Object.assign({}, this.state.orgSubscriptions, { allowed_domains: allowedDomains });
    this.setState({ orgSubscriptions: updatedSubscription });
  }

  handleWhitelistedSave (type) {
    const payload = {};
    payload[type] = get(this.state.orgSubscriptions, [type]);

    patchSubscriptionForSubscriber(
      get(this.state.orgSubscriptions, ['preferred_identity', 'provider']),
      get(this.state.orgSubscriptions, ['preferred_identity', 'value']),
      get(this.state.orgSubscriptions, ['id']),
      payload
    )
      .then(() => {
        this.setState({
          showNotification: {
            status: 'success',
            statusMessage: 'Changes Saved Successfully',
            isNotificationOpen: true
          }
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          showNotification: {
            status: 'success',
            statusMessage: `Could not save the changes: ${error}`,
            isNotificationOpen: true
          }
        });
      });
  }

  handleAllowedIpsSave () {
    this.handleWhitelistedSave('allowed_ips');
  }

  handleAllowedDomainsSave () {
    this.handleWhitelistedSave('allowed_domains');
  }

  getAmountPaidText () {
    if (!this.state.orgSubscriptions.payment_amount) {
      return 0;
    }
    return `${this.state.orgSubscriptions.payment_amount} ${this.state.orgSubscriptions.payment_amount_currency || ''}`;
  }

  getSubscriptionAccessTypeLabel (accessType) {
    switch (accessType) {
      case 'user_limit':
        return 'Domain';
      case 'ip_based':
        return 'IP Address';
      default: return '';
    }
  }

  render () {
    const startDate = this.state.orgSubscriptions['start_timestamp'];
    const endDate = this.state.orgSubscriptions['end_timestamp'];

    const statusClass = classnames('group-subscription-header__status', `group-subscription-header__status--${this.state.orgSubscriptions['status']}`);

    return (
      <Layout>
        {this.state.showNotification.isNotificationOpen &&
          <StatusMessage statusMessage={this.state.showNotification.statusMessage}
            status={this.state.showNotification.status}
            onCancel={() => this.setState({ showNotification: { isNotificationOpen: false, status: '', statusMessage: '' } })} />}
        <TitleBar
          title='Edit subscription'
          breadcrumbTitle={this.state.organization.name}
          breadcrumbPath={`/organizations/${this.props.organizationId}`}
        />
        {this.state.orgSubscriptions &&
          <>
            <Card>
              <h3 className="group-subscription-header">
                <span className="group-subscription-header__title">Subscription Details</span>
                <span className={statusClass}>
                  {this.state.orgSubscriptions['status']}
                </span>
              </h3>
              <div className='form-row'>
                <TextFieldWithLabel
                  label='Subscription group'
                  value={this.state.orgSubscriptions.group_name}
                  dataTest='subscription-group'
                  disabled
                />
                <TextFieldWithLabel
                  label='Subscription plan'
                  value={this.state.orgSubscriptions.plan_name}
                  dataTest='subscription-plan'
                  disabled
                />
              </div>
              <div className='form-row'>
                {startDate && <TextFieldWithLabel
                  label='Start Date'
                  defaultValue={getDateFromISO(startDate, DATE.MONTH_EXP_FMT)}
                  dataTest='start-date'
                  disabled
                />}
                {endDate && <TextFieldWithLabel
                  label='End Date'
                  defaultValue={getDateFromISO(endDate, DATE.MONTH_EXP_FMT)}
                  dataTest='end-date'
                  disabled
                />}
              </div>
              <div className='form-row'>
                <TextFieldWithLabel
                  label='Suscription Type'
                  value={this.state.orgSubscriptions['subscription_type']}
                  dataTest='suscription-type'
                  disabled
                />
                <TextFieldWithLabel
                  label='Plan Occurance'
                  value={this.state.orgSubscriptions['plan_occurrence']}
                  dataTest='plan-occurance'
                  disabled
                />
              </div>
              <div className='form-row'>
                <TextFieldWithLabel
                  label='Access Type'
                  value={this.getSubscriptionAccessTypeLabel(this.state.orgSubscriptions['access_type'])}
                  dataTest='access-type'
                  disabled
                />
                <TextFieldWithLabel
                  label='Amount Paid'
                  value={this.getAmountPaidText()}
                  dataTest='amount-paid'
                  disabled
                />
              </div>
              <div className='form-row'>
                <TextFieldWithLabel
                  label='User limit'
                  value={this.state.orgSubscriptions.access_type === 'user_limit' ? this.state.orgSubscriptions.user_limit : 'Unlimited'}
                  dataTest='user-limit'
                  disabled
                />
              </div>
              {this.state.orgSubscriptions.notes &&
                <TextFieldWithLabel
                  label='Notes'
                  value={this.state.orgSubscriptions.notes}
                  dataTest='notes'
                  disabled
                />}
            </Card>
            {this.state.orgSubscriptions.access_type === 'user_limit' &&
            <>
              <Card title="Add Domains To Be Whitelisted">
                <AllowedDomainsInput
                  allowedDomains={this.state.orgSubscriptions.allowed_domains || []}
                  onDomainsChange={this.handleAllowedDomainsChange}
                />
                <ButtonGroup>
                  <Button
                    primary
                    onClick={() => this.handleAllowedDomainsSave()}
                    dataTest="save-domain-address-btn"
                  >
                    Save
                  </Button>
                </ButtonGroup>
              </Card>
              <AddOrgSubscriptionMember
                primaryUser={this.primaryUser()}
                subscriptionId={this.props.subscriptionId}
                onAddingMember={() => this.getSubscriptionMembers()}
              />
              <OrganizationSubscriptionMembers
                members={this.state.subscriptionMembers}
                deleteMembers={id => this.deleteMembers(id)}
                toggleAllMemberSelection={checked => this.toggleAllMemberSelection(checked)}
                toggleMemberSelection={(member, i) => this.toggleMemberSelection(member, i)}
                someMembersSelected={this.isAnyMemberSelected()}
                pageCount={this.state.totalPages}
                totalEntries={this.state.totalEntries}
                onPageChange={page => this.handlePageChange(page)}
                subscriptionId={this.props.subscriptionId}
                getSubscriptionMembers={this.getSubscriptionMembers}
              />
            </>
            }

            {this.state.orgSubscriptions.access_type === 'ip_based' &&
            <Card title='Add Static IP Addresses'>
              <AllowedIpsInput
                allowedIps={this.state.orgSubscriptions.allowed_ips}
                onIpsChange={this.handleAllowedIpsChange}
              />
              <ButtonGroup>
                <Button
                  primary
                  onClick={() => this.handleAllowedIpsSave()}
                  dataTest='save-ip-address-btn'
                >
                  Save
                </Button>
              </ButtonGroup>
            </Card>
            }
          </>}
      </Layout>
    );
  }
}

export default OrganizationSubscriptions;
