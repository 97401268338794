import React from 'react';
import './RadioButton.scss';

const RadioButton = ({ label, checked, onChange, value, dataTest, disabled = false }) => {
  return (
    <div className='radio-button-wrapper'>
      <input className='radio-button-wrapper__box' disabled={disabled} type='radio' checked={checked} value={value} onChange={onChange} data-test={dataTest} />
      <label>{label}</label>
    </div>
  );
};

export default RadioButton;
