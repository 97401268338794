import React, { useEffect, useState } from 'react';

import { Button, Card, CardHeader, Link } from '../library-components';
import CustomAttributesInspector from './custom-attributes-inspector';
import {
  imagePreviewCdnUrl,
  getFilePreviewUri
} from '../../components/upload-image/utils';
import './custom-attributes-card.scss';

const AttributeValues = ({ attr, previewUrl }) => {
  if (!attr) return null;
  if (attr.type === 'image') {
    const figCaption = attr.value.split('/').pop();
    return (
      <div className="img-caption-wrapper">
        <img src={previewUrl} alt={attr.name} className="custom-attr-img" />
        <figcaption className="image-caption">{figCaption}</figcaption>
      </div>
    );
  }
  return (
    <div>
      <p
        className="attr-value ql-editor"
        dangerouslySetInnerHTML={{ __html: attr.value }}
      />
    </div>
  );
};

const CustomAttributesCard = ({ customAttributes, onChange, isCampaignClosed }) => {
  const [isInspectorActive, setInspectorState] = useState(false);
  const [activeAttributeIndex, setActiveAttributeIndex] = useState(-1);
  const [activeAttribute, setActiveAttribute] = useState({});
  const [attributes, setAttributes] = useState([...customAttributes]);
  const [imageFiles] = useState({});
  const [imagePreviewURLs, setImagePreviewUrl] = useState({});

  useEffect(() => {
    setAttributes([...customAttributes]);
  }, [customAttributes]);

  function setStatesOnUpdateAttributes () {
    setAttributes(attributes);
    setActiveAttributeIndex(-1);
    setActiveAttribute({});
    onChange([...attributes]);
  }

  function closeInspector () {
    setActiveAttributeIndex(-1);
    setActiveAttribute({});
    setInspectorState(false);
  }

  function addCustomAttribute (customAttribute) {
    attributes.push(Object.assign({}, customAttribute));
    setStatesOnUpdateAttributes();
  }

  function updateAttribute (attribute, index) {
    attributes[index] = attribute;
    setStatesOnUpdateAttributes();
  }

  function deleteCustomAttribute (index) {
    attributes.splice(index, 1);
    setStatesOnUpdateAttributes();
  }

  function onEdit (index) {
    setActiveAttributeIndex(index);
    setActiveAttribute({ ...attributes[index] });
    setInspectorState(true);
  }

  function usedAttributeNames () {
    return activeAttributeIndex === -1
      ? attributes.map((a) => a.name.toLowerCase())
      : attributes
        .filter((attr, index) => index !== activeAttributeIndex)
        .map((a) => a.name.toLowerCase());
  }

  function onClickAddAttribute () {
    setActiveAttributeIndex(-1);
    setActiveAttribute({});
    setInspectorState(true);
  }

  function getImagePreviewUrl (attr) {
    if (attr.type !== 'image') return null;
    if (Object.prototype.hasOwnProperty.call(imageFiles, attr.name)) {
      getFilePreviewUri(imageFiles[attr.name])
        .then((dataUri) => {
          if (imagePreviewURLs[attr.name] !== dataUri) {
            setImagePreviewUrl({ ...imagePreviewURLs, [attr.name]: dataUri });
          }
        })
        .catch(error => console.log('file load error: ' + error));
    } else {
      return imagePreviewCdnUrl(attr.value);
    }
    return null;
  }

  return (
    <>
      <Card>
        <CardHeader
          dataTest="custom_attributes_title"
          title="Custom Attributes"
        />
        <div className="static-description">
          Attributes must have a unique name. Attributes can be used directly
          from within Page Builder or fetched via
          &#8220;AccessType.getSubscriptionGroups() function api call&#8221;.
        </div>
        {!isCampaignClosed &&
          <Button
            onClick={() => onClickAddAttribute()}
            customClass="add-custum-attribute-button"
          >
            + Add Attribute
          </Button>
        }
        {attributes && attributes.length > 0 && (
          <div className="attr-wrapper">
            {attributes.map((attr, index) => (
              <div className="attr-container" key={`${attr.name}-${index}`}>
                <div className="attr-content">
                  <div className="attr-name">{attr.name}</div>
                  <AttributeValues
                    attr={attr}
                    previewUrl={
                      getImagePreviewUrl(attr) || imagePreviewURLs[attr.name]
                    }
                  />
                </div>
                <Link dataTest={'edit-custom-attribute'} onClick={() => onEdit(index)}>Edit</Link>
              </div>
            ))}
          </div>
        )}
      </Card>
      <CustomAttributesInspector
        activeAttributeIndex={activeAttributeIndex}
        activeAttributeObj={activeAttribute}
        updateAttribute={updateAttribute}
        isInspectorActive={isInspectorActive}
        onClose={closeInspector}
        addCustomAttribute={addCustomAttribute}
        deleteCustomAttribute={deleteCustomAttribute}
        isNewAttribute={activeAttributeIndex === -1}
        usedAttributeNames={usedAttributeNames()}
        imageFiles={imageFiles}
        disabled={isCampaignClosed}
      />
    </>
  );
};

export default CustomAttributesCard;
