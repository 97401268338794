import React from 'react';
import PropTypes from 'prop-types';

const MoreVertical = ({ width, height, fill, uniqueId }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'
      width={width} height={height}>
      <defs>
        <path d='M8 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z'
          id='aa' />
      </defs>
      <g transform='translate(-6)' fill='none' fillRule='evenodd'>
        <mask id={`more-vertical-${uniqueId}`} fill='#fff'>
          <use xlinkHref='#aa' />
        </mask>
        <use fill='#000' fillRule='nonzero' xlinkHref='#aa' />
        <g mask={`url(#more-vertical-${uniqueId})`} fill={fill}>
          <path d='M0 0h16v16H0z' />
        </g>
      </g>
    </svg>
  );
};

MoreVertical.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  uniqueId: PropTypes.number
};

MoreVertical.defaultProps = {
  width: '4',
  height: '16',
  fill: '#818A98',
  uniqueId: 'b'
};

export default MoreVertical;
