import React from 'react';

const Paypal = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100" height="32" viewBox="0 0 100 32">
    <defs>
      <path id="a" d="M.014 23.415h95.691V.195H.015z"/>
      <path id="c" d="M.014 23.415h95.691V.195H.015z"/>
      <path id="e" d="M.014 23.415h95.691V.195H.015z"/>
      <path id="g" d="M.014 23.415h95.691V.195H.015z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#2790C3" fillRule="nonzero" d="M74.311 14.315c-.288 1.889-1.73 1.889-3.126 1.889h-.794l.557-3.527a.439.439 0 0 1 .434-.37h.364c.95 0 1.846 0 2.309.541.277.324.36.804.256 1.467zm-.607-4.928H68.44a.731.731 0 0 0-.722.617L65.59 23.498a.438.438 0 0 0 .433.507h2.7a.512.512 0 0 0 .506-.432l.603-3.826a.731.731 0 0 1 .722-.617h1.666c3.466 0 5.466-1.678 5.99-5.002.235-1.454.009-2.597-.672-3.397-.748-.88-2.075-1.344-3.835-1.344z"/>
      <path fill="#27346A" fillRule="nonzero" d="M36.777 14.315c-.287 1.889-1.73 1.889-3.125 1.889h-.794l.557-3.527a.439.439 0 0 1 .433-.37h.364c.95 0 1.847 0 2.31.541.276.324.36.804.255 1.467zm-.607-4.928h-5.262a.731.731 0 0 0-.723.617l-2.128 13.494a.439.439 0 0 0 .434.507h2.512c.36 0 .667-.262.723-.617l.574-3.64a.73.73 0 0 1 .722-.618h1.665c3.466 0 5.467-1.678 5.99-5.002.235-1.454.01-2.597-.672-3.397-.748-.88-2.074-1.344-3.835-1.344zM48.387 19.16c-.243 1.44-1.386 2.406-2.844 2.406-.73 0-1.316-.235-1.692-.68-.373-.442-.513-1.07-.395-1.77.227-1.427 1.388-2.425 2.824-2.425.716 0 1.296.237 1.68.686.386.453.538 1.086.427 1.782zm3.512-4.905h-2.52c-.216 0-.4.156-.433.37l-.111.705-.176-.256c-.546-.792-1.762-1.057-2.977-1.057-2.784 0-5.162 2.11-5.625 5.07-.24 1.475.101 2.886.938 3.87.77.905 1.867 1.282 3.174 1.282 2.244 0 3.489-1.442 3.489-1.442l-.113.7a.439.439 0 0 0 .433.508h2.27a.73.73 0 0 0 .722-.617l1.362-8.626a.438.438 0 0 0-.433-.507z"/>
      <path fill="#2790C3" fillRule="nonzero" d="M85.92 19.16c-.243 1.44-1.386 2.406-2.843 2.406-.732 0-1.316-.235-1.693-.68-.372-.442-.513-1.07-.394-1.77.226-1.427 1.388-2.425 2.824-2.425.715 0 1.296.237 1.68.686.385.453.537 1.086.427 1.782zm3.512-4.905h-2.52c-.215 0-.4.156-.433.37l-.11.705-.177-.256c-.546-.792-1.762-1.057-2.976-1.057-2.784 0-5.162 2.11-5.625 5.07-.24 1.475.101 2.886.938 3.87.769.905 1.866 1.282 3.174 1.282 2.244 0 3.488-1.442 3.488-1.442l-.112.7a.439.439 0 0 0 .433.508h2.27c.359 0 .665-.262.722-.617l1.362-8.626a.438.438 0 0 0-.434-.507z"/>
      <g transform="translate(0 4)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a"/>
        </mask>
        <path fill="#27346A" fillRule="nonzero" d="M65.32 10.255h-2.533a.731.731 0 0 0-.605.32l-3.494 5.146-1.48-4.945a.731.731 0 0 0-.7-.521h-2.49c-.301 0-.513.295-.416.58l2.79 8.186-2.624 3.701a.439.439 0 0 0 .358.693h2.53c.24 0 .465-.118.601-.315l8.423-12.157a.439.439 0 0 0-.36-.688" />
      </g>
      <g transform="translate(0 4)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c"/>
        </mask>
        <path fill="#2790C3" fillRule="nonzero" d="M92.402 5.757l-2.16 13.74a.439.439 0 0 0 .434.508h2.172c.36 0 .666-.262.722-.617L95.7 5.894a.439.439 0 0 0-.433-.507h-2.431c-.216 0-.4.157-.434.37" />
      </g>
      <g transform="translate(0 4)">
        <mask id="f" fill="#fff">
          <use xlinkHref="#e"/>
        </mask>
        <path fill="#27346A" fillRule="nonzero" d="M9.97 22.458l.707-4.486.045-.248a.92.92 0 0 1 .91-.777h.562c.937 0 1.797-.1 2.557-.298a6.487 6.487 0 0 0 2.145-.973 5.794 5.794 0 0 0 1.619-1.771c.45-.754.78-1.661.98-2.696.179-.915.212-1.733.1-2.434-.118-.74-.403-1.37-.847-1.876a3.577 3.577 0 0 0-1.022-.79l-.01-.004v-.012c.143-.913.137-1.674-.017-2.33-.154-.656-.467-1.247-.958-1.806C15.725.799 13.878.212 11.25.212H4.033a1.015 1.015 0 0 0-1.002.857L.026 20.125a.599.599 0 0 0 .594.695h4.477l-.004.021-.307 1.95a.524.524 0 0 0 .517.605h3.755a.885.885 0 0 0 .875-.747l.036-.191" mask="url(#f)"/>
      </g>
      <g transform="translate(0 4)">
        <mask id="h" fill="#fff">
          <use xlinkHref="#g"/>
        </mask>
        <path fill="#27346A" fillRule="nonzero" d="M7.397 6.118a.903.903 0 0 1 .892-.763h5.657c.67 0 1.295.044 1.866.136a8.219 8.219 0 0 1 .926.206 5.657 5.657 0 0 1 .996.396c.283-1.806-.002-3.035-.98-4.148C15.68.719 13.737.195 11.25.195H4.033c-.508 0-.94.369-1.02.87L.008 20.123a.62.62 0 0 0 .612.716h4.455l2.322-14.72" mask="url(#h)"/>
      </g>
      <path fill="#2790C3" fillRule="nonzero" d="M17.734 10.093c-.022.138-.046.279-.074.423-.951 4.887-4.208 6.576-8.367 6.576H7.176c-.508 0-.937.37-1.017.872l-1.391 8.824a.542.542 0 0 0 .535.627h3.755a.903.903 0 0 0 .893-.763l.036-.19.708-4.487.045-.248a.903.903 0 0 1 .893-.762h.561c3.639 0 6.487-1.478 7.32-5.753.348-1.786.168-3.277-.752-4.325a3.577 3.577 0 0 0-1.028-.794"/>
      <path fill="#1F264F" fillRule="nonzero" d="M16.738 9.697a8.219 8.219 0 0 0-.925-.206 11.802 11.802 0 0 0-1.867-.136H8.289a.902.902 0 0 0-.892.763L6.194 17.74l-.035.223a1.03 1.03 0 0 1 1.017-.872h2.117c4.16 0 7.416-1.69 8.367-6.576.028-.144.052-.285.074-.423a5.114 5.114 0 0 0-.996-.396"/>
    </g>
  </svg>
);

export default Paypal;
