import React from 'react';
import PropTypes from 'prop-types';

const Search = ({ width, height, fill }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height}>
    <path fill={fill} fillRule='evenodd' d='M6 10a4 4 0 1 1 0-8 4 4 0 0 1 0 8m9.707 4.293l-4.82-4.82A5.968 5.968 0 0 0 12 6 6 6 0 0 0 0 6a6 6 0 0 0 6 6 5.968 5.968 0 0 0 3.473-1.113l4.82 4.82a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414'
    />
  </svg>
);

Search.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
};

Search.defaultProps = {
  width: '16',
  height: '16',
  fill: '#919EAB'
};

export default Search;
