import React from 'react';

const Googlepay = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='74' height='26'>
    <g fill='none'>
      <path fill='#5F6368' d='M31.923 13.204v7.83h-2.492V1.697h6.61a5.989 5.989 0 0 1 4.281 1.674 5.373 5.373 0 0 1 1.788 4.081 5.348 5.348 0 0 1-1.788 4.104c-1.156 1.099-2.583 1.647-4.28 1.646h-4.119v.002zm0-9.126v6.75h4.18a3.3 3.3 0 0 0 2.466-1 3.29 3.29 0 0 0 0-4.726 3.257 3.257 0 0 0-2.466-1.026h-4.18v.002zm15.93 3.294c1.843 0 3.297.49 4.364 1.472 1.066.98 1.598 2.326 1.597 4.036v8.154H51.43v-1.836h-.108c-1.032 1.512-2.405 2.268-4.118 2.268-1.463 0-2.687-.432-3.671-1.296a4.132 4.132 0 0 1-1.477-3.24c0-1.37.52-2.458 1.557-3.266 1.039-.809 2.425-1.214 4.159-1.216 1.48 0 2.699.27 3.656.81v-.568a2.824 2.824 0 0 0-1.029-2.199 3.537 3.537 0 0 0-2.406-.905c-1.392 0-2.494.585-3.305 1.756l-2.196-1.378c1.208-1.728 2.995-2.592 5.362-2.592zm-3.224 9.612a1.982 1.982 0 0 0 .827 1.62 3.047 3.047 0 0 0 1.936.648 3.981 3.981 0 0 0 2.804-1.16c.826-.775 1.239-1.684 1.239-2.727-.777-.617-1.861-.925-3.251-.925-1.013 0-1.857.243-2.533.73-.683.493-1.022 1.093-1.022 1.814zM67.5 7.804l-8.323 19.062h-2.573l3.089-6.67L54.22 7.804h2.71l3.955 9.504h.054l3.847-9.504H67.5z' />
      <path fill='#4285F4' d='M21.85 11.516a13.057 13.057 0 0 0-.191-2.259H11.147v4.278h6.02a5.141 5.141 0 0 1-2.226 3.376v2.777h3.594c2.104-1.933 3.316-4.792 3.316-8.172z' />
      <path fill='#34A853' d='M11.147 22.371c3.008 0 5.54-.984 7.388-2.681l-3.594-2.777c-1 .676-2.288 1.061-3.794 1.061-2.908 0-5.375-1.953-6.258-4.585H1.187v2.862a11.15 11.15 0 0 0 9.96 6.12z' />
      <path fill='#FBBC04' d='M4.89 13.389a6.634 6.634 0 0 1 0-4.252V6.275H1.186a11.075 11.075 0 0 0 0 9.976l3.702-2.862z' />
      <path fill='#EA4335' d='M11.147 4.551a6.063 6.063 0 0 1 4.276 1.667l3.181-3.17A10.73 10.73 0 0 0 11.147.153a11.15 11.15 0 0 0-9.96 6.12L4.89 9.138c.883-2.632 3.35-4.586 6.258-4.586z' />
    </g>
  </svg>
);

export default Googlepay;
