import React from 'react';
import MoreVertical from '../library-components/Icons/MoreVertical';
import withToggle from '../behaviors/with_toggle';
import './table-row-with-action-dropdown.scss';

const Dropdown = ({ actions, classnames, maxWidth }) => {
  const styles = {
    maxWidth: maxWidth
  };
  return (
    <ul className={`dropdown ${classnames}`} style={styles}>
      {actions.map((item, index) =>
        <li className='dropdown__item' key={index} onClick={(e) => item.actionHandler(item.id)} >{item.action}</li>
      )}
    </ul>
  );
};

const TableRow = ({ isToggled, toggle, id, children, dropdownWidth = '10em', dropdownActions, dropdownClassname, showVerticalMenu = true, dataTest }) => {
  return (
    <div className='table-default__row table-row-with-action' data-test={dataTest}>
      {children}
      {showVerticalMenu && <a href='javascript:void(0)' className='table-row-with-action__more-vertical' onClick={toggle}>
        <MoreVertical uniqueId={id} />
        {isToggled && <Dropdown key={id}
          actions={dropdownActions}
          classnames={`table-row-with-action__dropdown ${dropdownClassname}`}
          maxWidth={dropdownWidth} />}
      </a>}

    </div>
  );
};

const TableRowWithActionDropdown = withToggle(TableRow);

export default TableRowWithActionDropdown;
