import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash-es';

import { getAccountAllReports, getDownloadUrl } from '../api';
import { TitleBar, Card, Layout, Link, StatusMessage, Table, Pagination } from '../components/library-components';
import { getDateFromISO } from '../helpers/dateUtils';
import { DATE } from '../constants';

export class CsvReports extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      total_pages: 1,
      total_entries: 0,
      showNotification: { isNotificationOpen: false, status: '', statusMessage: '' },
      subscriptionsReports: []
    };

    this.onPageChange = this.onPageChange.bind(this);
  }

  onPageChange (page) {
    this.loadSubscriptionsReports(page);
  }

  render () {
    return (
      <Layout>
        <TitleBar title="Generated Reports"/>

        <Card title='Reports'>

          <Table header={['Title', 'Duration', 'Generated By', 'Generated at', '']} className='table-default--5cols subscriptions-details'>
            {this.state.subscriptionsReports.map((subscriptionReport, index) =>
              <div key={index} className='table-default__row'>
                <div key={index} data-test={'title' + index} className='table-default__cell truncate-word'>{_.startCase(subscriptionReport.type)}</div>
                <div key={index} data-test={'duration' + index} className='table-default__cell truncate-word'>{ getDateFromISO(subscriptionReport.duration_start, DATE.YEAR_FIRST_FMT) } to { getDateFromISO(subscriptionReport.duration_end, DATE.YEAR_FIRST_FMT) }</div>
                <div key={index} data-test={'generated_by' + index} className='table-default__cell capitalize'>{subscriptionReport.generated_by_user_name || 'Accesstype System'}</div>
                <div key={index} data-test={'generated_at' + index} className='table-default__cell'>{getDateFromISO(subscriptionReport.updated_at, DATE.YEAR_FIRST_SEC_FMT)}</div>
                <div key={index} data-test={'download_reports' + index} className='table-default__cell'>{subscriptionReport['status'] === 'generated' ? <Link iconLeft='download-arrow' onClick={() => this.downloadReport(subscriptionReport.filename)}> Download </Link> : <p>{subscriptionReport['status']}</p>}</div>
              </div>
            )}
          </Table>
          <Pagination
            onPageChange={this.onPageChange}
            pageCount={this.state.total_pages}
            containerClassName='pagination'
            pageRangeDisplayed={0}
            marginPagesDisplayed={1}
            pageClassName='pagination__item'
            breakClassName='pagination__item'
            breakLabel='...'
            totalEnteries={`${this.state.total_entries} rows`}
          />

        </Card>
        {this.state.showNotification.isNotificationOpen &&
          <StatusMessage statusMessage={this.state.showNotification.statusMessage}
            status={this.state.showNotification.status}
            onCancel={() => this.setState({ showNotification: { isNotificationOpen: false, status: '', statusMessage: '' } })} />}
      </Layout>
    );
  }

  loadSubscriptionsReports (pageNum = 1) {
    getAccountAllReports(pageNum).then(response => {
      this.setState({ subscriptionsReports: response.reports, total_pages: response.meta.total_pages, total_entries: response.meta.total_entries });
    });
  }

  componentDidMount () {
    this.loadSubscriptionsReports();
  }

  downloadReport (filename) {
    getDownloadUrl(filename).then(downloadUrl => (window.location.href = downloadUrl));
  }
}

function mapStateToProps (state) {
  return {
  };
}

function mapDispatchToProps (dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CsvReports);
