import React from 'react';

function Icon () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
      <path d="M24 40L8 24 24 8l2.1 2.1-12.4 12.4H40v3H13.7l12.4 12.4z"></path>
    </svg>
  );
}

export default Icon;
