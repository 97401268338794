import React from 'react';
import _ from 'lodash-es';

import { Card, SelectFieldWithLabel, StatusMessage, NumberFieldWithLabel, TextArea, ButtonGroup, Button } from '../components/library-components';

import { getSubscriptionGroups, postPreviewSubscription, postCreateSubscription } from '../api';

import AllowedIpsInput from '../components/allowed-ips-input/allowed-ips-input';
import AllowedDomainsInput from '../components/allowed-domains-input/allowed-domains-input';

class AddOrganizationSubscription extends React.Component {
  constructor () {
    super();
    this.state = {
      subscriptionGroups: [],
      selectedSubscriptionGroup: null,
      selectedSubscriptionPlan: null,
      showNotification: false,
      selectedAccessType: 'user_limit',
      userLimit: '',
      allowedIps: [],
      allowedDomains: [],
      errors: {}
    };
    this.handleAllowedIpsChange = this.handleAllowedIpsChange.bind(this);
    this.handleAllowedDomainsChange = this.handleAllowedDomainsChange.bind(this);
    this.handleUserLimitChange = this.handleUserLimitChange.bind(this);
    this.validateUserLimit = this.validateUserLimit.bind(this);
  }

  componentDidMount () {
    getSubscriptionGroups('group_access')
      .then(subscriptionGroups => this.setState({ subscriptionGroups }));
  }

  makeSelectOptions (arrayOptions) {
    return arrayOptions.map(option => ({ label: option.name || option.title, value: option }));
  }

  getSubscriptionPlans () {
    const plans = _.get(this.state, ['selectedSubscriptionGroup', 'value', 'subscription_plans'], [])
      .filter(plan => !plan.recurring);
    return this.makeSelectOptions(plans);
  }

  createSubscription () {
    const errors = Object.assign({}, this.state.errors);
    if (this.state.selectedAccessType === 'user_limit') {
      const limitError = this.validateUserLimit(this.state.userLimit);
      if (limitError) {
        errors['userLimit'] = limitError;
        this.setState({ errors: errors });
        return;
      }
    }
    const payment = {
      payment_type: 'manual',
      amount_cents: this.state.amountCents,
      amount_currency: this.state.amountCurrency
    };
    const subscription = {
      notes: this.state.notes || '',
      subscription_plan_id: this.state.selectedSubscriptionPlan.value.id,
      payment: payment,
      metadata: { whitelisted_domains: this.props.whiteListedDomains },
      access_type: this.state.selectedAccessType
    };
    if (this.state.selectedAccessType === 'ip_based') {
      subscription['allowed_ips'] = this.state.allowedIps;
    } else {
      subscription['user_limit'] = this.state.userLimit;
      subscription['allowed_domains'] = this.state.allowedDomains;
    }

    postPreviewSubscription('email', this.props.orgPrimaryEmail, { subscription })
      .then(response => postCreateSubscription('email', this.props.orgPrimaryEmail, this.state.selectedSubscriptionPlan.value.id, payment, subscription, response['attempt_token']))
      .then(() => {
        this.props.getOrganizationSubscriptions();
        this.setState({ selectedSubscriptionGroup: null, selectedSubscriptionPlan: null });
      });
  }

  handleAllowedIpsChange (ips) {
    this.setState({
      allowedIps: ips,
      editMode: true
    });
  }

  handleAllowedDomainsChange (domains) {
    this.setState({
      allowedDomains: domains,
      editMode: true
    });
  }

  handleUserLimitChange (limit) {
    const errors = Object.assign({}, this.state.errors);
    const limitError = this.validateUserLimit(limit);
    if (limitError) {
      errors['userLimit'] = limitError;
    } else {
      delete errors['userLimit'];
    }
    this.setState({ userLimit: limit, errors: errors });
  }

  validateUserLimit (limit) {
    if (limit === '0') {
      return 'Sorry! User limit cannot be ‘0’. Please provide a number greater than 0';
    } else if (limit === '' || !parseInt(limit) || parseInt(limit) < 0) {
      return 'Please enter a valid number';
    }
  }

  render () {
    const accessOptions = [
      {
        label: 'Domain',
        value: 'user_limit'
      },
      {
        label: 'IP Address Based',
        value: 'ip_based'
      }
    ];
    if (!this.props.allowAddingSubscription) {
      return (
        <>
          <Card>
            <div
              onClick={() => this.setState({ showNotification: true })}
              style={{ cursor: 'pointer' }}
              data-test-id='add-subscription-btn'
            >
              <b>Add New subscription</b>
            </div>
          </Card>
          {this.state.showNotification && (
            <StatusMessage
              statusMessage='Please save the data before creating new subscription.'
              status='error'
              onCancel={() => { this.setState({ showNotification: false }); }}
            />
          )}
        </>
      );
    }

    return (
      <Card title='Add New subscription'>
        <div className='form-row'>
          <SelectFieldWithLabel
            label='Subscription group'
            value={this.state.selectedSubscriptionGroup}
            options={this.makeSelectOptions(this.state.subscriptionGroups)}
            onChange={e => this.setState({ selectedSubscriptionGroup: e })}
            clearable={false}
          />
          <SelectFieldWithLabel
            label='Subscription plan'
            value={this.state.selectedSubscriptionPlan}
            options={this.getSubscriptionPlans()}
            onChange={e => this.setState({ selectedSubscriptionPlan: e, amountCents: e.value.price_cents, amountCurrency: e.value.price_currency })}
            clearable={false}
            disabled={this.state.selectedSubscriptionGroup === null}
          />
        </div>

        {this.state.selectedSubscriptionPlan && (
          <>
            <div className="access-type-container">
              <div className='form-row'>

                <div className='text-and-select org_page'>
                  <div className='org_page__column-container'>
                    <SelectFieldWithLabel
                      label='Content Access Type'
                      value={this.state.selectedAccessType}
                      options={accessOptions}
                      onChange={e => this.setState({ selectedAccessType: e.value })}
                      clearable={false}
                    />
                  </div>
                  <p className="access_type_note">
                    Content access type can be either <strong>User limit based</strong> or <strong>IP address based</strong>. Select the one you would like to use for this subscription
                  </p>
                </div>
              </div>

              {this.state.selectedAccessType === 'user_limit' &&
                <>
                  <div className='form-row'>
                    <NumberFieldWithLabel
                      label='User Limit'
                      value={this.state.userLimit}
                      onChange={(e) => this.handleUserLimitChange(e.target.value)}
                      errors={_.get(this.state.errors, ['userLimit']) || _.get(this.state.errors, ['userLimit'])}
                      placeholder='Enter user limit'
                      dataTest='subscription-user-limit'
                      min={1}
                    />
                  </div>
                  <AllowedDomainsInput
                    allowedDomains={this.state.allowedDomains}
                    onDomainsChange={this.handleAllowedDomainsChange}
                  />
                </>}

              {this.state.selectedAccessType === 'ip_based' &&
                <AllowedIpsInput
                  allowedIps={this.state.allowedIps}
                  onIpsChange={this.handleAllowedIpsChange}
                />}

            </div>
            <div className='form-row'>
              <div className='text-and-select'>
                <NumberFieldWithLabel
                  label='Amount Paid'
                  onChange={(e) => this.setState({ amountCents: e.target.value * 100 })}
                  placeholder='Amount Paid'
                  dataTest='amount-paid'
                  value={this.state.amountCents / 100}
                />

                <SelectFieldWithLabel
                  className='select-currency'
                  value={this.state.amountCurrency}
                  options={[{ label: this.state.amountCurrency, value: this.state.amountCurrency }]}
                  clearable={false}
                />
              </div>
            </div>
            <TextArea
              label='Notes'
              value={this.state.notes}
              placeholder="Enter subscription's notes"
              onChange={(e) => this.setState({ notes: e.target.value })}
              dataTest='notes'
            />
            <ButtonGroup>
              <Button
                primary
                onClick={() => this.createSubscription()}
                dataTest='save_subscription'
              >
                Save
              </Button>
            </ButtonGroup>
          </>
        )}
      </Card>
    );
  }
}

export default AddOrganizationSubscription;
