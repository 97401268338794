import React from 'react';
import PropTypes from 'prop-types';

import wrapLabelAndError from '../../behaviors/wrap_label_and_error';

import ReactQuill, { Quill } from 'react-quill';
import './quill.snow.css';
import { DESCRIPTION_TOOLBAR_OPTIONS, QUILL_EDITOR_FORMATS } from '../../../constants';
import IndentStyle from './indent-style';

const Alignment = Quill.import('attributors/style/align');

Quill.register(Alignment, true);
Quill.register(IndentStyle, true);

const QuillEditor = ({
  placeholder, value, onChange, dataTest, disabled = false
}) => (
  <ReactQuill
    placeholder={placeholder}
    readOnly={disabled}
    theme="snow"
    modules={DESCRIPTION_TOOLBAR_OPTIONS}
    formats={QUILL_EDITOR_FORMATS}
    value={value}
    onChange={onChange}
    data-test={dataTest}
  />
);

QuillEditor.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  dataTest: PropTypes.string
};

const QuillEditorWithLabel = wrapLabelAndError(QuillEditor);
export default QuillEditorWithLabel;
