import React from 'react';
import _ from 'lodash-es';
import { Card } from '../library-components';
import { Button as EmButton } from '@quintype/em/components/button';
import './metadata-fields.scss';
import TextFieldWithLabel from '../../components/library-components/TextFieldWithLabel/TextFieldWithLabel';
import Trash from '../../pages/Icons/trash';

function immutableSplice (arr, start, deleteCount, ...items) {
  return [...arr.slice(0, start), ...items, ...arr.slice(start + deleteCount)];
}

export default class MetadataFields extends React.Component {
  addNewMetadataField (e) {
    e.preventDefault();
    this.props.onChange(this.props.metadata_fields.concat(''));
  }

  updateMetadataField (index, value) {
    this.props.onChange(_.set(this.props.metadata_fields, index, value));
  }

  deleteMetadataField (e, index) {
    e.preventDefault();
    this.props.onChange(immutableSplice(this.props.metadata_fields, index, 1));
  }

  render () {
    return (
      <Card title="Custom Fields">
        <div className="metadata-fields">
          <p>Fields to be seen and used by the user in the website</p>
          {!this.props.isCampaignClosed && (
            <EmButton
              classname="metadata-fields__button-new"
              onClick={(e) => this.addNewMetadataField(e)}
            >
              + Add Field
            </EmButton>
          )}
          {this.props.metadata_fields.map((metadataField, index) => (
            <div className="form-row" key={index}>
              <div className="metadata-fields__input-group">
                <TextFieldWithLabel
                  disabled={this.props.isCampaignClosed}
                  label={` Metadata ${index + 1} `}
                  value={metadataField}
                  onChange={(e) =>
                    this.updateMetadataField(index, e.target.value)
                  }
                  placeholder="New Metadata Field"
                />

                {!this.props.isCampaignClosed && (
                  <button
                    className="metadata-fields__close-button"
                    onClick={(e) => this.deleteMetadataField(e, index)}
                  >
                    <Trash />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </Card>
    );
  }
}
