import { render } from 'react-dom';
import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducer from '../reducers';
import actionCable from 'actioncable';

import { SubscriptionGroups, Subscriber, AnalyticsSubscriptions, PaymentGateways, SubscriptionAttempts,
  CreateUpdateSubscriptionGroup, CreateUpdateSubscriptionPlan, Coupons, Settings,
  CustomerEmailNotifications, SsoLogin, UsersAndRoles, PayPerAssetGroups, SearchSubscriber,
  PayPerAssetAttempts, AnalyticalReports,
  PaywallSettings, Invoicing, CsvReports, Webhooks, CreateWebhook, Admin, GeneralSettings, AdminEmailNotifications, Organizations, CreateUpdateOrganization, OrganizationSubscriptions, Invoices, CsvGuidelines, Home, CreateAccount, DashBoard } from '../pages';

import PayPerAssetPlan from '../pages/pay_per_asset_plan';

const app = {
  store: createStore(reducer, { config: global.qtConfig }, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()),
  renderSubscriptionGroups: (container) => mountComponent(SubscriptionGroups, container),
  renderCreateOrUpdateSubscriptionGroup: (container, id) => mountComponent(CreateUpdateSubscriptionGroup, container, { subscriptionGroupId: id }),
  renderSubscriber: (container, provider, identity) => mountComponent(Subscriber, container, { provider, identity }),
  renderSubscriberSearch: (container) => mountComponent(SearchSubscriber, container),
  renderHome: (container) => mountComponent(Home, container),
  renderSsoLogin: (container, currentUserId, authUri, pageType, authProvider, token) => mountComponent(SsoLogin, container, { currentUserId, authUri, pageType, authProvider, token }),
  renderAdmin: (container) => mountComponent(Admin, container),
  renderPaymentGateways: (container) => mountComponent(PaymentGateways, container),
  renderSubscriptionAttempts: (container, subscriptionGroupId) => mountComponent(SubscriptionAttempts, container, { subscriptionGroupId: subscriptionGroupId || 'all', cableApp: cableApp }),
  renderCreateOrUpdateSubscriptionPlan: (container, subscriptionGroupId, subscriptionPlanId) => mountComponent(CreateUpdateSubscriptionPlan, container, { subscriptionGroupId, subscriptionPlanId }),
  renderCreateOrUpdatePayPerAssetPlan: (container, subscriptionGroupId, subscriptionPlanId) => mountComponent(PayPerAssetPlan, container, { subscriptionGroupId, subscriptionPlanId }),
  renderCoupons: (container) => mountComponent(Coupons, container),
  renderSettings: (container) => mountComponent(Settings, container),
  renderPayPerAssetGroups: (container, groupId) => mountComponent(PayPerAssetGroups, container, { groupId: groupId }),
  renderCustomerEmailNotifications: (container) => mountComponent(CustomerEmailNotifications, container),
  renderUsersAndRoles: (container) => mountComponent(UsersAndRoles, container),
  renderAnalyticalGraphs: (container) => mountComponent(AnalyticalReports, container),
  renderAnalyticalReports: (container) => mountComponent(CsvReports, container),
  renderPayPerAssetAttempts: (container, payPerAssetGroupId) => mountComponent(PayPerAssetAttempts, container, { payPerAssetGroupId: payPerAssetGroupId, cableApp: cableApp }),
  renderPaywallSettings: (container) => mountComponent(PaywallSettings, container),
  renderInvoicing: (container) => mountComponent(Invoicing, container),
  renderWebhooks: (container) => mountComponent(Webhooks, container),
  renderCreateWebhook: (container, webhookId) => mountComponent(CreateWebhook, container, { webhookId: webhookId }),
  renderGeneralSettings: (container) => mountComponent(GeneralSettings, container),
  renderAdminEmailNotifications: (container) => mountComponent(AdminEmailNotifications, container),
  renderOrganizations: container => mountComponent(Organizations, container),
  renderCreateUpdateOrganization: (container, id) => mountComponent(CreateUpdateOrganization, container, { organizationId: id }),
  renderOrganizationSubscriptions: (container, organizationId, subscriptionId) => mountComponent(OrganizationSubscriptions, container, { organizationId, subscriptionId }),
  renderInvoices: (container) => mountComponent(Invoices, container, { cableApp: cableApp }),
  renderAnalyticsSubscriptions: (container, subscriptionGroupId) => mountComponent(AnalyticsSubscriptions, container, { cableApp: cableApp, subscriptionGroupId: subscriptionGroupId || 'all' }),
  renderCsvGuidelines: container => mountComponent(CsvGuidelines, container),
  renderCreateAccountForm: (container, isAnyExistingAccount) => mountComponent(CreateAccount, container, { isAnyExistingAccount }),
  renderDashBoard: (container) => mountComponent(DashBoard, container, { cableApp })
};

function mountComponent (clazz, container, props = {}) {
  return render(React.createElement(Provider, { store: app.store }, React.createElement(clazz, props)), container);
}

const initialCallbacks = window.qtReady || [];

function currentAccountUserReducer (state = [], action) {
  switch (action.type) {
    case 'CURRENT_ACCOUNT_USER': return action.currentAccountUser;
    default: return state;
  }
}

window.qtReady = {
  push: function (f) {
    f(app);
  },
  currentAccountUserStore: createStore(currentAccountUserReducer)
};

window.actionCableNotifier = {
  websocketServerUrl: window.location.hostname === 'localhost' ? `ws://${window.location.hostname}:3000/cable` : `wss://${window.location.hostname}/cable`
};

const cableApp = {};
cableApp.cable = actionCable.createConsumer(window.actionCableNotifier.websocketServerUrl);

initialCallbacks.forEach(function (f) {
  window.qtReady.push(f);
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('../pages', () => { console.log('Hot Reloading!'); initialCallbacks.forEach((f) => window.qtReady.push(f)); });
}
