import React, { useState, useEffect } from 'react';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

import {
  TitleBar,
  Table,
  Pagination,
  Card,
  Layout,
  StatusMessage,
  SelectAndSearch
} from '../components/library-components';
import SubscriberIdentity from '../components/subscriber-identity';
import { getIdentity } from '../components/subscriber-identity/utils';
import { getSubscribers } from '../api';
import '../../stylesheets/pages/subscriptions.scss';

function getSubscriberIdentity (subscriber) {
  const subscriberIdentities = subscriber['subscriber_identities'];

  return getIdentity(subscriberIdentities);
}

function subscriberRedirectUrl (identity) {
  if (!identity) return null;
  const provider = identity.provider;
  const value = identity.value;
  const url = {
    wordpress: `/subscribers/wordpress/${value}.html`,
    email: `/subscribers/email/${value}.html`,
    quintype: `/subscribers/quintype/${value}.html`,
    phone_number: `/subscribers/phone_number/${value}.html`
  };
  return url[provider];
}

function SearchSubscriber () {
  const [showNotification, setNotification] = useState({
    isNotificationOpen: false,
    status: '',
    statusMessage: ''
  });

  const [subscribers, setSubscribers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);

  function onPageChange (page = 1) {
    getAllSubscribers(page);
  }

  async function getAllSubscribers (page = 1) {
    const result = await getSubscribers(page);
    setSubscribers(result.subscribers);
    setTotalPages(result.total_pages);
    setTotalEntries(result.total_entries);
  }

  useEffect(() => {
    getAllSubscribers();
  }, []);

  function handleRowClick (subscriber) {
    const subscriberIdentity = getSubscriberIdentity(subscriber);
    const url = subscriberRedirectUrl(subscriberIdentity);
    if (url) {
      window.location.href = url;
    }
  }

  return (
    <Layout>
      <TitleBar title="Subscribers" />

      <Card title="Search Subscriber">
        <SelectAndSearch subscriptionGroupId={'all'} />
        <Table
          header={['Name', ['Email /', 'Identity'], ['Created Date', '(DD/MM/YYYY)'], 'Subscriber Status']}
          className="table-default--4cols"
        >
          {subscribers.map((subscriber, index) => {
            const subscriberStatus = subscriber.active ? 'Active' : 'Expired';
            const dateString = subscriber.created_at
              ? format(
                parseISO(subscriber.created_at),
                "dd'/'MM'/'yyyy',' hh:mm aa"
              )
              : '';

            return (
              <div
                key={index}
                className="table-default__row"
                onClick={() => {
                  handleRowClick(subscriber);
                }}
              >
                <div className="table-default__cell truncate-word">
                  {subscriber.name || ''}
                </div>
                <div className="table-default__cell">
                  <SubscriberIdentity subscriber={subscriber} />
                </div>
                <div className="table-default__cell">{dateString}</div>
                <div
                  className={`table-default__cell ${
                    subscriber.active ? 'sub-active' : 'sub-inactive'
                  }`}
                >
                  {subscriberStatus}
                </div>
              </div>
            );
          })}
        </Table>

        <Pagination
          onPageChange={onPageChange}
          pageCount={totalPages}
          containerClassName="pagination"
          pageRangeDisplayed={0}
          marginPagesDisplayed={1}
          pageClassName="pagination__item"
          breakClassName="pagination__item"
          breakLabel="..."
          totalEnteries={`${totalEntries} rows`}
        />
      </Card>

      {showNotification.isNotificationOpen && (
        <StatusMessage
          statusMessage={showNotification.statusMessage}
          status={showNotification.status}
          onCancel={() =>
            setNotification({
              showNotification: {
                isNotificationOpen: false,
                status: '',
                statusMessage: ''
              }
            })
          }
        />
      )}
    </Layout>
  );
}

export default SearchSubscriber;
