const chartEvents = [
  {
    eventName: 'error',
    callback (error) {
      const errorId = error.eventArgs[0].id;
      error.google.visualization.errors.removeError(errorId);
    }
  }
];

export default chartEvents;
