import React from 'react';
import { TextFieldWithLabel, Link } from '../library-components';

function UserForm (props) {
  const { pageType, data, onFormValueChange } = props;
  return <div className="form-fields">
    {
      pageType === 'create_user' && pageType !== 'forgot-password' &&
      <TextFieldWithLabel
        label="Name"
        value={data.name}
        error={data.nameError}
        onChange={e => onFormValueChange('name', e.target.value)}
        type="name"
      />
    }
    {
      pageType !== 'reset-password' &&
      <TextFieldWithLabel
        label="Email"
        value={data.email}
        error={data.emailError}
        onChange={e => onFormValueChange('email', e.target.value)}
        type="email"
      />
    }
    {
      pageType !== 'forgot-password' &&
        <TextFieldWithLabel
          label="Password"
          inputType="password"
          value={data.password}
          error={data.passwordError}
          onChange={e => onFormValueChange('password', e.target.value)}
        />
    }
    {
      pageType === 'reset-password' &&
        <TextFieldWithLabel
          label="Confirm Password"
          inputType="password"
          value={data.confirmPassword}
          error={data.passwordError}
          onChange={e => onFormValueChange('confirmPassword', e.target.value)}
        />
    }
    {
      pageType === 'login' &&
      <p className="forgot--cta">
        <Link external={false} url="/forgot-password">Forgot Password?</Link>
      </p>
    }
  </div>;
}

export default UserForm;
