import React from 'react';
import { Card, Button, ButtonGroup, Alert, CardHeader, TextFieldWithLabel, TextAreaWithLabel, StatusMessage, SliderCheckbox } from '../library-components';
import { getInvoiceSettings, createOrUpdateInvoiceSettings } from '../../api';

export default class InvoiceConfiguration extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showNotification: { isNotificationOpen: false, status: '', statusMessage: '' }
    };
  }

  componentDidMount () {
    getInvoiceSettings()
      .then((invoiceSettings) => this.setState({ invoice_settings: invoiceSettings }));
  }

  updateField (fieldPath, value) {
    this.setState({ invoice_settings: Object.assign({}, this.state.invoice_settings, { [fieldPath]: value }) });
  }

  updateInvoiceSettings (e) {
    e.preventDefault();
    createOrUpdateInvoiceSettings(this.state.invoice_settings)
      .then(() => this.setState({ showNotification: { isNotificationOpen: true, status: 'success', statusMessage: 'Invoice settings saved' } }))
      .catch((response) => this.setState({ showNotification: { isNotificationOpen: true, status: 'error', statusMessage: `Unable to save invoice settings, ${response.errors.message}` } }));
  }

  render () {
    return (this.state.invoice_settings && <Card>
      <CardHeader
        title='Invoice Configuration' />

      <SliderCheckbox label='Enabled Invoicing' name='enable_invoicing'
        enabled={this.state.invoice_settings['invoice_enabled']}
        onChange={(e) => this.updateField('invoice_enabled', e.target.checked)}
        dataTest='enable-invoicing-switch' />

      <div className='form-row'>
        <TextFieldWithLabel
          label='Sequence Prefix'
          onChange={(e) => this.updateField('sequence_prefix', e.target.value)}
          value={this.state.invoice_settings['sequence_prefix']}
          dataTest='invoice-sequence-prefix' />
      </div>

      <TextAreaWithLabel
        label='Template'
        rows={10}
        placeholder='Template HTML goes here'
        value={this.state.invoice_settings['template']}
        onChange={(e) => this.updateField('template', e.target.value)}
        dataTest='invoice-template-text-area' />

      <ButtonGroup>
        <Button primary onClick={(e) => this.updateInvoiceSettings(e)} dataTest='update-invoice-settings'>Update</Button>
      </ButtonGroup>
      {this.state.showNotification.isNotificationOpen &&
      <StatusMessage statusMessage={this.state.showNotification.statusMessage}
        status={this.state.showNotification.status}
        onCancel={() => this.setState({ showNotification: { isNotificationOpen: false, status: '', statusMessage: '' } })} />}
      {this.state.showDeleteConfirm && <Alert title='Delete Tax Rate'
        open
        destructive
        onCancel={() => this.onDeleteCancel()}
        onConfirm={() => this.onDeleteConfirm()}>
          This action will delete the tax rate permanantly. Do you still want to continue?
      </Alert>}

    </Card>) || null;
  }
}
