export const handleEventBehaviour = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

export const sanitizeFileName = (filename) => {
  return filename && (filename).replace(/[:/?#[\]@!$&'()*+,;=<>%{}|\\^`]/g, '');
};

export const getFileName = (savedImageUrl, previewFile) => {
  if (savedImageUrl) {
    const decodedUri = decodeURIComponent(savedImageUrl);
    const uriArr = decodedUri.split('/');
    return uriArr[uriArr.length - 1];
  }

  if (previewFile) {
    return sanitizeFileName(previewFile.name);
  }

  return null;
};

export const getImagePreviewUrl = (savedImageUrl, imagePreviewUrl) => {
  if (savedImageUrl) {
    return savedImageUrl;
  }

  if (imagePreviewUrl) {
    return imagePreviewUrl;
  }

  return null;
};

export const imagePreviewCdnUrl = (imageS3Key) => {
  if (imageS3Key && window.qtConfig) return window.qtConfig.cdnName + imageS3Key;
  return null;
};

export const getFilePreviewUri = (file) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
};
