import _ from 'lodash-es';

const isActiveMenu = menuPath => {
  const path = window.location.pathname;
  const isStartPathSame = _.startsWith(path, menuPath);
  return menuPath === path || isStartPathSame;
};

var navbar = {};
navbar.links = [
  {
    dropdown: true,
    text: 'Analytics',
    icon: 'analytics',
    dataTest: 'analytics-dropdown',
    items: [
      {
        headline: 'Analytics',
        links: [
          {
            linkTo: '/analytics',
            text: 'Dashboard',
            active: isActiveMenu('/analytics'),
            dataTest: 'analytics'
          },
          {
            linkTo: '/analytical_reports',
            text: 'Analytical Reports',
            active: isActiveMenu('/analytical_reports'),
            dataTest: 'analytical_reports'
          },
          {
            linkTo: '/analytics_subscriptions',
            text: 'Subscriptions',
            active: isActiveMenu('/analytics_subscriptions'),
            dataTest: 'analytics_subscriptions'
          },
          ...(_.get(window.qtConfig, 'accountReportsEnabled', 'false') === 'true' ? [{
            linkTo: '/generated_reports',
            text: 'Generated Reports',
            active: isActiveMenu('/generated_reports'),
            dataTest: 'generated_reports'
          }] : []),
          {
            linkTo: '/invoices',
            text: 'Invoices',
            active: isActiveMenu('/invoices'),
            dataTest: 'invoices_page'
          }
        ]
      }
    ]
  },
  {
    dropdown: true,
    text: 'Groups and Plans',
    icon: 'subscription-group',
    dataTest: 'groups-and-plans-dropdown',
    items: [
      {
        headline: 'Groups and Plans',
        links: [
          {
            linkTo: '/subscription_attempts',
            text: 'Attempts',
            active: isActiveMenu('/subscription_attempts'),
            dataTest: 'subscription_attempts'
          },
          {
            linkTo: '/subscription_groups',
            text: 'Manage',
            active: isActiveMenu('/subscription_groups'),
            dataTest: 'subscription_groups'
          }
        ]
      },
      ...(_.get(window.qtConfig, 'displayPayPerAsset', 'false') === 'true' ? [{
        headline: 'Pay Per Asset',
        links: [
          {
            linkTo: '/pay_per_asset_attempts',
            text: 'Attempts',
            active: isActiveMenu('/pay_per_asset_attempts'),
            dataTest: 'pay_per_asset_attempts'
          },
          {
            linkTo: '/pay_per_asset_groups',
            text: 'Pricing',
            active: isActiveMenu('/pay_per_asset_groups'),
            dataTest: 'pay_per_asset_groups'
          }
        ]
      }] : [])
    ]
  },
  {
    dropdown: true,
    text: 'Customers',
    icon: 'customers',
    dataTest: 'customers',
    items: [
      {
        headline: 'Customers',
        links: [
          {
            linkTo: '/subscribers',
            text: 'Subscribers',
            active: isActiveMenu('/subscribers'),
            dataTest: 'subscribers'
          },
          ...(_.get(window.qtConfig, 'groupSubscriptionsEnabled', 'false') === 'true' ? [{
            linkTo: '/organizations',
            text: 'Organizations',
            active: isActiveMenu('/organizations'),
            dataTest: 'organizations'
          }] : [])
        ]
      }
    ]
  },
  {
    dropdown: true,
    text: 'Configure',
    icon: 'configure',
    dataTest: 'configure-dropdown',
    items: [
      {
        headline: 'Configure',
        links: [
          ...(_.get(window.qtConfig, 'meteredPaywallEnabled', 'false') === 'true' ? [{
            linkTo: '/paywall_settings',
            text: 'Metered Wall Settings',
            active: isActiveMenu('/paywall_settings'),
            dataTest: 'paywall_settings'
          }] : []),
          {
            linkTo: '/coupons',
            text: 'Coupons',
            active: isActiveMenu('/coupons'),
            dataTest: 'coupons'
          },
          {
            linkTo: '/integrations/payment_gateways',
            text: 'Payment Gateways',
            active: isActiveMenu('/integrations/payment_gateways'),
            dataTest: 'payment_gateways',
            superUserOnly: true
          },
          {
            linkTo: '/webhooks',
            text: 'Outgoing Webhooks',
            active: isActiveMenu('/webhooks'),
            dataTest: 'webhooks'
          },
          ...(_.get(window.qtConfig, 'invoicingEnabled', 'false') === 'true' ? [{
            linkTo: '/invoicing',
            text: 'Invoicing & Taxes',
            active: isActiveMenu('/invoicing'),
            dataTest: 'tax_rates'
          }] : []),
          {
            linkTo: '/admin_email_notifications',
            text: 'Admin Email Notifications',
            active: isActiveMenu('/admin_email_notifications'),
            dataTest: 'admin_email_notifications'
          },
          {
            linkTo: '/customer_email_notifications',
            text: 'Customer Email Notifications',
            active: isActiveMenu('/customer_email_notifications'),
            dataTest: 'email_configurations'
          }
        ]
      }
    ]
  },
  {
    dropdown: true,
    text: 'Settings',
    icon: 'settings',
    dataTest: 'settings-dropdown',
    items: [
      {
        headline: 'Settings',
        links: [
          {
            linkTo: '/general_settings',
            text: 'General',
            active: isActiveMenu('/general_settings'),
            dataTest: 'general_settings'
          },
          {
            linkTo: '/users_and_roles',
            text: 'Users & Roles',
            active: isActiveMenu('/users_and_roles'),
            dataTest: 'users_and_roles'
          }
        ]
      }
    ]
  }
];

export default navbar;
