import React, { useEffect, useState } from 'react';
import { validateEmail } from '../../helpers/emailUtils';
import { Button } from '@quintype/em/components/button';
import { TextField } from '../library-components';
import { postCreateUpdateAccountUser, searchUser } from '../../api';
import './add-user.scss';

export const AddUser = ({ callbackAfterGrantAccess }) => {
  const [email, setEmail] = useState('');
  const [isEmail, setIsEmail] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [user, setUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
    if (event.target.value.length === 0) {
      setErrorMessage('');
    }
    if (validateEmail(event.target.value)) {
      setIsEmail(true);
    } else setIsEmail(false);
  };

  const onClickGrantAccess = () => {
    if (user != null) {
      postCreateUpdateAccountUser({ user_id: user.id, role: 'user' })
        .then(() => {
          callbackAfterGrantAccess('success');
        }, (error) => callbackAfterGrantAccess('error', error.error.message));
    }
  };

  useEffect(() => {
    if (!isEmail && email.length > 0) {
      setDisableButton(true);
      setErrorMessage('Please enter a valid email id');
    }
    if (isEmail) {
      searchUser(email).then(searchResult => {
        if (searchResult.can_create_user) {
          setDisableButton(false);
          setUser(searchResult.user);
          setErrorMessage('');
        } else {
          setDisableButton(true);
          setErrorMessage(searchResult.message);
        }
      });
    }
  }, [isEmail, email]);

  return (
    <div className="add-user__container">
      <div className="add-user__text_field-container">
        <TextField className="add-user__text_field"
          value={email}
          placeholder="Email (eg: name@example.com)"
          onChange={onChangeEmail} />
      </div>
      <div className="grant-access__button-container">
        <Button type="primary" disabled={disableButton} onClick={onClickGrantAccess}> Grant Access </Button>
      </div>
      {errorMessage && <div className="add-user__error-message-container">
        <p> {errorMessage} </p>
      </div>}
    </div>
  );
};
