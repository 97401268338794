import React from 'react';
import _ from 'lodash-es';
import PropTypes from 'prop-types';

import { getCurrentAccountUserDetails, switchAccount } from '../../../api';

import { Sidebar, Header, Logo, User, VerticalMenu } from '../index';

import { CheckMark } from '@quintype/em/icons/checkmark';
import {
  ContextMenu,
  ContextMenuList,
  ContextMenuItem
} from '@quintype/em/components/context-menu';
import { Caret } from '@quintype/em/icons/caret';

import navbar from './MenuStructure';
import './Layout.scss';

export class Layout extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      accounts: [
        {
          id: parseInt(_.get(window.subConfig, 'currentAccountId', '')),
          name: _.get(window.subConfig, 'currentAccountName', '')
        }
      ],
      activeSubMenu: null,
      isSubMenuActive: false,
      isSideBarVisible: true,
      currentAccountId: parseInt(
        _.get(window.subConfig, 'currentAccountId', '')
      )
    };
    this.rightConatainerRef = null;
    this.onClickMainMenuItem = this.onClickMainMenuItem.bind(this);
    this.handleBrandLogoClick = this.handleBrandLogoClick.bind(this);
    this.handleEscapeKeyPress = this.handleEscapeKeyPress.bind(this);
    this.handleGlobalClick = this.handleGlobalClick.bind(this);
  }

  componentDidMount () {
    window.addEventListener('keydown', this.handleEscapeKeyPress);
    window.addEventListener('click', this.handleGlobalClick);
    getCurrentAccountUserDetails()
      .then(accountUser => {
        this.setState({
          accounts: accountUser.user_accounts,
          accountUser: accountUser,
          superUser: accountUser.role === 'superuser'
        });
      })
      .catch(error => {
        console.log(error);
      });

    this.decideAciveMainMenuWithSubMenu();
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleEscapeKeyPress);
    window.removeEventListener('click', this.handleGlobalClick);
  }

  handleGlobalClick (event) {
    if (
      this.rightConatainerRef &&
      !this.rightConatainerRef.contains(event.target)
    ) {
      this.setState({ isSubMenuActive: false, clickedMainMenu: null });
    }
  }

  handleEscapeKeyPress (event) {
    if (event.key === 'Escape' || event.keyCode === 27) {
      if (this.state.isSubMenuActive) {
        this.setState({ isSubMenuActive: false, clickedMainMenu: null });
      }
    }
  }

  decideAciveMainMenuWithSubMenu () {
    const arrayWithSubMenu = navbar.links.filter(mainObj => mainObj.items !== undefined);
    const activeMainMenuObject = arrayWithSubMenu.find(mainObj => mainObj.items.some(subObj => subObj.links.some(submenu => submenu.active === true)));
    this.setState({
      activeMainMenuButton: activeMainMenuObject ? activeMainMenuObject.icon : null
    });
  }

  onAccountChange (selectedAccountId) {
    if (selectedAccountId !== this.state.currentAccountId) {
      switchAccount(selectedAccountId).then(() => {
        window.location.href = '/subscription_groups';
      });
    }
  }

  handleBrandLogoClick () {
    this.setState({
      isSideBarVisible: !this.state.isSideBarVisible
    });
  }

  onClickMainMenuItem (e, menuObject, dropdown) {
    const activeSubMenuArray = menuObject.items || [];
    if (!dropdown) {
      window.location.href = menuObject['linkTo'];
      this.setState({
        isSubMenuActive: false
      });
    } else {
      this.setState(
        {
          clickedMainMenu: menuObject.icon,
          activeSubMenu: activeSubMenuArray,
          isSubMenuActive: true
        }
      );
    }

    e.preventDefault();
    e.stopPropagation();
  }

  render () {
    return (
      <div className='page-outer'>
        <Header>
          <Logo
            bgColor={true}
            handleBrandLogoClick={() => this.handleBrandLogoClick()}
          />
          <User
            userAvatar={_.get(this.state.accountUser, 'avatar', '')}
            userName={_.get(window.subConfig, 'currentAccountUserName', '')}
            accountName={_.get(window.subConfig, 'currentAccountName', '')}
            accounts={this.state.accounts}
          >
            <ContextMenu
              position={'bottomLeft'}
              icon={<Caret variant='down' width={20} height={20} />}
            >
              <ContextMenuList>
                <h4 className='accounts-headline'>Your Sites</h4>
                {this.state.accounts &&
                  this.state.accounts.map((account, index) => (
                    <ContextMenuItem
                      className='with-border'
                      key={index}
                    >
                      {() => (
                        <a
                          data-test={'account-links'}
                          onClick={() => this.onAccountChange(account.id)}
                          className='accounts-links'
                        >
                          {account.name}
                          { (_.get(this.state.accountUser, 'id', '') === account.id) && <CheckMark/>}
                        </a>
                      )}
                    </ContextMenuItem>
                  ))}
                <div className='line-separator'></div>
                <ContextMenuItem>
                  {() => (
                    <a
                      data-test={'my-profile'}
                      className='links'
                      href='/general_settings'
                    >
                      My Profile
                    </a>
                  )}
                </ContextMenuItem>
                <ContextMenuItem>
                  {() => (
                    <a data-test={'logout'} className='links' href='/logout'>
                      Logout
                    </a>
                  )}
                </ContextMenuItem>
              </ContextMenuList>
            </ContextMenu>
          </User>
        </Header>
        <Sidebar isSideBarVisible={this.state.isSideBarVisible}>
          <VerticalMenu
            isSideBarVisible={this.state.isSideBarVisible}
            isSubMenuActive={this.state.isSubMenuActive}
            activeMainMenuButton={this.state.activeMainMenuButton}
            onClickMainMenuItem={this.onClickMainMenuItem}
            activeSubMenu={this.state.activeSubMenu}
            clickedMainMenu={this.state.clickedMainMenu}
            {...navbar}
            superUser={this.state.superUser}
          />
        </Sidebar>
        <RightContainer rightConatainerRef={ref => {
          this.rightConatainerRef = ref;
        }}>{this.props.children}</RightContainer>
      </div>
    );
  }
}

const RightContainer = ({ rightConatainerRef, children }) => {
  return <div ref={rightConatainerRef} className='right-container' >
    <div className='main-content'>{children}</div>
  </div>;
};

Layout.propTypes = {
  children: PropTypes.node
};

export default Layout;
